import React, { useState, useEffect, useRef } from "react";
import { Modal, Form, Button, Dropdown, Card, Media } from "react-bootstrap";
import Axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const SwapTeamModal = ({ show, onHide, currentTeam = {}, leagueTeams = [], switchingTeamForId, game = null, league }) => {
  const { id } = useParams();

  const timeout = useRef();
  const [teamsQuery, setTeamsQuery] = useState("");
  const [teamResults, setTeamResults] = useState([]);
  const [teamResultsLoading, setTeamResultsLoading] = useState(false);

  const [newTeam, setNewTeam] = useState(false);

  // alter team results on query change
  useEffect(() => {
    if (!teamsQuery.length && teamResults.length > 0) {
      setTeamResults([]);
    } else if (!teamsQuery.length) {
      return;
    } else {
      setTeamResultsLoading(true);

      clearTimeout(timeout.current);
      timeout.current = setTimeout(() => {
        Axios.post(`${process.env.REACT_APP_CORE_API}/api/teams/searchByGame`, { query: teamsQuery, game: game._id || null }).then(
          ({ data }) => {
            const withoutLeagueTeams = data.filter(({ _id }) => !leagueTeams?.map(({ team }) => team?._id).includes(_id));

            setTeamResults(withoutLeagueTeams);
            setTeamResultsLoading(false);
          }
        );
      }, 500);
    }
  }, [teamsQuery, leagueTeams]);

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      await Axios.post(`${process.env.REACT_APP_CORE_API}/api/leagues/${league._id}/matches/swapTeam`, {
        switchingTeamForId,
        newTeamId: newTeam,
      });

      toast.success("Successfully swap team! Refreshing data...");

      // A lot of shit changed. Refreshing the page is the easiest way to avoid glitches here
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (e) {
      toast.error("There was a problem switching team");
    }
  };

  const onSelectNewTeam = (team) => {
    setNewTeam(team);
    setTeamsQuery("");
    setTeamResults([]);
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Swap Team</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-secondary">Swap DELETED team with another team will replace in all matches within this league.</p>

        <Form onSubmit={onSubmit}>
          {/* <Form.Group>
            <Form.Label>Current Team</Form.Label>
            <Form.Control disabled readOnly value={currentTeam.name} />
          </Form.Group> */}

          <Form.Group>
            <Form.Label>Replacement Team</Form.Label>
            <Form.Control autoFocus placeholder="Search for teams" onChange={(e) => setTeamsQuery(e.target.value)} value={teamsQuery} />
            {(teamResultsLoading || !!teamResults.length) && (
              <Card bg="light">
                <ul className="list-unstyled">
                  {teamResultsLoading ? (
                    <Dropdown.Item className="text-secondary">Loading...</Dropdown.Item>
                  ) : (
                    teamResults.map((team) => (
                      <Dropdown.Item key={team._id} onClick={() => onSelectNewTeam(team)}>
                        {team.name}
                      </Dropdown.Item>
                    ))
                  )}
                </ul>
              </Card>
            )}
          </Form.Group>

          {newTeam && (
            <Media className="mb-3">
              <img
                width={25}
                height={25}
                className="mr-3"
                src="https://leagues.gg/storage/images/teams/avatars/AqkDWdBkgZ75Z382LxZXPCf6OtyVx7SxFdCh594D.png"
                alt="Generic placeholder"
              />
              <Media.Body>
                <span>{newTeam.name}</span>
              </Media.Body>
            </Media>
          )}

          <Button type="submit" size="lg" variant="success" disabled={!newTeam} block>
            Switch
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default SwapTeamModal;
