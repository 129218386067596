var Base = require('tournament')
  , $ = require('interlude');

const WB = 1
    , LB = 2
    , WO = -1;

// Id class - so each Id has an automatic string representation
function Id(bracket, round, match) {
  if (!(this instanceof Id)) {
    return new Id(bracket, round, match);
  }
  this.s = bracket;
  this.r = round;
  this.m = match;
}
Id.prototype.toString = function () {
  return (this.s === WB ? 'WB' : 'LB') + ' R' + this.r + ' M' + this.m;
};

// ------------------------------------------------------------------
// Initialization helpers
// ------------------------------------------------------------------


// shortcut to create a match id as Koth tourneys are very specific about locations
var gId = function (b, r, m) {
  return new Id(b, r, m);
};

// helpers to initialize Koth tournaments
// http://clux.org/entries/view/2407
var evenSeed = function (i, p) {
  var k = Math.floor(Math.log(i) / Math.log(2))
    , r = i - Math.pow(2, k);
  if (r === 0) {
    return Math.pow(2, p - k);
  }
  var nr = (i - 2*r).toString(2).split('').reverse().join('');
  return (parseInt(nr, 2) << p - nr.length) + Math.pow(2, p - k - 1);
};


// make ALL matches for a Koth elimination tournament
var elimination = function (size) {
  var matches = [];
  // first WB round to initialize players
  for (var i = size; i >= 1; i -= 1) {
    if(i == size || i == size-1){
        if(i == size){
            matches.push({ id: gId(WB, 1, 1), p: [i, i-1] });
        }
    }else{
        matches.push({ id: gId(WB, size-i, 1), p: [0, i] });
    }
  }


  return matches.sort(Base.compareMatches); // sort so they can be scored in order
};

// ------------------------------------------------------------------
// progression helpers - assume instance context
// ------------------------------------------------------------------

// find the match and position a winner should move "right" to in the current bracket
var right = function (id) {
  var b = id.s
    , r = id.r
    , g = id.m
    , p = this.p;



  // cases where progression stops for winners
  var isFinalSe = (this.last === WB && r === p)
    , isFinalDe = (this.last === LB && b === LB && r === 2*p)
    , isBronze = (this.last === WB && b === LB)
    , isShortLbGf = (b === LB && r === 2*p - 1 && !this.isLong);


  if (isFinalSe || isFinalDe || isBronze || isShortLbGf) {
    return null;
  }

//   // special case of WB winner moving to LB GF G1
//   if (this.last >= LB && b === WB && r === p) {
//     return [gId(LB, 2*p - 1, 1), 0];
//   }

  // for LB positioning
  var ghalf = (b === LB && $.odd(r)) ? g : Math.floor((g + 1) / 2);

  var pos;
  if (b === WB) {
    pos = (g + 1) % 2; // normal WB progression
  }

  // normal progression

  return [gId(b, r + 1, ghalf), pos];
};




// given a direction (one of the above two), move an 'advancer' to that location
var playerInsert = function (progress, adv) {
  if (progress) {
    var id = progress[0]
      , pos = progress[1]
      , insertM = this.findMatch(id);

    if (!insertM) {
    // End of the tournament
      return
    }

    insertM.p[pos] = adv;
  }
};

// helper to initially score matches with walkovers correctly
var woScore = function (progressFn, m) {
  var idx = m.p.indexOf(WO);
  if (idx >= 0) {
    // set scores manually to avoid the `_verify` walkover scoring restriction
    m.m = (idx === 0) ? [0, 1] : [1, 0];
    progressFn(m);
  }
};  

// ------------------------------------------------------------------
// statistics helpers
// ------------------------------------------------------------------

var lbPos = function (p, maxr) {
  // model position as y = 2^(k+1) + c_k2^k + 1
  // where k(maxr) = floor(roundDiff/2)
  // works upto and including LB final (gf players must be positioned manually)
  var metric = 2*p - maxr;
  var k = Math.floor(metric/2) - 1; // every other doubles
  if (k < 0) {
    throw new Error('lbPos model works for k>=0 only');
  }
  var ck = Math.pow(2, k) * (metric % 2);
  return Math.pow(2, k + 1) + 1 + ck;
};

var wbPos = function (p, maxr) {
  // similar but simpler, double each round, and note that ties are + 1
  // works up to and including semis (WBF + BF must be positioned manually)
  return Math.pow(2, p - maxr) + 1;
};

var placement = function (last, p, maxr) {
  return (last === LB) ? lbPos(p, maxr) : wbPos(p, maxr);
};

// ------------------------------------------------------------------
// Interface
// ------------------------------------------------------------------

var Koth = Base.sub('Koth', function (opts, initParent) {
  this.isLong = opts.isLong; // isLong for WB => hasBF, isLong for LB => hasGf2
  this.last = opts.last;
  this.limit = opts.limit;
  this.downMix = opts.downMix;
  this.p = Math.ceil(Math.log(this.numPlayers) / Math.log(2));
  initParent(elimination(this.numPlayers, this.p, this.last, this.isLong));

  // manually progress WO markers
  var scorer = woScore.bind(null, this._progress.bind(this));
  this.findMatches({s: WB, r: 1}).forEach(scorer);
//   if (this.last > WB) {
//     this.findMatches({s: LB, r: 1}).forEach(scorer);
//   }
});

// ------------------------------------------------------------------
// Static helpers and constants
// ------------------------------------------------------------------

Koth.configure({
  defaults: function (np, o) {
    o.isLong = !o.short;
    o.last = o.last || WB;
    o.limit = o.limit | 0;
    o.downMix = Boolean(o.downMix && o.last > WB);
    return o;
  },

  invalid: function (np, opts) {
    if (np < 4 || np > 1024) {
      return 'numPlayers must be >= 4 and <= 1024';
    }
    if ([WB, LB].indexOf(opts.last) < 0) {
      return 'last elimination bracket must be either WB or LB';
    }
    if (opts.limit) {
      return 'limits not yet supported';
    }
    return null;
  }
});

var consts = {WB: WB, LB: LB, WO: WO};
Object.keys(consts).forEach(function (key) {
  Object.defineProperty(Koth, key, {
    enumerable: true,
    value: consts[key]
  });
});

Koth.attachNames = function (fn) {
  Koth.prototype.roundName = function (partialId) {
    return fn(consts, this.last, this.p, partialId);
  };
};

// ------------------------------------------------------------------
// Expected methods
// ------------------------------------------------------------------

Koth.prototype._progress = function (m) {
    var inserter = playerInsert.bind(this);
    var w = (m.m[0] > m.m[1]) ? m.p[0] : m.p[1]
    if(! this.right(m.id)){
        // console.log([gId(1, m.id.r+1, 1), 0], w)
        inserter([gId(1, m.id.r+1, 1), 0], w); 
    }else{
        inserter(this.right(m.id), w);
    }
};

Koth.prototype._verify = function (m, score) {
  if (m.p[0] === WO || m.p[1] === WO) {
    return "cannot override score in walkover'd match";
  }
  if (score[0] === score[1]) {
    return 'cannot draw a Koth';
  }
  return null;
};

Koth.prototype._safe = function (m) {
  // ensure matches [right, down, down ∘ right] are all unplayed (ignoring WO)
  var r = this.right(m.id)
    , d = this.down(m.id)
    , rm = r && this.findMatch(r[0])
    , dm = d && this.findMatch(d[0])
    , dr = dm && this.right(dm.id) // right from down
    , drm = dr && this.findMatch(dr[0]);

  return [rm, dm, drm].every(function (next) {
    // safe iff (match not there, or unplayed, or contains WO markers)
    return !next || !next.m || next.p[0] === WO || next.p[1] === WO;
  });
};

Koth.prototype._early = function () {
  var gf1 = this.matches[this.matches.length - 2];
  return this.isLong && this.last === LB && gf1.m && gf1.m[0] > gf1.m[1];
};

Koth.prototype._stats = function (res, g) {
  var isLong = this.isLong
    , last  = this.last
    , p = this.p
    , isBf = isLong && last === WB && g.id.s === LB
    , isWbGf = last === WB && g.id.s === WB && g.id.r === p
    , isLbGfs = last === LB && g.id.s === LB && g.id.r >= 2*p - 1
    , isLongSemi = isLong && last === WB && g.id.s === WB && g.id.r === p-1
    , canPosition = !isBf && !isWbGf && !isLbGfs && !isLongSemi
    , maxr = (g.id.s < last) ? this.down(g.id, false)[0].r : g.id.r;

  // position players based on reaching the match
  g.p.filter($.gt(0)).forEach(function (s) {
    Base.resultEntry(res, s).pos = canPosition ?
      placement(last, p, maxr): // estimate from minimally achieved last round
      2 + Number(isBf || isLongSemi)*2; // finals are 2 or 4 initially
  });

  // compute stats for played matches - ignore WOs (then p found in next)
  if (g.p.indexOf(WO) < 0 && g.m) {
    // when we have scores, we have a winner and a loser
    var p0 = Base.resultEntry(res, g.p[0])
      , p1 = Base.resultEntry(res, g.p[1])
      , w = (g.m[0] > g.m[1]) ? p0 : p1;

    // inc wins
    w.wins += 1;
    p0.for += g.m[0];
    p1.for += g.m[1];
    p0.against += g.m[1];
    p1.against += g.m[0];

    // bump winners of finals
    var wbWinnerWon = p0.seed === w.seed;
    var isConclusiveLbGf = isLbGfs && (g.id.r === 2*p || !isLong || wbWinnerWon);
    if (isBf || isWbGf || isConclusiveLbGf) {
      w.pos -= 1;
    }
  }
  return res;
};

// exposed helpers - extras
Koth.prototype.right = right;

Koth.Id = Id;
module.exports = Koth;
