import React, { useState, useEffect, useCallback, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { Breadcrumb, Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faListAlt } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import TournamnetCreateEditModal from "./includes/TournamentCreateEditModal";
import SingleTournament from "./includes/SingleTournament";
import TournamentReorderModal from "./includes/TournamentsReorderModal";
import { AppContext } from "../../../../context/AppContext";

const SeasonTournaments = () => {
  const { user } = useContext(AppContext);
  const { id } = useParams();
  const [season, setSeason] = useState({});
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [showReorderModal, setShowReorderModal] = useState(false);
  const [deletingLeague, setDeletingLeague] = useState(false);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/seasons/${id}`).then(({ data }) => {
      setSeason(data);
      setLoading(false);
    });
  }, [id]);

  const setLeagues = useCallback((callback) => {
    setSeason((season) => ({
      ...season,
      tournaments: callback(season.tournaments),
    }));
  }, []);

  const onCreateLeague = () => {
    setModalData(false); // ensure modal data is always blank!
    setShowModal(true);
  };

  const onEditLeague = (tournamentData) => {
    setModalData(tournamentData);
    setShowModal(true);
  };

  const onRemoveLeague = async (id) => {
    setDeletingLeague(id);

    if (window.confirm("Are you sure you want to delete this tournament? This action is irreversible!")) {
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/tournaments/${id}?withMatches=true`).then(({ data }) => {
        data.matches &&
          data.matches.forEach((el) => {
            Axios.delete(`${process.env.REACT_APP_CORE_API}/api/matches/${el._id}`);
          });
      });
      await Axios.delete(`${process.env.REACT_APP_CORE_API}/api/tournaments/${id}`);
      setLeagues((leagues) => leagues.filter(({ _id }) => _id !== id));
    }

    setDeletingLeague(false);
  };

  return (
    <div>
      <Breadcrumb>
        {!loading ? (
          <>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/games" }}>
              {season.game.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/games/${season.game._id}/groups` }}>
              {season.group.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/games/groups/${season.group._id}/seasons` }}>
              {season.name} {season?.year}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Tournaments</Breadcrumb.Item>
          </>
        ) : (
          <Breadcrumb.Item active>Loading...</Breadcrumb.Item>
        )}
      </Breadcrumb>

      <div className="admin-page-title">
        <h2>Tournaments</h2>

        <div>
          {!loading && !!season.leagues.length && (
            <Button variant="secondary" className="mr-2" onClick={() => setShowReorderModal(true)}>
              <FontAwesomeIcon icon={faListAlt} /> Reorder
            </Button>
          )}

          <Button variant="success" onClick={onCreateLeague} disabled={!user.state.admin && !user.state.permissions.user.seasons}>
            <FontAwesomeIcon icon={faPlus} /> Create
          </Button>
        </div>
      </div>

      {loading ? (
        "Loading"
      ) : !season.tournaments.length ? (
        'No tournaments to show. Click the "Create" button to create one.'
      ) : (
        <div>
          <Row>
            {season.tournaments.map((tournament) => (
              <Col xl={4} lg={6} md={6} sm={12} key={tournament._id}>
                <SingleTournament
                  tournament={tournament}
                  onEditLeague={onEditLeague}
                  deletingLeague={deletingLeague}
                  onRemoveLeague={onRemoveLeague}
                  creationPermission={!user.state.admin ? user.state.permissions.user.setting && user.state.permissions.user.seasons : true}
                />
              </Col>
            ))}
          </Row>

          <TournamentReorderModal
            show={showReorderModal}
            onHide={() => setShowReorderModal(false)}
            leagues={season.tournaments}
            setLeagues={(g) => setLeagues(g)}
          />
        </div>
      )}

      <TournamnetCreateEditModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        data={modalData}
        setLeagues={(g) => setLeagues(g)}
      />
    </div>
  );
};

export default SeasonTournaments;
