import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Axios from "axios";
import { useParams } from "react-router-dom";
import { countries } from "../../../../../utils/countries";

const GroupCreateEditModal = ({ show, handleClose, data = {}, setGroups }) => {
  const { id: game } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState(data.country?.code);
  const [nationalities, setNationalities] = useState([]);

  useEffect(() => {
    // Axios.get("https://restcountries.eu/rest/v2/all?fields=demonym;alpha2Code", { headers: false }).then(({ data }) => {
    //   setNationalities(
    //     data
    //       .filter(({ demonym }) => demonym)
    //       .filter((v, i, a) => a.findIndex((t) => t.demonym === v.demonym) === i)
    //       .sort((a, b) => a.demonym.localeCompare(b.demonym))
    //   );
    //   setCountry(data.country?.code);
    // });
    setNationalities(
      countries
        .filter(({ demonym }) => demonym)
        .filter((v, i, a) => a.findIndex((t) => t.demonym === v.demonym) === i)
        .sort((a, b) => a.demonym.localeCompare(b.demonym))
    );
    setCountry(data.country?.code);
  }, []);
  //  Change

  const onSubmit = async (formData) => {
    setLoading(true);

    if (country) {
      const nationality = nationalities.find((data) => data.alpha2Code === country);
      formData = { ...formData, country: { code: country, name: nationality.demonym } };
    }

    if (data) {
      // Edit
      const { data: resData } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/gameGroups/${data._id}`, formData);

      // update group in parent state
      setGroups((groups) => groups.map((group) => (group._id === data._id ? resData.updatedGroup : group)));
    } else {
      // Create
      const { data: resData } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/gameGroups`, { game, ...formData });

      // Update parent state
      setGroups((groups) => [...groups, resData.group]);
    }

    setLoading(false);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{data ? "Edit" : "Create"} Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              ref={register({ required: "Required" })}
              type="text"
              id="name"
              name="name"
              defaultValue={data.name}
              isInvalid={errors.name}
            />
            <Form.Control.Feedback type="invalid">{errors.name && errors.name.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>Country</Form.Label>
            <div className="d-flex align-items-center">
              <Form.Control
                as="select"
                ref={register({ required: true })}
                id="country"
                name="country"
                defaultValue={data.country?.code}
                onChange={(e) => setCountry(e.target.value)}
                isInvalid={errors.country}
              >
                <option value="">Select country</option>
                {nationalities.map(({ alpha2Code, demonym }) => (
                  <option key={alpha2Code} value={alpha2Code}>
                    {demonym}
                  </option>
                ))}
              </Form.Control>
              {!!country && <img src={`https://www.flagcdn.com/128x96/${country.toLowerCase()}.png`} width={32} height={32} alt={country} className="ml-2" />}
            </div>
            <Form.Control.Feedback type="invalid">{errors.country && errors.country.message}</Form.Control.Feedback>
          </Form.Group>

          <Button type="submit" variant="success" className="float-right" disabled={loading}>
            {loading ? "Processing..." : data ? "Edit" : "Create"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default GroupCreateEditModal;
