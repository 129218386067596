import React from "react";
import { Modal, Card, Nav } from "react-bootstrap";
import { useState } from "react";
import Players from "./tabs/Players";

const GameConfigureModal = ({ show, setShow, data = {}, setGames }) => {
  const [activeTab, setActiveTab] = useState("Player Types");

  const { configuration = {} } = data;

  const tabs = [
    {
      name: "Player Types",
      content: <Players playerTypes={configuration.playerTypes} data={data} setShow={setShow} setGames={setGames} />,
    },
    { name: "test", content: "test" },
  ];

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header>
        <Modal.Title>Configure {data.shortName}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: 0 }}>
        <Card style={{ borderRadius: 0, border: 0, margin: 0 }}>
          <Card.Header>
            <Nav variant="tabs" activeKey={activeTab} onSelect={setActiveTab}>
              {tabs.map(({ name }) => (
                <Nav.Item key={name}>
                  <Nav.Link eventKey={name}>{name}</Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Card.Header>
          <Card.Body>{tabs.map(({ name, content }) => name === activeTab && <div key={name}>{content}</div>)}</Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
};

export default GameConfigureModal;
