import React, { useState, useRef, useEffect } from "react";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import Axios from "axios";
import { toast } from "react-toastify";
import { Modal, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo, faRedo } from "@fortawesome/free-solid-svg-icons";

const EditVideoModal = ({ show, onHide, name, width, video=null, crop = true, rotate = true, borderRadius, afterImageUpload }) => {
  const editor = useRef();
  const [image, setImage] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [scale, setScale] = useState(1);
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState(null);


  const handleDrop = (dropped) => setImage(dropped[0]);
  const clearImage = () => setImage(false);
  const handleScale = (e) => setScale(parseFloat(e.target.value));
  const rotateLeft = () => setRotation(rotation - 90);
  const rotateRight = () => setRotation(rotation + 90);



  const submitFile = async (e) => {
    setUploading(true);
    e.preventDefault();
    try {
      if (!file) {
        setUploading(false);
        throw new Error('Select a file first!');
       
      }
      const formData = new FormData();
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      formData.append('video', file[0]);
      
      Axios.post(`${process.env.REACT_APP_CORE_API}/api/upload/video`, formData, config)
        .then(({ data }) => {
          afterImageUpload(data.file);
          setUploading(false);
        })
        .catch((err) => {
          const msg = err.response.data ? err.response.data.msg : "There was a problem uploading your file";
          toast.error(msg);
          setUploading(false);
        });


    } catch (error) {
      // handle error
      setUploading(false);
      toast.error(String(error));
    }
  };


  useEffect(() => {
    clearImage();
    setUploading(false);
  }, [show]);

  useEffect(() => {
    setRotation(0);
    setScale(1);
  }, [image]);

  return (
    <Modal show={show} onHide={onHide} size={width > 499 && width < 699 ? "lg" : width > 699 ? "xl" : false}>
      <Modal.Header closeButton>
        <Modal.Title>Upload {name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {video && <video className="mb-4" controls autoplay={true}
          style={{
            width: `100%`,
            height: `100%`
            }}>
                <source src={video}  type="video/mp4" />
                  Your browser does not support the video tag.
            </video>
          }
      <form onSubmit={submitFile}>
        
       
      
     <div className="editing-image">
            
            <div className="avatar-editor-controls">
              
              <div className="d-flex justify-content-between">
              <input type="file" onChange={event => setFile(event.target.files)} />
                <Button disabled={(file ? false : true) || uploading} type="submit" variant="success">
                  {uploading ? "Uploading..." : "Upload"}
                </Button>
              </div>
            </div>
          </div>
          </form>
      
      </Modal.Body>
    </Modal>
  );
};

export default EditVideoModal;
