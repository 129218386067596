import React, { useState, useEffect, useContext, useMemo } from "react";
import DataTable from "react-data-table-component";
import moment from "moment-timezone/builds/moment-timezone-with-data";
import { Button, Alert, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faEye,
  faPencilAlt,
  faLock,
  faTrashAlt,
  faUsers,
  faWrench,
  faGamepad,
  faCheck,
  faUnlock,
  faImage
} from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import { toast } from "react-toastify";
import MatchScoreModal from "./includes/MatchScoreModal";
import MatchScoreModalCSGO from "./includes/MatchScoreModalCSGO";
import MatchSetPlayersModal from "./includes/MatchSetPlayersModal";
import MatchVetoModalCSGO from "./includes/MatchVetoModalCSGO";
import MoveMatchModal from "./includes/MoveMatchModal";
import Swal from "sweetalert2";
import { GrCode } from "react-icons/gr";
import { BsFillCameraVideoFill, BsSquareHalf } from "react-icons/bs";
import "./matches.scss";
import { AppContext } from "../../../../../context/AppContext";
import { TIMEZONES } from "../../../../../utils/timezones";
const statuses = ["", "complete", "inProgress", "upcoming"];

const Matches = ({ teams = [], matches, updateMatches, onEditMatch, league, setLeague, setPrefferedTimezone, prefferedTimezone }) => {
  const { user } = useContext(AppContext);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showScoreModal, setShowScoreModal] = useState(false);
  const [showSetPlayersModal, setShowSetPlayersModal] = useState(false);
  const [showEditVeto, setShowEditVeto] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [vetoModalData, setVetoModalData] = useState(false);
  const [filteredMatches, setfilteredMatches] = useState([]);
  const [count, setCount] = useState(0);
  const [showMoveMatchModal, setShowMoveMatchModal] = useState(false);
  const [generateCodeloaderSpinner, setGenerateCodeloaderSpinner] = useState(false);
  const [clearSelectedRows, setClearSelectedRows] = useState(true);

  /**
   * 0 => all matches including completed and upcoming
   * 1 => only upcoming matches
   * 2 => only completed matches
   */

  useEffect(() => {
    if (matches.length) filterMatch();
  }, [matches, count]);

  const filterMatch = () => {
    if (count) {
      let _filteredmatches = matches.filter((match) => match.status === statuses[count]);
      if (!_filteredmatches.length) _filteredmatches = [{}];

      setfilteredMatches(_filteredmatches);
    } else {
      setfilteredMatches(matches);
    }
  };
  const getLeagueTeamData = (id) => {
    return teams.find(({ team }) => team?._id === id) || {};
  };
  const onReschedulingLock = async (match) => {
    try {
      Swal.fire({
        text: `Do you want to ${match.isLockReschedule ? "Unlock" : "Lock"} rescheduling of this match`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#28a745",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/matches/${match._id}/lock`, {
            lock: !match.isLockReschedule,
          });
          updateMatches((matches) => {
            const index = matches.findIndex(({ _id }) => _id.toString() === data.updatedMatch._id.toString());
            matches[index].isLockReschedule = !matches[index].isLockReschedule;
            return matches;
          });
          toast.success(`Successfully match rescheduling locked!`);
        }
      });
    } catch (error) {
      toast.error("There was a problem processing rescheduling locked 😞");
    }
  };

  const onSetPlayers = (match) => {
    setModalData(match);
    setShowSetPlayersModal(true);
  };

  const onEditVeto = (match) => {
    setVetoModalData(match);
    setShowEditVeto(true);
  };

  const onSetScore = (match) => {
    setModalData(match);
    setShowScoreModal(true);
  };

  const editMatch = async (matchId, body) => {
    try {
      const { data } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/matches/${matchId}`, body);
      updateMatches((matches) =>
        matches.map((match) => (match._id.toString() === data.updatedMatch._id.toString() ? data.updatedMatch : match))
      );
    } catch (error) {
      toast.error("There was problem in updating match");
    }
  };

  const generateTournamentCodes = async (match) => {
    try {
      setGenerateCodeloaderSpinner && setGenerateCodeloaderSpinner(match._id);
      const { data } = await Axios.post(`${process.env.REACT_APP_LOL_API}/api/matches/${match._id}/generateTournamentCodes`);
      updateMatches((matches) =>
        matches.map((match) => (match._id.toString() === data.updatedMatch._id.toString() ? data.updatedMatch : match))
      );
      setGenerateCodeloaderSpinner && setGenerateCodeloaderSpinner(false);
    } catch (error) {
      setGenerateCodeloaderSpinner && setGenerateCodeloaderSpinner(false);
      toast.error("Failed to generate tournament codes");
    }
  };

  // const updateScoreT1 = t1Score + t2Score;
  // const updateScoreT2 = t1Score + t2Score;

  const columns = [
    {
      name: "Date",
      sortable: true,
      className: "last-column",
      sortFunction: (a, b) => moment(a).isBefore(b),
      cell: ({ datetime }) => {
        const zonedDatetime = moment.tz(datetime, null, true, prefferedTimezone);
        const zone = zonedDatetime?.zoneAbbr();
        return zonedDatetime.format("DD/MM/YYYY HH:mm") + ` (${zone?.includes("+") || zone?.includes("-") ? "UTC" + zone : zone})`;
      },
      grow: 2,
    },
    { name: "Round / Week", sortable: true, cell: ({ round, week }) => (round && week ? `R: ${round} / W: ${week}` : "") },
    { name: "Best of", sortable: true, selector: "bestOf" },
    {
      name: "Team 1",
      sortable: true,
      cell: ({ t1, lolData, csgoData }) =>
        !t1 && !lolData && !csgoData ? (
          ""
        ) : (
          <div style={{ opacity: t1 && getLeagueTeamData(t1._id).kicked ? 0.25 : 1 }}>
            {t1 ? (
              <>
                {t1.name} {getLeagueTeamData(t1._id).kicked && "(Kicked)"}{" "}
                {((lolData && !!(lolData?.players?.t1 || []).length) || (csgoData && !!(csgoData?.players?.t1 || []).length)) && (
                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Players Set</Tooltip>}>
                    <FontAwesomeIcon className="text-success" icon={faCheckCircle} />
                  </OverlayTrigger>
                )}
              </>
            ) : (
              "[DELETED]"
            )}
          </div>
        ),
    },
    {
      name: "Team 2",
      sortable: true,
      cell: ({ t2, lolData, csgoData }) =>
        !t2 && !lolData && !csgoData ? (
          ""
        ) : (
          <div style={{ opacity: t2 && getLeagueTeamData(t2._id).kicked ? 0.25 : 1 }}>
            {t2 ? (
              <>
                {t2.name} {getLeagueTeamData(t2._id).kicked && "(Kicked)"}{" "}
                {((lolData && !!(lolData?.players?.t2 || []).length) || (csgoData && !!(csgoData?.players?.t2 || []).length)) && (
                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Players Set</Tooltip>}>
                    <FontAwesomeIcon className="text-success" icon={faCheckCircle} />
                  </OverlayTrigger>
                )}
              </>
            ) : (
              "[DELETED]"
            )}
          </div>
        ),
    },
    {
      name: "Score",

      cell: ({ t1Score, t2Score, t2, t1 }) => (
        <span>
          {t1Score === undefined ? (
            ""
          ) : getLeagueTeamData(t2?._id).kicked || getLeagueTeamData(t1?._id).kicked ? (
            <span style={{ color: "red" }}>{t1Score} -</span>
          ) : (
            <span>{t1Score} -</span>
          )}{" "}
          {t2Score === undefined ? (
            ""
          ) : getLeagueTeamData(t1?._id).kicked || getLeagueTeamData(t2?._id).kicked ? (
            <span style={{ color: "red" }}>{t2Score}</span>
          ) : (
            t2Score
          )}
        </span>
      ),
    },

    {
      name: (
        <div
          onClick={() => {
            console.log("Running");
            if (count === 0) {
              setCount(1);
            } else if (count === 1) {
              setCount(2);
            } else if (count === 2) {
              setCount(3);
            } else if (count === 3) {
              setCount(0);
            }
          }}
        >
          {statuses[count] !== "" ? `Status (${statuses[count]})` : `Status (All)`}
        </div>
      ),
      cell: ({ status }) =>
        status === "complete" ? (
          <span className="text-success">Completed</span>
        ) : status === "inProgress" ? (
          <span className="text-warning">In Progress</span>
        ) : status === "upcoming" ? (
          <span className="text-secondary">Upcoming</span>
        ) : (
          ""
        ),
    },
    {
      right: true,
      width: "20%",
      cell: (filteredMatches) =>
        filteredMatches._id ? (
          <>
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">View Match</Tooltip>}>
              <a
                className="btn text-secondary"
                href={`${process.env.REACT_APP_LEAGUES_URL}/match/${filteredMatches._id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faEye} />
              </a>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Set Players</Tooltip>}>
              <Button
                variant="link"
                className="text-secondary"
                onClick={() => onSetPlayers(filteredMatches)}
                disabled={!user.state.admin && !user.state.permissions.user.matches}
              >
                <FontAwesomeIcon icon={faUsers} />
              </Button>
            </OverlayTrigger>
            {filteredMatches.veto && (
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Edit Veto</Tooltip>}>
                <Button variant="link" className="text-secondary" onClick={() => onEditVeto(filteredMatches)}>
                  <FontAwesomeIcon icon={faGamepad} />
                </Button>
              </OverlayTrigger>
            )}
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Set Score Manually</Tooltip>}>
              <Button
                variant="link"
                className="text-secondary"
                onClick={() => onSetScore(filteredMatches)}
                disabled={!user.state.admin && !user.state.permissions.user.matches}
              >
                <FontAwesomeIcon icon={faWrench} />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Edit Match</Tooltip>}>
              <Button
                variant="link"
                className="text-secondary"
                onClick={() => onEditMatch(filteredMatches)}
                disabled={!user.state.admin && !user.state.permissions.user.matches}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip">{filteredMatches.isLockReschedule ? "Unlock" : "Lock"} rescheduling</Tooltip>}
            >
              <Button
                variant="link"
                className="text-secondary"
                style={{ opacity: filteredMatches.isLockReschedule ? 1 : 0.5 }}
                onClick={() => onReschedulingLock(filteredMatches)}
                disabled={filteredMatches.status === "complete"}
              >
                <FontAwesomeIcon icon={filteredMatches.isLockReschedule ? faLock : faUnlock} />
              </Button>
            </OverlayTrigger>
          </>
        ) : (
          ""
        ),
    },
    {
      right: true,
      cell: (filteredMatches) => {
        return filteredMatches._id ? (
          <>
            <div className="vertical-divider" />
            <div className="match-icons-grid">
              {league?.game?.name.toLowerCase() === "league of legends" ? (
                generateCodeloaderSpinner !== filteredMatches._id ||
                (typeof generateCodeloaderSpinner === "boolean" && generateCodeloaderSpinner === false) ? (
                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Tournament Codes</Tooltip>}>
                    <Button
                      variant="link"
                      onClick={() => generateTournamentCodes(filteredMatches)}
                      className={
                        !user.state.admin && !user.state.permissions.user.matches ? "disabled-link text-secondary" : "text-secondary"
                      }
                    >
                      <GrCode className={filteredMatches.lolData?.tournamentCodes?.length ? "" : "faded"} />
                    </Button>
                  </OverlayTrigger>
                ) : (
                  <>
                    <Spinner animation="border" className="m-auto" size="sm" role="status"></Spinner>
                  </>
                )
              ) : (
                <></>
              )}
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Broadcasting</Tooltip>}>
                <Button
                  variant="link"
                  className={!user.state.admin && !user.state.permissions.user.matches ? "disabled-link text-secondary" : "text-secondary"}
                  onClick={() => editMatch(filteredMatches._id, { broadcasted: !filteredMatches.broadcasted })}
                >
                  <BsFillCameraVideoFill style={{ opacity: filteredMatches.broadcasted !== true ? 0.5 : 1 }} />
                </Button>
              </OverlayTrigger>
              {league.game.name.toLowerCase() === "league of legends" && (
                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Side Selection</Tooltip>}>
                  <Button
                    variant="link"
                    className={
                      !user.state.admin && !user.state.permissions.user.matches ? "disabled-link text-secondary" : "text-secondary"
                    }
                    onClick={() => editMatch(filteredMatches._id, { sideSelection: !filteredMatches.sideSelection })}
                  >
                    <BsSquareHalf style={{ opacity: filteredMatches.sideSelection !== true ? 0.5 : 1 }} />
                  </Button>
                </OverlayTrigger>
              )}
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Match Completion</Tooltip>}>
                <Button
                  variant="link"
                  className={!user.state.admin && !user.state.permissions.user.matches ? "disabled-link text-secondary" : "text-secondary"}
                  onClick={() =>
                    editMatch(filteredMatches._id, { status: filteredMatches.status === "upcoming" ? "complete" : "upcoming" })
                  }
                >
                  <FontAwesomeIcon icon={faCheck} className={filteredMatches.status === "upcoming" ? "faded" : ""} />
                </Button>
              </OverlayTrigger>
              {league.game.name.toLowerCase() === "cs:go" && (
                <OverlayTrigger placement="top" overlay={!filteredMatches.demoFile ? <Tooltip id="tooltip">No Demo File</Tooltip> : <Tooltip id="tooltip">Delete Demo File</Tooltip>}>
                <Button
                  // disabled={!filteredMatches.demo}
                  variant="link"
                  className={!user.state.admin && !user.state.permissions.user.matches ? "disabled-link text-secondary" : "text-secondary"}
                  onClick={() => {
                    if(filteredMatches.demoFile){
                      if(window.confirm('Are you sure to delete?')){ editMatch(filteredMatches._id, { demoFile: "" })};
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faImage} className={!filteredMatches.demoFile ? "faded" : ""} />
                </Button>
              </OverlayTrigger>
              )}
            </div>
          </>
        ) : null;
      },
    },
  ];

  const contextAction = useMemo(() => {
    const onDeleteMatches = async () => {
      const ids = selectedRows.map(({ _id }) => _id);

      if (window.confirm(`Are you sure you want to delete ${ids.length} matches?`)) {
        try {
          await Axios.post(`${process.env.REACT_APP_CORE_API}/api/matches/deleteMulti`, { ids });

          updateMatches((matches) => matches.filter(({ _id }) => !ids.includes(_id)));
          setSelectedRows([]);
          setClearSelectedRows((prevState) => !prevState);
          toast.success(`Successfully deleted ${ids.length} matches`);
        } catch (e) {
          toast.error("There was a problem deleting the selected matches");
        }
      }
    };

    const broadcastMutlipleMatches = async (broadcasted = false) => {
      try {
        const ids = selectedRows.map(({ _id }) => _id);
        const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/matches/broadcastMulti?broadcasted=${broadcasted}`, {
          ids,
        });
        updateMatches((matches) => matches.map((match) => (ids.includes(match._id.toString()) ? { ...match, broadcasted } : match)));
        setSelectedRows([]);
        setClearSelectedRows((prevState) => !prevState);
      } catch (error) {
        toast.error("Failed to broadcast Multiple Matches");
      }
    };
    return (
      <div>
        <Button size="sm" variant="danger" onClick={onDeleteMatches}>
          <FontAwesomeIcon icon={faTrashAlt} />
          &nbsp;Delete Selected
        </Button>
        <Button size="sm" variant="primary" className="ml-1" onClick={() => setShowMoveMatchModal(true)}>
          Move Match
        </Button>
        <Button size="sm" variant="primary" className="ml-1" onClick={() => broadcastMutlipleMatches(true)}>
          Broadcasted
        </Button>
        <Button size="sm" variant="primary" className="ml-1" onClick={() => broadcastMutlipleMatches(false)}>
          Not Broadcasted
        </Button>
      </div>
    );
  }, [selectedRows, updateMatches]);

  return (
    <div>
      <DataTable
        title={
          <div className="matches-header">
            <span className="matches-header__title">Matches</span>
            <div className="matches-header__timezone-dropdown">
              <span className="matches-header__timezone-dropdown-title">Show in time zone:</span>
              <select
                className="form-control"
                name="timezone"
                value={prefferedTimezone}
                onChange={(e) => setPrefferedTimezone(e.target.value)}
              >
                {TIMEZONES.map(({ name, value }, index) => (
                  <option value={value} key={index}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        }
        columns={columns}
        data={filteredMatches}
        selectableRows
        contextActions={contextAction}
        onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}
        clearSelectedRows={clearSelectedRows}
        pagination
        paginationPerPage={100}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
      />

      {league && league.game && league.game.shortName === "CSGO" ? (
        <MatchScoreModalCSGO
          show={showScoreModal}
          onHide={() => setShowScoreModal(false)}
          match={modalData}
          updateMatches={updateMatches}
          setLeague={setLeague}
        />
      ) : (
        <MatchScoreModal
          show={showScoreModal}
          onHide={() => setShowScoreModal(false)}
          match={modalData}
          codesLoading={generateCodeloaderSpinner}
          setGenerateCodeloaderSpinner={setGenerateCodeloaderSpinner}
          updateMatches={updateMatches}
          setLeague={setLeague}
          user={user}
        />
      )}

      {league?.game?.shortName === "CSGO" && (
        <MatchVetoModalCSGO
          show={showEditVeto}
          onHide={() => setShowEditVeto(false)}
          match={vetoModalData}
          league={league}
          vetoInProgress={new Date(vetoModalData.datetime) < new Date() || vetoModalData.veto?.completed ? false : true}
        />
      )}

      <MatchSetPlayersModal
        show={showSetPlayersModal}
        onHide={() => setShowSetPlayersModal(false)}
        match={modalData}
        updateMatches={updateMatches}
        league={league}
        flag={league?.game?.shortName === "CSGO"}
      />

      <MoveMatchModal
        show={showMoveMatchModal}
        onHide={() => {
          setShowMoveMatchModal(false);
          setClearSelectedRows((prevState) => !prevState);
        }}
        selectedRows={selectedRows}
        updateMatches={updateMatches}
      />
    </div>
  );
};

export default Matches;
