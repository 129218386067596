import moment from 'moment';

export const arrayChunks = (array, chunk_size) => {
  return Array(Math.ceil(array.length / chunk_size))
    .fill()
    .map((_, index) => index * chunk_size)
    .map((begin) => array.slice(begin, begin + chunk_size));
};
export const formatData = (data, short, isBrackets) => {
  const processedData = [[], []];
  //MAKE ARRAYS WHICH CAN BE FED TO BRACKETS OBJECT
  data.matches.forEach(match => {
      const { s, r, m } = match.id
      if (match.teams[0]) match.teams[0].score = match.matchData?.t1Score;
      if (match.teams[1]) match.teams[1].score = match.matchData?.t2Score;
      const proccesedObject = match;
      if (processedData[s - 1][r - 1])
          processedData[s - 1][r - 1].push(proccesedObject);
      else processedData[s - 1][r - 1] = [proccesedObject];
  });
  let matchIndex = 1;
  // ASSIGN MATCH NUMBERS
  processedData.forEach(track => track.forEach(round => round.forEach(match => match.index = matchIndex++)));
  //MOVE FINAL TO UPPER BRACKET
  if (!short) {
      if (processedData[1].length !== 0) {
          const lastRound = [...processedData[1][processedData[1].length - 1]];
          processedData[1].splice(processedData[1].length - 1, 1);
          processedData[0].push(lastRound);
      }
  }
  // MOVE LAST THREE ROUNDS TO THE END
  if (isBrackets) {
      if (processedData[0].length > 3 && processedData[0].length < processedData[1].length) {
          const diff = processedData[1].length - processedData[0].length;
          const elelemtnsCountOnQuarterFinal = processedData[0][processedData[0].length - 3].length;
          for (let j = 0; j < diff; j++) {
              processedData[0].splice(processedData[0].length - 3, 0, [...Array(elelemtnsCountOnQuarterFinal)])
          }
      } else if (processedData[0].length <= 3 && processedData[0].length < processedData[1].length) {
          const diff = processedData[1].length - processedData[0].length;
          const elelemtnsCountOnQuarterFinal = processedData[0][processedData[0].length - 3] && processedData[0][processedData[0].length - 3].length;
          for (let j = 0; j < diff; j++) {
              processedData[0].splice(processedData[0].length - 3, 0, [...Array(elelemtnsCountOnQuarterFinal)].map(el => ({ hidden: true })))
          }

      }
  }
  return processedData;
}

export const makeCountdownString = millis => {
  const _seconds = Math.round(millis / 1000);
  const _mins = Math.round(_seconds / 60);
  const _hours = Math.round(_mins / 60);
  const _days = Math.round(_hours / 24);
  let seconds = Math.round(_seconds % 60);
  let mins = Math.round(_mins % 60);
  let hours = Math.round(_hours % 24);
  let days = Math.round(_days);
  seconds = String(seconds).length < 2 ? "0" + seconds : String(seconds);
  mins = String(mins).length < 2 ? "0" + mins : String(mins);
  hours = String(hours).length < 2 ? "0" + hours : String(hours);
  days = String(days);
  return `${days}:${hours}:${mins}:${seconds}`;
}

export const csgoDataProcessor = async (data,winnerTeamSelected,teamDetails) => {
    console.log('data before ProcessedData',winnerTeamSelected,teamDetails);
    
    
    let loaserTeam = Object.values(teamDetails).filter((singTeam) => singTeam._id !== winnerTeamSelected);

    /**Team Process data start*/
    // let teamProcessedData = {};
    var obj = [
        data.FirstHalfScore || {},
        data.SecondHalfScore || {},
        data.OvertimeScore || {}
    ];

   let processedteamData = {
        team1 : {
            totalRoundScore: Number(0),
            winner: false,
            totalTableRoundScore: Number(0),
            startingSide: null,
            totalKills: Number(0),
            totalDeaths: Number(0),
            totalDamage: Number(0),
            roundDiffrence: null,
            totalCash: Number(0),
            teamDbId:null
        },
        team2 : {
            totalRoundScore: Number(0),
            winner: false,
            totalTableRoundScore: Number(0),
            startingSide: null,
            totalKills: Number(0),
            totalDeaths: Number(0),
            totalDamage: Number(0),
            roundDiffrence: null,
            totalCash: Number(0),
            teamDbId:null
        }
    };

    obj.forEach(function(d) {
        
        if(d['team1'] && d['team2']){
            processedteamData.team1.totalRoundScore = Number(Number(processedteamData.team1.totalRoundScore + Number(d['team1'])));

            processedteamData.team2.totalRoundScore = Number(Number(processedteamData.team2.totalRoundScore + Number(d['team2'])));
        }
    });

        processedteamData.team1.roundDiffrence = processedteamData.team1.totalRoundScore - processedteamData.team2.totalRoundScore;
        processedteamData.team2.roundDiffrence = processedteamData.team2.totalRoundScore - processedteamData.team1.totalRoundScore;
 
        let t1 = teamDetails.t1._id;
        let t2 = teamDetails.t2._id;

    if(processedteamData.team1.totalRoundScore > processedteamData.team2.totalRoundScore){
        processedteamData.team1.winner = true;
        
        processedteamData.team1.teamDbId = winnerTeamSelected;

        processedteamData.team2.teamDbId = loaserTeam[0]._id;

    }else if(processedteamData.team2.totalRoundScore > processedteamData.team1.totalRoundScore){
        processedteamData.team2.winner = true;

        processedteamData.team2.teamDbId = winnerTeamSelected;
        
        processedteamData.team1.teamDbId = loaserTeam[0]._id;
    }else{
        processedteamData.team2.winner = false;
        processedteamData.team1.winner = false;
        processedteamData.team2.teamDbId = null;
        processedteamData.team1.teamDbId = null
    }
  

    Object.keys(processedteamData).map((value,key) => {
        // console.log('pdTeam=value',value) == team1
        // console.log('pdTeam=key',key) === 0
        
        //      Table total round score -
        //     if “Overtime count” = 0 then this value is just “total round score”
        //     if “Overtime count” > 0, and “Winner” == true, then this is 16
        //     if “Overtime count” > 0, and “Winner” == false, then this is 15
        //      data -> OvertimeScore->OvertimeID

        if(data.OvertimeScore && data.OvertimeScore.OvertimeID && Number(data.OvertimeScore.OvertimeID) === 0){
            processedteamData[value].totalTableRoundScore = processedteamData[value].totalRoundScore;
        }else if(data.OvertimeScore && data.OvertimeScore.OvertimeID && Number(data.OvertimeScore.OvertimeID) > 0 && processedteamData[value].winner === true){
            processedteamData[value].totalTableRoundScore = 16;
        }else if(data.OvertimeScore && data.OvertimeScore.OvertimeID && Number(data.OvertimeScore.OvertimeID) > 0 && processedteamData[value].winner === false){
            processedteamData[value].totalTableRoundScore = 15;
        }


        // Starting side - 
        // if “Winner” == true, and “Loser side” == “T”, then “T”
        // if “Winner” == false, and “Loser side” == “T”, then “CT”
        // if “Winner” == true, and “Loser side” == “CT”, then “CT”
        // if “Winner” == false, and “Loser side” == “CT”, then “T”

        if(processedteamData[value].winner === true && data.History.LoserMostRecentTeam === 'T'){
            processedteamData[value].startingSide = "T";
        }else if(processedteamData[value].winner === false && data.History.LoserMostRecentTeam === 'T'){
            processedteamData[value].startingSide = "CT";
        }else if(processedteamData[value].winner === true && data.History.LoserMostRecentTeam === 'CT'){
            processedteamData[value].startingSide = "CT";
        }else if(processedteamData[value].winner === false && data.History.LoserMostRecentTeam === 'CT'){
            processedteamData[value].startingSide = "T";
        }
    

        
    });

    
    /**Team Process Data end */

    /**Process players data Start*/
   
    let playersOfTeam_One = Object.keys(data.PlayersOnTeam1);
    let playersOfTeam_Two = Object.keys(data.PlayersOnTeam2);

    playersOfTeam_One.map((singlePlayerKey) => {

        let singlePlayerData = data.PlayersOnTeam1[singlePlayerKey];

        if(singlePlayerData){
            processedteamData.team1.totalKills = Number(processedteamData.team1.totalKills)+Number(singlePlayerData.kills);
            processedteamData.team1.totalDeaths = Number(processedteamData.team1.totalDeaths)+Number(singlePlayerData.deaths);
            processedteamData.team1.totalDamage = Number(processedteamData.team1.totalDamage)+Number(singlePlayerData.MatchStats.Totals.Damage);
            processedteamData.team1.totalCash = Number(processedteamData.team1.totalCash)+Number(singlePlayerData.MatchStats.Totals.CashEarned);
            

            let processedData = {};
            if(singlePlayerData.deaths === "0"){
                processedData.KD = 'PERFECT';
            }else{
                let calc_kd = Number(singlePlayerData.deaths) / Number(singlePlayerData.kills)
                processedData.KD = String(calc_kd.toFixed(1));
            }
            
            let processedData_PlusMinus = Number(singlePlayerData.kills) / Number(singlePlayerData.deaths);
            processedData['PlusMinus'] = String(processedData_PlusMinus.toFixed(1));
        

            if(singlePlayerData.enemyHSs === "0"){
                processedData['HSPercentage'] = "0";
            }else{
                processedData['HSPercentage'] = String(Number((singlePlayerData.enemyHSs) / Number(singlePlayerData.kills) * 100).toFixed(1));
            }

            let calc_processedData_ADR = Number(singlePlayerData.MatchStats.Totals.Damage) / Number(data.round);
            processedData['ADR'] = String(calc_processedData_ADR.toFixed(1));

            data.PlayersOnTeam1[singlePlayerKey] = {...data.PlayersOnTeam1[singlePlayerKey], processedData};
        }
    });
    playersOfTeam_Two.map((singlePlayerKey) => {
        let singlePlayerData = data.PlayersOnTeam2[singlePlayerKey];
        if(singlePlayerData){
            processedteamData.team2.totalKills = Number(processedteamData.team2.totalKills)+Number(singlePlayerData.kills);
            processedteamData.team2.totalDeaths = Number(processedteamData.team2.totalDeaths)+Number(singlePlayerData.deaths);
            processedteamData.team2.totalDamage = Number(processedteamData.team2.totalDamage)+Number(singlePlayerData.MatchStats.Totals.Damage);
            processedteamData.team2.totalCash = Number(processedteamData.team2.totalCash)+Number(singlePlayerData.MatchStats.Totals.CashEarned);


            let processedData = {};
            if(singlePlayerData.deaths === "0"){
                processedData.KD = 'PERFECT';
            }else{
                let calc_kd = Number(singlePlayerData.deaths) / Number(singlePlayerData.kills)
                processedData.KD = String(calc_kd.toFixed(1));
            }
            
            let processedData_PlusMinus = Number(singlePlayerData.kills) / Number(singlePlayerData.deaths);
            processedData['PlusMinus'] = String(processedData_PlusMinus.toFixed(1));
        

            if(singlePlayerData.enemyHSs === "0"){
                processedData['HSPercentage'] = "0";
            }else{
                processedData['HSPercentage'] = String(Number((singlePlayerData.enemyHSs) / Number(singlePlayerData.kills) * 100).toFixed(1));
            }

            let calc_processedData_ADR = Number(singlePlayerData.MatchStats.Totals.Damage) / Number(data.round);
            processedData['ADR'] = String(calc_processedData_ADR.toFixed(1));

            data.PlayersOnTeam2[singlePlayerKey] = {...data.PlayersOnTeam2[singlePlayerKey], processedData};
        }
    });

    data = {...data,processedteamData};
    /**Process players data End*/

    return data;
}