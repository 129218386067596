import React from "react";
import { Switch } from "react-router-dom";
import { AuthRoute, GuestRoute, } from "./utils/CustomRouteTypes";
import AllGames from "./components/Games/AllGames/AllGames";
import Home from "./components/Home/Home";
import Login from "./components/Login/Login";
import GamesRouter from "./components/Games/GamesRouter";
import ScoreSystems from "./components/ScoreSystems/ScoreSystems";
import Entities from "./components/Entities/Entities";
import UserIndividual from "./components/Entities/Individual/UserIndividual";
import CoachIndividual from "./components/Entities/Individual/CoachIndividual";
import OrganisationIndividual from "./components/Entities/Individual/OrganisationIndividual";
import PlayerIndividual from "./components/Entities/Individual/PlayerIndividual";
import TeamIndividual from "./components/Entities/Individual/TeamIndividual";
import Adverts from "./components/Adverts/Adverts";
import ManageAdvert from "./components/Adverts/ManageAdvert";
import SingleGameEdit from "./components/Games/AllGames/SingleGameEdit/SingleGameEdit";
import SingleGameEditFrontPage from "./components/Games/AllGames/FrontPage/SingleGameEditFrontPage";
import Logs from "./components/Logs/Logs";
import Roles from "./components/Roles/Roles"
import Translation from "./components/languages/translation";
/*
  EVERY ROUTE MUST BE AN AuthRoute, except Login! FOR OBVIOUS REASONS! :-)
*/

const AppRouter = () => {
  return (
    <Switch>
      <AuthRoute path="/" exact>
        <Home />
      </AuthRoute>
      <GuestRoute path="/login">
        <Login />
      </GuestRoute>

      {/* Pages */}
      <AuthRoute path="/games">
        <GamesRouter />
      </AuthRoute>

      <AuthRoute path="/scrim-games">
        <GamesRouter />
      </AuthRoute>

      <AuthRoute path="/game-settings" exact>
        <AllGames settingFeature={true} />
      </AuthRoute>

      <AuthRoute path="/game-settings/:id/edit" exact>
        <SingleGameEdit />
      </AuthRoute>

      <AuthRoute path="/game-settings/:id/edit-front-page" exact>
        <SingleGameEditFrontPage />
      </AuthRoute>

      <AuthRoute path="/entities" exact>
        <Entities />
      </AuthRoute>

      <AuthRoute path="/entities/:id/user-individual" exact>
        <UserIndividual />
      </AuthRoute>

      <AuthRoute path="/entities/:id/player-individual" exact>
        <PlayerIndividual />
      </AuthRoute>

      <AuthRoute path="/entities/:id/coach-individual" exact>
        <CoachIndividual />
      </AuthRoute>

      <AuthRoute path="/entities/:id/team-individual" exact>
        <TeamIndividual />
      </AuthRoute>

      <AuthRoute path="/entities/:id/organisation-individual" exact>
        <OrganisationIndividual />
      </AuthRoute>

      <AuthRoute path="/roles" exact>
        <Roles />
      </AuthRoute>

      <AuthRoute path="/score-systems">
        <ScoreSystems />
      </AuthRoute>

      <AuthRoute path="/adverts" exact>
        <Adverts />
      </AuthRoute>

      <AuthRoute path="/adverts/:id/manage">
        <ManageAdvert />
      </AuthRoute>

      <AuthRoute path="/logs">
        <Logs />
      </AuthRoute>
      <AuthRoute path="/translation">
        <Translation />
      </AuthRoute>
      <AuthRoute path="*">404</AuthRoute>
    </Switch>
  );
};

export default AppRouter;
