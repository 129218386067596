import React, { useState, useEffect } from "react";
import { Table, Form, Button, Card } from "react-bootstrap";
import Axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import Reorder, { reorder } from "react-reorder";
import { sortTeams } from "./_helpers/scoreCalculate";

const TableOverview = ({ teams = [], setLeague, league = {} }) => {
  console.log('as', teams);

  const { id: leagueId } = useParams();
  const [saving, setSaving] = useState(false);
  const [newTeams, setNewTeams] = useState([]);
  const [label, setLabel] = useState(league.overviewManualSort ? "Automatic": "Manual");
  const [orderedTeams, setOrderedTeams] = useState([]);

  // Set local newTeams array when teams prop changes
  useEffect(() => {
    console.log(league);
    // using new sorting techniques
    setOrderedTeams(sortTeams(teams, league, label === "Automatic"));
    teams.sort((a, b) => b.score - a.score);
    setNewTeams(sortTeams(teams, league, label === "Automatic"));
  }, [teams, label]);
  useEffect(() => {
      setLabel(league.overviewManualSort ? "Automatic": "Manual")
  }, [league]);

  const onReorder = (_, previousIndex, nextIndex) => {
    console.log("prevIndex", previousIndex); console.log("nextIndex", nextIndex)
    setOrderedTeams((os) => reorder(os, previousIndex, nextIndex));
  };


  const onUpdateTeam = (atIndex, key, value) => {
    value = parseFloat(value || 0);

    label === "Automatic" &&
      setOrderedTeams((teams) => {
        return teams.map((team, i) => (i === atIndex ? { ...team, [key]: value } : team));
      });

    label === "Manual" &&
      setNewTeams((teams) => {
        return teams.map((team, i) => (i === atIndex ? { ...team, [key]: value } : team));
      });
  };

  const onSave = async () => {
    setSaving(true);
    const newOrder = orderedTeams.map((team, i) => {
      team.order = i
      team.team = team.team._id
      return team
    });
    let isManualSort = (label === "Automatic")
    const postData = newTeams.map((team) => ({ ...team, team: team.team._id || team.team, order: null })); // need to cast populated team back to just id

    // update league with new teams array
    try {
        const { data } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/leagues/tableOverview/${leagueId}`, { teams: isManualSort ? newOrder : postData, isManualSort});
        setLeague((league) => ({ ...league, teams: data?.updatedLeague?.teams, overviewManualSort: data?.updatedLeague?.overviewManualSort }));
     
      toast.success("Saved");
    } catch (e) {
      toast.error("There was a problem saving");
    }

    setSaving(false);
  };

  const onRecalculateTableOverview = async () => {
    if (window.confirm("Are you sure you want to do this?")) {
      try {
        const { data } = await Axios.post(`${process.env.REACT_APP_LOL_API}/api/leagues/${leagueId}/recalculateScoreboard`);
        setLeague((league) => ({ ...league, teams: data.updatedLeague.teams, overviewManualSort: label == "Automatic" }));
        label === "Automatic" && setOrderedTeams(sortTeams(data.updatedLeague.teams, league));
        label === "Manual" && setNewTeams(data.updatedLeague.teams);
        toast.success("Successfully recalculated scores! ✅");
      } catch (e) {
        return toast.error("There was a problem recalculating scoreboard");
      }
    }
  };

  return (
    <div>

      <Form>
        <Form.Check
          type="switch"
          id="custom-switch"
          label={`Toggle to ${label}`}
          onChange={() => {
            setLabel("Automatic");
            label === "Automatic" && setLabel("Manual");
          }}
          checked={label === "Automatic"}
          
        />
      </Form>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Team Name</th>
            <th>Score</th>
            <th>Matches</th>
            <th>Maps Won</th>
            <th>Maps Lost</th>
          </tr>
        </thead>
        {label === "Automatic" && orderedTeams?.length && (
          // <tbody>
          <Reorder
            lock="horizontal"
            reorderId="teams" // Unique ID that is used internally to track this list (required)
            holdTime={100} // Default hold time before dragging begins (mouse & touch) (optional), defaults to 0
            onReorder={onReorder} // Callback when an item is dropped (you will need this to update your state)
            className="reorder-list"
            // placeholder={
            //   <Card bg="light" body>
            //     &nbsp;
            //   </Card> // Custom placeholder element (optional), defaults to clone of dragged element
            // }
            component={"tbody"}
          >
            {orderedTeams.map(
              ({ team, score, matches, mapsWon, mapsLost }, i) =>
                team && (
                  <tr className="" key={i}>
                    <td>{team.name}</td>
                    <td>
                      <Form.Control
                        type="number"
                        min={0}
                        value={score || 0}
                        onChange={(e) => onUpdateTeam(i, "score", e.target.value)}
                        style={{ maxWidth: 150 }}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        min={0}
                        value={matches || 0}
                        onChange={(e) => onUpdateTeam(i, "matches", e.target.value)}
                        style={{ maxWidth: 150 }}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        min={0}
                        value={mapsWon || 0}
                        onChange={(e) => onUpdateTeam(i, "mapsWon", e.target.value)}
                        style={{ maxWidth: 150 }}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        min={0}
                        value={mapsLost || 0}
                        onChange={(e) => onUpdateTeam(i, "mapsLost", e.target.value)}
                        style={{ maxWidth: 150 }}
                      />
                    </td>
                  </tr>
                )
            )}
          </Reorder>
          // </tbody>
        )}

        {label === "Manual" && (
          <tbody>
            {newTeams.map(
              ({ team, score, matches, mapsWon, mapsLost }, i) =>
                team && (
                  <tr key={i}>
                    <td>{team.name}</td>
                    <td>
                      <Form.Control
                        type="number"
                        min={0}
                        value={score || 0}
                        onChange={(e) => onUpdateTeam(i, "score", e.target.value)}
                        style={{ maxWidth: 150 }}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        min={0}
                        value={matches || 0}
                        onChange={(e) => onUpdateTeam(i, "matches", e.target.value)}
                        style={{ maxWidth: 150 }}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        min={0}
                        value={mapsWon || 0}
                        onChange={(e) => onUpdateTeam(i, "mapsWon", e.target.value)}
                        style={{ maxWidth: 150 }}
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        min={0}
                        value={mapsLost || 0}
                        onChange={(e) => onUpdateTeam(i, "mapsLost", e.target.value)}
                        style={{ maxWidth: 150 }}
                      />
                    </td>
                  </tr>
                )
            )}
          </tbody>
        )}
      </Table>

      <div className="d-flex justify-content-between">
        <Button variant="success" onClick={onSave} disabled={saving}>
          {saving ? "Saving..." : "Save Changes"}
        </Button>

        <Button variant="secondary" onClick={onRecalculateTableOverview}>
          <FontAwesomeIcon icon={faRedoAlt} /> Recalculate Table Overview
        </Button>
      </div>
    </div>

  );
};

export default TableOverview;
