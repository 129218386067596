import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import Axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import EditImageModal from "../../../../_modals/EditImageModal/EditImageModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { TIMEZONES } from "./timezones";
import Datetime from "react-datetime";
import { countries } from "../../../../../utils/countries";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import RichTextbox from "./includes/RichTextbox";

const Information = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [multiStageLeague, setMultiStageLeague] = useState(false);
  const { register, errors } = useForm();
  const [country, setCountry] = useState(false);
  const [nationalities, setNationalities] = useState([]);
  const [modals, setModals] = useState({});
  const [startDates, setStartDates] = useState([]);
  const [registrationDeadlines, setRegistrationDeadlines] = useState([]);
  const [activeStageTab] = useState(0);
  const [responseloader, setResponseLoading] = useState(false);
  const FORMAT = ["Single Elimination", "Double Elimination", "King of the Hill"];
  const pickModeOptions = [
    { name: "Tournament draft", value: "TOURNAMENT_DRAFT" },
    { name: "Draft pick", value: "DRAFT_PICK" },
    { name: "Blind pick", value: "BLIND_PICK" },
    { name: "All Random", value: "ALL_RANDOM" },
  ];

  const onUpdateImage = async (type, imageUrl, stageIndex) => {
    try {
      let multiStageLeagueCopy = {...multiStageLeague};
      if(multiStageLeague.stages[stageIndex].stageType === 'league'){
        const { data } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/leagues/${multiStageLeague.stages[stageIndex].league._id}/image`, { type, image: imageUrl });
        multiStageLeagueCopy.stages[stageIndex].league = {...multiStageLeagueCopy.stages[stageIndex].league, ...data.updatedImages};
      }else{
        const { data } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/tournaments/${multiStageLeague.stages[stageIndex].tournament._id}/image`, { type, image: imageUrl });
        multiStageLeagueCopy.stages[stageIndex].tournament = {...multiStageLeagueCopy.stages[stageIndex].tournament, ...data.updatedImages};
      }
      setMultiStageLeague(multiStageLeagueCopy)
      setModals({ ...modals, [type]: false });
    } catch (err) {
      toast.error("There was a problem updating images!");
    }
  };

  useEffect(() => {
    setResponseLoading(true);
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}`)
      .then(({data}) => {
        setMultiStageLeague(data.multiStageLeague);
        setStartDates(data.multiStageLeague.stages.map(stage => stage.stageType === 'league' ? stage.league.startDate : stage.tournament.startDate));
        setRegistrationDeadlines(data.multiStageLeague.stages.map(stage => stage.stageType === 'league' ? "" : stage.tournament.registerationDeadline));
        data.multiStageLeague.stages[0].stageType === 'league' ? setCountry(data.multiStageLeague.stages[0].league?.country?.code) : setCountry(data.multiStageLeague.stages[0].tournament?.country?.code);
      })
      .catch((err) => {
        console.log("ERROR  : ", err)
        toast.error("There was a problem in getting stages");
      });
    setNationalities(
      countries
        .filter(({ demonym }) => demonym)
        .filter((v, i, a) => a.findIndex((t) => t.demonym === v.demonym) === i)
        .sort((a, b) => a.demonym.localeCompare(b.demonym))
    );
    setResponseLoading(false);
  }, []);

  const updateMultiStageLeague = async (multiStageLeague)=>{
      try{
       const res = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${multiStageLeague._id}`, multiStageLeague)
       if(res){
         const {data} = res.data
        setMultiStageLeague((prevState)=> ({...prevState, name: data.name}))
         toast.success("Saved");
       }

      }catch (e){
        console.log("ERROR : ", e)
        toast.error("There was a problem saving");
      }
  }
  const onSubmit = async (stage) => {
    setLoading(true);
    try {
      if(stage.stageType === 'league'){
        let league = {
          name: stage.league.name,
          timezone : stage.league.timezone,
          abbreviation : stage.league.abbreviation,
          leagueDivision : !stage.league.leagueDivision ? "" : stage.league.leagueDivision.toString(),
          leagueDivisionName : stage.league.leagueDivisionName,
          startDate : stage.league.startDate,
          estDuration : stage.league.estDuration,
          bestOf : !stage.league.bestOf && undefined,
          matchDays : stage.league.matchDays,
          discord : stage.league.discord,
          video : stage.league.video,
          twitch : stage.league.twitch,
          rulesGhostSlug : stage.league.rulesGhostSlug,
          about : stage.league.about,
        }
        if (country) {
          const nationality = nationalities.find((data) => data.alpha2Code === country);
          league = {
            ...league,
            country: { code: country, name: nationality.demonym }
          };
        }
        await Axios.put(`${process.env.REACT_APP_CORE_API}/api/leagues/${stage.league._id}`, league);
      }else{
        let tournament = {
          name: stage.tournament.name,
          timezone : stage.tournament.timezone,
          abbreviation : stage.tournament.abbreviation,
          tournamentDivision : !stage.tournament.tournamentDivision ? "" : stage.tournament.tournamentDivision.toString(),
          tournamentDivisionName : stage.tournament.tournamentDivisionName,
          startDate : stage.tournament.startDate,
          registerationDeadline : stage.tournament.registerationDeadline,
          tournamentFormate : stage?.tournament?.tournamentFormate,
          isOpen : stage.tournament.isOpen,
          teamSlots : stage.tournament.teamSlots,
          teamSize : stage.tournament.teamSize,
          numberOfSubs : stage.tournament.numberOfSubs,
          gameServer : stage.tournament.gameServer,
          map : stage.tournament.map,
          pickMode : stage.tournament.pickMode,
          prizeText : stage.tournament.prizeText,
          entryFee : !stage.tournament.entryFee && 0,
          hostedBy : stage.tournament.hostedBy,
          discord : stage.tournament.discord,
          video : stage.tournament.video,
          twitch : stage.tournament.twitch,
          rulesGhostSlug : stage.tournament.rulesGhostSlug,
          about : stage.tournament.about,
          poweredBy : stage.tournament.poweredBy,
        }
        if (country) {
          const nationality = nationalities.find((data) => data.alpha2Code === country);
          tournament = {
            ...tournament,
            country: { code: country, name: nationality.demonym }
          };
        }
        await Axios.put(`${process.env.REACT_APP_CORE_API}/api/tournaments/${stage.tournament._id}`, tournament);
      }
      toast.success("Saved");
    } catch (e) {
      console.log("ERROR : ", e)
      toast.error("There was a problem saving");
    }

    setLoading(false);
  };

  return (
    <div>
      <Form onSubmit={onSubmit}>
        {/* <Tabs 
          id="controlled-tab-example"
          defaultActiveKey={activeStageTab}
          className="mb-3 sub-tabs"
          mountOnEnter={false}
        > */}
          {responseloader ? "Loading..." : multiStageLeague && multiStageLeague.stages.map((stage, stageIndex) => {
            if(stageIndex < 1){
              return(
                <>
            {/* <Tab key={stageIndex} eventKey={stageIndex} title={stage.name}> */}
              <Row>
                <Col md={3}>
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      // ref={register({ required: true })}
                      type="text"
                      id="name"
                      name="name"
                      defaultValue={ multiStageLeague?.name }
                      onBlur={(e) => {
                        e.preventDefault()
                        multiStageLeague.name = e.target.value 
                        updateMultiStageLeague(multiStageLeague);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.name && errors.name.message}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Label>Open for</Form.Label>
                  <div className="d-flex align-items-center">
                    <Form.Control
                      as="select"
                      ref={register({ required: true })}
                      id="country"
                      name="country"
                      value={country}
                      onBlur={() => onSubmit(stage)}
                    >
                      <option value="">Select country</option>
                      {nationalities.map(({ alpha2Code, demonym }) => (
                        <option key={alpha2Code} value={alpha2Code}>
                          {demonym}
                        </option>
                      ))}
                    </Form.Control>
                    {!!country && (
                      <img
                        src={`https://www.flagcdn.com/128x96/${country.toLowerCase()}.png`}
                        width={32}
                        height={32}
                        alt={country}
                        className="ml-2"
                      />
                    )}
                  </div>
                  <Form.Control.Feedback type="invalid">{errors.country && errors.country.message}</Form.Control.Feedback>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Time zone</Form.Label>
                    <Form.Control
                      as="select"
                      ref={register({ required: true })}
                      defaultValue={stage?.stageType === 'league' ? stage?.league?.timezone : stage?.tournament?.timezone}
                      id="timezone"
                      name="timezone"
                      onBlur={(e) => {
                        stage.stageType === 'league' ? stage.league.timezone = e.target.value : stage.tournament.timezone = e.target.value
                        onSubmit(stage);
                      }}
                    >
                      <option value="">Select timezone</option>
                      {TIMEZONES.map((zone, key) => (
                        <option key={key} value={zone.value}>
                          {zone.name}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{errors.timezone && errors.timezone.message}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Abbreviation</Form.Label>
                    <Form.Control
                      ref={register()}
                      type="text"
                      id="abbreviation"
                      name="abbreviation"
                      defaultValue={stage?.stageType === 'league' ? stage?.league?.abbreviation || "" : stage?.tournament?.abbreviation || ""}
                      onBlur={(e) => {
                        stage.stageType === 'league' ? stage.league.abbreviation = e.target.value : stage.tournament.abbreviation = e.target.value;
                        onSubmit(stage);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.abbreviation && errors.abbreviation.message}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Division</Form.Label>
                    <Form.Control
                      ref={register()}
                      type="number"
                      id="leagueDivision"
                      name="leagueDivision"
                      defaultValue={stage?.stageType === 'league' ? stage?.league?.leagueDivision : stage?.tournament?.tournamentDivision}
                      onBlur={(e) => {
                        stage.stageType === 'league' ? stage.league.leagueDivision = e.target.value : stage.tournament.tournamentDivision = e.target.value
                        onSubmit(stage);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.leagueDivision && errors.leagueDivision.message}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Division Name</Form.Label>
                    <Form.Control
                      ref={register()}
                      type="text"
                      id="leagueDivisionName"
                      name="leagueDivisionName"
                      defaultValue={stage?.stageType === 'league' ? stage?.league?.leagueDivisionName : stage?.tournament?.tournamentDivisionName}
                      onBlur={(e) => {
                        stage.stageType === 'league' ? stage.league.leagueDivisionName = e.target.value : stage.tournament.tournamentDivisionName = e.target.value
                        onSubmit(stage)
                      }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.leagueDivisionName && errors.leagueDivisionName.message}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Start Date</Form.Label>
                    <div>
                      <Datetime
                        // ref={register()}
                        utc={true}
                        name="startDate"
                        value={startDates[stageIndex]}
                        onChange={(data) => {
                          if(stage?.stageType === 'league'){
                            stage.league.startDate = new Date(data).toISOString();
                          }else{
                            stage.tournament.startDate = new Date(data).toISOString();
                          }
                          let startDatesCopy = [...startDates];
                          startDatesCopy[stageIndex] = new Date(data).toISOString();
                          setStartDates(startDatesCopy);
                        }}
                        onBlur={() => onSubmit(stage)}
                        dateFormat="MMMM Do YYYY"
                        timeFormat={false}
                      />
                    </div>
                  </Form.Group>
                </Col>
                {stage?.stageType === 'league' ?
                  <Col>
                    <Form.Group>
                      <Form.Label>Estimated Duration</Form.Label>
                      <Form.Control
                        ref={register()}
                        type="text"
                        id="estDuration"
                        name="estDuration"
                        defaultValue={stage.league.estDuration}
                        placeholder="Example: 10 weeks"
                        onBlur={(e) => {
                          stage.stageType === 'league' ? stage.league.estDuration = e.target.value : stage.tournament.estDuration = e.target.value
                          onSubmit(stage)
                        }}
                      />
                      <Form.Control.Feedback type="invalid">{errors.estDuration && errors.estDuration.message}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  :
                  <Col>
                    <Form.Group>
                      <Form.Label>Registeration Deadline</Form.Label>
                      <Datetime
                        utc={true}
                        id="registerationDeadline"
                        name="registerationDeadline"
                        dateFormat="DD/MM/YYYY"
                        timeFormat="HH:mm:ss"re
                        value={registrationDeadlines[stageIndex]}
                        onChange={(data) => {
                          stage.tournament.registerationDeadline = new Date(data).toISOString();
                          let registrationDeadlinesCopy = [...registrationDeadlines];
                          registrationDeadlinesCopy[stageIndex] = new Date(data).toISOString();
                          setRegistrationDeadlines(registrationDeadlinesCopy);
                        }}
                        onBlur={(e) => onSubmit(stage)}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.registerationDeadline && errors.registerationDeadline.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                }
                {stage?.stageType === 'league' ?
                  <Col>
                    <Form.Group>
                      <Form.Label>Best Of</Form.Label>
                      <Form.Control
                        ref={register()}
                        type="number"
                        id="bestOf"
                        name="bestOf"
                        defaultValue={stage.league.bestOf}
                        onBlur={(e) => {
                          stage.league.bestOf = e.target.value
                          onSubmit(stage)
                        }}
                      />
                      <Form.Control.Feedback type="invalid">{errors.bestOf && errors.bestOf.message}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  :
                  <Col>
                    <Form.Group>
                      <Form.Label>Tournament Format</Form.Label>
                      <Form.Control
                        ref={register()}
                        id="tournamentFormate"
                        name="tournamentFormate"
                        defaultValue={stage?.tournament?.tournamentFormate}
                        onBlur={(e) => {
                          stage.tournament.tournamentFormate = e.target.value;
                          onSubmit(stage)
                        }}
                        as="select"
                      >
                        {FORMAT.map((format) => (
                          <option value={format}>{format}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                }
              </Row>

              {stage.stageType === 'league' && 
                <Form.Group>
                  <Form.Label>Match Days</Form.Label>
                  <Form.Control
                    as="textarea"
                    ref={register()}
                    id="matchDays"
                    rows={3}
                    name="matchDays"
                    defaultValue={stage.league.matchDays}
                    onBlur={(e) => {
                      stage.league.matchDays = e.target.value
                      onSubmit(stage)
                    }}
                  />
                  <Form.Control.Feedback type="invalid">{errors.matchDays && errors.matchDays.message}</Form.Control.Feedback>
                </Form.Group>
              }
              {stage.stageType === 'tournament' &&
                <>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Open Tournament</Form.Label>
                        <Form.Control
                          ref={register()}
                          id="isOpen"
                          name="isOpen"
                          defaultValue={stage.tournament.isOpen}
                          onBlur={(e) => {
                            stage.tournament.isOpen = e.target.value
                            onSubmit(stage)
                          }}
                          as="select"
                        >
                          <option value={true}>True</option>
                          <option value={false}>False</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Max Team Slots</Form.Label>
                        <Form.Control
                          ref={register()}
                          type="number"
                          id="teamSlots"
                          name="teamSlots"
                          min={4}
                          defaultValue={stage.tournament.teamSlots}
                          onBlur={(e) => {
                            stage.tournament.teamSlots = e.target.value;
                            onSubmit(stage)
                          }}
                        />
                        <Form.Control.Feedback type="invalid">{errors.teamSlots && errors.teamSlots.message}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Team Size</Form.Label>
                        <Form.Control
                          ref={register()}
                          id="teamSize"
                          name="teamSize"
                          defaultValue={stage.tournament.teamSize}
                          onBlur={(e) => {
                            stage.tournament.teamSize = e.target.value;
                            onSubmit(stage)}}
                          as="select"
                        >
                          <option value={1}>1 vs 1</option>
                          <option value={3}>3 vs 3</option>
                          <option value={5}>5 vs 5</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>Number Of Subs</Form.Label>
                        <Form.Control
                          ref={register()}
                          type="number"
                          id="numberOfSubs"
                          name="numberOfSubs"
                          defaultValue={stage.tournament.numberOfSubs || 5}
                          onBlur={(e) => {
                            stage.tournament.numberOfSubs = e.target.value;
                            onSubmit(stage)
                          }}
                        />
                        <Form.Control.Feedback type="invalid">{errors.teamSize && errors.teamSize.message}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  {stage.tournament.game?.name !== "CS:GO" ?
                    <Row>
                      <Col>
                        <Form.Group>
                        <Form.Label>Game Server</Form.Label>
                        <Form.Control
                          ref={register()}
                          id="gameServer"
                          name="gameServer"
                          defaultValue={stage.tournament.gameServer}
                          onBlur={(e) => {
                            stage.tournament.gameServer = e.target.value;
                            onSubmit(stage)
                          }}
                          as="select"
                        >
                          <option value={"NA"}>NA</option>
                          <option value={"EUW"}>EUW</option>
                          <option value={"ENU"}>ENU</option>
                          <option value={"KR"}>KR</option>
                          <option value={"BR"}>BR</option>
                          <option value={"JP"}>JP</option>
                          <option value={"RU"}>RU</option>
                          <option value={"OCE"}>OCE</option>
                          <option value={"TR"}>TR</option>
                          <option value={"LAN"}>LAN</option>
                          <option value={"LAS"}>LAS</option>
                        </Form.Control>
                      </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Map</Form.Label>
                          <Form.Control 
                            ref={register()} 
                            id="map" 
                            name="map" 
                            defaultValue={stage.tournament.map} 
                            onBlur={(e) => {
                              stage.tournament.map = e.target.value;
                              onSubmit(stage)
                            }}
                            as="select">
                            <option value={"SUMMONERS_RIFT"}>Summoner's Rift</option>
                            <option value={"HOWLING_ABYSS"}>Howling Abyss</option>
                          </Form.Control>
                        </Form.Group>  
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Pick Mode</Form.Label>
                          <Form.Control
                            ref={register()}
                            id="pickMode"
                            name="pickMode"
                            defaultValue={stage.tournament.pickMode || "TOURNAMENT_DRAFT"}
                            onBlur={(e) => {
                              stage.tournament.pickMode = e.target.value;
                              onSubmit(stage)
                            }}
                            as="select"
                          >
                            {pickModeOptions.map(({ name, value }) => (
                              <option id={name} value={value}>
                                {name}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row> : null
                  }
                  <Row>
                    <Col>
                        <Form.Group>
                          <Form.Label>Prize Text</Form.Label>
                          <Form.Control
                            ref={register()}
                            type="text"
                            id="prizeText"
                            name="prizeText"
                            defaultValue={stage.tournament.prizeText}
                            onBlur={(e) => {
                              stage.tournament.prizeText = e.target.value;
                              onSubmit(stage)
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Entry Fee</Form.Label>
                          <Form.Control
                            ref={register()}
                            type="number"
                            id="entryFee"
                            name="entryFee"
                            min={0}
                            defaultValue={stage.tournament.entryFee}
                            onBlur={(e) => {
                              stage.tournament.entryFee = e.target.value;
                              onSubmit(stage)
                            }}
                          />
                          <Form.Control.Feedback type="invalid">{errors.entryFee && errors.entryFee.message}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Hosted By</Form.Label>
                          <Form.Control
                            ref={register()}
                            type="text"
                            id="hostedBy"
                            name="hostedBy"
                            defaultValue={stage.tournament.hostedBy}
                            onBlur={(e) => {
                              stage.tournament.hostedBy = e.target.value;
                              onSubmit(stage)
                            }}
                          />
                          <Form.Control.Feedback type="invalid">{errors.tournamentGroup && errors.tournamentGroup.message}</Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                  </Row>
                </>
              }


              <Form.Group>
                <Form.Label>Discord Link</Form.Label>
                <Form.Control
                  ref={register()}
                  type="url"
                  id="discord"
                  name="discord"
                  placeholder="Example: https://discord.gg/...."
                  defaultValue={stage.stageType === 'league' ? stage.league.discord : stage.tournament.discord}
                  onBlur={(e) => {
                    stage.stageType === 'league' ? stage.league.discord = e.target.value : stage.tournament.discord = e.target.value;
                    onSubmit(stage)
                  }}
                />
                <Form.Control.Feedback type="invalid">{errors.discord && errors.discord.message}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>YouTube Video</Form.Label>
                <Form.Control
                  ref={register()}
                  type="url"
                  id="video"
                  name="video"
                  placeholder="Example: https://youtube.com/...."
                  defaultValue={stage.stageType === 'league' ? stage.league.video : stage.tournament.video}
                  onBlur={(e) => {
                    stage.stageType === 'league' ? stage.league.video = e.target.value : stage.tournament.video = e.target.value;
                    onSubmit(stage)
                  }}
                />
                <Form.Control.Feedback type="invalid">{errors.video && errors.video.message}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>Twitch Channel</Form.Label>
                <Form.Control
                  ref={register()}
                  type="url"
                  id="twitch"
                  name="twitch"
                  placeholder="Example: https://twitch.tv/...."
                  defaultValue={stage.stageType === 'league' ? stage.league.twitch : stage.tournament.twitch}
                  onBlur={(e) => {
                    stage.stageType === 'league' ? stage.league.twitch = e.target.value : stage.tournament.twitch = e.target.value;
                    onSubmit(stage)
                  }}
                />
                <Form.Control.Feedback type="invalid">{errors.twitch && errors.twitch.message}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>Rules Ghost Slug</Form.Label>
                <Form.Control
                  ref={register()}
                  type="text"
                  id="rulesGhostSlug"
                  name="rulesGhostSlug"
                  placeholder="Example: leagues-gg-rules-2"
                  defaultValue={stage.stageType === 'league' ? stage.league.rulesGhostSlug : stage.tournament.rulesGhostSlug}
                  onBlur={(e) => {
                    stage.stageType === 'league' ? stage.league.rulesGhostSlug = e.target.value : stage.tournament.rulesGhostSlug = e.target.value;
                    onSubmit(stage)
                  }}
                />
                <Form.Control.Feedback type="invalid">{errors.rulesGhostSlug && errors.rulesGhostSlug.message}</Form.Control.Feedback>
              </Form.Group>

              <Form.Label>About</Form.Label>
              <RichTextbox
                id="about"
                name="about"
                content={stage.stageType === 'league' ? stage.league?.about : stage.tournament?.about}
                onBlur={(content) => {
                  if(stage.stageType === 'league'){
                    stage.league.about = content.target.innerHTML;
                  }else{
                    stage.tournament.about = content.target.innerHTML;
                  }
                  onSubmit(stage)
                }}
              />

              {stage.stageType === 'tournament' && 
                <>
                  <Form.Label>Powered By</Form.Label>
                  <RichTextbox
                    id="poweredBy"
                    name="poweredBy"
                    content={stage.tournament?.poweredBy}
                    onBlur={(content) => {
                      stage.tournament.poweredBy = content.target.innerHTML;
                      onSubmit(stage)
                    }}
                  />
                </>
              }

              <div>
                <div className="d-flex">
                  {/* Add new images to the array */}
                  {[
                    { name: "Logo", key: "logoImage", width: 250, height: 250, displayWidth: 150, displayHeight: 150, crop: true },
                    { name: "Thumbnail", key: "thumbnailImage", width: 500, height: 300, displayWidth: 250, displayHeight: 150, crop: true },
                    { name: "Cover", key: "coverImage", width: 800, height: 200, displayWidth: 500, displayHeight: 150, crop: false },
                  ].map(({ name, key, width, height, displayWidth, displayHeight, crop }) => (
                    <div key={key}>
                      <>
                        <EditImageModal
                          show={modals[key]}
                          onHide={() => setModals({ ...modals, [key]: false })}
                          name={name}
                          width={width}
                          height={height}
                          afterImageUpload={(file) => onUpdateImage(key, file, stageIndex)}
                          crop={crop}
                        />

                        <Form.Group className="mr-4">
                          <Form.Label>{name}</Form.Label>
                          <div
                            className="edit-image-btn"
                            style={{
                              backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${stage.stageType === 'league' ? stage.league[key] : stage.tournament[key]})`,
                              width: displayWidth,
                              height: displayHeight,
                            }}
                            onClick={() => setModals({ ...modals, [key]: true })}
                          >
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </div>
                        </Form.Group>
                      </>
                    </div>
                  ))}
                </div>
              </div> 
              {stage.stageType === 'tournament' &&
                <div>
                  <div className="d-flex">
                    {/* Add new images to the array */}
                    {[
                      {
                        name: "Advertisement 1",
                        key: "firstAd",
                        width: 500,
                        height: 300,
                        displayWidth: 250,
                        displayHeight: 150,
                        crop: true,
                        isCheck: true,
                      },
                      {
                        name: "Advertisement 2",
                        key: "secondAd",
                        width: 500,
                        height: 300,
                        displayWidth: 250,
                        displayHeight: 150,
                        crop: true,
                        isCheck: true,
                      },
                    ].map(({ name, key, width, height, displayWidth, displayHeight, crop, isCheck }) => (
                      <div key={key}>
                        <EditImageModal
                          show={modals[key]}
                          onHide={() => setModals({ ...modals, [key]: false })}
                          name={name}
                          width={width}
                          height={height}
                          afterImageUpload={(file) => onUpdateImage(key, file, stageIndex)}
                          crop={crop}
                        />

                        <Form.Group className="mr-4">
                          <Form.Label>{name}</Form.Label>
                          {isCheck ? (
                            <Form.Switch id={key} label="Replace ad" onChange={(e) => stage.tournament[key] = !stage.tournament[key]} />
                          ) : null}
                          <div
                            className="edit-image-btn"
                            style={{
                              backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${stage.tournament[key]})`,
                              width: displayWidth,
                              height: displayHeight,
                            }}
                            onClick={() => setModals({ ...modals, [key]: true })}
                          >
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </div>
                        </Form.Group>
                      </div>
                    ))}
                  </div>
                </div> 
              }
              <Button type="submit" variant="success" disabled={loading} onClick={() => onSubmit(stageIndex)}>
                {loading ? "Saving..." : "Save Changes"}
              </Button>
            {/* </Tab> */}
            </>
          )}
          })}
        {/* </Tabs> */}
      </Form>
    </div>
  );
};

export default Information;
