import React, { useState } from "react";
import { Card, Nav, Button, Col, Row } from "react-bootstrap";
import Matches from "./Matches";
// import CreateEditMatchModal from "./includes/CreateEditMatchModal";
import FilterMatches from "../includes/FilterMatches";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ScrimEditModal from '../includes/ScrimEditModal';

const TabsManager = ({ scrims, setScrims, setQuery, game, getData, handlePerPageChange, totalRows, loading}) => {
  const [activeTab, setActiveTab] = useState("Matches");
  const [showScrimModal, setShowScrimModal] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [filteredScrims, setFilteredScrims] = useState([]);
 
  const onEditScrim = (data) => {
    setShowScrimModal(true);
    setModalData(data);
  };

  const getScrimsData = (search) => {
    setQuery(search);
    getData();
  }

  // ADD/REMOVE TABS THROUGH THIS ARRAY
  const TABS = [
    {
      name: "Matches",
      content: (
        <Matches
          // teams={league.teams}
          getData={getScrimsData}
          handlePerPageChange={handlePerPageChange}
          totalRows={totalRows}
          scrims={scrims}
          setScrims={setScrims}
          loading = {loading}
          onEditScrim={onEditScrim}
          game={game}
         
        />
      )
    }
  ];

  return (
    <>
      <Card>
        <Card.Header>
          <Nav variant="tabs" activeKey={activeTab} onSelect={(t) => setActiveTab(t)}>
            {TABS.map(({ name }) => (
              <Nav.Item key={name}>
                <Nav.Link eventKey={name} active={activeTab === name}>
                  {name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Card.Header>
        <Card.Body>
          {/* Show create match/filtering on matches/calendar only */}
          {["Matches"].includes(activeTab) && (
            <Row>
              <Col lg={12}>
                <FilterMatches getScrimsData={getScrimsData}/>
              </Col>
            </Row>
          )}
          <ScrimEditModal
            show={showScrimModal}
            onHide={() => setShowScrimModal(false)}
            scrim={modalData}
            game={game}
            onDelete={() => getData()}
            onUpdate={() => getData()}
            // league={league}
            // updateMatches={updateMatches}
          />

          {TABS.map(({ name, content }) => name === activeTab && <div key={name}>{content}</div>)}
        </Card.Body>
      </Card>
    </>
  );
};

export default TabsManager;
