import React, { useState, useEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const FilterMatches = ({ matches, setFilteredMatches }) => {
  const [filterQuery, setFilterQuery] = useState("");

  // When query changes, filter matches
  useEffect(() => {
    const q = filterQuery?.toLowerCase();
    const newMatches = matches?.filter((m) => {
      if(!m){
        return
      }
      const { t1, t2 , datetime } = m
      const t1n = t1 ? t1.name : "BYE";
      const t2n = t2 ? t2.name : "BYE";

      // If the search contains [x vs x] search for those teams
      const vsQuery = q.match(/(.+)\s+v.?s.?\s+(.+)/i);
      if (vsQuery) {
        return (
          (t1n.toLowerCase().includes(vsQuery[1].trim()) && t2n.toLowerCase().includes(vsQuery[2].trim())) ||
          (t2n.toLowerCase().includes(vsQuery[1].trim()) && t1n.toLowerCase().includes(vsQuery[2].trim()))
        );
      }

      // Regular search algorithm

      // Add data to this that you want to search
      const searchFields = [t1n, t2n, moment(datetime).format("DD/MM/YYYY HH:mm")];

      return !!searchFields.filter((field) => field?.toLowerCase().includes(q)).length;
    });

    setFilteredMatches(newMatches);
  }, [filterQuery, matches, setFilteredMatches]);

  return (
    <Form.Group>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text id="inputGroupPrepend">
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control type="search" placeholder="Filter matches" value={filterQuery} onChange={(e) => setFilterQuery(e.target.value)} />
      </InputGroup>
    </Form.Group>
  );
};

export default FilterMatches;
