import React, { useState } from "react";
import { Card, Modal, Nav } from "react-bootstrap";
import SetPlayers from "./SetPlayers";

const MatchSetPlayersModal = ({ show, onHide, match, updateMatches, league, flag, isScrim, game = null, updateMatchRecords }) => {
  const [activeTab, setActiveTab] = useState(0);

  if (!match) return null;

  const { t1 = {}, t2 = {},sp1 = {},sp2 = {},isSeedingMatch } = match;
  // console.log("t1: ", t1)
  // console.log("t2: ", t2)


  const tabs = [
    {
      name: isSeedingMatch ? `Seed ${sp1?.seedPosition}` : t1 ? `${t1?.name}` : "[DELETED]",
      content: t1 ? (
        <SetPlayers
          onClose={onHide}
          match={match}
          updateMatches={updateMatches}
          league={league}
          teamData={t1}
          teamSelector="t1"
          isScrim={isScrim}
          flag={flag}
          game={game}
          updateMatchRecords={updateMatchRecords}
        />
      ) : (
        "Team not found."
      ),
    },
    {
      name: isSeedingMatch ? `Seed ${sp2?.seedPosition}` : t2 ? `${t2?.name}` : "[DELETED]",
      content: t2 ? (
        <SetPlayers
          onClose={onHide}
          match={match}
          updateMatches={updateMatches}
          league={league}
          teamData={t2}
          teamSelector="t2"
          isScrim={isScrim}
          flag={flag}
          game={game}
          updateMatchRecords={updateMatchRecords}
        />
      ) : (
        "Team not found."
      ),
    },
  ];

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Manually Set Players</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ padding: 0 }}>
        <Card style={{ borderRadius: 0, border: 0, margin: 0 }}>
          <Card.Header>
            <Nav variant="tabs" activeKey={activeTab} onSelect={(t) => setActiveTab(parseInt(t))}>
              {tabs.map(({ name }, i) => (
                <Nav.Item key={i}>
                  <Nav.Link eventKey={i}>{name}</Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Card.Header>
          <Card.Body>{tabs.map(({ content }, i) => i === activeTab && <div key={i}>{content}</div>)}</Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
};

export default MatchSetPlayersModal;
