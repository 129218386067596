import React, { useState, useEffect } from "react";
import { Table, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import EditImageModal from "../../../../../_modals/EditImageModal/EditImageModal";
import Axios from "axios";
import { toast } from "react-toastify";

const Players = ({ playerTypes = [], data: gameData = {}, setGame }) => {
  const [players, setPlayers] = useState([]);
  const [editImageModal, setEditImageModal] = useState(false);
  const [roleReassign, setRoleReassign] = useState(gameData?.configuration?.playerRoleReassign || false);

  useEffect(() => {
    if (playerTypes.length === 1 && playerTypes[0].name === "Player") setPlayers([{ name: "SUB", icon: false }]);
    else setPlayers(playerTypes);
  }, [playerTypes]);

  const onAddPlayer = () => {
    const _players = players.filter(player => player.name !== "SUB");
    const subPlayer = players.find(player => player.name === "SUB");

    setPlayers([..._players, { icon: false, name: "" }, subPlayer]);
  };

  const onUpdateRow = (index, key, value) => {
    setPlayers((players) => players.map((player, i) => (index === i ? { ...player, [key]: value } : player)));
  };

  const onEditIcon = (i) => {
    setEditImageModal({ i });
  };

  const onRemovePlayer = (index) => {
    if (players.length > 1) {
      setPlayers((players) => players.filter((_, i) => i !== index));
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/games/${gameData._id}/config`,
        { ...gameData.configuration, playerTypes: players, playerRoleReassign: roleReassign }
      );
      data && data.updatedGame && setGame(data.updatedGame);
      toast.success("Successful");
    } catch (e) {
      toast.error("Could not update player types");
    }

  };

  return (
    <>
      <Form onSubmit={onSubmit}>
        <div style={{position: 'absolute'}}>
          <Form.Check 
            type='checkbox'
            id={`default-checkbox`}
            label='Allow multiple roles assigning'
            checked={roleReassign}
            onChange={(e) => {setRoleReassign(!roleReassign); onSubmit(e)}}
          />
        </div>
        <div className="d-flex justify-content-end mb-2">
          <Button size="sm" variant="link" onClick={onAddPlayer} className="p-0">
            <FontAwesomeIcon icon={faPlus} /> Add Player Type
          </Button>
        </div>

        <Table striped bordered>
          <thead>
            <tr>
              <th>Icon</th>
              <th>Players</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {players && players.map(({ icon, name, numberOfPlayers = Number(0) }, i) => (
              <tr key={i}>
                <td style={{ backgroundColor: "#222", width: 110 }}>
                  {icon && <img src={icon} alt={name} width={38} height={38} style={{ borderRadius: 3 }} />}
                  <Button variant="link" className="text-white" onClick={() => onEditIcon(i)}>
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </Button>
                </td>
                <td className="justify-content-between">
                  <Form.Control min={0} max={10} type="number" value={numberOfPlayers} onChange={(e) => onUpdateRow(i, "numberOfPlayers", e.target.value)} />
                </td>
                <td className="d-flex justify-content-between">
                  <Form.Control type="text" value={name} onChange={(e) => onUpdateRow(i, "name", e.target.value)} style={{ width: 150 }} />
                  {players.length > 1 && name !== "SUB" && (
                    <Button variant="link" className="text-danger" onClick={() => onRemovePlayer(i)}>
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>

        </Table>
        <p><small>The "Players" field will determine how many of that player type you can have on a team. 0 will be special and mean unlimited.</small></p>
        <Button type="submit" size="lg" block variant="success">
          Save Player Types
        </Button>
      </Form>

      <EditImageModal
        show={!!editImageModal}
        onHide={() => setEditImageModal(false)}
        name="Player Type Icon"
        width={100}
        height={100}
        crop={true}
        afterImageUpload={(url) => {
          onUpdateRow(editImageModal.i, "icon", url);
          setEditImageModal(false);
        }}
      />
    </>
  );
};

export default Players;
