import React from "react";
import Axios from "axios";
const UpdateTranslationApi = async (payload) => {
  try {
    const resp = await Axios({
      method: "put",
      url: `${process.env.REACT_APP_CORE_API}/api/language/updateTranslations`,
      data: payload.updatevaluecall,
    });
    return resp.data;
  } catch (err) {
    return {error : true};
  }
};
export default UpdateTranslationApi;
