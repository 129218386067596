import React, { useEffect, useState } from "react";
import { Button, Form, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import Axios from "axios";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import moment from "moment-timezone/builds/moment-timezone-with-data";
// import { TIMEZONES } from "../../../../../utils/timezones";
import { TIMEZONES } from "../Leagues/LeagueMatches/Tabs/timezones";
import _ from 'lodash';
import * as Duel from "duel";
import * as Koth from "../../../libs/koth";
import Datetime from "react-datetime";
import { setSeedingData } from "./MultiStageLeagueMatches/action";
//multistaging
const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const GenerateMatches = () => {
  const { query } = useLocation();
  const [stageIndex] = useState(query?.stageIndex);
  const { id } = useParams();
  const history = useHistory();
  const [league, setLeague] = useState(false);
  const [loadingSS, setLoadingSS] = useState(true);
  const [scoreSystems, setScoreSystems] = useState([]);
  const [vetoFlag, setVetoFlag] = useState(true);
  const [showVeto, setShowVeto] = useState(false);
  const [leagueSelectedScoreSystem, setLeagueSelectedScoreSystem] = useState(false);
  const [bestOf, setBestOf] = useState(false);
  const [startDate, setStartDate] = useState(false);
  const [weeklySchedule, setWeeklySchedule] = useState([]);
  const [noOfWeeks, setNoOfWeeks] = useState(0);
  const [teamMeetQuantity, setTeamMeetQuantity] = useState(0);
  const [broadcasted, setBroadcasted] = useState(false);
  const [sideSelection, setSideSelection] = useState(false);
  const [prefferedTimezone, setPrefferedTimezone] = useState(TIMEZONES[0].value);
  const [numberOfTeamsWithByes, setNumberOfTeamsWithByes] = useState(0);
  const [numberOfMatches, setNumberOfMatches] = useState(0);
  const [roundsPerWeek, setRoundsPerWeek] = useState(0);
  const [allStages, setAllStages] = useState([]);
  const [selectedStage, setSelectedStage] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(false);

  // TOURNAMENT STATES
  const [tournament, setTournament] = useState(false);
  const [tournamentScoreSystem] = useState([
    { name: "Single Elimination", id: 1 },
    { name: "Double Elimination", id: 2 },
    { name: "King of the Hill", id: 3 },
  ]);
  const [tournamentTypes] = useState([
    { name: "No Third Place", id: 1 },
    { name: "Include Third Place", id: 2 },
  ]);
  const [tournamentSelectedScoreSystem, setTournamentSelectedScoreSystem] = useState(false);
  const [tournamnetType, setTournamentType] = useState(false);
  const [isShort, setIsShort] = useState(true);
  const [timeBetweenMatches, setTimeBetweenMatches] = useState(75);
  const [round, setRounds] = useState(0);
  const [numberOfTournamentMatches, setNumberOfTournamentMatches] = useState(false);
  const [matchSchedule, setMatchSchedule] = useState({});
  const [roundArr, setRoundArr] = useState([]);
  const [isGenerated, setIsGenerated] = useState(false);
  const [noOfTeams, setNoOfTeam] = useState(0);
  const [teamOrder, setTeamOrder] = useState([]);

  //! the following and code related to it will change in future
  const [noOfSeeds, setNoOfSeeds] = useState(0);
  const [seedsOrder, setSeedsOrder] = useState([]);
  const [generateOnSeeds, setGenerateOnSeeds] = useState(false);
  useEffect(()=>{
    const fetchData = async (id,stageId,_data)=>{
      return await setSeedingData(id,stageId,_data)

    }
    if(allStages.length === 1){
      let _data = []
      let id 
      let stageId
      const groups = allStages[0]?.groups
      if(groups.length === 1){
        id = allStages[0]?.multiStageLeague?._id
        stageId = allStages[0]._id
        groups.forEach((el,i)=>{
          if(el?.teams.length === 0){
            let _teams = []
            let noOfTeams = el.noOfTeams
            // allStages[0]?.tournament?.teams ? allStages[0]?.tournament?.teams : allStages[0]?.league?.teams
            let teams = allStages[0]?.tournament?.teams || allStages[0]?.league?.teams
            if(teams.length > 0){
              teams.forEach((elem,ind) =>{
                if(ind < noOfTeams){
                  let obj = {
                    seedPosition: ind + 1,
                    team: elem.team._id
                  }
                  _teams.push(obj)
                }
              })
              _data.push({_id:el?._id,teams:_teams})
         
                fetchData(id,stageId,_data).then((res)=> {getStageData()})

              
            }

          }
          
        })

      }
    }
  },[allStages])
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/scoreSystems`).then(async ({ data }) => {
      await data.forEach(scrSystem => {
        if (scrSystem.name === "Best of") {
          scrSystem.configuration = _.orderBy(scrSystem.configuration, ['bestof'], ['asc']);
        }
      })

      setScoreSystems(data);
      setShowVeto(false);
      setLoadingSS(false);
    });
    getStageData()
  }, [id]);

  useEffect(() => {
    if (selectedStage) {
      if (selectedStage.stageType === 'league') {
        Axios.get(`${process.env.REACT_APP_CORE_API}/api/leagues/${selectedStage.league._id}`).then(({ data }) => {
          setLeague(data);
        });
      } else {
        Axios.get(`${process.env.REACT_APP_CORE_API}/api/tournaments/${selectedStage.tournament._id}`).then(({ data }) => {
          setTournament(data);
          setShowVeto(false);
        });
      }
    }
  }, [selectedStage])

  const getStageData = ()=>{
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/stage`)
      .then(({ data }) => {
        setAllStages(data.data);
        setSelectedStage(stageIndex ? data.data[stageIndex] : false);
      })
      .catch((err) => {
        toast.error("There was a problem in getting stages");
      });
  }
  const getScoreSystemData = () => {
    if (leagueSelectedScoreSystem) {
      return scoreSystems.find(({ _id }) => _id === leagueSelectedScoreSystem);
    }
    return false;
  };

  const updateWeeklySchedule = (index, key, value) => {
    const schedule = weeklySchedule;
    schedule[index] = { ...schedule[index], [key]: value };
    setWeeklySchedule([...schedule]);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let vetoEnable = showVeto ? vetoFlag : false;
    const updatedWeeklySchedule = weeklySchedule.reduce(
      (acc, _scheduleItem) => [...acc, { ..._scheduleItem, time: moment(_scheduleItem.time).tz(prefferedTimezone, true) }],
      []
    );

    try {
      if (selectedStage.stageType === 'league') {
        // if (generateOnSeeds)
        if(true) {
          await Axios.post(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${league._id}/matches/generateSeedMatch?isVeto=${vetoEnable}`, {
            league: league._id,
            seedPositions: seedsOrder.map((team) => ({ group: selectedGroup._id, seedPosition: team })),
            scoreSystem: leagueSelectedScoreSystem,
            bestOf,
            startDate,
            weekSchedule: updatedWeeklySchedule,
            durationWeeks: noOfWeeks,
            teamsMeetQty: parseInt(selectedStage.encounters),
            broadcasted,
            sideSelection,
            groups: [selectedGroup._id]
          });
        } else {
          await Axios.post(`${process.env.REACT_APP_CORE_API}/api/leagues/${league._id}/matches/generate?isVeto=${vetoEnable}`, {
            league: league._id,
            teams: teamOrder.map(({ team }) => team),
            scoreSystem: leagueSelectedScoreSystem,
            bestOf,
            startDate,
            weekSchedule: updatedWeeklySchedule,
            durationWeeks: noOfWeeks,
            teamsMeetQty: parseInt(selectedStage.encounters),
            broadcasted,
            sideSelection,
            groups: [selectedGroup._id]
          });
        }
      } else {
        onShuffle();
        const updatedMatchSchedule = Object.keys(matchSchedule).reduce(
          (acc, scheduleItemIndex) => ({
            ...acc,
            [scheduleItemIndex]: {
              ...matchSchedule[scheduleItemIndex],
              date: moment(matchSchedule[scheduleItemIndex].date).tz(prefferedTimezone, true),
            },
          }),
          {}
        );
        const body = {
          tournament: selectedStage.tournament._id,
          teams: teamOrder.map(({ team }) => team),
          scoreSystem: parseInt(tournamentSelectedScoreSystem),
          bestOf,
          matchSchedule: updatedMatchSchedule,
          broadcasted,
          sideSelection,
          groups: [selectedGroup._id]
        };
        let vetoEnable = showVeto ? vetoFlag : false;

        await Axios.post(
          `${process.env.REACT_APP_CORE_API}/api/tournaments/${selectedStage.tournament._id}/matches/generateMatches/multistage/tournament?isShort=${isShort}&isVeto=${vetoEnable}`,
          body
        );
      }
      toast.success("Successfully generated matches");
      history.push("matches");
    } catch (e) {
      toast.error("Problem generating matches!");
    }
  };

  // TOURNAMENT FUNCTIONS

  const handleSelectTournamentType = (e) => {
    if (parseInt(e) === 1) {
      setTournamentType(parseInt(e));
      setIsShort(true);
    } else {
      setTournamentType(parseInt(e));
      setIsShort(false);
    }
  };

  const generateResult = () => {
    if (tournamentSelectedScoreSystem === 1) {
      let duel = new Duel(selectedGroup?.teams?.length, { short: isShort });
      duel.matches = _.orderBy(duel.matches, ["id.r"], ["asc"]);
      setRounds(duel?.matches[duel?.matches?.length - 1]?.id?.r);
      setNumberOfTournamentMatches(duel.matches.length);
      let _roundArr = [];
      for (let i = 0; i < duel?.matches[duel?.matches?.length - 1]?.id?.r; i++) {
        _roundArr.push(i);
        let date = selectedStage?.tournament?.startDate ? new Date(selectedStage?.tournament.startDate) : new Date();
        //date.setHours(10,0,0,0);
        if (i === 0) {
          matchSchedule[i + 1] = {
            date: new Date(date.getTime()),
          };
        } else {
          matchSchedule[i + 1] = {
            date: new Date(matchSchedule[i]?.date?.getTime() + timeBetweenMatches * bestOf * 60000),
          };
        }

        setMatchSchedule({
          ...matchSchedule,
        });
      }
      setRoundArr(_roundArr);
    } else if (tournamentSelectedScoreSystem === 2) {
      let duel = new Duel(selectedGroup.teams?.length, { last: tournamentSelectedScoreSystem, short: isShort });
      setRounds(duel?.matches[duel?.matches?.length - 1]?.id?.r);
      setNumberOfTournamentMatches(duel.matches.length);
      let _roundArr = [];
      for (let i = 0; i < duel?.matches[duel?.matches?.length - 1]?.id?.r; i++) {
        _roundArr.push(i);
        let date = selectedStage?.tournament?.startDate ? new Date(selectedStage?.tournament.startDate) : new Date();
        //date.setHours(10,0,0,0);
        if (i === 0) {
          matchSchedule[i + 1] = {
            date: new Date(date.getTime()),
          };
        } else {
          matchSchedule[i + 1] = {
            date: new Date(matchSchedule[i]?.date?.getTime() + timeBetweenMatches * bestOf * 60000),
          };
        }
        setMatchSchedule({
          ...matchSchedule,
        });
      }
      setRoundArr(_roundArr);
    } else if (tournamentSelectedScoreSystem === 3) {
      let duel = new Koth(selectedGroup.teams?.length, { short: isShort });
      duel.matches = _.orderBy(duel.matches, ["id.r"], ["asc"]);
      setRounds(duel?.matches[duel?.matches?.length - 1]?.id?.r);
      setNumberOfTournamentMatches(duel.matches.length);
      let _roundArr = [];
      for (let i = 0; i < duel?.matches[duel?.matches?.length - 1]?.id?.r; i++) {
        _roundArr.push(i);
        let date = selectedStage?.tournament?.startDate ? new Date(selectedStage?.tournament.startDate) : new Date();
        //date.setHours(10,0,0,0);
        if (i === 0) {
          matchSchedule[i + 1] = {
            date: new Date(date.getTime()),
          };
        } else {
          matchSchedule[i + 1] = {
            date: new Date(matchSchedule[i]?.date?.getTime() + timeBetweenMatches * bestOf * 60000),
          };
        }

        setMatchSchedule({
          ...matchSchedule,
        });
      }
      setRoundArr(_roundArr);
    }

    setIsGenerated(true);
  };

  const onShuffle = () => {
    let _order = [];
    let _teams = [];
    let order = 1;
    for (let team in teamOrder) {
      _order.push(order);
      order++;
    }

    for (let i = 0; i < teamOrder.length; i++) {
      let team = teamOrder[i];
      if (team.seedOrder === 999) {
        team.seedOrder = _order[i];
      }
      _teams.push(team);
    }

    setTeamOrder(_teams);
  };

  return (
    <div>
      <div className="mb-4">
        <Button as={Link} to="matches" variant="link" className="mr-2 mb-2 p-0">
          <FontAwesomeIcon icon={faArrowLeft} /> Back to league
        </Button>
        <h2>Generate Matches</h2>
      </div>

      <Form onSubmit={onSubmit}>
        <h5 className="mt-3 mb-3">General</h5>
        <Form.Group>
          <Form.Label>Stages</Form.Label>
          <Form.Control
            name="stageSelect"
            as="select"
            onChange={(e) => {
              setSelectedStage(allStages[e.target.value]);
              if (selectedGroup) {
                e.target.form.elements.groupSelect.value = "";
                setSelectedGroup(false);
                setLeagueSelectedScoreSystem(false);
                setTournamentSelectedScoreSystem(false);
                setBestOf(false)
              }
            }}
          >
            <option selected disabled value="">{selectedStage ? selectedStage.name : "Select the stage"}</option>
            {allStages?.map((stage, stageIndex) => (
              <option
                key={stageIndex}
                value={stageIndex}
                label={stage.name}
              />
            ))}
          </Form.Control>
        </Form.Group>
        {selectedStage && (
          <Form.Group>
            <Form.Label>Groups</Form.Label>
            <Form.Control
              name="groupSelect"
              as="select"
              onChange={(e) => {
                const idx = e.target.value;
                if (selectedStage.groups[idx].teams.length > 0) {
                  let teamByes = selectedStage.groups[e.target.value].teams.length % 2 === 1 ? selectedStage.groups[e.target.value].teams.length + 1 : selectedStage.groups[e.target.value].teams.length;
                  setNumberOfTeamsWithByes(teamByes)
                  setSelectedGroup(selectedStage.groups[e.target.value])
                  setTeamOrder(selectedStage.groups[e.target.value].teams);
                  setNoOfTeam(selectedStage.groups[e.target.value].teams.length);
                } else {
                  let group = selectedStage.groups[idx];
                  setSelectedGroup(selectedStage.groups[idx]);
                  let newSeeds = [];
                  for (let i = 1; i <= group.noOfTeams; i++) {
                    newSeeds.push(i);
                  }
                  setSeedsOrder(newSeeds);
                  let teamByes = newSeeds.length % 2 === 1 ? newSeeds.length + 1 : newSeeds.length;
                  setNumberOfTeamsWithByes(teamByes)
                  setNoOfSeeds(group.noOfTeams)
                  setNoOfTeam(group.noOfTeams)
                  setGenerateOnSeeds(true);
                }
                selectedStage.stageType === 'tournament' && setTournamentSelectedScoreSystem(selectedStage.bracketType)
                selectedStage.stageType === 'tournament' && selectedStage.playThirdPlaceOrder ? handleSelectTournamentType(2) : handleSelectTournamentType(1)
              }}
            >
              <option selected value="">Select group</option>

              {selectedStage?.groups?.map((group, groupIndex) => (
                <option
                  key={groupIndex}
                  value={groupIndex}
                  label={group.name}
                />
              ))}
            </Form.Control>
          </Form.Group>
        )}
        {selectedStage && selectedStage.stageType === 'league' ?
          // LEAGUE FORM
          <>
            {selectedGroup && (
              <Form.Group>
                <Form.Label>Score System</Form.Label>
                <Form.Control as="select" value={leagueSelectedScoreSystem || "_DEFAULT_"} onChange={(e) => setLeagueSelectedScoreSystem(e.target.value)}>
                  <option disabled value="_DEFAULT_">
                    {loadingSS ? "Loading..." : "Select score system"}
                  </option>
                  {scoreSystems.map(({ _id, name }) => (
                    <option key={_id} value={_id}>
                      {name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            )}
            {leagueSelectedScoreSystem && (
              <Form.Group>
                <Form.Label>Best Of</Form.Label>
                <Form.Control
                  as="select"
                  value={bestOf || "_DEFAULT_"}
                  onChange={(e) => {
                    setBestOf(e.target.value);
                    setShowVeto(
                      leagueSelectedScoreSystem.toString() === "5f6c76bc09fc214fea5dc921" &&
                        league?.game?.shortName === "CSGO" &&
                        [1, 3, 5].includes(parseFloat(e.target.value))
                        ? true
                        : false
                    );
                  }}
                >
                  <option disabled value="_DEFAULT_">
                    Select best of
                  </option>
                  {getScoreSystemData().configuration.map(({ bestOf }) => (
                    <option key={bestOf} value={bestOf}>
                      Best Of {bestOf}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            )}

            {league && bestOf && (
              <>
                <div className="d-flex">
                  <Form.Group className="mr-4">
                    <Form.Label>Start Date</Form.Label>
                    <div>
                      <Form.Control as={DatePicker} selected={startDate} onChange={setStartDate} inline={true} />
                    </div>
                  </Form.Group>

                  <div>
                    <Form.Group>
                      <Form.Label>Number of Weeks</Form.Label>
                      <Form.Control
                        type="number"
                        value={noOfWeeks}
                        onChange={(e) => e.target.value >= 0 && setNoOfWeeks(parseInt(e.target.value))}
                        min={1}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>How many times should teams play against each other?</Form.Label>
                      <Form.Control
                        type="number"
                        value={selectedStage.encounters}
                        onChange={(e) => {
                          e.target.value >= 0 && setTeamMeetQuantity(e.target.value)
                          e.target.value >= 0 && setSelectedStage({ ...selectedStage, encounters: e.target.value });
                          setNumberOfMatches((numberOfTeamsWithByes / 2) * (numberOfTeamsWithByes - 1) * e.target.value);
                          setRoundsPerWeek(Math.max(Math.ceil(((numberOfTeamsWithByes - 1) * e.target.value) / noOfWeeks), 0))
                        }}
                        min={1}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Time zone</Form.Label>
                      <Form.Control
                        as="select"
                        value={prefferedTimezone}
                        defaultValue={prefferedTimezone}
                        onChange={(e) => setPrefferedTimezone(e.target.value)}
                      >
                        {TIMEZONES.map(({ name, value }, index) => (
                          <option value={value} key={index}>
                            {name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Teams</Form.Label>
                          <Form.Control plaintext readOnly value={selectedGroup && selectedGroup.teams.length} />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Rounds</Form.Label>

                          <Form.Control plaintext readOnly value={(numberOfTeamsWithByes - 1) * teamMeetQuantity} />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Matches</Form.Label>
                          <Form.Control plaintext readOnly value={numberOfMatches} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={4}>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox1">
                          <Form.Check
                            type="checkbox"
                            name="broadcasted"
                            checked={broadcasted}
                            label="Broadcasted"
                            onChange={(e) => {
                              setBroadcasted((prevState) => !prevState);
                            }}
                          />
                        </Form.Group>
                      </Col>

                      <Col sm={4}>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox2">
                          <Form.Check
                            type="checkbox"
                            name="sideSelection"
                            checked={sideSelection}
                            label="Side Selection"
                            onChange={(e) => {
                              setSideSelection((prevState) => !prevState);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      {showVeto && (
                        <Col sm={4}>
                          <Form.Group className="mb-3" controlId="formBasicCheckbox3">
                            <Form.Check
                              type="checkbox"
                              name="vetoFlagEnable"
                              checked={vetoFlag}
                              label="Enable Veto"
                              onChange={(e) => {
                                setVetoFlag(!vetoFlag);
                              }}
                            />
                          </Form.Group>
                        </Col>
                      )}
                    </Row>
                  </div>
                </div>
                {!!(noOfWeeks) && (
                  <>
                    <h5 className="mt-4 mb-3">Weekly Schedule</h5>
                    {Array(roundsPerWeek)
                      .fill()
                      .map((_, i) => (
                        <div className="d-inline-block mr-4">
                          <Form.Group>
                            <Form.Label>Round {i + 1} Day</Form.Label>
                            <Form.Control
                              as="select"
                              value={weeklySchedule[i] ? weeklySchedule[i].day : "_DEFAULT_"}
                              onChange={(e) => updateWeeklySchedule(i, "day", e.target.value)}
                            >
                              <option disabled value="_DEFAULT_">
                                Select day
                              </option>
                              {weekdays.map((day) => (
                                <option key={day} value={day}>
                                  {day}
                                </option>
                              ))}
                            </Form.Control>
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Round {i + 1} Time</Form.Label>
                            <div>
                              <Form.Control
                                as={DatePicker}
                                value={weeklySchedule[i] && moment(weeklySchedule[i].time).format("HH:mm")}
                                onChange={(e) => updateWeeklySchedule(i, "time", moment(e).toISOString())}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                              />
                            </div>
                          </Form.Group>
                        </div>
                      ))}

                    <Button
                      type="submit"
                      variant="success"
                      size="lg"
                      className="mt-4 mb-5"
                      block
                      disabled={weeklySchedule.filter((s) => s).length !== roundsPerWeek}
                    >
                      Generate Matchess
                    </Button>
                  </>
                )}
              </>
            )}


          </>
          : // TOURNAMENT FORM 
          (selectedStage && selectedStage.stageType === 'tournament') &&
          <>
            {(selectedGroup && tournament) && (noOfTeams < 4 || noOfTeams > 1024) ? (
              <div>
                <h2 className="text-center">The Number of Teams Should be between 4 - 1024</h2>
              </div>
            ) : (
              <>
                {selectedGroup && (<Form.Group>
                  <Form.Label>Tournament Format</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedStage?.bracketType || "_DEFAULT_"}
                    onChange={(e) => {
                      setTournamentSelectedScoreSystem(parseInt(e.target.value));
                      setSelectedStage({ ...selectedStage, bracketType: parseInt(e.target.value) })
                    }}
                    disabled={selectedStage?.bracketType}
                  >
                    <option disabled value="_DEFAULT_">
                      {!tournamentScoreSystem?.length ? "Loading..." : "Select format"}
                    </option>
                    {tournamentScoreSystem?.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>)}
                {tournamentSelectedScoreSystem && tournamentSelectedScoreSystem !== 3 ? (
                  <Form.Group>
                    <Form.Label>Third-place Decider</Form.Label>
                    <Form.Control
                      as="select"
                      value={tournamnetType || "_DEFAULT_"}
                      onChange={(e) => handleSelectTournamentType(e.target.value)}
                      disabled={tournamnetType}
                    >
                      <option disabled value="_DEFAULT_">
                        {!tournamentTypes?.length ? "Loading..." : "Select"}
                      </option>
                      {tournamentTypes?.map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                ) : null}

                {tournamentSelectedScoreSystem && (
                  <Form.Group>
                    <Form.Label>Best Of</Form.Label>
                    <Form.Control
                      as="select"
                      value={bestOf || "_DEFAULT_"}
                      onChange={(e) => {
                        setBestOf(parseInt(e.target.value));
                        setShowVeto(tournament?.game?.shortName === "CSGO" && [1, 3, 5].includes(parseFloat(e.target.value)) ? true : false);
                      }}
                    >
                      <option disabled value="_DEFAULT_">
                        Select best of
                      </option>
                      <option key={1} value={1}>
                        Best Of {1}
                      </option>
                      <option key={3} value={3}>
                        Best Of {3}
                      </option>
                      <option key={5} value={5}>
                        Best Of {5}
                      </option>
                      <option key={7} value={7}>
                        Best Of {7}
                      </option>
                      <option key={9} value={9}>
                        Best Of {9}
                      </option>
                    </Form.Control>
                  </Form.Group>
                )}

                {tournament && bestOf ? (
                  <>
                    <Form.Group>
                      <Form.Label>Time between matches (min.)</Form.Label>
                      <Form.Control type="number" value={timeBetweenMatches} onChange={(e) => setTimeBetweenMatches(e.target.value)} />
                    </Form.Group>
                    <Row>
                      <Col sm={4}>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox1">
                          <Form.Check
                            type="checkbox"
                            name="broadcasted"
                            checked={broadcasted}
                            label="Broadcasted"
                            onChange={(e) => {
                              setBroadcasted((prevState) => !prevState);
                            }}
                          />
                        </Form.Group>
                      </Col>

                      <Col sm={4}>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox2">
                          <Form.Check
                            type="checkbox"
                            name="sideSelection"
                            checked={sideSelection}
                            label="Side Selection"
                            onChange={(e) => {
                              setSideSelection((prevState) => !prevState);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      {showVeto && (
                        <Col sm={4}>
                          <Form.Group className="mb-3" controlId="formBasicCheckbox3">
                            <Form.Check
                              type="checkbox"
                              name="vetoFlagEnable"
                              checked={vetoFlag}
                              label="Enable Veto"
                              onChange={(e) => {
                                setVetoFlag(!vetoFlag);
                              }}
                            />
                          </Form.Group>
                        </Col>
                      )}
                    </Row>

                    <Button variant="success" size="lg" className="mt-4 mb-5" onClick={() => generateResult()}>
                      {`${isGenerated ? "Re-" : ""}Calculate`}
                    </Button>
                  </>
                ) : null}

                {tournament && isGenerated && bestOf && (
                  <>
                    <Row>
                      {/* <Form.Group className="mr-4">
                    <Form.Label>Start Date</Form.Label>
                    <div>
                      <Form.Control as={DatePicker} selected={startDate} onChange={setStartDate} inline={true} />
                    </div>
                  </Form.Group> */}
                      {/* <Col>
                      <ShuffleTeams className="col-5" teams={teamOrder} setTeamOrder={setTeamOrder} />
                    </Col> */}
                      <Col>
                        {/* <Form.Group>
                      <Form.Label>Number of Weeks</Form.Label>
                      <Form.Control
                        type="number"
                        value={noOfWeeks}
                        onChange={(e) => e.target.value >= 0 && setNoOfWeeks(e.target.value)}
                        min={1}
                      />
                    </Form.Group> */}
                        <Row>
                          <Col>
                            <Form.Group>
                              <Form.Label>Teams</Form.Label>
                              <Form.Control plaintext readOnly value={selectedGroup && selectedGroup.teams.length} />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group>
                              <Form.Label>Rounds</Form.Label>
                              <Form.Control plaintext readOnly value={round} />
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group>
                              <Form.Label>Matches</Form.Label>
                              <Form.Control plaintext readOnly value={numberOfTournamentMatches} />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Select Time zone</Form.Label>
                          <Form.Control
                            as="select"
                            name="prefferedTimezone"
                            value={prefferedTimezone}
                            initialValue={prefferedTimezone}
                            onChange={(e) => setPrefferedTimezone(e.target.value)}
                          >
                            {TIMEZONES.map(({ name, value }, index) => (
                              <option key={index} value={value}>
                                {name}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <h5 className="mt-4 mb-3">Match Scheduler</h5>

                    {roundArr.map((r) => {
                      return (
                        <Row key={r}>
                          <Col>
                            <Form.Group>
                              <Form.Label>Round {r + 1} Date</Form.Label>
                              <Datetime
                                value={matchSchedule[r + 1].date}
                                dateFormat="DD/MM/YYYY"
                                timeFormat="HH:mm:ss"
                                onChange={(event) => {
                                  setMatchSchedule({
                                    ...matchSchedule,
                                    [r + 1]: {
                                      date: event,
                                    },
                                  });
                                }}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      );
                    })}
                    <Button type="submit" variant="success" size="lg" className="mt-4 mb-5" block disabled={!numberOfTournamentMatches || !bestOf}>
                      Generate Matches
                    </Button>
                  </>
                )}
              </>
            )}
          </>
        }
      </Form>
    </div>
  );
};

export default GenerateMatches;
