import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Axios from "axios";
import { useParams } from "react-router-dom";

const LeagueCreateEditModal = ({ show, handleClose, data = {}, addMultiStageLeague }) => {
  const { id: season } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  
  useEffect(() => {
    if(data) {
      setName(data.name)
    }  
  }, []);

  const changeName = (name) => {
    if(data){
      data.name = name;
    }
    setName(name);
  }

  const onSubmit = async (formData) => {
    setLoading(true);
    if (data) {
      // Edit
      await Axios.put(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${data._id}`, {...formData,timezone:data.timezone});
    } else {
      // Create
      const { data: resData } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague`, { season, ...formData })

      // Update parent state
      addMultiStageLeague(resData.data);
    }
    setName("");
    setLoading(false);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{data ? "Edit" : "Create"} Multi Stage League</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              ref={register({ required: "Required" })}
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={e => changeName(e.target.value) }
              isInvalid={errors.name}
            />
            <Form.Control.Feedback type="invalid">{errors.name && errors.name.message}</Form.Control.Feedback>
          </Form.Group>

          <Button type="submit" variant="success" className="float-right" disabled={loading}>
            {loading ? "Processing..." : data ? "Edit" : "Create"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default LeagueCreateEditModal;
