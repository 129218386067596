import React from "react";
import { Form, Row, Col } from "react-bootstrap";

const ViewRole = ({ roleName, organisationRole }) => {
  return (
    <div>
      <Row>
        <Col md={12}>
          <Form.Group>
            <Form.Label>Role Name</Form.Label>
            <Form.Control
              type="text"
              value={roleName}
              disabled={true}
            />
          </Form.Group>
        </Col>
      </Row>
      <Form.Check
        type="switch"
        label="Calendar"
        className="roleSwitches"
        disabled={true}
        checked={organisationRole?.calendar}
      />
      <Form.Check
        type="switch"
        label="Analyzer"
        className="roleSwitches"
        disabled={true}
        checked={organisationRole?.analyzer}
      />
      <Form.Check
        type="switch"
        label="SMS"
        className="roleSwitches"
        disabled={true}
        checked={organisationRole?.sms}
      />
      <Form.Check
        type="switch"
        label="Contracts"
        className="roleSwitches"
        disabled={true}
        checked={organisationRole?.contracts}
      />
    </div>
  );
};

export default ViewRole;
