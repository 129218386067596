import React, { useState, useEffect } from "react";
import { Modal, Form, Col, Row, Button } from "react-bootstrap";
import Axios from "axios";
import Datetime from "react-datetime";
import moment from "moment";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { TIMEZONES } from "../../../../utils/timezones";

const ScrimEditModal = ({ show, onHide, scrim, onUpdate, game, onDelete }) => {
  const { id } = useParams();
  const [loadingSS, setLoadingSS] = useState(true);
  const [team1, setTeam1] = useState(false);
  const [team2, setTeam2] = useState(false);
  const [minRank, setMinRank] = useState(false);
  const [maxRank, setMaxRank] = useState(false);
  const [checkInPeriod, setCheckInPeriod] = useState(false);
  const [pickMode, setPickMode] = useState(false);
  const [timeZone, setTimeZone] = useState(TIMEZONES[0].value);
  const [map, setMap] = useState(false);
  const [vetoFlag, setVetoFlag] = useState(true);
  const [showVeto, setShowVeto] = useState(false);
  const [numberOfMatches, setNumberOfMatches] = useState(false);
  const [date, setDate] = useState(false);
  const [isdatechange, setIsdatechange] = useState(false);
  const [ranks, setRanks] = useState(game && game.gameRanks);
  const numOfMatchesOptions = game?.shortName == "CSGO" ? [1, 3, 5] : [1, 2, 3, 4, 5];
  const checkInPeriodOptions = ["15 Minutes", "30 Minutes", "1 Hour", "2 Hours", "3 Hours", "4 Hours", "5 Hours", "6 Hours"];
  const mapOptions =
    game?.shortName === "LoL"
      ? [
          { value: "SUMMONERS_RIFT", name: "Summoner's Rift" },
          { name: "Howling Abyss", value: "HOWLING_ABYSS" },
        ]
      : ["Veto", "Inferno", "Mirage", "Nuke", "Overpass", "Dust2", "Vertigo", "Ancient"];
  const pickModeOptions = [
    { name: "Tournament draft", value: "TOURNAMENT_DRAFT" },
    { name: "Draft pick", value: "DRAFT_PICK" },
    { name: "Blind pick", value: "BLIND_PICK" },
    { name: "All Random", value: "ALL_RANDOM" },
  ];
  const [isCsgo, setIsCsgo] = useState(game && game.shortName == "CSGO" ? true : false);
  const changeDateTime = (dt) => {
    setIsdatechange(true);
    setDate(dt);
  };

  useEffect(() => {
    setRanks(game.gameRanks);
    game && game.shortName && setIsCsgo(game.shortName == "CSGO" ? true : false);
  }, [game]);

  const onDeleteScrim = async () => {
    const ids = [scrim._id];

    if (window.confirm("Are you sure you want to delete this scrim?")) {
      try {
        await Axios.post(`${process.env.REACT_APP_CORE_API}/api/scrims/deleteMulti`, { ids });
        onDelete();
        toast.success(`Successfully deleted scrim`);
        onHide();
        setIsdatechange(false);
      } catch (e) {
        toast.error("There was a problem deleting the selected scrim");
      }
    }
  };

  useEffect(() => {
    setTeam1(scrim ? scrim.team && scrim.team.name : false);
    setMinRank(scrim ? scrim.minRank : false);
    setMaxRank(scrim ? scrim.maxRank : false);
    setTeam2(scrim && scrim.acceptedMatchUp ? scrim.acceptedMatchUp?.team?.name : false);
    setNumberOfMatches(scrim ? scrim.numberOfMatches : false);
    // setShowVeto(match && league?.game?.shortName === "CSGO" && [1, 3, 5].includes(match.bestOf) ? true : false);
    setDate(scrim ? moment(scrim.date).format("MM/DD/YYYY HH:mm") : false);
    if (scrim.timeZone !== null && scrim.timeZone !== "" && scrim.timeZone != undefined) {
      setDate(scrim ? moment(scrim.date).tz(scrim.timeZone).format("DD/MM/YYYY HH:mm") : false);
    } else {
      setDate(scrim ? moment(scrim.date).format("DD/MM/YYYY HH:mm") : false);
    }
    setCheckInPeriod(scrim ? scrim.checkInPeriod : false);
    setMap(scrim ? scrim.map : false);
    setPickMode(scrim ? scrim.pickMode : false);
    setTimeZone(scrim && scrim.timeZone ? scrim.timeZone : TIMEZONES[0].value);
  }, [scrim, show]);

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      if (formValid) {
        const ifDateChange = isdatechange ? { date: moment(date, "DD/MM/YYYY HH:mm:ss").tz(timeZone, true) } : {};
        const postData = { map, pickMode, numberOfMatches, checkInPeriod, minRank, maxRank, ...ifDateChange, timeZone };
        // let vetoEnable = showVeto ? vetoFlag : false;
        if (scrim) {
          const { data } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/scrims/${scrim._id}`, postData);
          onUpdate();
        }

        toast.success(`Successfully ${scrim ? "edited" : "created"} ${!!scrim.match ? "Match" : "Scrim"}!`);
        onHide();
      }
    } catch (e) {
      toast.error("There was a problem processing this request 😞");
    }
  };

  const formValid = !!(scrim && team1 && date && numberOfMatches && checkInPeriod && map && pickMode);

  const modalHeaderDate = moment(scrim.date).format("DD/MM/YYYY @ HH:mm");

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        {scrim ? (
          <Modal.Title>
            {scrim.team ? scrim.team.name : "BYE"} vs {scrim?.acceptedMatchUp?.team ? scrim.acceptedMatchUp.team.name : "BYE"} (
            {modalHeaderDate})
          </Modal.Title>
        ) : (
          <Modal.Title>Create Match</Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          {scrim && (
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Team 1</Form.Label>
                  <Form.Control disabled type="text" value={team1} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Team 2</Form.Label>
                  <Form.Control disabled type="text" value={team2 || "Not Accepted"} />
                </Form.Group>
              </Col>
            </Row>
          )}

          {scrim && ranks && !isCsgo && (
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Min Rank</Form.Label>
                  <Form.Control as="select" value={minRank || "_DEFAULT_"} onChange={(e) => setMinRank(e.target.value)}>
                    <option value="_DEFAULT_" disabled>
                      Select Min Rank
                    </option>
                    {ranks.map((value, index) => (
                      <option key={index} value={index}>
                        {value}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Max Rank</Form.Label>
                  <Form.Control as="select" value={maxRank || "_DEFAULT_"} onChange={(e) => setMaxRank(e.target.value)}>
                    <option value="_DEFAULT_" disabled>
                      Select Max Rank
                    </option>
                    {ranks.map((value, index) => (
                      <option key={index} value={index}>
                        {value}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Check-In Period</Form.Label>
                <Form.Control as="select" value={checkInPeriod || "_DEFAULT_"} onChange={(e) => setCheckInPeriod(e.target.value)}>
                  <option disabled value="_DEFAULT_">
                    Set check-in period
                  </option>
                  {checkInPeriodOptions.map((option) => (
                    <option value={option}>{option}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            {!isCsgo && (
              <Col>
                <Form.Group>
                  <Form.Label>Pick Mode</Form.Label>
                  <Form.Control as="select" value={pickMode || "_DEFAULT_"} onChange={(e) => setPickMode(e.target.value)}>
                    <option disabled value="_DEFAULT_">
                      Select Pick Mode
                    </option>
                    {pickModeOptions.map(({ name, value }, index) => (
                      <option value={value} key={index}>
                        {name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            )}
            <Col>
              <Form.Group>
                <Form.Label>Map Type</Form.Label>
                <Form.Control
                  disabled={game.shortName == "CSGO" && [3, 5].includes(numberOfMatches)}
                  as="select"
                  value={map || "_DEFAULT_"}
                  onChange={(e) => setMap(e.target.value)}
                >
                  <option disabled value="_DEFAULT_">
                    Select Map Type
                  </option>
                  {game.shortName === "CSGO"
                    ? mapOptions.map((option) => <option value={option}>{option}</option>)
                    : mapOptions.map(({ name, value }, index) => (
                        <option key={index} value={value}>
                          {name}
                        </option>
                      ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group>
                <Form.Label>No. of Matches</Form.Label>
                <Form.Control
                  as="select"
                  value={numberOfMatches || "_DEFAULT_"}
                  onChange={(e) => setNumberOfMatches(parseFloat(e.target.value))}
                >
                  <option disabled value="_DEFAULT_">
                    Select best of
                  </option>
                  {numOfMatchesOptions.map((num) => (
                    <option value={num}>{num}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Date</Form.Label>
                <div>
                  <Datetime value={date} onChange={changeDateTime} dateFormat="DD/MM/YYYY" timeFormat="HH:mm:ss" />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Time Zone</Form.Label>
                <Form.Control as="select" value={timeZone || TIMEZONES[0].value} onChange={(e) => setTimeZone(e.target.value)}>
                  {TIMEZONES.map(({ name, value }) => (
                    <option value={value}>{name}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          {/* 
          {scrim && (
            <>
              {showVeto && (
                <Row>
                  <Col sm={6}>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Check
                        type="checkbox"
                        name="vetoFlagEnable"
                        checked={vetoFlag}
                        label="Enable Veto"
                        onChange={(e) => {
                          setVetoFlag(!vetoFlag);
                        }}
                        disabled = {match && match.status == "complete"}

                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}
            </>
          )} */}

          <div className="d-flex">
            <Button style={{ width: "100%" }} type="submit" variant="success" size="lg" disabled={!formValid}>
              {scrim ? "Edit" : "Create"} {scrim.match ? "Match" : "Scrim"}
            </Button>
            {scrim && (
              <Button style={{ marginLeft: 15, width: 50 }} onClick={onDeleteScrim} variant="danger" size="lg" block>
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            )}
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ScrimEditModal;
