import React, { useState, useEffect } from "react";
import { Modal, Form, FormGroup, Button } from "react-bootstrap";
import Axios from "axios";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

const SingleAdCreateEditModal = ({ show, handleClose, data, setAdverts, adverts }) => {
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [url_DK, setUrl_DK] = useState("");
  const [url_SE, setUrl_SE] = useState("");
  const [url_NO, setUrl_NO] = useState("");
  const [url_FI, setUrl_FI] = useState("");
  const [uploading, setUploading] = useState(false);
  const [image, setImage] = useState(false);
  const [games, setGames] = useState([]);
  const [forGames, setForGames] = useState([]);


  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/games`).then(({ data }) => {
      setGames(data);
     
    });
  }, []);

  useEffect(() => {
    if (data) {
      setName(data.name);
      setUrl(data.url);

      const sentGames = [];
      data.forGames.map((fgm) => sentGames.push({'game':fgm.game._id}));

      setForGames(sentGames);
    }
  }, [data]);
// console.log('forgames=',forGames)
  const handleDrop = (dropped) => setImage(dropped[0]);

  const onSubmit = async (e) => {

    // const sentGames = [];
    // forGames.map((fgm) => sentGames.push({'game':fgm}));
    e.preventDefault();
    if (!data && !image) return toast.error("No image provided");

    if (data && !image) {
      // alert('roma')
      setAdverts(adverts.map((advert) => (advert._id === data._id ? { ...advert, name, url, forGames } : advert)));
      return handleClose();
    }

    if (image) {
      setUploading(true);
      const formData = new FormData();
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      formData.append("image", image);

      Axios.post(`${process.env.REACT_APP_CORE_API}/api/upload`, formData, config)
        .then(({ data: resData }) => {
          const newAd = { name, url, forGames,img: resData.file, views: 0, viewsUnique: 0, clicks: 0, clicksUnique: 0 };

          if (data) {
            //update
            setAdverts(adverts.map((advert) => (advert._id === data._id ? newAd : advert)));
          } else {
            //create
            setAdverts([...adverts, newAd]);
          }

          setUploading(false);
          handleClose();
        })
        .catch((err) => {
          const msg = err.response.data ? err.response.data.msg : "There was a problem uploading your file";
          toast.error(msg);
          setUploading(false);
        });
    }
  };

  const onGamesSelected = async (e) => {
    var options = e.target.options;
    var value = [];
      for (var i = 0, l = options.length; i < l; i++) {
        if (options[i].selected) {
          value.push({'game':options[i].value});
        }
      }
      setForGames(value);
  }

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{data ? "Edit" : "Create"} Promotion</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <FormGroup>
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" id="size" name="size" value={name} onChange={(e) => setName(e.target.value)} />
          </FormGroup>

          <FormGroup>
            <Form.Label>URL Default</Form.Label>
            <Form.Control type="url" id="url" name="url" value={url} onChange={(e) => setUrl(e.target.value)} />
          </FormGroup>

          {["url_DK","url_SE","url_NO","url_FI"].map((urlCountry) => {
          
          })}

          <FormGroup>
            <Form.Label>URL for denmark</Form.Label>
            <Form.Control type="url" id="url_DK" name="url_DK" value={url_DK} onChange={(e) => setUrl_DK(e.target.value)} />
          </FormGroup>

          <FormGroup>
            <Form.Label>URL for swedan</Form.Label>
            <Form.Control type="url" id="url_SE" name="url_SE" value={url_SE} onChange={(e) => setUrl_SE(e.target.value)} />
          </FormGroup>

          <FormGroup>
            <Form.Label>URL for norway</Form.Label>
            <Form.Control type="url" id="url_NO" name="url_NO" value={url_NO} onChange={(e) => setUrl_NO(e.target.value)} />
          </FormGroup>

          <FormGroup>
            <Form.Label>URL for finland</Form.Label>
            <Form.Control type="url" id="url_FI" name="url_FI" value={url_FI} onChange={(e) => setUrl_FI(e.target.value)} />
          </FormGroup>

          <FormGroup>
            <Form.Label>For Games</Form.Label>
            <Form.Control as="select" multiple id="forGames" name="forGames" onChange={onGamesSelected}>
              {games
                .map((game) => (
                  <option key={game._id} selected={forGames.map((forga) => forga?.game).includes(game._id)} value={game._id}>
                    {game.name} ({game.shortName})
                  </option>
                ))}
            </Form.Control>
          </FormGroup>

          <FormGroup>
            <Form.Label>Upload Image</Form.Label>
            <Dropzone multiple={false} accept={["image/jpeg", "image/jpg", "image/png", "image/gif"]} onDrop={handleDrop}>
              {({ getRootProps, getInputProps }) => (
                <div className="image-dropzone" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>Drag and drop or click to upload image</p>
                </div>
              )}
            </Dropzone>
          </FormGroup>

          {image && (
            <p>
              <FontAwesomeIcon icon={faCheckCircle} className="text-success" />
              {image.path}
            </p>
          )}

          {data && (
            <FormGroup>
              <Form.Label>Existing Image</Form.Label>
              <div>
                <img src={data.img} alt="" width="300px" />
              </div>
            </FormGroup>
          )}

          <div className="d-flex justify-content-end">
            <Button type="submit" variant="success" disabled={uploading}>
              {uploading ? "Uploading image..." : data ? "Edit" : "Create"}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default SingleAdCreateEditModal;
