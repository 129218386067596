import React, { useState, useEffect, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import Axios from "axios";
import moment from "moment";
import DataTable from "react-data-table-component";
import { Button, Alert, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPencilAlt,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "./_table.scss"
import FilterUsers from "./includes/FilterUsers";
import { Link } from "react-router-dom";

const Users = () => {
  const timeout = useRef();

  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [query, setQuery] = useState({});
  const [filterQuery, setFilterQuery] = useState("");

  const columns = [
    {
      name: "Date Created",
      cell: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
      maxWidth: "160px",
    },
    {
      name: "Name",
      cell: ({ firstName, nickname, lastName }) => `${firstName} ${nickname ? `"${nickname}"` : ""} ${lastName}`,
      maxWidth: "210px",
    },
    {
      name: "Email",
      cell: ({ email }) => email,
      maxWidth: "320px",
    },
    {
      name: "Discord",
      cell: ({ discord }) => discord,
      maxWidth: "200px",
    },
    {
      name: "Twitter",
      cell: ({ twitter }) => twitter,
    },
    {
      right: true,
      maxWidth: "200px",
      name: <div style={{ fontWeight: "bold", fontSize: 15 }}>{totalRows} Users</div>,
      cell: (users) => (
        users._id && (
          <>
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Delete</Tooltip>}>
              <Button variant="link" className="btn text-secondary" onClick={() => onRemoveUser(users._id)}>
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">View</Tooltip>}>
              <a
                className="btn text-secondary"
                href={`${process.env.REACT_APP_LEAGUES_URL}/players/${users._id}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ padding: "8px" }}
              >
                <FontAwesomeIcon icon={faEye} />
              </a>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Edit</Tooltip>}>
              <Button variant="link" className="text-secondary" style={{ padding: "8px" }} as={Link} to={`/entities/${users._id}/user-individual`}>
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
            </OverlayTrigger>
          </>
        )
      ),
    },
  ];

  useEffect(() => {
    getData();
  }, [rowsPerPage])

  const getData = async (page = 1) => {
    const _query = {
      limit: rowsPerPage,
      offset: (page - 1) * rowsPerPage,
      ...query
    }
    if (filterQuery.length) {
      filterResult(filterQuery, _query.offset);
    } else {
      allUserResult(_query)
    }
  }

  const handlePerPageChange = async (newPerPage) => {
    try {
      setLoading(true);
      setRowsPerPage(newPerPage);
    } catch (e) {
      setLoading(false);

      const msg = e.response.data ? e.response.data.msg : "Error!";
      toast.error(msg);
    }
  }

  const onRemoveUser = async (id) => {
    if (window.confirm("Are you sure you want to delete this User? This action is irreversible!")) {
      await Axios.delete(`${process.env.REACT_APP_CORE_API}/api/users/deleteUser/${id}`);
      setUsers((user) => user.filter(({ _id }) => _id !== id));
      setTotalRows(totalRows - 1);
      setFilteredUsers([]);

      toast.success("Successfully deleted");
    }
  };

  const onDeleteMultiUsers = async () => {
    const ids = selectedRows.map(({ _id }) => _id);

    if (window.confirm(`Are you sure you want to delete ${ids.length} users?`)) {
      try {
        await Axios.post(`${process.env.REACT_APP_CORE_API}/api/users/deleteMulti`, { ids });
        setUsers((users) => users.filter(({ _id }) => !ids.includes(_id)));
        setTotalRows(totalRows - selectedRows.length);
        setFilteredUsers([]);
        setSelectedRows([]);

        toast.success(`Successfully deleted ${ids.length} users`);
      } catch (e) {
        toast.error("There was a problem deleting the selected users");
      }
    }
  };
  
  const allUserResult = async (_query) => {
    const quertStr = new URLSearchParams(_query).toString()
      setLoading(true);
      const { data } = await Axios.get(`${process.env.REACT_APP_CORE_API}/api/users?${quertStr}`);
      if (data) {
        setUsers(data.users);
        setTotalRows(data.total);
      }
      setLoading(false);
  }

  const filterResult = async (searchQuery, offset) => {
    setFilterQuery(searchQuery)
    clearTimeout(timeout.current);
    setFilteredUsers([]);

    if (searchQuery === "") allUserResult();

    else {
      const query = searchQuery.toLowerCase();
      setLoading(true);

      timeout.current = setTimeout(async () => {
        const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/users/searchUsers`, { query, limit: rowsPerPage, offset });
        setFilteredUsers(data.results);
        setTotalRows(data.total);
        setLoading(false);
      }, 100);
    }
  }

  return (
    <div>
      <Row>
        <Col lg={12}>
          <FilterUsers filterResult={filterResult} filterQuery={filterQuery} />
        </Col>
      </Row>
      {!!selectedRows.length && (
        <Alert key="table-actions" variant="secondary">
          <div className="d-flex justify-content-between align-items-center">
            <div>{selectedRows.length} rows selected</div>
            <div>
              <Button size="sm" variant="danger" onClick={onDeleteMultiUsers}>
                <FontAwesomeIcon icon={faTrashAlt} />
                &nbsp;Delete Selected
              </Button>
            </div>
          </div>
        </Alert>
      )}

      <DataTable
        progressPending={loading}
        paginationRowsPerPageOptions={[50, 100, 200]}
        paginationPerPage={rowsPerPage}
        paginationTotalRows={totalRows}
        pagination={true}
        paginationServer={true}
        onChangeRowsPerPage={handlePerPageChange}
        onChangePage={getData}
        noHeader={true}
        columns={columns}
        data={filterQuery.length ? filteredUsers : users}
        selectableRows={true}
        selectableRowsHighlight={true}
        onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}
        style={{ overflow: "inherit" }}
        fixedHeader={true}
        persistTableHead={true}
        selectedRows={selectedRows}
      />
    </div>
  );
};

export default Users;
