import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AppContext } from "../context/AppContext";

export const AuthRoute = ({ path, children }) => {
  const { authenticated } = useContext(AppContext);

  return authenticated ? <Route path={path}>{children}</Route> : <Redirect to="/login" />;
};

export const GuestRoute = ({ path, children }) => {
  const { authenticated } = useContext(AppContext);

  return !authenticated ? <Route path={path}>{children}</Route> : <Redirect to="/" />;
};
