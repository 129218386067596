import React, { useState, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import { Button, OverlayTrigger, Tooltip, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faPencilAlt, faPlus } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import { toast } from "react-toastify";
import RoleCreateEditModal from "./Modal/RoleCreateEditModal"
import FilterRoles from "./include/FilterRoles";
import { AppContext } from "../../context/AppContext";

const Roles = () => {
  const { user } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const [filteredRoles, setFilteredRoles] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [query, setQuery] = useState({});
  const [totalRows, setTotalRows] = useState(0);
  const [createRole, setCreateRole] = useState(false);
  const [modalData, setModalData] = useState(false);
  const columns = [
    {
      name: "Date Created",
      cell: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
      maxWidth: "190px",
    },
    {
      name: "Name",
      cell: ({ name }) => name,
      maxWidth: "250px",
    },
    {
      name: "Type",
      cell: ({ type }) => type,
      maxWidth: "200px",
    },
    {
      name: "Description",
      cell: ({ description }) => description,
    },
    {
      right: true,
      cell: (roles) => (
        <>
          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Edit</Tooltip>}>
            <Button variant="link" className="text-secondary" onClick={() => onEditRole(roles)}>
              <FontAwesomeIcon icon={faPencilAlt} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Delete</Tooltip>}>
            <Button variant="link" className="text-danger" onClick={() => onRemoveRole(roles._id)}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </OverlayTrigger>
        </>
      ),
    },
  ];

  useEffect(() => {
    getData();
  }, [rowsPerPage])

  const getData = async (page = 1) => {
    const _query = {
      limit: rowsPerPage,
      offset: (page - 1) * rowsPerPage,
      ...query
    }
    const quertStr = new URLSearchParams(_query).toString()
    setLoading(true);

    const { data } = await Axios.get(`${process.env.REACT_APP_CORE_API}/api/roles?${quertStr}`);
    if (data) {
      setRoles(data.roles);
      setTotalRows(data.total);
    }
    setLoading(false);
  }

  const onRemoveRole = async (id) => {
    if (window.confirm("Are you sure you want to delete this Role? This action is irreversible!")) {
      await Axios.delete(`${process.env.REACT_APP_CORE_API}/api/roles/${id}`);

      setRoles((role) => role.filter(({ _id }) => _id !== id));
      setTotalRows(totalRows - 1);
      setFilteredRoles([]);

      toast.success("Successfully deleted Role");
    }
  };

  const handlePerPageChange = async (newPerPage) => {
    try {
      setLoading(true);
      setRowsPerPage(newPerPage);
    } catch (e) {
      setLoading(false);

      const msg = e.response.data ? e.response.data.msg : "Game Updating Failed!";
      toast.error(msg);
    }
  }

  const onCreateRole = () => {
    setModalData(false);
    setCreateRole(true);
  };

  const onEditRole = (rolesData) => {
    setModalData(rolesData);
    setCreateRole(true);
  };

  return (
    <div>
      <div className="admin-page-title">
        <h2>Roles</h2>
        <Button variant="success" className="space-right btn-success" onClick={onCreateRole} disabled={!user.state.admin && !user.state.permissions.user.roles}>
          <FontAwesomeIcon icon={faPlus} /> Create Role
        </Button>
      </div>

      <Row>
        <Col lg={12}>
          <FilterRoles setFilteredRoles={setFilteredRoles} setLoading={setLoading} />
        </Col>
      </Row>

      <DataTable
        progressPending={loading}
        paginationRowsPerPageOptions={[30]}
        paginationPerPage={rowsPerPage}
        paginationTotalRows={filteredRoles.length ? filteredRoles.length : totalRows}
        pagination={true}
        paginationServer={true}
        onChangeRowsPerPage={handlePerPageChange}
        onChangePage={getData}
        noHeader={true}
        columns={columns}
        data={filteredRoles.length ? filteredRoles : roles}
        style={{ overflow: "inherit" }}
        fixedHeader={true}
        persistTableHead={true}
      />

      <RoleCreateEditModal
        show={createRole}
        handleClose={() => setCreateRole(false)}
        modalData={modalData}
        roles={roles}
        setRoles={setRoles}
      />
    </div>
  )
}

export default Roles;
