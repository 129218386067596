import { update } from "lodash-es";
import React, { useState, useEffect, createRef } from "react";
import Axios from "axios";
import { Modal, Form, Button, Tooltip, OverlayTrigger, FormGroup } from "react-bootstrap";

const SingleSegmentModule = ({updateComponent,modalData,segData}) => {
    
   const [gameData,setGameData]=useState(modalData.pageData && modalData.pageData.segmentData || {});

    const update = (key,value) => {
        let data = {...gameData,[key]:value};
        setGameData(data)
        updateComponent(data)
    }

 
    return (
    <>
    
       <Form>
       
        <FormGroup>
            <Form.Label>Background Text</Form.Label>
            <Form.Control type="text" id="BackgroundText" name="BackgroundText" value={gameData ? gameData.BackgroundText : null} onChange={(e) => update('BackgroundText',e.target.value)} />
        </FormGroup>
        <FormGroup>
            <Form.Label>White Text</Form.Label>
            <Form.Control type="text" id="WhiteText" name="WhiteText" value={gameData ? gameData.WhiteText : null} onChange={(e) => update('WhiteText',e.target.value)} />
        </FormGroup>
        
        <FormGroup>
            <Form.Label>Blue Text</Form.Label>
            <Form.Control type="text" id="BlueText" name="BlueText" value={gameData ? gameData.BlueText : null} onChange={(e) => update('BlueText',e.target.value)} />
        </FormGroup>

        

        <FormGroup>
            <Form.Label>Link</Form.Label>
            <Form.Control type="text" id="Link" name="Link" value={gameData ? gameData.Link : null} onChange={(e) => update('Link',e.target.value)} />
        </FormGroup>
            
              
            </Form>
    </>)
}

export default SingleSegmentModule;

