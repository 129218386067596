import React, { useState } from "react";
import Axios from "axios";
import { Button, Form, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

const SetPlayers = ({ onClose, teamData = {}, teamSelector, match, updateMatches, league }) => {
  const [loading, setLoading] = useState(false);
  const { _id, lolData } = match;

  const [roles, setRoles] = useState(
    league.game.configuration.playerTypes.map(({ name, optional }) => {
      const existingSetPlayer = lolData?.players && (lolData?.players[teamSelector] || []).find(({ role = "" }) => role.includes(name));

      return {
        role: name,
        player: existingSetPlayer ? existingSetPlayer?.player : false,
        optional,
      };
    })
  );

  const isAllPlayersSet = () => {
    const requiredRoles = roles.filter(({ optional }) => !optional);

    return requiredRoles.filter(({ player }) => player).length >= requiredRoles.length;
  };

  const onUpdatePlayer = (roleName, newPlayerId) => {
    setRoles((roles) => roles.map((role) => (role.role === roleName ? { ...role, player: newPlayerId } : role)));
  };

  const onSetPlayers = async () => {
    if (!isAllPlayersSet()) return false;
    setLoading(true);

    try {
      const { data } = await Axios.post(`${process.env.REACT_APP_LOL_API}/api/matches/${_id}/setPlayers?type=Tournament`, { roles, teamSelector });
      if(data.newMatch){
        updateMatches((matches) => matches.map((m) => (m._id === _id ? data.newMatch : m)));
      }
      setLoading(false);
      toast.success("Successfully set players for " + teamData.name);
      onClose();
    } catch (e) {
      toast.error("Could not set players for this match");
      setLoading(false);
    }
  };

  return (
    <div>
      {roles.map(({ icon, role, player, optional }) => (
        <Form.Group key={role}>
          <Form.Label>{role}</Form.Label>
          <Form.Control as="select" value={player || "_DEFAULT_"} onChange={(e) => onUpdatePlayer(role, e.target.value)}>
            <option value="_DEFAULT_" disabled>
              No Player Selected
            </option>
            {teamData.members
              .filter(({ role = "" }) => role.includes("Player"))
              .map(({ player }) => player && <option value={player._id}>{player.name}</option>)}
          </Form.Control>
        </Form.Group>
      ))}

      <Button onClick={onSetPlayers} variant="success" className="mt-2" disabled={loading || !isAllPlayersSet()}>
        {loading ? <Spinner animation="border" size="sm" /> : "Save"}
      </Button>
    </div>
  );
};

export default SetPlayers;
