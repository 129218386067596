import { update } from "lodash-es";
import React, { useState, useEffect, createRef } from "react";
import Axios from "axios";
import { Modal, Form, Button, Tooltip, OverlayTrigger } from "react-bootstrap";

const SingleSegmentModule = ({updateComponent,modalData,segData}) => {
    
   const [gameData,setGameData]=useState(modalData.pageData && modalData.pageData.segmentData || {});
   const [games,setGames] = useState([]);
    const update = (key,value) => {
        let data = {...gameData,[key]:value};
        setGameData(data)
        updateComponent(data)
    }

    useEffect(() => {
        Axios.get(`${process.env.REACT_APP_CORE_API}/api/games`).then(({ data }) => {
          setGames(() => {
            return data.filter((game) => game.name !==  'No Game');
          })
         
        });
      }, []);

    return (
    <><br />
       
       <Form>
       
            <Form.Group>
                <Form.Label>Select game</Form.Label>
                <div className="d-flex align-items-center">
                    <Form.Control
                        as="select"
                        id='game'
                        name='game'
                        value={gameData ? gameData.game : null}
                        onChange={(e) => update('game',e.target.value)}
                    >
                        <option disabled value="">Select Game</option>
                        <option value="ALL">ALL</option>
                        {games.map((code) => (
                            <option value={code.ghostTag}>{code.name} ({code.ghostTag})</option>
                            ))}
                    </Form.Control>
            </div></Form.Group>


            <Form.Group>
                <Form.Label>Select Advert</Form.Label>
                <div className="d-flex align-items-center">
                    <Form.Control
                        as="select"
                        id='SelectAdvert'
                        name='SelectAdvert'
                        value={gameData ? gameData.SelectAdvert : null}
                        onChange={(e) => update('SelectAdvert',e.target.value)}
                    >
                        <option disabled value="">Select Advert</option>
                        <option value="ALL">ALL</option>
                        {games.map((code) => (
                            <option value={code._id}>{code.name}</option>
                            ))}
                    </Form.Control>
            </div></Form.Group>

            

              <Form.Group>
                <Form.Check
                  type="checkbox"
                  id="HideFeaturedArticle"
                  label="Hide Featured Article"
                  defaultChecked={gameData ? gameData.HideFeaturedArticle : false}
                  onChange={(e) => update("HideFeaturedArticle",e.target.checked)}
                />
                <small>Checking this checkbox will hide the Big Article and Advert from the segment.</small>
              </Form.Group>
              
            </Form>
    </>)
}

export default SingleSegmentModule;

