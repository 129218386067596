import React, { useState, useEffect, createRef } from "react";
import { Modal, Form, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import Axios from "axios";

const ImportCloneModal = ({ show, onHide, modalData, onUpdate }) => {
  const [games,setGames] = useState([]);
  const [frontPageData,setFrontPageData] = useState(null);
  const [frontPageDataType,setFrontPageDataType] = useState(null);


  const updateGame = (value) => {
   let selectedGame = games.filter((game) => game._id === value);
    if(selectedGame){
      setFrontPageData(selectedGame[0].frontpage);
    }
  }

  const updateType = (value) => {
    let selectedGameType = frontPageData[value];
     if(selectedGameType){
      setFrontPageDataType(selectedGameType);
     }
   }

   const importSegments = () => {
    if (window.confirm("Are you sure you want to import? It will remove all the current segments.")) {
      onHide();
      console.log('frontPageDataType-',frontPageDataType)
      onUpdate(frontPageDataType);
    }
   }


  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/games`).then(({ data }) => {
      setGames(() => {
        return data.filter((game) => game.name !==  'No Game');
      })
     
    });
  }, []);


  return (
    <Modal 
      show={show} 
      onHide={onHide} 
      onClose={onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title>Import/Clone</Modal.Title>
        
      </Modal.Header>
      
      <Modal.Body>
      <Form >
            <Form.Group>
              <Form.Label>Choose game</Form.Label>
              <div className="d-flex align-items-center">
                  <Form.Control
                      as="select"
                      id='game'
                      name='game'
                      value={null}
                      onChange={(e) => updateGame(e.target.value)}
                  >
                      <option value="">Select Game</option>
                      {games.map((code) => (
                          <option value={code._id}>{code.name}</option>
                          ))}
                  </Form.Control>
              </div>
            </Form.Group>

            {frontPageData && <Form.Group>
              <Form.Label>Choose type</Form.Label>
              <div className="d-flex align-items-center">
                  <Form.Control
                      as="select"
                      id='type'
                      name='type'
                      value={null}
                      onChange={(e) => updateType(e.target.value)}
                  >
                      <option value="">Select Type</option>
                      {Object.keys(frontPageData).map((code) => (
                          <option 
                          disabled={frontPageData[code].length ? false : true} 
                          value={code}>{code} ({frontPageData[code].length} Segments inside)</option>
                          ))}
                  </Form.Control>
              </div>
            </Form.Group>}
      </Form>
      </Modal.Body>

      <Modal.Footer className="d-block p-3">
         <Button variant="success" size="sm" 
          onClick={() => importSegments()}
          disabled={frontPageDataType ? false : true}
        >
          Import
          </Button>
          <Button variant="danger" size="sm" 
            onClick={onHide}
          >
          Discard
          </Button>
      </Modal.Footer>

    </Modal>
  );
};

export default ImportCloneModal;
