import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import React, { useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { toast } from "react-toastify";

const Prizes = ({ league, setLeague }) => {
  const [prizes, setPrizes] = useState(league.prizes);
  const [submitting, setSubmitting] = useState(false);

  const arraysEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  };

  const onUpdatePrize = (atIndex, value) => {
    setPrizes((prizes) => {
      return prizes.map((p, i) => (i === atIndex ? value : p));
    });
  };

  const onAddPrize = () => {
    setPrizes([...prizes, ""]);
  };

  const onDeletePrize = (atIndex) => {
    setPrizes(prizes.filter((_, i) => i !== atIndex));
  };

  const onSave = async () => {
    setSubmitting(true);

    try {
      await Axios.put(`${process.env.REACT_APP_CORE_API}/api/tournaments/${league._id}`, { prizes });

      // update league
      setLeague((league) => ({ ...league, prizes }));

      toast.success("Saved");
    } catch (e) {
      toast.error("There was a problem saving");
    }

    setSubmitting(false);
  };

  return (
    <div>
      <div className="d-flex justify-content-end mb-2">
        <Button variant="success" size="sm" onClick={onAddPrize}>
          <FontAwesomeIcon icon={faPlus} /> Add Prize
        </Button>
      </div>

      {!prizes.length ? (
        "No prizes added. Add one using the button at the top right!"
      ) : (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Place</th>
              <th>Prize</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {prizes.map((prize, i) => (
              <tr key={i}>
                <td>#{i + 1}</td>
                <td>
                  <Form.Control type="text" defaultValue={prize} onChange={(e) => onUpdatePrize(i, e.target.value)} />
                </td>
                <td>
                  <Button variant="link" className="text-danger" onClick={() => onDeletePrize(i)}>
                    <FontAwesomeIcon icon={faTrash} /> Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {!arraysEqual(league.prizes, prizes) && (
        <div className="mt-3">
          <Button type="button" variant="success" onClick={onSave} disabled={submitting}>
            {submitting ? "Saving" : "Save"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Prizes;
