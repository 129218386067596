import React from "react";
import { Form, Row, Col } from "react-bootstrap";

const ViewInheritedPermission = ({ roleName, inheritedRole }) => {
  return (
    <div>
      {roleName ?
        <>
          <Row>
            <Col md={12}>
              <h6>{roleName?.length > 1 ? "Organisation Names" : "Organisation Name"}</h6>
              <ul className="orgNames">
                {roleName.map((name, i) => (
                  name !== "" && <li className="orgName" key={i}>{name}</li>
                ))}
              </ul>
            </Col>
          </Row>
          <Form.Check
            type="switch"
            label="Calendar"
            className="roleSwitches"
            disabled={true}
            checked={inheritedRole ? inheritedRole.calendar : false}
          />
          <Form.Check
            type="switch"
            label="Analyzer"
            className="roleSwitches"
            disabled={true}
            checked={inheritedRole ? inheritedRole.analyzer : false}
          />
          <Form.Check
            type="switch"
            label="SMS"
            className="roleSwitches"
            disabled={true}
            checked={inheritedRole ? inheritedRole.sms : false}
          />
          <Form.Check
            type="switch"
            label="Contracts"
            className="roleSwitches"
            disabled={true}
            checked={inheritedRole ? inheritedRole.contracts : false}
          />
        </>
        : ""
      }
    </div>
  );
};

export default ViewInheritedPermission;
