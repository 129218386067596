import React, { useState, useEffect } from "react";
import { Card, Button, Media, Tooltip, OverlayTrigger } from "react-bootstrap";
import moment from "moment";
import AddTeamsModal from "./modals/AddTeamsModal";
import SwapTeamsModal from "./modals/SwapTeamsModal";
import EditPlaceHolderModal from "./modals/EditPlaceHolderModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faSync, faTrashAlt, faPlus, faEdit } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import { toast } from "react-toastify";
import SwitchTeamModal from "./modals/SwitchTeamModal";

const MultiStageTeamsListCard = ({ stage, permission }) => {
  const [league, setLeague] = useState(false);
  const [showTeamModal, setShowTeamModal] = useState(false);
  const [showSwitchModal, setShowSwitchModal] = useState(false);
  const [switchingTeam, setSwitchingTeam] = useState(false);
  const [showSwapModal, setShowSwapModal] = useState(false);
  const [swapTeam, setSwapTeam] = useState(false);
  const [filterTeams, setFilterTeams] = useState([]);
  const [editPlaceHolderTeam, setEditPlaceHolderTeam] = useState(false);
  const [showEditPlaceHolderModal, setShowEditPlaceHolderModal] = useState(false);

  useEffect(() => {
    if (stage.stageType === 'league') {
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/leagues/${stage.league._id}?withMatches=true`).then(({ data }) => {
        setLeague(data);
      });
    } else {
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/tournaments/${stage.tournament._id}?withMatches=true`).then(({ data }) => {
        setLeague(data);
      });
    }
  }, [stage]);

  useEffect(() => {
    if (league) {
      gettingIdsOfMatchTeams();
    }
  }, [league]);

  const gettingIdsOfMatchTeams = () => {
    let currentMatchTeamsIds = [];
    league.matches.forEach((match) => {
      currentMatchTeamsIds.push(match?.t1?._id, match?.t2?._id);
    });

    let filter = [];
    league.teams.forEach((el) => {
      if (currentMatchTeamsIds.includes(el?.team?._id)) {
        filter.push(el?.team?._id);
      }
    });
    setFilterTeams(filter);
  };

  const onRemoveTeam = async (id) => {
    const { name, initials, logo, game, placeHolder, _id: currentTeamId } = league.teams.find((team) => team._id === id).team;
    const placeholderTeamData = { name, initials, logo, league, game, organisation: "61dc169b2a8c7f1cb4d65cd2", placeHolder };

    if (placeholderTeamData.placeHolder === false) {
      try {
        const bodyData = {
          name: placeholderTeamData.name,
          initials: placeholderTeamData.initials,
          league: placeholderTeamData.league._id,
          game: placeholderTeamData.game,
          organisation: placeholderTeamData.organisation,
          placeHolder: true,
          logo: placeholderTeamData.logo,
        };
        if (stage?.stageType === 'league') {
          await Axios.post(`${process.env.REACT_APP_CORE_API}/api/leagues/${league._id}/matches/placeHolderSwitchTeam`, {
            currentTeamId,
            newTeam: bodyData,
          });
        } else {
          await Axios.post(`${process.env.REACT_APP_CORE_API}/api/tournaments/${league._id}/matches/placeHolderSwitchTeam`, {
            currentTeamId,
            newTeam: bodyData,
          });
        }
        setLeague((league) => ({ ...league, teams: league.teams.filter(({ _id }) => _id !== id) }));
        toast.success("Successfully Replace with Placeholder team");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (e) {
        toast.error("Replacing with Placeholder team");
      }
    } else {
      try {
        if (stage?.stageType === 'league') {
          await Axios.delete(`${process.env.REACT_APP_CORE_API}/api/leagues/${league._id}/teams/${id}`);
        } else {
          await Axios.delete(`${process.env.REACT_APP_CORE_API}/api/tournaments/${league._id}/teams/${id}`);
        }
        setLeague((league) => ({ ...league, teams: league.teams.filter(({ _id }) => _id !== id) }));
        toast.success("Successfully removed team");
      } catch (e) {
        toast.error("Problem removing team");
      }
    }
  };

  const onKickTeam = async (id, value) => {
    try {
      if (stage?.stageType === 'league') {
        const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/leagues/${league._id}/teams/${id}/kick/${value}`);
        setLeague({ ...data.data });
      } else {
        await Axios.post(`${process.env.REACT_APP_CORE_API}/api/tournaments/${league._id}/teams/${id}/kick/${value}`);
        setLeague((league) => ({ ...league, teams: league.teams.map((team) => (team._id === id ? { ...team, kicked: value } : team)) }));
      }
      toast.success(`Successfully ${!value && "un"}kicked team`);
    } catch (e) {
      toast.error("Problem kicking team");
    }
  };

  const editPlaceHolder = async (team) => {
    setEditPlaceHolderTeam(team);
    setShowEditPlaceHolderModal(true);
  };

  const onSwitchTeam = (team) => {
    setSwitchingTeam(team);
    setShowSwitchModal(true);
  };

  const onSwapTeam = (_id) => {
    setSwapTeam(_id);
    setShowSwapModal(true);
  };

  const addplaceholder = async () => {
    try {
      const bodyData = {
        name: "Placeholder",
        initials: "PH",
        game: league.game._id,
        organisation: "61dc169b2a8c7f1cb4d65cd2",
        placeHolder: true,
      };
      if (stage?.stageType === 'league') {
        bodyData.league = league._id;
        const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/leagues/${league._id}/teams/placeholder`, bodyData);
        setLeague((league) => ({ ...league, ...data.updatedLeague }));
      } else {
        bodyData.tournament = league._id;
        const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/tournaments/${league._id}/teams/placeholder`, bodyData);
        setLeague((league) => ({ ...league, ...data.updatedTournament }));
      }
      toast.success("Successfully created team!");
    } catch (e) {
      toast.error(e.response?.data?.msg || "There was a problem creating your team");
    }
  };

  const onEditPlaceholderSuccess = (updatedTeamPH,isImage = false) => {
    setLeague((league) => {
      let updatedIndex = league?.teams.findIndex(team => team?.team?._id == updatedTeamPH?._id);
      if (updatedIndex > -1) {
        league.teams[updatedIndex].team = { ...updatedTeamPH };
      }
      return { ...league };
    });
    if(!isImage) setShowEditPlaceHolderModal(false);
  }

  return (
    <div>
      {showTeamModal && (
        <AddTeamsModal
          game={league?.game}
          show={showTeamModal}
          onHide={() => setShowTeamModal(false)}
          teams={league?.teams}
          setLeague={() => setLeague()}
          stage={stage}
          league={league}
        />
      )}
      {stage?.stageType === 'league' &&
        <SwapTeamsModal
          game={league?.game}
          show={showSwapModal}
          onHide={() => setShowSwapModal(false)}
          teams={league?.teams}
          setLeague={() => setLeague()}
          switchingTeamForId={swapTeam}
          leagueTeams={league?.teams}
          league={league}
        />
      }
      {showSwitchModal && (
        <SwitchTeamModal
          game={league?.game}
          show={showSwitchModal}
          onHide={() => setShowSwitchModal(false)}
          currentTeam={switchingTeam}
          leagueTeams={league?.teams}
          league={league}
          setLeague={setLeague}
          stage={stage}
        />
      )}
      {showEditPlaceHolderModal && (
        <EditPlaceHolderModal
          game={league?.game}
          show={showEditPlaceHolderModal}
          onHide={() => setShowEditPlaceHolderModal(false)}
          currentTeam={editPlaceHolderTeam}
          leagueTeams={league?.teams}
          league={league}
          setLeague={setLeague}
          onSuccees={onEditPlaceholderSuccess}
        />
      )}

      {/* Teams list */}
      <Card>
        <Card.Header className="d-flex justify-content-between">
          <div>Teams ({league?.teams?.length})</div>
          <Button
            variant="link"
            onClick={() => setShowTeamModal(true)}
            className={`p-0 ${permission ? "disabled-link p-0" : "p-0"}`}
          >
            Add Team
          </Button>
          <Button variant="link" onClick={addplaceholder} className={`p-0 ${permission ? "disabled-link p-0" : "p-0"}`}>
            Add Placeholder
          </Button>
        </Card.Header>
        <Card.Body>
          {!league?.teams?.length
            ? "No teams"
            : league.teams.map(({ _id, team, kicked, createdAt, name, initials, logo }) =>
              team ? (
                <div className="leagues-team" key={_id}>
                  {kicked && (
                    <div className="kicked-overlay">
                      {team.name} is kicked
                      <Button
                        size="sm"
                        variant="outline-danger"
                        className="ml-2"
                        onClick={() => onKickTeam(team._id, false)}
                        disabled={permission}
                      >
                        Unkick
                      </Button>
                    </div>
                  )}

                  <Media className={`mb-4 ${kicked && "translucent-overlay"}`}>
                    <img width={50} height={50} className="mr-3" style={{...(team.logoCropped ?{borderRadius: "50%"} :{})}} src={team.logo} alt={team.name} />
                    <Media.Body className="d-flex justify-content-between">
                      <div>
                        <a href={`${process.env.REACT_APP_LEAGUES_URL}/teams/${team._id}`} target="_blank" rel="noopener noreferrer">
                          {team.name}
                        </a>

                        <div className="text-secondary">
                          <small>Added {moment(createdAt).fromNow()}</small>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        {team.placeHolder === true && (
                          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Edit PlaceHolder Team</Tooltip>}>
                            <Button variant="link" onClick={() => editPlaceHolder(team)} disabled={permission}>
                              <FontAwesomeIcon icon={faEdit} />
                            </Button>
                          </OverlayTrigger>
                        )}
                        {filterTeams.includes(team._id) ? (
                          <div>
                            {/* {(team.placeHolder === true &&
                            < OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Edit PlaceHolder Team</Tooltip>}>
                              <Button variant="link" onClick={() => editPlaceHolder(team)} disabled={permission}>
                                <FontAwesomeIcon icon={faEdit} />
                              </Button>
                            </OverlayTrigger>
                          )} */}
                            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Switch with other team</Tooltip>}>
                              <Button variant="link" className="text-secondary" onClick={() => onSwitchTeam(team)} disabled={permission}>
                                <FontAwesomeIcon icon={faSync} />
                              </Button>
                            </OverlayTrigger>

                            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Kick from league</Tooltip>}>
                              <Button
                                variant="link"
                                className="text-danger"
                                onClick={() => onKickTeam(team._id, true)}
                                disabled={permission}
                              >
                                <FontAwesomeIcon icon={faBan} />
                              </Button>
                            </OverlayTrigger>
                          </div>
                        ) : (
                          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Remove from league</Tooltip>}>
                            <Button variant="link" className="text-danger" onClick={() => onRemoveTeam(_id)} disabled={permission}>
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                          </OverlayTrigger>
                        )}
                      </div>
                    </Media.Body>
                  </Media>
                </div>
              ) : (
                <div className="leagues-team" key={_id}>
                  <Media className={`mb-4`}>
                    <Media.Body className="d-flex justify-content-between">
                      <div>[DELETED]</div>
                      <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Swap team</Tooltip>}>
                        <Button variant="link" className="text-secondary" onClick={() => onSwapTeam(_id)} disabled={permission}>
                          <FontAwesomeIcon icon={faPlus} />
                        </Button>
                      </OverlayTrigger>
                    </Media.Body>
                  </Media>
                </div>
              )
            )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default MultiStageTeamsListCard;
