import React, { useState, useEffect, useContext } from "react";
import { Breadcrumb, Row, Col, Card, Button } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faMagic } from "@fortawesome/free-solid-svg-icons";
import MultiStageLeagueTabsManager from "./MultiStageTabsManager";
import MultiStageTeamsListCard from "./MultiStageLeagueTeams/MultiStageTeamsListCard";
import { AppContext } from "../../../context/AppContext";

const MultiStageLeagueDashboard = () => {
  const { user } = useContext(AppContext);
  const { id } = useParams();
  const [multiStageLeague, setMultiStageLeague] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}`)
    .then(({ data }) => {
      setMultiStageLeague(data.multiStageLeague);
      setLoading(false);
    })
    // .catch((err) => {console.log("multi stage error => :", err)})
  }, []);

  return (
    <div>
      <Breadcrumb>
        {!loading && multiStageLeague ? (
          <>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/games" }}>
              {multiStageLeague?.season?.game?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/games/${multiStageLeague?.season?.game?._id}/groups` }}>
              {multiStageLeague?.season?.group?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/games/groups/${multiStageLeague?.season?.group?._id}/seasons` }}>
              {multiStageLeague?.season?.name} {multiStageLeague?.season?.year}
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/games/seasons/${multiStageLeague?.season?._id}/multi-stage-league` }}>
              {multiStageLeague?.name}
            </Breadcrumb.Item>
          </>
        ) : (
          <Breadcrumb.Item active>Loading...</Breadcrumb.Item>
        )}
      </Breadcrumb>
      {multiStageLeague?.stages && <>
        <div className="admin-page-title">
          <div>
            <img src={multiStageLeague?.stages[0].league ? multiStageLeague?.stages[0].league?.logoImage : multiStageLeague?.stages[0].tournament?.logoImage} alt={multiStageLeague?.stages[0].league ? multiStageLeague?.stages[0].league?.name : multiStageLeague?.stages[0].tournament?.name} />
            <h2>{multiStageLeague?.name}</h2>
          </div>
        </div>

        <Row>
          {/* LEFT/main column */}
          <Col xl="9">
            {loading ? "Loading..." : <MultiStageLeagueTabsManager league={multiStageLeague.stages[0].league || multiStageLeague.stages[0].tournament} setMultiStageLeague={setMultiStageLeague} user={user} />}
          </Col>

          {/* Right column */}
          <Col xl="3">
            <Card bg="light" text="dark">
              <Card.Header>Quick Actions</Card.Header>
              <Card.Body>
                <Button as={Link} to="generateMatches" variant="link" className={!user.state.admin && !user.state.permissions.user.matches ? "disabled-link d-inline-block p-0 mb-1" : "d-inline-block p-0 mb-1"}>
                  <FontAwesomeIcon icon={faMagic} /> Generate Matches
                </Button>
                <Button variant="link" as="a" href={`${process.env.REACT_APP_LEAGUES_URL}/single-multi-stage-league/${id}`} target="_blank" className="d-block text-left p-0 mb-1">
                  <FontAwesomeIcon icon={faEye} /> View on Leagues.gg
                </Button>
              </Card.Body>
            </Card>
            {(!loading && multiStageLeague) && <MultiStageTeamsListCard 
              stage={multiStageLeague.stages[0]}
            permission={!user.state.admin && !user.state.permissions.user.matches} />}
          </Col>
        </Row>
      </>}
    </div>
  );
};

export default MultiStageLeagueDashboard;
