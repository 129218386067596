import React from "react";
import { Container } from "react-bootstrap";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Navbar from "./components/Navbar/Navbar";
import { WithAppContext } from "./context/AppContext";
import AppRouter from "./AppRouter";

// CSS
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-datetime/css/react-datetime.css";
import "./app.scss";

const App = () => {
  return (
    <div className="App-admin">
      <BrowserRouter>
        <WithAppContext>
          <ToastContainer position="bottom-left" autoClose={5000} hideProgressBar={true} newestOnTop={true} pauseOnFocusLoss />
          <Navbar />

          <Container fluid className="admin-content">
            <AppRouter />
          </Container>
        </WithAppContext>
      </BrowserRouter>
    </div>
  );
};

export default App;
