import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { Col, Row, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import GameCreateEditModal from "./includes/GameCreateEditModal";
import SingleGame from "./includes/SingleGame";
import GameConfigureModal from "./includes/GameConfigureModal/GameConfigureModal";
import { AppContext } from "../../../context/AppContext";

const AllGames = ({ settingFeature = false, scrims = false }) => {
  const { user } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [games, setGames] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showConfigureModal, setShowConfigureModal] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [deletingGame, setDeletingGame] = useState(false);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/games`).then(({ data }) => {
      setGames(data);
      setLoading(false);
    });
  }, []);

  const onCreateGame = () => {
    setModalData(false); // ensure modal data is always blank!
    setShowModal(true);
  };

  const onEditGame = (gameData) => {
    setModalData(gameData);
    setShowModal(true);
  };

  const onConfigureGame = (gameData) => {
    setModalData(gameData);
    setShowConfigureModal(true);
  };

  const onRemoveGame = async (id) => {
    setDeletingGame(id);

    if (window.confirm("Are you sure you want to delete this game? This action is irreversible!")) {
      await Axios.delete(`${process.env.REACT_APP_CORE_API}/api/games/${id}`);
      setGames((games) => games.filter(({ _id }) => _id !== id));
    }

    setDeletingGame(false);
  };

  return (
    <div>
      <div className="admin-page-title">
        <h2>{settingFeature ? 'Games' : scrims ? 'Scrims' : 'Leagues and Tournaments'}</h2>
        <Button variant="success" onClick={onCreateGame} disabled={!user.state.admin ? !user.state.permissions?.user?.game : false}>
          <FontAwesomeIcon icon={faPlus} /> Create
        </Button>
      </div>

      {loading ? (
        "Loading..."
      ) : (
        <Row>
          {games.map((game) => (
            (settingFeature || !game.defaultGame) && <Col xl={4} lg={6} md={6} sm={12} key={game._id}>
              <SingleGame
                settingFeature={settingFeature}
                scrims={scrims}
                gameData={game}
                onEditGame={onEditGame}
                onConfigureGame={onConfigureGame}
                deletingGame={deletingGame}
                onRemoveGame={onRemoveGame}
                user={user}
              />
            </Col>
          ))}
        </Row>
      )}

      <GameCreateEditModal show={showModal} setShow={setShowModal} data={modalData} setGames={setGames} />
      <GameConfigureModal show={showConfigureModal} setShow={setShowConfigureModal} data={modalData} setGames={setGames} />
    </div>
  );
};

export default AllGames;
