import React, { useState, useEffect } from "react";
import { Table, Form, Button, Card } from "react-bootstrap";
import Axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import Reorder, { reorder } from "react-reorder";
import { sortTeams } from "./_helpers/multiStageSorting";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import "./LeaderBoard.css";
const LeaderBoard = ({
  teams = [],
  setLeague,
  league = {},
  selectedGroupIndex,
  setSelectedGroupIndex,
  selectedStageIndex,
  setSelectedStageIndex,
  setIsAllStage,
  setIsAllGroup,
}) => {
  const { id: leagueId } = useParams();
  const { id } = useParams();
  const [saving, setSaving] = useState(false);
  const [newTeams, setNewTeams] = useState([]);
  const [orderedTeams, setOrderedTeams] = useState([]);
  // const [allStages, setAllStages] = useState([]);
  const [allStagesLeaderboard, setAllStagesLeaderboard] = useState([]);
  const [activeStageTab, setActiveStageTab] = useState(0);
  const [responseloader, setResponseLoading] = useState(false);
  const [render, rerender] = useState(false);
  const setSeedPositions = (allData, tempTeams, sIndex) => {
    if (sIndex > 0) {
      let allPrevTeams = [];
      let prevsIndex = sIndex - 1;
      let prevStage = allData[prevsIndex].groups
      prevStage.forEach((group)=> {
        allPrevTeams.push(group.teams)
      })
      let _prevTeams = allPrevTeams.flat(2)
      let modTeams = tempTeams.map(el=>_prevTeams.find(elem=> elem.team === el.team))
      return modTeams
    }else{
      return tempTeams
    }
  };
  const fetchLeaderBoard = () => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/stage/leaderboard`)
      .then(({ data }) => {
        if (data.data) {
          let allData = data.data;
          let sIndex = selectedStageIndex;
          let gIndex = selectedGroupIndex;
          if (selectedStageIndex === allData.length) {
            sIndex = 0;
            gIndex = 0;
            setIsAllStage(false);
          }
          if (selectedGroupIndex === allData[sIndex].groups.length) {
            gIndex = 0;
            setIsAllGroup(false);
          }
          let group = allData[sIndex].groups[gIndex];
          
          let tempTeams = group.teams;
          let _tempTeams =setSeedPositions(allData,tempTeams,sIndex)
          let updatedTeams = sortTeams(_tempTeams, league, group.overviewManualSort);
          allData[sIndex].groups[gIndex].teams = updatedTeams;
          setAllStagesLeaderboard(allData);
        }
      })
      .catch((err) => {
        toast.error("There was a problem in getting stages");
      });
  };

  useEffect(() => {
    if (allStagesLeaderboard.length > 0) {
      let allStagesLeaderboardCopy = [...allStagesLeaderboard];
      let group = allStagesLeaderboardCopy[selectedStageIndex].groups[selectedGroupIndex];
      let tempTeams = group.teams;
      let _tempTeams =setSeedPositions(allStagesLeaderboardCopy,tempTeams,selectedStageIndex)
      let updatedTeams = sortTeams(_tempTeams, league, group.overviewManualSort);
      allStagesLeaderboardCopy[selectedStageIndex].groups[selectedGroupIndex].teams = updatedTeams;
      setAllStagesLeaderboard(allStagesLeaderboardCopy);
    }
  }, [selectedStageIndex, selectedGroupIndex]);

  // Use Effect
  useEffect(() => {
    fetchLeaderBoard();
  }, []);

  // useEffect(() => {
  //   setLabel(league.overviewManualSort ? "Automatic" : "Manual")
  // }, [league]);
  const getTeamName = (thisTeam, teamIndex, stageIndex, seedPosition, matchComplete) => {
    let groupName;
    let teamName = "";
    const prevStage = allStagesLeaderboard[stageIndex - 1];
    if (prevStage) {
      prevStage.groups.forEach((group, ind) => {
        group.teams.forEach((team, index) => {
          if (team.team._id === thisTeam._id || team.team._id === thisTeam) {
            groupName = group.name;
          }
        });
      });
    }
    if (stageIndex === 0) {
      return matchComplete ? thisTeam.name ?? "TBD" : "TBD";
    }
    return `${matchComplete ? thisTeam.name ?? "TBD" : "TBD"} (${groupName} - Seed ${seedPosition})`;
  };
  const onChangeStageTeamSorting = (stageIndex, groupIndex) => {
    const { overviewManualSort } = allStagesLeaderboard[stageIndex].groups[groupIndex];
    let allStagesLeaderboardCopy = [...allStagesLeaderboard];
    allStagesLeaderboardCopy[stageIndex].groups[groupIndex].overviewManualSort = !overviewManualSort;
    setAllStagesLeaderboard(allStagesLeaderboardCopy);
    // debugger
    // rerender(!render);
  };

  const onReorder = (stageIndex, groupIndex, previousIndex, nextIndex) => {
    const allStagesLeaderboardCopy = [...allStagesLeaderboard];
    allStagesLeaderboardCopy[stageIndex].groups[groupIndex].teams = reorder(
      allStagesLeaderboard[stageIndex].groups[groupIndex].teams,
      previousIndex,
      nextIndex
    );
    setAllStagesLeaderboard(allStagesLeaderboardCopy);
    // setAllStagesLeaderboard((os) => {console.log("TEAM : ", os[stageIndex].groups[groupIndex].teams); reorder(os[stageIndex].groups[groupIndex].teams, previousIndex, nextIndex)});
    // let temp = {};
    // temp = allStagesLeaderboardCopy[stageIndex].groups[groupIndex].teams[nextIndex];
    // allStagesLeaderboardCopy[stageIndex].groups[groupIndex].teams[nextIndex] = allStagesLeaderboardCopy[stageIndex].groups[groupIndex].teams[previousIndex];
    // allStagesLeaderboardCopy[stageIndex].groups[groupIndex].teams[previousIndex] = temp;
    // setAllStagesLeaderboard(allStagesLeaderboardCopy);
  };

  const onUpdateTeam = (stageIndex, groupIndex, teamIndex, key, value) => {
    const allStagesLeaderboardCopy = [...allStagesLeaderboard];
    allStagesLeaderboardCopy[stageIndex].groups[groupIndex].teams[teamIndex][key] = value;
    setAllStagesLeaderboard(allStagesLeaderboardCopy);
    // rerender(!render);
  };

  const onSave = async (stageIndex, groupIndex) => {
    setSaving(true);
    const group = allStagesLeaderboard[stageIndex].groups[groupIndex];
    const newOrder = group.teams.map((team, i) => {
      team.order = i;
      team.team = team.team;
      return team;
    });
    let isManualSort = group.overviewManualSort;
    const postData = group.teams.map((team) => ({ ...team, team: team.team, order: null })); // need to cast populated team back to just id

    group.teams = isManualSort ? newOrder : postData;
    // debugger
    try {
      var putreq = await Axios.put(
        `${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/stage/${allStagesLeaderboard[stageIndex]._id}/group/leaderboard`,
        { group }
      );
      console.log(
        `${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/stage/${allStagesLeaderboard[stageIndex]._id}/group/leaderboard`
      );
      console.log(group);
      //  debugger
      toast.success("Saved");
    } catch (e) {
      toast.error("There was a problem saving");
    }
    setSaving(false);
  };

  const onRecalculateGroup = async (stageIndex, groupIndex) => {
    if (allStagesLeaderboard[stageIndex].stageType === "league") {
      if (window.confirm("Are you sure you want to do this?")) {
        try {
          const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/recalculateGroupScoreboard`, {
            stageType: allStagesLeaderboard[stageIndex].stageType,
            leagueId: allStagesLeaderboard[stageIndex].league._id,
            groupId: allStagesLeaderboard[stageIndex].groups[groupIndex]._id,
          });
          fetchLeaderBoard();
          // setLeague((league) => ({ ...league, teams: data.updatedLeague.teams, overviewManualSort: label == "Automatic" }));
          // label === "Automatic" && setOrderedTeams(sortTeams(data.updatedLeague.teams, league));
          // label === "Manual" && setNewTeams(data.updatedLeague.teams);
          toast.success("Successfully recalculated scores! ✅");
        } catch (e) {
          return toast.error("There was a problem recalculating scoreboard");
        }
      }
    } else {
      toast.error("Tournament score can not be recalculated");
    }
  };

  const onRecalculateStage = async (stageIndex) => {
    if (allStagesLeaderboard[stageIndex].stageType === "league") {
      if (window.confirm("Are you sure you want to do this?")) {
        try {
          const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/recalculateStageScoreboard`, {
            stageType: allStagesLeaderboard[stageIndex].stageType,
            leagueId: allStagesLeaderboard[stageIndex].league._id,
          });
          fetchLeaderBoard();
          // setLeague((league) => ({ ...league, teams: data.updatedLeague.teams, overviewManualSort: label == "Automatic" }));
          // label === "Automatic" && setOrderedTeams(sortTeams(data.updatedLeague.teams, league));
          // label === "Manual" && setNewTeams(data.updatedLeague.teams);
          toast.success("Successfully recalculated scores! ✅");
        } catch (e) {
          return toast.error("There was a problem recalculating scoreboard");
        }
      }
    } else {
      toast.error("Tournament score can not be recalculated");
    }
  };

  return (
    <div>
      <Form className="sub-tabs-form-leaderboard">
        <Tabs
          id="controlled-tab-example"
          // defaultActiveKey={selectedStageIndex}
          activeKey={selectedStageIndex}
          className="mb-3 sub-tabs"
          mountOnEnter={false}
          onSelect={(t) => {
            setSelectedStageIndex(t);
            setSelectedGroupIndex(0);
          }}
        >
          {responseloader
            ? "Loading..."
            : allStagesLeaderboard.map((stage, stageIndex) => (
                <Tab key={stageIndex} eventKey={stageIndex} title={stage.name}>
                  <Tabs
                    id="controlled-tab-example"
                    //  defaultActiveKey={selectedGroupIndex}
                    activeKey={selectedGroupIndex}
                    className="mb-3 sub-tabs"
                    mountOnEnter={false}
                    onSelect={(e) => setSelectedGroupIndex(e)}
                  >
                    {responseloader
                      ? "Loading..."
                      : stage.groups.map((group, groupIndex) => (
                          <Tab key={groupIndex} eventKey={groupIndex} title={group.name}>
                            <label className="switch">
                              <input
                                type="checkbox"
                                onChange={() => onChangeStageTeamSorting(stageIndex, groupIndex)}
                                checked={group.overviewManualSort}
                              />
                              <span className="slider round" />
                            </label>
                            <label className="form-check-label" htmlFor="flexSwitchCheckChecked" style={{ marginLeft: 10 }}>
                              {`Toggle to ${group.overviewManualSort ? "Automatic" : "Manual"}`}
                            </label>
                            <Table striped bordered hover responsive>
                              <thead>
                                <tr>
                                  <th>Team Name</th>
                                  <th>Score</th>
                                  <th>Matches</th>
                                  <th>Maps Won</th>
                                  <th>Maps Lost</th>
                                </tr>
                              </thead>
                              {/* FOR EMPTY */}
                              {group.teams.length === 0 &&
                                Array(group.noOfTeams)
                                  .fill(0)
                                  .map((_, i) => (
                                    <tr className="" key={i}>
                                      <td>{"TBD"}</td>
                                      <td>
                                        <Form.Control
                                          type="number"
                                          min={0}
                                          value={0}
                                          // onChange={(e) => onUpdateTeam(stageIndex, groupIndex, teamIndex, "score", e.target.value)}
                                          style={{ maxWidth: 150 }}
                                        />
                                      </td>
                                      <td>
                                        <Form.Control
                                          type="number"
                                          min={0}
                                          value={0}
                                          // onChange={(e) => onUpdateTeam(stageIndex, groupIndex, teamIndex, "matches", e.target.value)}
                                          style={{ maxWidth: 150 }}
                                        />
                                      </td>
                                      <td>
                                        <Form.Control
                                          type="number"
                                          min={0}
                                          value={0}
                                          // onChange={(e) => onUpdateTeam(stageIndex, groupIndex, teamIndex, "mapsWon", e.target.value)}
                                          style={{ maxWidth: 150 }}
                                        />
                                      </td>
                                      <td>
                                        <Form.Control
                                          type="number"
                                          min={0}
                                          value={0}
                                          // onChange={(e) => onUpdateTeam(stageIndex, groupIndex, teamIndex, "mapsLost", e.target.value)}
                                          style={{ maxWidth: 150 }}
                                        />
                                      </td>
                                    </tr>
                                  ))}
                              {/* MANUAL SORTING */}
                              {group.overviewManualSort && group.teams?.length && (
                                // <tbody>
                                <Reorder
                                  lock="horizontal"
                                  reorderId={group?._id} // Unique ID that is used internally to track this list (required)
                                  holdTime={100} // Default hold time before dragging begins (mouse & touch) (optional), defaults to 0
                                  // onReorder={() => onReorder(stageIndex,groupIndex)} // Callback when an item is dropped (you will need this to update your state)
                                  onReorder={(e, previousIndex, nextIndex) => onReorder(stageIndex, groupIndex, previousIndex, nextIndex)}
                                  className="reorder-list"
                                  // placeholder={
                                  //   <Card bg="light" body>
                                  //     &nbsp;
                                  //   </Card> // Custom placeholder element (optional), defaults to clone of dragged element
                                  // }
                                  component={"tbody"}
                                >
                                  {group.teams.map(
                                    ({ team, score, matches, mapsWon, mapsLost, seedPosition }, teamIndex) =>
                                      team && (
                                        <tr className="" key={teamIndex}>
                                          <td>{getTeamName(team, teamIndex, stageIndex, seedPosition, group.matchCompleted)}</td>
                                          <td>
                                            <Form.Control
                                              type="number"
                                              min={0}
                                              value={score || 0}
                                              onChange={(e) => onUpdateTeam(stageIndex, groupIndex, teamIndex, "score", e.target.value)}
                                              style={{ maxWidth: 150 }}
                                            />
                                          </td>
                                          <td>
                                            <Form.Control
                                              type="number"
                                              min={0}
                                              value={matches || 0}
                                              onChange={(e) => onUpdateTeam(stageIndex, groupIndex, teamIndex, "matches", e.target.value)}
                                              style={{ maxWidth: 150 }}
                                            />
                                          </td>
                                          <td>
                                            <Form.Control
                                              type="number"
                                              min={0}
                                              value={mapsWon || 0}
                                              onChange={(e) => onUpdateTeam(stageIndex, groupIndex, teamIndex, "mapsWon", e.target.value)}
                                              style={{ maxWidth: 150 }}
                                            />
                                          </td>
                                          <td>
                                            <Form.Control
                                              type="number"
                                              min={0}
                                              value={mapsLost || 0}
                                              onChange={(e) => onUpdateTeam(stageIndex, groupIndex, teamIndex, "mapsLost", e.target.value)}
                                              style={{ maxWidth: 150 }}
                                            />
                                          </td>
                                        </tr>
                                      )
                                  )}
                                </Reorder>
                                // </tbody>
                              )}
                              {/* AUTOMATIC SORTING */}
                              {!group.overviewManualSort && (
                                <tbody>
                                  {group.teams.map(
                                    ({ team, score, matches, mapsWon, mapsLost, seedPosition }, teamIndex) =>
                                      team &&
                                      (console.log("a kudgfaushg fu kjgadf", teams, team, score, matches, mapsWon, mapsLost),
                                      (
                                        <tr key={teamIndex}>
                                          <td>{getTeamName(team, teamIndex, stageIndex, seedPosition, group.matchCompleted)}</td>
                                          <td>
                                            <Form.Control
                                              type="number"
                                              min={0}
                                              value={score || 0}
                                              onChange={(e) => onUpdateTeam(stageIndex, groupIndex, teamIndex, "score", e.target.value)}
                                              style={{ maxWidth: 150 }}
                                            />
                                          </td>
                                          <td>
                                            <Form.Control
                                              type="number"
                                              min={0}
                                              value={matches || 0}
                                              onChange={(e) => onUpdateTeam(stageIndex, groupIndex, teamIndex, "matches", e.target.value)}
                                              style={{ maxWidth: 150 }}
                                            />
                                          </td>
                                          <td>
                                            <Form.Control
                                              type="number"
                                              min={0}
                                              value={mapsWon || 0}
                                              onChange={(e) => onUpdateTeam(stageIndex, groupIndex, teamIndex, "mapsWon", e.target.value)}
                                              style={{ maxWidth: 150 }}
                                            />
                                          </td>
                                          <td>
                                            <Form.Control
                                              type="number"
                                              min={0}
                                              value={mapsLost || 0}
                                              onChange={(e) => onUpdateTeam(stageIndex, groupIndex, teamIndex, "mapsLost", e.target.value)}
                                              style={{ maxWidth: 150 }}
                                            />
                                          </td>
                                        </tr>
                                      ))
                                  )}
                                </tbody>
                              )}
                            </Table>
                            <div>
                              <div className="d-flex justify-content-between">
                                <div>
                                  <Button variant="success" onClick={() => onSave(stageIndex, groupIndex)}>
                                    {saving ? "Saving..." : "Save Changes"}
                                  </Button>
                                </div>
                                <div>
                                  <Button
                                    variant="secondary"
                                    onClick={() => onRecalculateGroup(stageIndex, groupIndex)}
                                    style={{ marginRight: 20 }}
                                  >
                                    <FontAwesomeIcon icon={faRedoAlt} /> Recalculate group
                                  </Button>
                                  <Button variant="secondary" onClick={() => onRecalculateStage(stageIndex)}>
                                    <FontAwesomeIcon icon={faRedoAlt} /> Recalculate stage
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Tab>
                        ))}
                  </Tabs>
                </Tab>
              ))}
        </Tabs>
      </Form>
    </div>
  );
};

export default LeaderBoard;
