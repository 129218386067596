import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Axios from "axios";
import { useParams } from "react-router-dom";
import DatePicker from "react-datepicker";

const seasonCatagories = [
  { name: "Spring", icon: "FaLeaf" },
  { name: "Summer", icon: "FaSun" },
  { name: "Fall", icon: "FaCanadianMapleLeaf" },
  { name: "Winter", icon: "FaRegSnowflake" },
  { name: "Offseason", icon: "FaMoon" },
  { name: "Other", icon: "FaQuestion" },
];
const SeasonCreateEditModal = ({ show, handleClose, data = {}, setSeasons }) => {
  const { id: group } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  const onSubmit = async (formData) => {
    setLoading(true);
    formData.showOnHomepage = !!formData.showOnHomepage; //because of strange value from the switch
    formData.startDate = startDate.toISOString();
    if (data) {
      // Edit
      const { data: resData } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/seasons/${data._id}`, formData);
      setSeasons((seasons) => seasons.map((season) => (season._id === data._id ? resData.updatedSeason : season)));
    } else {
      // Create
      const { data: resData } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/seasons`, { group, ...formData });
      setSeasons((seasons) => [...seasons, resData.season]);
    }
    setLoading(false);
    handleClose();
  };

  useEffect(() => {
    if (data && data.startDate) {
      setStartDate(new Date(data.startDate));
    } else if (data && !data.startDate) {
      setStartDate(null);
    } else {
      setStartDate(new Date());
    }
  }, [data]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{data ? "Edit" : "Create"} Season</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              ref={register({ required: "Required" })}
              type="text"
              id="name"
              name="name"
              defaultValue={data.name}
              isInvalid={errors.name}
            />
            <Form.Control.Feedback type="invalid">{errors.name && errors.name.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>Year</Form.Label>
            <Form.Control
              ref={register({ required: "Required" })}
              type="text"
              id="year"
              name="year"
              defaultValue={data.year}
              isInvalid={errors.year}
            />
            <Form.Control.Feedback type="invalid">{errors.year && errors.year.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>Category</Form.Label>
            <Form.Control
              ref={register({ required: "Required" })}
              as="select"
              id="category"
              name="category"
              defaultValue={data.category || "_DEFAULT_"}
              isInvalid={errors.category}
            >
              <option disabled value="_DEFAULT_">
                Select Category
              </option>
              {seasonCatagories.map((category) => (
                <option key={category.name} value={category.name}>
                  {category.name}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">{errors.category && errors.category.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Check
              ref={register()}
              type="switch"
              id="showOnHomepage"
              label="Show on homepage"
              name="showOnHomepage"
              defaultChecked={data.showOnHomepage}
              isInvalid={errors.showOnHomepage}
            />
            <Form.Control.Feedback type="invalid">{errors.showOnHomepage && errors.showOnHomepage.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>Start Date</Form.Label>
            <div>
              <Form.Control
                as={DatePicker}
                id="startDate"
                name="startDate"
                selected={startDate}
                onChange={setStartDate}
                isInvalid={errors.startDate}
                required
              ></Form.Control>
            </div>
            <Form.Control.Feedback type="invalid">{errors.startDate && errors.startDate.message}</Form.Control.Feedback>
          </Form.Group>

          <Button type="submit" variant="success" className="float-right" disabled={loading}>
            {loading ? "Processing..." : data ? "Edit" : "Create"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default SeasonCreateEditModal;
