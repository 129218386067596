import React, { useState, useEffect, createRef } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import Axios from "axios";
import { toast } from "react-toastify";
import { countries } from "../../../utils/countries";
import CreateLangApi from "../utils/CreateNewLangApi";
const CreateLanguage = ({ show, onHide }) => {
  const [endonym, setEndonym] = useState();
  const [language, setLanguage] = useState();
  const [countryflag, setCountryFlag] = useState();
  const [languageCode, setLanguageCode] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [nationalities, setNationalities] = useState([]);
  useEffect(() => {
    setNationalities(
      countries
        .filter(({ demonym }) => demonym)
        .filter((v, i, a) => a.findIndex((t) => t.demonym === v.demonym) === i)
        .sort((a, b) => a.demonym.localeCompare(b.demonym))
    );
  }, []);
  const onSubmitLang = async (e) => {
    e.preventDefault()
    
    const obj = {
      endonym: endonym,
      language: language,
      flag: countryflag,
      langCode: languageCode,
    };
    if (endonym.length < 1|| language.length < 1|| countryflag.length> 4 || languageCode.length < 1){
      window.alert("nothing should be empty")
    }
    else{
      setSubmitting(true)
   const response =  await CreateLangApi(obj);
   setSubmitting(false)
      onHide()
    if (response){
      toast.success("New Language is Created");
      window.location.reload();
    }else{
      toast.error("Try again! New Language is not created");

    }
  }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create New Language</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={onSubmitLang}>
          <Form.Group className="mb-3">
            <Form.Label>Endonym</Form.Label>
            <Form.Control required type="text" name="endonym" value={endonym} onChange={(e) => setEndonym(e.target.value)} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Language</Form.Label>
            <Form.Control required type="text" name="language" value={language} onChange={(e) => setLanguage(e.target.value)} />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Flag</Form.Label>

            <div className="d-flex flex-direction-row">
              <Form.Control
                as="select"
                name="flag"
                value={countryflag}
                defaultValue={countryflag}
                onChange={(e) => setCountryFlag(e.target.value)}
                style={{ width: "90%" }}
                required
              >
                <option value="">Country</option>
                {nationalities.map(({ alpha2Code, demonym }) => (
                  <option key={alpha2Code} value={alpha2Code}>
                    {demonym}
                  </option>
                ))}
              </Form.Control>
              {!!countryflag && <img src={`https://www.flagcdn.com/128x96/${countryflag.toLowerCase()}.png`} width={32} height={32} alt={countryflag} className="ml-2" />}
            </div>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Language Code</Form.Label>
            <Form.Control required type="text" name="langCode" value={languageCode} onChange={(e) => setLanguageCode(e.target.value)} />
          </Form.Group>
          <div className="d-flex justify-content-end">
            <Button type="submit" size="sm" variant="success" disabled={submitting}>
              {submitting ? "Saving..." : "Save"}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateLanguage;
