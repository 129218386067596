import React, { useState, useEffect } from "react";
import { Modal, Form, Col, Row, Button } from "react-bootstrap";
import Axios from "axios";
import Datetime from "react-datetime";
import moment from "moment-timezone/builds/moment-timezone-with-data";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { BsArrowLeftRight } from "react-icons/bs";
import { TIMEZONES } from "../timezones";


const MultiStageLeagueCreateEditMatchModal = ({ show, onHide, match, allStages, updateMatch, prefferedTimezone, stageIndex,groupIndex, updateMatchRecords }) => {
  const { id } = useParams();
  const [loadingSS, setLoadingSS] = useState(true);
  const [scoreSystems, setScoreSystems] = useState([]);
  const [league, setLeague] = useState(false);
  const [team1, setTeam1] = useState(false);
  const [team1Ind, setTeam1Ind] = useState(false);
  const [team2, setTeam2] = useState(false);
  const [team2Ind, setTeam2Ind] = useState(false);
  const [vetoFlag, setVetoFlag] = useState(true);
  const [showVeto, setShowVeto] = useState(false);
  const [selectedScoreSystem, setSelectedScoreSystem] = useState(false);
  const [bestOf, setBestOf] = useState(false);
  const [switchSides, setSwitchSides] = useState(true);
  const [date, setDate] = useState(match ? match.datetime : false);
  // const [week, setWeek] = useState(1);
  const [round, setRound] = useState(1);
  const [customReschdule, setCustomReschdule] = useState(match && match.reschedule ? true : false);
  const [earliestDate, setEarliestDate] = useState(false);
  const [latestDate, setLatestDate] = useState(false);
  const [minDate, setMinDate] = useState(false);
  const [maxDate, setMaxDate] = useState(false);
  const [sideSelection, setSideSelection] = useState(false);
  const [broadcasted, setBroadcasted] = useState(false);
  const [isDateChanged, setIsDateChanged] = useState(false);
  const [localPrefferedTimezone, setLocalPrefferedTimezone] = useState(prefferedTimezone);
  const [render, rerender] = useState(false);
  const [teamOneName, setTeamOneName] = useState([
    "Seed 1",
    "Seed 2",
    "Seed 3",
    "Seed 4",
    "Seed 5",
    "Seed 6",
    "Seed 7",
    "Seed 8",
    "Seed 9",
    "Seed 10",
  ]);
  const [teamTwoName, setTeamTwoName] = useState([
    "Seed 1",
    "Seed 2",
    "Seed 3",
    "Seed 4",
    "Seed 5",
    "Seed 6",
    "Seed 7",
    "Seed 8",
    "Seed 9",
    "Seed 10",
  ]);
  useEffect(()=>{
    let _seedPositions = []
    let ind = 0
    let maxSeeds = 0
    if(stageIndex !== false && stageIndex >= 0){
      allStages[stageIndex].groups.forEach(group=>{
        let _arr = []
        for(let i= 0 ; i < group.noOfTeams; i++){
          let index =i + 1
          if(maxSeeds < group.noOfTeams){
            maxSeeds = group.noOfTeams
          }
          let name = 'Seed '+ index +' ['+group?.groupName +']'
          _arr.push({name: name, group: group._id, seedPosition: index})
        }
        _seedPositions.push(_arr)
      })
      let _newArr =[]
      Array.from(Array(maxSeeds)).forEach((el,index)=>{
        let teams= _seedPositions.flat(1).filter(el => el.seedPosition === index + 1)
        if(teams){
          _newArr.push(teams)
        }
      })
      setTeamOneName(_newArr.flat(1))
      setTeamTwoName(_newArr.flat(1))
  }
  },[stageIndex])
  useEffect(() => {
    setLocalPrefferedTimezone(prefferedTimezone);
  }, [prefferedTimezone]);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/scoreSystems`).then(({ data }) => {
      setScoreSystems(data);
      setLoadingSS(false);
    });
    if (allStages?.length > 0) {
      if (allStages[0]?.stageType === "league") {
        Axios.get(`${process.env.REACT_APP_CORE_API}/api/leagues/${allStages[0]?.league}`).then(({ data }) => {
          data.teams.forEach((team) => {
            allStages[0].groups.forEach((grp) => {
              grp.teams.forEach((grpTeam) => {
                if (team.team._id.toString() === grpTeam.team.toString()) {
                  team.group = grp.groupName;
                }
              });
            });
          });
          setLeague(data);
          setLocalPrefferedTimezone(data.timezone || "Etc/UTC");
          setEarliestDate(
            match && match.reschedule
              ? moment(match.reschedule.earliestDate).toISOString()
              : data.reschedule
              ? moment(data.reschedule.earliestDate).toISOString()
              : false
          );
          setLatestDate(
            match && match.reschedule
              ? moment(match.reschedule.latestDate).toISOString()
              : data.reschedule
              ? moment(data.reschedule.latestDate).toISOString()
              : false
          );
          rerender(!render);
        });
      }
    }
  }, [show]);

  useEffect(() => {
    calculateDateRanges();
    let sp1team,sp2team = null
    if(match.sp1){
      let team1G = match.groups.find(el => el._id === match.sp1.group).name
      sp1team = 'Seed '+ match.sp1.seedPosition +' ['+team1G +']'
    }
    if(match.sp2){
      let team2G = match.groups.find(el => el._id === match.sp2.group).name
      sp2team = 'Seed '+ match.sp2.seedPosition +' ['+team2G +']'
    }
    setTeam1(match?.t1 ? match.t1?._id : sp1team);
    setTeam2(match?.t2 ? match.t2?._id : sp2team);
    setSelectedScoreSystem(match ? match?.scoreSystem?._id : false);
    setBestOf(match ? match.bestOf : false);
    setSwitchSides(match ? match.switchSides : true);
    setBroadcasted(match ? match.broadcasted : false);
    setSideSelection(match ? match.sideSelection : false);
    if (localPrefferedTimezone && localPrefferedTimezone !== "" && localPrefferedTimezone != undefined) {
      setDate(match ? moment(match.datetime).tz(localPrefferedTimezone).format("DD/MM/YYYY HH:mm") : false);
    } else if (league.timezone !== null && league.timezone !== "" && league.timezone != undefined) {
      setDate(match ? moment(match.datetime).tz(league.timezone).format("DD/MM/YYYY HH:mm") : false);
    } else {
      setDate(match ? moment(match.datetime).format("DD/MM/YYYY HH:mm") : false);
    }
    // setWeek(match ? match.week : 1);
    setRound(match ? match.round : 1);
    setCustomReschdule(match && match.reschedule ? true : false);
    setShowVeto(match && league?.game?.shortName === "CSGO" && [1, 3, 5].includes(match.bestOf) ? true : false);
  }, [match, show]);

  const calculateDateRanges = () => {
    let min = moment(match.createdAt).subtract(1, "days").set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    let max = moment(match.createdAt).add(7, "days").set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    setMinDate(min);
    setMaxDate(max);
  };

  const getScoreSystemData = () => {
    if (selectedScoreSystem) {
      return scoreSystems.find(({ _id }) => _id === selectedScoreSystem);
    }

    return false;
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const ifDateChanged = isDateChanged ? { datetime: moment(date, "DD/MM/YYYY HH:mm:ss").tz(localPrefferedTimezone, true) } : {datetime: match?.datetime};
      if (formValid) {
        const postData = {
          seedPosition1: team1Ind ? team1Ind : match?.sp1?.seedPosition,
          seedPosition2: team2Ind ? team2Ind : match?.sp2?.seedPosition,
          switchSides,
          sideSelection,
          broadcasted,
          scoreSystem: selectedScoreSystem,
          bestOf,
          ...ifDateChanged,
          week: 1,
          round,
          reschedule: { earliestDate, latestDate },
        };

        if(!team1.includes(" ") && !team2.includes(" ")){
          delete postData.seedPosition1;
          postData.t1 = team1;
          delete postData.seedPosition2;
          postData.t2 = team2;
        }
        let t1Group, t2Group = null;
        if(team1.includes(" ") || team2.includes(" ")){
          t1Group = teamOneName.find(el=> el.name === team1).group
          t2Group = teamTwoName.find(el=> el.name === team2).group || allStages[stageIndex].groups[0]?._id

        }else{
          allStages[stageIndex].groups.forEach(group => {
            group.teams.forEach(team => {
              if(team.team === team1)
                t1Group = group._id;
              if(team.team === team2)
                t2Group = group._id;
            })
          });
        }
        if(allStages[stageIndex].stageType === 'league'){
          postData.league = allStages[stageIndex].league;
        }
        if (t1Group === t2Group) {
          postData.groups = [t1Group];
        } else {
          postData.groups = [t1Group, t2Group];
        }
        if (
          (league.reschedule &&
            moment(earliestDate).isSame(league.reschedule?.earliestDate) &&
            moment(latestDate).isSame(league.reschedule?.latestDate)) ||
          !customReschdule
        ) {
          postData.reschedule = null;
        }

        let vetoEnable = showVeto ? vetoFlag : false;

        if (match && (!team1.includes(" ") && !team2.includes(" "))) {
          await Axios.put(`${process.env.REACT_APP_CORE_API}/api/matches/${match._id}?isVeto=${vetoEnable}`, postData);
          updateMatch();

        } else if(!team1.includes(" ") && !team2.includes(" ")) {

          await Axios.post(`${process.env.REACT_APP_CORE_API}/api/matches?isVeto=${vetoEnable}`, {
            league: id,
            ...postData,
          });
          updateMatch();
        } else {
          await Axios.post(`${process.env.REACT_APP_CORE_API}/api/matches/seedBase?isVeto=${vetoEnable}`, {
            league: id,
            ...postData,
            matchId : match._id

          });
          updateMatch();
        }
        updateMatchRecords();
        toast.success(`Successfully ${match ? "edited" : "created"} match!`);
        onHide();
        setIsDateChanged(false);
      }
    } catch (e) {
      console.log("error", e);
      toast.error("There was a problem processing this request 😞");
    }
  };

  const onDeleteMatch = async () => {
    const ids = [match._id];

    if (window.confirm("Are you sure you want to delete this match?")) {
      try {
        await Axios.post(`${process.env.REACT_APP_CORE_API}/api/matches/deleteMulti`, { ids });

        // updateMatches((matches) => matches.filter(({ _id }) => !ids.includes(_id)));
        toast.success(`Successfully deleted ${ids.length} matches`);
        onHide();
      } catch (e) {
        toast.error("There was a problem deleting the selected matches");
      }
    }
  };

  const formValid = !!(
    team1 &&
    team2 &&
    selectedScoreSystem &&
    bestOf &&
    // week &&
    round &&
    date &&
    (customReschdule && (!earliestDate || !latestDate) ? false : true)
  );

  const swapTeams = () => {
    let temp = team1;
    setTeam1(team2);
    setTeam2(temp);
    temp = match.t1Score;
    match.t1Score = match.t2Score;
    match.t2Score = temp;
  };
 const onTeamOneSelect = (value) => {
  //  console.log({value});
  // if(value === team2) {
  //   toast.error("Already Exist in team 2!");
  // } else {
    let index = league.teams.findIndex(el=> el.team._id === value) + 1
    if(index < 1){
      index = teamOneName.find(el=> el.name === value).seedPosition
    }
  //  console.log({index});

    setTeam1(value)
    setTeam1Ind(index)
  // }
 }
 const onTeamTwoSelect = (value) => {
  // if(value === team1) {
  //   toast.error("Already Exist in team 1!");
    
  // } else {
    let index = league.teams.findIndex(el=> el.team._id === value) + 1
    if(index < 1){
      index = teamTwoName.find(el=> el.name === value).seedPosition
    }
    setTeam2Ind(index)
    setTeam2(value)
  // }
 }
  return (
    league && (
      <Modal show={show} onHide={onHide} size="lg">
        <Modal.Header closeButton>
          {match ? (
            <Modal.Title>
              <sub className="d-block mb-3">
                <small>Week {match.week}</small>
              </sub>
              {match.t1 ? match.t1.name : `Seed ${match.sp1.seedPosition}` } vs {match.t2 ? match.t2.name : `Seed ${match.sp2.seedPosition}` } (
              {moment.utc(match.datetime).tz(league?.timezone)?.format("DD/MM/YYYY @ HH:mm")})
            </Modal.Title>
          ) : (
            <Modal.Title>Create Match</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmit}>
            {league && (
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Team 1</Form.Label>
                    <Form.Control
                      as="select"
                      value={team1 || "_DEFAULT_"}
                      // onChange={(e) => setTeam1(e.target.value)}
                      onChange={(e) => onTeamOneSelect(e.target.value)}
                    >
                      <option value="_DEFAULT_" disabled>
                        Select team 1
                      </option>
                      {league?.teams.length === 0
                        ? teamOneName.map(
                            (team, teamIndex) =>
                              team && (
                                <option
                                  key={team.name}
                                  value={team.name}
                                  //defaultValue={match ? ((teamIndex === parseInt(match.sp1.seedPosition) - 1) ? true : false) : team}
                                  // defaultValue={match ? (teamIndex === match.sp1.seedPosition - 1 ? true : false) : team}
                                  selected={match ? (teamIndex === parseInt(match.sp1.seedPosition) - 1 ? true : false) : false}
                                >
                                  {team.name}
                                </option>
                              )
                          )
                        : league.teams.map(
                            (team) =>
                              team && (
                                <option key={team._id} value={team.team._id}>
                                  {`${team.team.name} [${team.group || "NO SPOT"}]`}
                                </option>
                              )
                          )}
                    </Form.Control>
                  </Form.Group>
                </Col>
                {match && (
                  <Col sm={1} className="d-flex justify-content-end align-items-end">
                    <Form.Group>
                      <Button onClick={swapTeams} disabled={!team1 || !team2}>
                        <BsArrowLeftRight />
                      </Button>
                    </Form.Group>
                  </Col>
                )}
                <Col>
                  <Form.Group>
                    <Form.Label>Team 2</Form.Label>
                    <Form.Control
                      as="select"
                      value={team2 || "_DEFAULT_"}
                      // onChange={(e) => setTeam2(e.target.value)}
                      onChange={(e) => onTeamTwoSelect(e.target.value)}
                    >
                      <option value="_DEFAULT_" disabled>
                        Select team 2
                      </option>
                      {league?.teams.length === 0
                        ? teamTwoName.map(
                            (team, teamIndex) =>
                              team && (
                                <option
                                  key={team.name}
                                  value={team.name}
                                  selected={match ? (teamIndex === parseInt(match.sp2.seedPosition) - 1 ? true : false) : false}
                                >
                                  {team.name}
                                </option>
                              )
                          )
                        : league.teams.map(
                            (team) =>
                              team && (
                                <option key={team._id} value={team.team._id}>
                                  {`${team.team.name} [${team.group || "NO SPOT"}]`}
                                </option>
                              )
                          )}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            )}

            <Form.Group>
              <Form.Label>Score System</Form.Label>
              <Form.Control as="select" value={selectedScoreSystem || "_DEFAULT_"} onChange={(e) => setSelectedScoreSystem(e.target.value)}>
                <option disabled value="_DEFAULT_">
                  {loadingSS ? "Loading..." : "Select score system"}
                </option>
                {scoreSystems.map(({ _id, name }) => (
                  <option key={_id} value={_id}>
                    {name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            {!loadingSS && selectedScoreSystem && (
              <>
                <Form.Group>
                  <Form.Label>Best Of</Form.Label>
                  <Form.Control
                    as="select"
                    defaultValue={bestOf || "_DEFAULT_"}
                    onChange={(e) => {
                      setBestOf(parseFloat(e.target.value));
                      setShowVeto(
                        selectedScoreSystem.toString() === "5f6c76bc09fc214fea5dc921" &&
                          league?.game?.shortName === "CSGO" &&
                          [1, 3, 5].includes(parseFloat(e.target.value))
                          ? true
                          : false
                      );
                    }}
                  >
                    <option disabled value="_DEFAULT_">
                      Select best of
                    </option>
                    {getScoreSystemData()?.configuration?.map(({ bestOf }) => (
                      <option key={bestOf} value={bestOf}>
                        Best Of {bestOf}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Check
                    label="Switch Sides Every Other Map"
                    id="switch-sides"
                    checked={switchSides}
                    onChange={(e) => setSwitchSides(e.target.checked)}
                  />
                </Form.Group>
                <Row>
                  <Col sm={4}>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox1">
                      <Form.Check
                        type="checkbox"
                        name="sideselection"
                        checked={sideSelection}
                        label="Side Selection"
                        onChange={(e) => {
                          setSideSelection((prevState) => !prevState);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox2">
                      <Form.Check
                        type="checkbox"
                        name="broadcasted"
                        checked={broadcasted}
                        label="Broadcasted"
                        onChange={(e) => {
                          setBroadcasted((prevState) => !prevState);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  {showVeto && (
                    <Col sm={4}>
                      <Form.Group className="mb-3" controlId="formBasicCheckbox3">
                        <Form.Check
                          type="checkbox"
                          name="vetoFlagEnable"
                          checked={vetoFlag}
                          label="Enable Veto"
                          onChange={(e) => {
                            setVetoFlag(!vetoFlag);
                          }}
                          disabled={match && match.status == "complete"}
                        />
                      </Form.Group>
                    </Col>
                  )}
                </Row>

                {match && (
                  <p className="text-danger">
                    NOTE: If you are changing the switch sides setting after match data has already been received, you MUST reset the match
                    first, come back here and change the switch sides setting, then manually fetch data again.
                  </p>
                )}
              </>
            )}

            <Row>
              <Col sm={3}>
                <Form.Group>
                  <Form.Label>Round</Form.Label>
                  <Form.Control type="number" min={1} value={round} onChange={(e) => setRound(parseFloat(e.target.value))} />
                </Form.Group>
              </Col>
              {/* <Col sm={3}>
              <Form.Group>
                <Form.Label>Week</Form.Label>
                <Form.Control type="number" min={1} value={week} onChange={(e) => setWeek(parseFloat(e.target.value))} />
              </Form.Group>
            </Col> */}
              <Col>
                <Form.Group>
                  <Form.Label>Date</Form.Label>
                  <div>
                    <Datetime
                      value={date}
                      onChange={(date) => {
                        setDate(date);
                        setIsDateChanged(true);
                      }}
                      dateFormat="DD/MM/YYYY"
                      timeFormat="HH:mm:ss"
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Time zone</Form.Label>
                  <Form.Control
                    as="select"
                    name="timezone"
                    defaultValue={localPrefferedTimezone}
                    value={localPrefferedTimezone}
                    onChange={(e) => {
                      setLocalPrefferedTimezone(e.target.value);
                      setIsDateChanged(true);
                    }}
                  >
                    {TIMEZONES.map(({ value, name }, index) => (
                      <option value={value} key={index}>
                        {name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            {/* Code commented temporarily and will be added in future deployments */}
            {!match.isLockReschedule && match.status !== "complete" && (
              <>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Check
                        type="checkbox"
                        name="customRescduling"
                        checked={customReschdule}
                        label="Use custom match rescheduling"
                        onChange={(e) => {
                          setCustomReschdule(!customReschdule);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Earliest Date</Form.Label>
                      <Datetime
                        value={earliestDate ? moment(earliestDate).format("DD/MM/YYYY HH:mm:ss") : null}
                        onChange={setEarliestDate}
                        dateFormat="DD/MM/YYYY"
                        timeFormat="HH:mm:ss"
                        inputProps={{ disabled: !customReschdule }}
                        isValidDate={(currentDate) => currentDate.isBetween(minDate, maxDate)}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Latest Date</Form.Label>
                      <Datetime
                        value={latestDate ? moment(latestDate).format("DD/MM/YYYY HH:mm:ss") : null}
                        onChange={setLatestDate}
                        dateFormat="DD/MM/YYYY"
                        timeFormat="HH:mm:ss"
                        inputProps={{ disabled: !customReschdule }}
                        isValidDate={(currentDate) => currentDate.isBetween(minDate, maxDate)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}
            <div className="d-flex">
              <Button style={{ width: "100%" }} type="submit" variant="success" size="lg" disabled={!formValid}>
                {match ? "Edit" : "Create"} Match
              </Button>

              {/* Only if editing */}
              {match && (
                <Button style={{ marginLeft: 15, width: 50 }} onClick={onDeleteMatch} variant="danger" size="lg" block>
                  <FontAwesomeIcon icon={faTrashAlt} />
                </Button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    )
  );
};

export default MultiStageLeagueCreateEditMatchModal;
