import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import Axios from "axios";
import { toast } from "react-toastify";
import EditImageModal from "../../../../../_modals/EditImageModal/EditImageModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import moment from "moment";
import { countries } from "../../../../../../utils/countries";


const General = ({ user, setUser }) => {
  const [country, setCountry] = useState(user.nationality.code);
  const [nationalities, setNationalities] = useState(countries
    .filter(({ demonym }) => demonym)
    .filter((v, i, a) => a.findIndex((t) => t.demonym === v.demonym) === i)
    .sort((a, b) => a.demonym.localeCompare(b.demonym)));
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, errors, setError } = useForm({
    defaultValues: {
      nationality: user.nationality.code
    }
  });
  const [modals, setModals] = useState({});
  const [cerror, setCerror] = useState({});

  const onUpdateImage = async (type, imageUrl) => {
    try {
      const { data } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/users/${user._id}/image`, { type, image: imageUrl });
      setUser((user) => ({ ...user, ...data.updatedImages }));
      setModals({ ...modals, [type]: false });

      toast.success("User Pictures updated");
    } catch (err) {
      toast.error("There was a problem updating user images!");
    }
  };

  const onSubmit = async (data) => {
    
    if (user.email !== data.email) {
      const { data: emailExists } = await Axios.get(`${process.env.REACT_APP_CORE_API}/api/users/emailExists/${data.email}`);
      if (emailExists) {
        setError("email","Email: Already Exists")
        toast.error("Email: Already Exists");
        return
      }
    }

    const birthday = moment(`${data.birthdayDay} ${data.birthdayMonth} ${data.birthdayYear}`).toISOString();
    if (moment().diff(birthday, "years") < 10) {
      setError("birthday", "tooYoung", "You need to be at least 10 to sign up for Leagues");
      return;
    }

    // Delete old birthday keys
    delete data.birthdayDay;
    delete data.birthdayMonth;
    delete data.birthdayYear;

    try {
      setLoading(true);
      if (country) {
        const nationality = nationalities.find((data) => data.alpha2Code === country);
        data = {
          ...data,
          nationality: { code: country, name: nationality.demonym },
        };
      }

      const { data: newData } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/users/${user._id}`, { ...data, birthday });
      user.email = data.email
      setUser({ ...user, ...newData.updatedUserDetail })
      setLoading(false);

      toast.success("Details updated");
    } catch (e) {
      const msg = e.response.data ? e.response.data.msg : "Fatal error";
      setLoading(false);
      toast.error(msg);
    }
  };

  // Social fields are dynamically rendered from this array! The value must match the regex in order to be valid
  const socialFields = [
    { name: "Discord", placeholder: "Name #0000", regex: /.*\s?#\s?([0-9]{4})$/, invalidMsg: "Discord name is not valid" },
    { name: "Twitch", placeholder: "Twitch.tv/channel", regex: /(?:(?:http|https):\/\/)?(?:www.)?(?:twitch.tv)\/([A-Za-z0-9-_]+)/ },
    { name: "Facebook", placeholder: "Facebook.com/name", regex: /(?:(?:http|https):\/\/)?(?:www.)?(?:facebook.com)\/([A-Za-z0-9-_]+)/ },
    { name: "Twitter", placeholder: "Twitter.com/handle", regex: /(?:(?:http|https):\/\/)?(?:www.)?(?:twitter.com)\/([A-Za-z0-9-_]+)/ },
    {
      name: "Instagram",
      placeholder: "Instagram.com/handle",
      regex: /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am)\/([A-Za-z0-9-_]+)/,
    },
    {
      name: "YouTube",
      placeholder: "Youtube.com/channel",
      regex: /(?:(?:http|https):\/\/)?(?:www.)?(?:youtube.com|youtu.be)\/([A-Za-z0-9-_]+)/,
    },
  ];

  const handleChange = (value, fieldName, regex) => {
    let validate = false
    if (value !== "") {
      if (value.match(regex)) {
        validate = true;
        let cuserrors = cerror;
        delete cuserrors[fieldName]
        setCerror({ ...cuserrors })
      } else {
        validate = false;
        let cuserrors = cerror;
        setCerror({ ...cuserrors, [fieldName]: `Invalid ${fieldName} URL ${value}` })
      }
    } else {
      let cuserrors = cerror;
      delete cuserrors[fieldName]
      setCerror({ ...cuserrors })
    }
  }

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={3}>
            <Form.Group>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                ref={register({ required: "Required" })}
                type="text"
                id="firstName"
                name="firstName"
                defaultValue={user.firstName}
                isInvalid={errors.firstName}
              />
              <Form.Control.Feedback type="invalid">{errors.firstName && errors.firstName.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                ref={register({ required: "Required" })}
                type="text"
                id="lastName"
                name="lastName"
                defaultValue={user.lastName}
                isInvalid={errors.firstName}
              />
              <Form.Control.Feedback type="invalid">{errors.lastName && errors.lastName.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Nickname</Form.Label>
              <Form.Control
                ref={register()}
                type="text"
                id="nickname"
                name="nickname"
                defaultValue={user.nickname}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={1}>
            <Form.Group>
              <Form.Label>Birthday</Form.Label>
              <Form.Control
                ref={register({ required: "Required", min: "1", max: "31" })}
                type="number"
                id="birthdayDay"
                name="birthdayDay"
                placeholder="Day"
                defaultValue={moment(user.birthday).format("D") || ""}
                isInvalid={errors.birthdayDay}
              />
              <Form.Control.Feedback type="invalid">{errors.birthdayDay && errors.birthdayDay.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={3}>
            <div className="d-flex align-items-center">
              <Form.Control
                as="select"
                ref={register({ required: true })}
                id="birthdayMonth"
                name="birthdayMonth"
                defaultValue={moment(user.birthday).format("MMMM") || ""}
                style={{ marginTop: "2em" }}
              >
                <option value="" disabled>
                  Month
                </option>
                {[
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ].map((month, index) => (
                  <option key={index} value={month}>{month}</option>
                ))}
              </Form.Control>
            </div>
          </Col>
          <Col md={2}>
            <Form.Control
              ref={register({ required: "Required", min: 1900, max: moment().format("Y") })}
              type="number"
              id="birthdayYear"
              name="birthdayYear"
              style={{ marginTop: "2em" }}
              placeholder="Year"
              defaultValue={moment(user.birthday).format("YYYY") || ""}
              isInvalid={errors.birthdayYear}
            />
            <Form.Control.Feedback type="invalid">{errors.birthdayYear && errors.birthdayYear.message}</Form.Control.Feedback>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Gender</Form.Label>
              <div className="d-flex align-items-center">
                <Form.Control
                  as="select"
                  ref={register({ required: true })}
                  id="gender"
                  name="gender"
                  defaultValue={user.gender}
                >
                  <option value="" disabled>
                    Choose gender
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                  <option value="Prefer not to say">Prefer not to say</option>
                </Form.Control>
              </div>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Nationality</Form.Label>
              <div className="d-flex align-items-center">
                <Form.Control
                  as="select"
                  ref={register({ required: "Required" })}
                  id="nationality"
                  name="nationality"
                  onChange={(e) => setCountry(e.target.value)}
                >
                  <option value="" disabled>
                    Choose nationality
                  </option>
                  {nationalities.map(({ alpha2Code, demonym }) => (
                    <option key={alpha2Code} value={alpha2Code}>
                      {demonym}
                    </option>
                  ))}
                </Form.Control>
              </div>
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group>
              <Form.Label>Phone Number</Form.Label>
              <div className="d-flex align-items-center">
                <Form.Control
                  as="select"
                  ref={register()}
                  name="phoneExt" id="phoneExt"
                  defaultValue={user.phoneExt ? user.phoneExt : "+45"}
                >
                  <option value="" disabled>
                    Choose Code
                  </option>
                  {[
                    "+30",
                    "+31",
                    "+32",
                    "+33",
                    "+34",
                    "+36",
                    "+39",
                    "+40",
                    "+43",
                    "+44",
                    "+45",
                    "+46",
                    "+47",
                    "+48",
                    "+49",
                    "+350",
                    "+351",
                    "+352",
                    "+353",
                    "+354",
                    "+356",
                    "+357",
                    "+358",
                    "+359",
                    "+370",
                    "+371",
                    "+372",
                    "+385",
                    "+386",
                    "+420",
                    "+421",
                    "+423",
                  ].map((ext, index) => (
                    <option key={index} value={ext}>{ext}</option>
                  ))}
                </Form.Control>
              </div>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Control
              ref={register({ maxLength: { value: 13, message: "Phone number too long" } })}
              type="number"
              id="phone"
              name="phone"
              autoComplete="off"
              defaultValue={user.phone}
              placeholder="Phone number"
              style={{ marginTop: "2em" }}
              isInvalid={errors.phone}
            />
            <Form.Control.Feedback type="invalid">{errors.phone && errors.phone.message}</Form.Control.Feedback>
          </Col>
        </Row>
        <Row>
        <Col md={4}>
         <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control
              ref={register({ required: "Required" })}
              type="text"
              id="email"
              name="email"
              defaultValue={user.email}
              isInvalid={errors.email}
            />
            <Form.Control.Feedback type="invalid">{errors.email && errors.email.message}</Form.Control.Feedback>
          </Form.Group> 
        </Col>
        </Row>
        <Form.Group>
          <Form.Label>About</Form.Label>
          <Form.Control
            ref={register({ maxLength: 5000 })}
            as="textarea"
            name="about"
            id="about"
            rows={5}
            placeholder="Write something about yourself"
            defaultValue={user.about}
            isInvalid={errors.about}
          />
          <Form.Control.Feedback type="invalid">{errors.about && errors.about.message}</Form.Control.Feedback>
        </Form.Group>

        <Row>
          {socialFields.map(({ name, placeholder, regex, invalidMsg }, i) => (
            <Col md={6} key={i}>
              <Form.Group>
                <Form.Label>{name}</Form.Label>
                {name === 'Discord' ? <>
                  <Form.Control
                    ref={register({
                      validate: (value) => (!value.match(regex) && value !== "" ? invalidMsg || `Invalid ${name} URL` : true),
                    })}
                    type="text"
                    id={name.toLowerCase()}
                    name={name.toLowerCase()}
                    autoComplete="off"
                    defaultValue={user[name.toLowerCase()]}
                    placeholder={placeholder}
                    isInvalid={errors[name.toLowerCase()]}
                  />
                  <Form.Control.Feedback type="invalid">{errors[name.toLowerCase()] && errors[name.toLowerCase()].message}</Form.Control.Feedback>
                </> : <>
                  <Form.Control
                    ref={register({
                      validate: (value) => (!value.match(regex) && value !== "" ? invalidMsg || `Invalid ${name} URL` : true),
                    })}
                    type="text"
                    id={name.toLowerCase()}
                    name={name.toLowerCase()}
                    defaultValue={user[name.toLowerCase()]}
                    placeholder={placeholder}
                    onBlur={(e) => handleChange(e.target.value, name.toLowerCase(), regex)}
                    isInvalid={errors[name.toLowerCase()]}
                  />
                  <Form.Control.Feedback type="invalid">{errors[name.toLowerCase()] && errors[name.toLowerCase()].message}</Form.Control.Feedback>
                </>
                }
              </Form.Group>
            </Col>
          ))}
        </Row>

        <>
          <div className="d-flex">
            {[
              { name: "Avatar", key: "avatarImage", width: 250, height: 250, displayWidth: 150, displayHeight: 150, crop: true },
              { name: "Cover", key: "headerImage", width: 800, height: 200, displayWidth: 500, displayHeight: 150, crop: false },
            ].map(({ name, key, width, height, displayWidth, displayHeight, crop }) => (
              <div key={key}>
                <EditImageModal
                  show={modals[key]}
                  onHide={() => setModals({ ...modals, [key]: false })}
                  name={name}
                  width={width}
                  height={height}
                  afterImageUpload={(file) => onUpdateImage(key, file)}
                  crop={crop}
                />

                <Form.Group className="mr-4">
                  <Form.Label>{name}</Form.Label>
                  <div
                    className="edit-image-btn"
                    style={{
                      backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${user[key]})`,
                      width: displayWidth,
                      height: displayHeight,
                    }}
                    onClick={() => setModals({ ...modals, [key]: true })}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </div>
                </Form.Group>
              </div>
            ))}
          </div>
        </>

        <Button type="submit" variant="success" disabled={loading}>
          {loading ? "Saving..." : "Save Changes"}
        </Button>
      </Form>
    </div >
  );
};

export default General;
