import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import Axios from "axios";
import { useForm } from "react-hook-form";
import ViewRole from "./include/ViewRole";
import ViewInheritedPermission from "./include/ViewInheritedPermission"
import { toast } from "react-toastify";
import _ from 'lodash';

const Permissions = ({ user, setUser }) => {
  const [userRoles, setUserRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [unassignLoading, setUnassignLoading] = useState(false);
  const [selectedUserRole, setSelectedUserRole] = useState({});
  const [inheritedPermission, setInheritedPermission] = useState({});
  const { register, handleSubmit, errors, setValue } = useForm();

  useEffect(() => {
    getRoles();
    getInheritedPermissions();
  }, [])

  const getRoles = async () => {
    const { data } = await Axios.get(`${process.env.REACT_APP_CORE_API}/api/roles/`);
    let userRole = data.roles.filter((el) => (el.type === "User"));
    setUserRoles(userRole);
  }

  const getInheritedPermissions = async () => {
    const { data } = await Axios.get(`${process.env.REACT_APP_CORE_API}/api/users/${user._id}/inhertiedPermissions`);
    setInheritedPermission(data.inheritedRole);
    setUser({ ...user, inheritedPermissions: data.inheritedRole });
  }

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      const { data: newData } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/users/${user._id}/assignRole`, { data });
      setUser({ ...user, ...newData.assignedRole })
      setLoading(false);
      setSelectedUserRole({});
      setValue("permissions", "");

      toast.success("Successfuly assign role to user");
    } catch (e) {
      setLoading(false);

      const msg = e.response.data ? e.response.data.msg : "Error with assigning role to user";
      toast.error(msg);
    }
  }

  const unAssignRole = async () => {
    try {
      setUnassignLoading(true);

      const { data } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/users/${user._id}/unassignRole`);
      setUser({ ...user, ...data.unassignedRole })
      setUnassignLoading(false);
      setSelectedUserRole({});
      setValue("permissions", "");
      
      toast.success("Successfuly Unassign role to user");
    } catch (e) {
      setUnassignLoading(false);

      const msg = e.response.data ? e.response.data.msg : "Error with Unassigning role to user";
      toast.error(msg);
    }
  }

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={6}>
            <h5>Own Permission</h5>
            <div className="ownPermission">
              <Form.Group>
                <Form.Label>User Roles</Form.Label>
                <div className="d-flex align-items-center">
                  <Form.Control
                    as="select"
                    ref={register({ required: true })}
                    id="permissions"
                    name="permissions"
                    defaultValue=""
                    onChange={(e) => setSelectedUserRole(userRoles.find((el) => (e.target.value === el._id)))}
                    isInvalid={errors.permissions}
                  >
                    <option value="" disabled>
                      Choose Permission
                    </option>
                    {userRoles.length && userRoles.map((role) => (
                      <option value={role._id} key={role?._id}>
                        {role?.name}
                      </option>
                    ))}
                  </Form.Control>
                </div>
              </Form.Group>

              {selectedUserRole?.user ?
                <ViewRole roleName={selectedUserRole.name} userRole={selectedUserRole.user} /> : ""
              }

              {user?.permissions?.user && !selectedUserRole?.user ?
                <ViewRole roleName={user?.permissions?.name} userRole={user?.permissions?.user} /> : ""
              }
            </div>
          </Col>

          <Col md={6}>
            <h5>Inherited Permission</h5>
            <div className="inheritedPermission">
              {
                inheritedPermission ?
                  <ViewInheritedPermission roleName={inheritedPermission.orgNames} inheritedRole={inheritedPermission.orgPermissions} /> : ""
              }
            </div>
          </Col>
        </Row>

        <Button type="submit" variant="primary" disabled={loading || _.isEmpty(selectedUserRole)}>
          {loading ? "Saving Role..." : "Save Role"}
        </Button>

        {user?.permissions?.user &&
          <Button variant="danger" disabled={unassignLoading} onClick={unAssignRole} style={{ marginLeft: "0.5em" }}>
            {unassignLoading ? "Unassigning Role..." : "Unassign Role"}
          </Button>
        }
      </Form>

    </div>
  );
};

export default Permissions;


