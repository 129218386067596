import React, { useEffect, useState } from "react";
import { Button, Form, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link, useParams, useHistory } from "react-router-dom";
import Axios from "axios";
// import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import _ from "lodash";
import * as Duel from "duel";
import * as Koth from "../../../../../libs/koth";
import Datetime from "react-datetime";
import { TIMEZONES } from "../../../../../utils/timezones";
import moment from "moment-timezone/builds/moment-timezone-with-data";

// const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
const defaultTime = 75;
const GenerateMatches = () => {
  const { id } = useParams();
  const history = useHistory();
  const [tournament, setTournament] = useState(false);
  const [round, setRounds] = useState(0);
  const [scoreSystems, setScoreSystems] = useState([]);
  const [vetoFlag, setVetoFlag] = useState(true);
  const [showVeto, setShowVeto] = useState(false);
  const [selectedScoreSystem, setSelectedScoreSystem] = useState(false);
  const [bestOf, setBestOf] = useState(false);
  const [roundArr, setRoundArr] = useState([]);
  const [teamOrder, setTeamOrder] = useState([]);
  const [numberOfMatches, setNumberOfMatches] = useState(false);
  const [matchSchedule, setMatchSchedule] = useState({});
  const [tournamentTypes, setTournamentTypes] = useState([]);
  const [isShort, setIsShort] = useState(true);
  const [tournamnetType, setTournamentType] = useState(false);
  const [noOfTeams, setNoOfTeam] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isGenerated, setIsGenerated] = useState(false);
  const [timeBetweenMatches, setTimeBetweenMatches] = useState(defaultTime);
  const [broadcasted, setBroadcasted] = useState(false);
  const [sideSelection, setSideSelection] = useState(false);
  const [prefferedTimezone, setPrefferedTimezone] = useState(TIMEZONES[0].value);

  useEffect(() => {
    setLoading(true);
    setScoreSystems([
      { name: "Single Elimination", id: 1 },
      { name: "Double Elimination", id: 2 },
      { name: "King of the Hill", id: 3 },
    ]);

    setTournamentTypes([
      { name: "No Third Place", id: 1 },
      { name: "Include Third Place", id: 2 },
    ]);
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/tournaments/${id}?withMatches=true`).then(({ data }) => {
      setTournament(data);
      setShowVeto(false);
      setTeamOrder(data.teams);
      if (data.teams) {
        setNoOfTeam(data.teams.length);
      }
      setLoading(false);
      // onShuffle()
    });
  }, [id]);

  const onShuffle = () => {
    let _order = [];
    let _teams = [];
    let order = 1;
    for (let team in teamOrder) {
      _order.push(order);
      order++;
    }

    for (let i = 0; i < teamOrder.length; i++) {
      let team = teamOrder[i];
      if (team.seedOrder === 999) {
        team.seedOrder = _order[i];
      }
      _teams.push(team);
    }

    setTeamOrder(_teams);
  };

  const handleSelectTournamentType = (e) => {
    if (parseInt(e) === 1) {
      setTournamentType(parseInt(e));
      setIsShort(true);
    } else {
      setTournamentType(parseInt(e));
      setIsShort(false);
    }
  };

  const generateResult = () => {
    if (selectedScoreSystem === 1) {
      let duel = new Duel(tournament?.teams?.length, { short: isShort });
      duel.matches = _.orderBy(duel.matches, ["id.r"], ["asc"]);
      setRounds(duel?.matches[duel?.matches?.length - 1]?.id?.r);
      setNumberOfMatches(duel.matches.length);
      let _roundArr = [];
      for (let i = 0; i < duel?.matches[duel?.matches?.length - 1]?.id?.r; i++) {
        _roundArr.push(i);
        let date = tournament?.startDate ? new Date(tournament.startDate) : new Date();
        //date.setHours(10,0,0,0);
        if (i === 0) {
          matchSchedule[i + 1] = {
            date: new Date(date.getTime()),
          };
        } else {
          matchSchedule[i + 1] = {
            date: new Date(matchSchedule[i]?.date?.getTime() + timeBetweenMatches * bestOf * 60000),
          };
        }

        setMatchSchedule({
          ...matchSchedule,
        });
      }
      setRoundArr(_roundArr);
    } else if (selectedScoreSystem === 2) {
      let duel = new Duel(tournament?.teams?.length, { last: selectedScoreSystem, short: isShort });
      setRounds(duel?.matches[duel?.matches?.length - 1]?.id?.r);
      setNumberOfMatches(duel.matches.length);
      let _roundArr = [];
      for (let i = 0; i < duel?.matches[duel?.matches?.length - 1]?.id?.r; i++) {
        _roundArr.push(i);
        let date = tournament?.startDate ? new Date(tournament.startDate) : new Date();
        //date.setHours(10,0,0,0);
        // console.log(date)
        if (i === 0) {
          matchSchedule[i + 1] = {
            date: new Date(date.getTime()),
          };
        } else {
          matchSchedule[i + 1] = {
            date: new Date(matchSchedule[i]?.date?.getTime() + timeBetweenMatches * bestOf * 60000),
          };
        }
        setMatchSchedule({
          ...matchSchedule,
        });
      }
      setRoundArr(_roundArr);
    } else if (selectedScoreSystem === 3) {
      let duel = new Koth(tournament?.teams?.length, { short: isShort });
      duel.matches = _.orderBy(duel.matches, ["id.r"], ["asc"]);
      setRounds(duel?.matches[duel?.matches?.length - 1]?.id?.r);
      setNumberOfMatches(duel.matches.length);
      let _roundArr = [];
      for (let i = 0; i < duel?.matches[duel?.matches?.length - 1]?.id?.r; i++) {
        _roundArr.push(i);
        let date = tournament?.startDate ? new Date(tournament.startDate) : new Date();
        //date.setHours(10,0,0,0);
        if (i === 0) {
          matchSchedule[i + 1] = {
            date: new Date(date.getTime()),
          };
        } else {
          matchSchedule[i + 1] = {
            date: new Date(matchSchedule[i]?.date?.getTime() + timeBetweenMatches * bestOf * 60000),
          };
        }

        setMatchSchedule({
          ...matchSchedule,
        });
      }
      setRoundArr(_roundArr);
    }

    setIsGenerated(true);
  };

  const handleSelectScoreSystem = (e) => {
    setSelectedScoreSystem(parseInt(e));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    onShuffle();

    const updatedMatchSchedule = Object.keys(matchSchedule).reduce(
      (acc, scheduleItemIndex) => ({
        ...acc,
        [scheduleItemIndex]: {
          ...matchSchedule[scheduleItemIndex],
          date: moment(matchSchedule[scheduleItemIndex].date).tz(prefferedTimezone, true),
        },
      }),
      {}
    );

    try {
      const body = {
        tournament: id,
        teams: teamOrder.map((team) => {
          return { ...team, team: team.team._id };
        }),
        scoreSystem: parseInt(selectedScoreSystem),
        bestOf,
        matchSchedule: updatedMatchSchedule,
        broadcasted,
        sideSelection,
      };
      let vetoEnable = showVeto ? vetoFlag : false;

      // console.log(body)
      await Axios.post(
        `${process.env.REACT_APP_CORE_API}/api/tournaments/${id}/matches/generate?isShort=${isShort}&isVeto=${vetoEnable}`,
        body
      );
      toast.success("Successfully generated matches");
      history.push("matches");
    } catch (e) {
      toast.error("Problem generating matches!");
    }
  };

  if (loading) {
    return <>Loading...</>;
  }

  return (
    <div>
      <div className="mb-4">
        <Button as={Link} to="matches" variant="link" className="mr-2 mb-2 p-0">
          <FontAwesomeIcon icon={faArrowLeft} /> Back to tournament
        </Button>
        <h2>Generate Matches</h2>
      </div>

      {noOfTeams < 4 || noOfTeams > 1024 ? (
        <div>
          <h2 className="text-center">The Number of Teams Should be between 4 - 1024</h2>
        </div>
      ) : (
        <>
          <h5 className="mt-3 mb-3">General</h5>
          <Form onSubmit={onSubmit}>
            <Form.Group>
              <Form.Label>Tournament Format</Form.Label>
              <Form.Control
                as="select"
                value={selectedScoreSystem || "_DEFAULT_"}
                onChange={(e) => handleSelectScoreSystem(e.target.value)}
              >
                <option disabled value="_DEFAULT_">
                  {!scoreSystems?.length ? "Loading..." : "Select format"}
                </option>
                {scoreSystems?.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            {selectedScoreSystem && selectedScoreSystem !== 3 ? (
              <Form.Group>
                <Form.Label>Third-place Decider</Form.Label>
                <Form.Control
                  as="select"
                  value={tournamnetType || "_DEFAULT_"}
                  onChange={(e) => handleSelectTournamentType(e.target.value)}
                >
                  <option disabled value="_DEFAULT_">
                    {!tournamentTypes?.length ? "Loading..." : "Select"}
                  </option>
                  {tournamentTypes?.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            ) : null}

            {selectedScoreSystem && (
              <Form.Group>
                <Form.Label>Best Of</Form.Label>
                <Form.Control
                  as="select"
                  value={bestOf || "_DEFAULT_"}
                  onChange={(e) => {
                    setBestOf(parseInt(e.target.value));
                    setShowVeto(tournament?.game?.shortName === "CSGO" && [1, 3, 5].includes(parseFloat(e.target.value)) ? true : false);
                  }}
                >
                  <option disabled value="_DEFAULT_">
                    Select best of
                  </option>
                  <option key={1} value={1}>
                    Best Of {1}
                  </option>
                  <option key={3} value={3}>
                    Best Of {3}
                  </option>
                  <option key={5} value={5}>
                    Best Of {5}
                  </option>
                  <option key={7} value={7}>
                    Best Of {7}
                  </option>
                  <option key={9} value={9}>
                    Best Of {9}
                  </option>
                </Form.Control>
              </Form.Group>
            )}

            {tournament && bestOf ? (
              <>
                <Form.Group>
                  <Form.Label>Time between matches (min.)</Form.Label>
                  <Form.Control type="number" value={timeBetweenMatches} onChange={(e) => setTimeBetweenMatches(e.target.value)} />
                </Form.Group>
                <Row>
                  <Col sm={4}>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox1">
                      <Form.Check
                        type="checkbox"
                        name="broadcasted"
                        checked={broadcasted}
                        label="Broadcasted"
                        onChange={(e) => {
                          setBroadcasted((prevState) => !prevState);
                        }}
                      />
                    </Form.Group>
                  </Col>

                  <Col sm={4}>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox2">
                      <Form.Check
                        type="checkbox"
                        name="sideSelection"
                        checked={sideSelection}
                        label="Side Selection"
                        onChange={(e) => {
                          setSideSelection((prevState) => !prevState);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  {showVeto && (
                    <Col sm={4}>
                      <Form.Group className="mb-3" controlId="formBasicCheckbox3">
                        <Form.Check
                          type="checkbox"
                          name="vetoFlagEnable"
                          checked={vetoFlag}
                          label="Enable Veto"
                          onChange={(e) => {
                            setVetoFlag(!vetoFlag);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  )}
                </Row>

                <Button variant="success" size="lg" className="mt-4 mb-5" onClick={() => generateResult()}>
                  {`${isGenerated ? "Re-" : ""}Calculate`}
                </Button>
              </>
            ) : null}

            {tournament && isGenerated && bestOf && (
              <>
                <Row>
                  {/* <Form.Group className="mr-4">
                <Form.Label>Start Date</Form.Label>
                <div>
                  <Form.Control as={DatePicker} selected={startDate} onChange={setStartDate} inline={true} />
                </div>
              </Form.Group> */}
                  {/* <Col>
                  <ShuffleTeams className="col-5" teams={teamOrder} setTeamOrder={setTeamOrder} />
                </Col> */}
                  <Col>
                    {/* <Form.Group>
                  <Form.Label>Number of Weeks</Form.Label>
                  <Form.Control
                    type="number"
                    value={noOfWeeks}
                    onChange={(e) => e.target.value >= 0 && setNoOfWeeks(e.target.value)}
                    min={1}
                  />
                </Form.Group> */}
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Teams</Form.Label>
                          <Form.Control plaintext readOnly value={tournament.teams.length} />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Rounds</Form.Label>
                          <Form.Control plaintext readOnly value={round} />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Matches</Form.Label>
                          <Form.Control plaintext readOnly value={numberOfMatches} />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Select Time zone</Form.Label>
                      <Form.Control
                        as="select"
                        name="prefferedTimezone"
                        value={prefferedTimezone}
                        initialValue={prefferedTimezone}
                        onChange={(e) => setPrefferedTimezone(e.target.value)}
                      >
                        {TIMEZONES.map(({ name, value }, index) => (
                          <option key={index} value={value}>
                            {name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <h5 className="mt-4 mb-3">Match Scheduler</h5>
                {roundArr.map((r) => {
                  return (
                    <Row key={r}>
                      <Col>
                        <Form.Group>
                          <Form.Label>Round {r + 1} Date</Form.Label>
                          <Datetime
                            value={matchSchedule[r + 1].date}
                            dateFormat="DD/MM/YYYY"
                            timeFormat="HH:mm:ss"
                            onChange={(event) => {
                              setMatchSchedule({
                                ...matchSchedule,
                                [r + 1]: {
                                  date: event,
                                },
                              });
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  );
                })}
              </>
            )}

            <>
              <Button type="submit" variant="success" size="lg" className="mt-4 mb-5" block disabled={!numberOfMatches || !bestOf}>
                Generate Matches
              </Button>
            </>
          </Form>
        </>
      )}
    </div>
  );
};

export default GenerateMatches;
