import React, { useEffect, useCallback } from "react";
import { Table, Form, FormGroup } from "react-bootstrap";

const SystemOutcomes = ({ bestOf, outcomes = [], updateConfig, existingBestOfs, maximumBestOf }) => {
  const onBestOfSelected = useCallback(
    (bestOf) => {
      bestOf = parseFloat(bestOf);

      const generatedOutcomes = [];
      const firstTo = Math.ceil(bestOf / 2);
      const loserScoreMax = bestOf % 2 === 0 ? firstTo : firstTo - 1;

      // Create all possible outcomes
      for (let loserScore = 0; loserScore <= loserScoreMax; loserScore++) {
        generatedOutcomes.push({
          score: `${loserScore === 0 && bestOf % 2 === 0 ? firstTo + 1 : firstTo} - ${loserScore}`,
          winnerPoints: firstTo,
          loserPoints: loserScore,
        });
      }

      updateConfig({ bestOf, outcomes: generatedOutcomes });
    },
    [updateConfig]
  );

  useEffect(() => {
    if (!outcomes.length) {
      onBestOfSelected(bestOf);
    }
  }, [bestOf, outcomes, onBestOfSelected]);

  const setPoints = (atIndex, type, points) => {
    points = parseFloat(points);

    updateConfig({
      bestOf,
      outcomes: outcomes.map((outcome, i) => {
        if (i === atIndex) outcome[type] = points;
        return outcome;
      }),
    });
  };

  return (
    <div>
      <FormGroup>
        <Form.Label>Best Of</Form.Label>
        <Form.Control as="select" id="bestOf" name="bestOf" value={bestOf} onChange={(e) => onBestOfSelected(e.target.value)}>
          {[1, 2, 3, 5, 7, 9]
            .filter((bestOf) => !existingBestOfs.includes(bestOf))
            .map((bestOf) => (
              <option key={bestOf} value={bestOf}>
                Best Of {bestOf}
              </option>
            ))}
        </Form.Control>
      </FormGroup>

      <FormGroup>
        <Form.Label>Outcomes</Form.Label>

        <Table striped bordered>
          <thead>
            <tr>
              <th>Score</th>
              <th>Winner Points</th>
              <th>Loser Points</th>
            </tr>
          </thead>
          <tbody>
            {outcomes.map(({ score, winnerPoints, loserPoints }, i) => (
              <tr key={i}>
                <td className="p-3">{score}</td>
                <td>
                  <Form.Control
                    type="number"
                    value={winnerPoints}
                    onChange={(e) => setPoints(i, "winnerPoints", e.target.value)}
                    style={{ width: 150 }}
                  />
                </td>
                <td>
                  <Form.Control
                    type="number"
                    value={loserPoints}
                    onChange={(e) => setPoints(i, "loserPoints", e.target.value)}
                    style={{ width: 150 }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </FormGroup>
    </div>
  );
};

export default SystemOutcomes;
