import React, { useState } from "react";
import { Card, Nav, Button, Col, Row } from "react-bootstrap";
import SegmentManager from "./SegmentManager";

const TabsManager = ({ frontPage = [], setTempraryData, isSaved, frontPageAccess, isAdmin }) => {
  const [activeTab, setActiveTab] = useState("Default");


  // ADD/REMOVE TABS THROUGH THIS ARRAY
  const TABS = [
    {
      name: "Default",
      content: <SegmentManager pageType='Default' data={frontPage} setTempraryData={() => setTempraryData()} isSaved={isSaved} frontPageAccess={frontPageAccess} isAdmin={isAdmin} />,
      disabled: !isAdmin && !frontPageAccess ? "disabled-link" : ""
    },
    {
      name: "Logged In",
      content: <SegmentManager pageType='LoggedIn' data={frontPage} setTempraryData={() => setTempraryData()} isSaved={isSaved} frontPageAccess={frontPageAccess} isAdmin={isAdmin} />,
      disabled: !isAdmin && !frontPageAccess ? "disabled-link" : ""
    },
    {
      name: "Pro User",
      content: <SegmentManager pageType='ProUser' data={frontPage} setTempraryData={() => setTempraryData()} isSaved={isSaved} frontPageAccess={frontPageAccess} isAdmin={isAdmin} />,
      disabled: !isAdmin && !frontPageAccess ? "disabled-link" : ""
    },
    {
      name: "Organisation Upgrade",
      content: <SegmentManager pageType='OrgUpgrade' data={frontPage} setTempraryData={() => setTempraryData()} isSaved={isSaved} frontPageAccess={frontPageAccess} isAdmin={isAdmin} />,
      disabled: !isAdmin && !frontPageAccess ? "disabled-link" : ""
    }
  ];

  return (
    <>
      <Card>
        <Card.Header>
          <Nav variant="tabs" activeKey={activeTab} onSelect={(t) => setActiveTab(t)}>
            {TABS.map(({ name, disabled }) => (
              <Nav.Item key={name}>
                <Nav.Link eventKey={name} active={activeTab === name} className={disabled}>
                  {name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Card.Header>
        <Card.Body>
          {TABS.map(({ name, content }) => name === activeTab && <div key={name}>{content}</div>)}
        </Card.Body>
      </Card>
    </>
  );
};

export default TabsManager;
