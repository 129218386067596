import React, { useState, useEffect, useContext } from "react";
import { Breadcrumb, Row, Col, Card, Button, Container } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faMagic } from "@fortawesome/free-solid-svg-icons";
import TabsManager from "./Tabs/TabsManager";
import GenMatchesPromptCard from "./GenerateMatches/GenMatchesPromptCard";
import TeamsListCard from "./TournmentTeams/TeamsListCard";
import { AppContext } from "../../../../context/AppContext";

const TournamentDashboard = () => {
  const { user } = useContext(AppContext);
  const { id } = useParams();
  const [tournament, setTournament] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/tournaments/${id}?withMatches=true`).then(({ data }) => {
      setTournament(data);
      setLoading(false);
    });
  }, [id]);

  return (
    <div>
      <Breadcrumb>
        {!loading ? (
          <>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/games" }}>
              {tournament.game.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/games/${tournament.game._id}/groups` }}>
              {tournament.group.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/games/groups/${tournament.group._id}/seasons` }}>
              {tournament.season.name} {tournament?.season?.year}
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/games/seasons/${tournament.season._id}/tournaments` }}>
              {tournament.name}
            </Breadcrumb.Item>
          </>
        ) : (
          <Breadcrumb.Item active>Loading...</Breadcrumb.Item>
        )}
      </Breadcrumb>

      <div className="admin-page-title">
        <Container fluid>
          <Row className="justify-content-between">
            <Col lg={8}>
              <div>
                <img src={tournament.logoImage} alt={tournament.name} />
                <h2>{tournament.name}</h2>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Row>
        {/* LEFT/main column */}
        <Col xl="9">
          {tournament.matches && !tournament.matches.length && <GenMatchesPromptCard />}

          {loading ? "Loading..." : <TabsManager league={tournament} setLeague={setTournament} user={user} />}
        </Col>

        {/* Right column */}
        <Col xl="3">
          <Card bg="light" text="dark">
            <Card.Header>Quick Actions</Card.Header>
            <Card.Body>
              <Button
                as={Link}
                to="generateMatches"
                variant="link"
                className={
                  !user.state.admin && !user.state.permissions.user.matches
                    ? "disabled-link d-inline-block p-0 mb-1"
                    : "d-inline-block p-0 mb-1"
                }
              >
                <FontAwesomeIcon icon={faMagic} /> {tournament?.matches?.length ? "Re-Generate Matches" : "Generate Matches"}
              </Button>
              <Button
                variant="link"
                as="a"
                href={`${process.env.REACT_APP_LEAGUES_URL}/tournament/${id}`}
                target="_blank"
                className="d-block text-left p-0 mb-1"
              >
                <FontAwesomeIcon icon={faEye} /> View on Leagues.gg
              </Button>
            </Card.Body>
          </Card>

          {!loading && (
            <TeamsListCard
              league={tournament}
              setLeague={setTournament}
              permission={!user.state.admin && !user.state.permissions.user.matches}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TournamentDashboard;
