import React, { useState , useEffect } from "react";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import { Button, Form, Table, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import './Prizes.css';

const Prizes = ({ league, setLeague,
  selectedStageIndex,
  setSelectedStageIndex
}) => {
  const { id } = useParams();
  const { register, handleSubmit, errors } = useForm({ mode: "all" });
  // const [prizes, setPrizes] = useState(league.prizes);
  const [submitting, setSubmitting] = useState(false);
  const [allStagePrizes, setAllStagePrizes] = useState([]);
  const [activeStageTab, setActiveStageTab] = useState(0);
  const [changedStagePrizes, setChangedStagePrizes] = useState([]);
  const [responseloader, setResponseLoading] = useState(false);

   // Use Effect get stages
   useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/stage/prizes`)
      .then(({data}) => {
        setAllStagePrizes(data.data);
        for (let i = 0; i < data.data.length; i++) {
          const changedStagePrizesCopy = changedStagePrizes;
          changedStagePrizesCopy.push(false);
          setChangedStagePrizes(changedStagePrizesCopy);
        }
      })
      .catch((err) => {
        toast.error("There was a problem in getting stages");
      });

  }, []);

  const stagePrizeChange = (stageIndex) => {
    const changedStagePrizesCopy = [...changedStagePrizes];
    changedStagePrizesCopy[stageIndex] = true;
    setChangedStagePrizes(changedStagePrizesCopy);
  }

  const changeStageHidePrize = (stageIndex) => {
    const allStagePrizesCopy = [...allStagePrizes];
    if(allStagePrizesCopy[stageIndex].stageType === 'league')
      allStagePrizesCopy[stageIndex].league.hidePrizes = !allStagePrizesCopy[stageIndex].league.hidePrizes;
    else
      allStagePrizesCopy[stageIndex].tournament.hidePrizes = !allStagePrizesCopy[stageIndex].tournament.hidePrizes;
    setAllStagePrizes(allStagePrizesCopy);
    stagePrizeChange(stageIndex);
  }

  const onUpdatePrize = (stageIndex, prizeIndex, value) => {
    const allStagePrizesCopy = [...allStagePrizes];
    if(allStagePrizesCopy[stageIndex].stageType === 'league')
      allStagePrizesCopy[stageIndex].league.prizes[prizeIndex] = value;
    else
      allStagePrizesCopy[stageIndex].tournament.prizes[prizeIndex] = value;
    setAllStagePrizes(allStagePrizesCopy);
    stagePrizeChange(stageIndex);
  };

  const onAddPrize = (stageIndex) => {
    const allStagePrizesCopy = [...allStagePrizes];
    if(allStagePrizesCopy[stageIndex].stageType === 'league')
      allStagePrizesCopy[stageIndex].league.prizes.push("");
    else
      allStagePrizesCopy[stageIndex].tournament.prizes.push("");
    setAllStagePrizes(allStagePrizesCopy);
    stagePrizeChange(stageIndex);
  };

  const onDeletePrize = (stageIndex, prizeIndex) => {
    const allStagePrizesCopy = [...allStagePrizes];
    if(allStagePrizesCopy[stageIndex].stageType === 'league')
      allStagePrizesCopy[stageIndex].league.prizes.splice(prizeIndex, 1);
    else
      allStagePrizesCopy[stageIndex].tournament.prizes.splice(prizeIndex, 1);
    setAllStagePrizes(allStagePrizesCopy);
    stagePrizeChange(stageIndex);
  };

  const onSave = async (stageIndex, stage) => {
    setSubmitting(true);

    try {
      let prizes = stage.stageType === 'league' ? stage?.league?.prizes : stage?.tournament?.prizes;
      let hidePrizes = stage.stageType === 'league' ? stage?.league?.hidePrizes : stage?.tournament?.hidePrizes
      await Axios.post(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/stage/${stage._id}/prizes`, {prizes, hidePrizes});
      const changedStagePrizesCopy = [...changedStagePrizes];
      changedStagePrizesCopy[stageIndex] = false;
      setChangedStagePrizes(changedStagePrizesCopy);
      toast.success("Saved");
    } catch (e) {
      console.log("ERROR : ", e)
      toast.error("There was a problem saving");
    }

    setSubmitting(false);
  };

  return (
    <div>
      <Form className="sub-tabs-form-prizes">
       <Tabs 
          id="controlled-tab-example"
          // defaultActiveKey={selectedStageIndex}
          activeKey={selectedStageIndex}
          className="mb-3 sub-tabs"
          mountOnEnter={false}
          onSelect={e => setSelectedStageIndex(e)}
          >
            {responseloader ? "Loading..." : allStagePrizes.map((stage, stageIndex) => (
            <Tab eventKey={stageIndex} title={stage.name}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                  <Form.Group style={{width:"inherit"}}>
                    <Form.Check
                      ref={register()}
                      type="checkbox"
                      name="checkbox"
                      // id="checkEvenGroupSize"
                      checked={stage.stageType === 'league' ? stage.league.hidePrizes : stage.tournament.hidePrizes}
                      label="Hide this stage’s prizes"
                      onChange={() => changeStageHidePrize(stageIndex)}
                    />
                  </Form.Group> 
                  <Button variant="success" size="sm" onClick={() => onAddPrize(stageIndex)} style={{
                        justifyContent: "flex-end",
                        display: "block",
                        float: "right",
                        width:"118px",
                        height:31
                  }}>
                    <FontAwesomeIcon icon={faPlus} /> Add Prize
                  </Button>
                </div>
                {(stage.stageType === 'league' ? !stage?.league?.prizes.length : !stage?.tournament?.prizes.length) ? (
                  "No prizes added. Add one using the button at the top right!"
                ) : (
                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th>Place</th>
                        <th>Prize</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {stage.stageType === 'league' ?
                        <>
                          {stage.league.prizes.map((prize, prizeIndex) => (
                            <tr key={prizeIndex}>
                              <td>#{prizeIndex + 1}</td>
                              <td>
                                <Form.Control type="text" defaultValue={prize} onChange={(e) => onUpdatePrize(stageIndex, prizeIndex, e.target.value)} />
                              </td>
                              <td>
                                <Button variant="link" className="text-danger" onClick={() => onDeletePrize(stageIndex, prizeIndex)}>
                                  <FontAwesomeIcon icon={faTrash} /> Delete
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </>
                        :
                        <>
                          {stage.tournament.prizes.map((prize, prizeIndex) => (
                            <tr key={prizeIndex}>
                              <td>#{prizeIndex + 1}</td>
                              <td>
                                <Form.Control type="text" defaultValue={prize} onChange={(e) => onUpdatePrize(stageIndex, prizeIndex, e.target.value)} />
                              </td>
                              <td>
                                <Button variant="link" className="text-danger" onClick={() => onDeletePrize(stageIndex, prizeIndex)}>
                                  <FontAwesomeIcon icon={faTrash} /> Delete
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </>
                      }
                    </tbody>
                  </Table>
                )}

                {changedStagePrizes[stageIndex] && (
                  <div className="mt-3">
                    <Button type="button" variant="success" onClick={() => onSave(stageIndex, stage)} disabled={submitting}>
                      {submitting ? "Saving" : "Save"}
                    </Button>
                  </div>
                )}
            </Tab>
            ))}
       </Tabs> 
      </Form> 
    </div>
  );
};

export default Prizes;
