import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-bootstrap";
import CoachTabsManager from "./Tabs/CoachTabsManager/CoachTabsManager";

const CoachIndividual = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [coach, setCoach] = useState({});

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/coaches/${id}`).then(({ data }) => {
      setCoach(data);
      setLoading(false);
    });
  }, [id]);


  return loading ? (
    "Loading..."
  ) : (
    <div>
      <div className="admin-page-title">
        <h2>{coach.name} (Coach)</h2>
        <Button variant="success" className="space-right btn-success" as={Link} to={`/entities`}>
          <FontAwesomeIcon icon={faBackward} /> Go Back
        </Button>
      </div>
      <Row>
        <Col>
          <CoachTabsManager coach={coach} setCoach={setCoach}/>
        </Col>
      </Row>
    </div>
  );
};

export default CoachIndividual;
