const SEEDING = {
  LOAD_SUCCES: "SEEDING_SUCCESS",
  SAVE_SUCCESS: "SEEDING_SAVE",
  RESET: "SEEDING_RESET",
  ELIGIBLE: "SEEDING_ELIGIBLE",
  SET_SEEDS: "SEEDING_SET_SEEDS",
  ALL_SUCCESS: "SEEDING_ALL_SUCCESS",
  UPDATE_STAGE_SEEDING: "UPDATE_STAGE_SEEDING",
  UPDATE_STAGE_SEEDING_RESET: "UPDATE_STAGE_SEEDING_RESET",
  SET_SAVE_LOADING: "SET_SAVE_LOADING",
  TOGGLE_AUTO_FILL: "TOGGLE_AUTO_FILL",
};
export { SEEDING };
