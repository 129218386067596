import React, { useState, useEffect, useCallback, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { Breadcrumb, Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faListAlt, faTaxi } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import LeagueCreateEditModal from "./includes/LeagueCreateEditModal";
import SingleLeague from "./includes/SingleLeague";
import LeaguesReorderModal from "./includes/LeaguesReorderModal";
import { AppContext } from "../../../../context/AppContext";

const SeasonLeagues = () => {
  const { user } = useContext(AppContext);
  const { id } = useParams();
  const [season, setSeason] = useState({});
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [showReorderModal, setShowReorderModal] = useState(false);
  const [deletingLeague, setDeletingLeague] = useState(false);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/seasons/${id}`).then(({ data }) => {
      setSeason(data);
      console.log("leagues of season -- ", data);
      setLoading(false);
    });
  }, [id]);

  const setLeagues = useCallback((callback) => {
    setSeason((season) => ({
      ...season,
      leagues: callback(season.leagues),
    }));
  }, []);

  const onCreateLeague = () => {
    setModalData(false); // ensure modal data is always blank!
    setShowModal(true);
  };

  const onEditLeague = (leagueData) => {
    setModalData(leagueData);
    setShowModal(true);
  };

  const onRemoveLeague = async (id) => {
    setDeletingLeague(id);
    if (window.confirm("Are you sure you want to delete this league? This action is irreversible!")) {
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/leagues/${id}?withMatches=true`).then(({ data }) => {
        data.matches &&
          data.matches.forEach((el) => {
            Axios.delete(`${process.env.REACT_APP_CORE_API}/api/matches/${el._id}`);
          });
      });
      await Axios.delete(`${process.env.REACT_APP_CORE_API}/api/leagues/${id}`);
      setLeagues((leagues) => leagues.filter(({ _id }) => _id !== id));
    }

    setDeletingLeague(false);
  };

  return (
    <div>
      <Breadcrumb>
        {!loading ? (
          <>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/games" }}>
              {season.game.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/games/${season.game._id}/groups` }}>
              {season.group.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/games/groups/${season.group._id}/seasons` }}>
              {season.name} {season?.year}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Leagues</Breadcrumb.Item>
          </>
        ) : (
          <Breadcrumb.Item active>Loading...</Breadcrumb.Item>
        )}
      </Breadcrumb>

      <div className="admin-page-title">
        <h2>Leagues</h2>

        <div>
          {!loading && !!season.leagues.length && (
            <Button variant="secondary" className="mr-2" onClick={() => setShowReorderModal(true)}>
              <FontAwesomeIcon icon={faListAlt} /> Reorder
            </Button>
          )}

          <Button variant="success" onClick={onCreateLeague} disabled={!user.state.admin && !user.state.permissions.user.seasons}>
            <FontAwesomeIcon icon={faPlus} /> Create
          </Button>
        </div>
      </div>

      {loading ? (
        "Loading"
      ) : !season.leagues.length ? (
        'No leagues to show. Click the "Create" button to create one.'
      ) : (
        <div>
          <Row>
            {season.leagues.map((league) => (
              <Col xl={4} lg={6} md={6} sm={12} key={league._id}>
                <SingleLeague league={league} onEditLeague={onEditLeague} deletingLeague={deletingLeague} onRemoveLeague={onRemoveLeague} creationPermission={!user.state.admin ? user.state.permissions.user.setting &&  user.state.permissions.user.seasons : true} />
              </Col>
            ))}
          </Row>

          <LeaguesReorderModal
            show={showReorderModal}
            onHide={() => setShowReorderModal(false)}
            leagues={season.leagues}
            setLeagues={(g) => setLeagues(g)}
          />
        </div>
      )}

      <LeagueCreateEditModal show={showModal} handleClose={() => setShowModal(false)} data={modalData} setLeagues={(g) => setLeagues(g)} />
    </div>
  );
};

export default SeasonLeagues;
