import React, { useState, useEffect, useContext } from "react";
import { Breadcrumb, Row, Col, Card, Button } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faMagic } from "@fortawesome/free-solid-svg-icons";
import TabsManager from "./Tabs/TabsManager";
import GenMatchesPromptCard from "./GenerateMatches/GenMatchesPromptCard";
import TeamsListCard from "./LeagueTeams/TeamsListCard";
import { AppContext } from "./../../../../context/AppContext";

const LeagueDashboard = () => {
  const { user } = useContext(AppContext);
  const { id } = useParams();
  const [league, setLeague] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/leagues/${id}?withMatches=true`).then(({ data }) => {
      setLeague(data);
      setLoading(false);
    });
  }, [id]);

  return (
    <div>
      <Breadcrumb>
        {!loading && league ? (
          <>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/games" }}>
              {league?.game?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/games/${league?.game?._id}/groups` }}>
              {league?.group?.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/games/groups/${league?.group?._id}/seasons` }}>
              {league?.season?.name} {league?.season?.year}
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/games/seasons/${league?.season?._id}/leagues` }}>
              {league?.name}
            </Breadcrumb.Item>
          </>
        ) : (
          <Breadcrumb.Item active>Loading...</Breadcrumb.Item>
        )}
      </Breadcrumb>
      {league && <>
        <div className="admin-page-title">
          <div>
            <img src={league?.logoImage} alt={league?.name} />
            <h2>{league?.name}</h2>
          </div>
        </div>

        <Row>
          {/* LEFT/main column */}
          <Col xl="9">
            {league.matches && !league.matches.length && <GenMatchesPromptCard />}

            {loading ? "Loading..." : <TabsManager league={league} setLeague={setLeague} user={user} />}
          </Col>

          {/* Right column */}
          <Col xl="3">
            <Card bg="light" text="dark">
              <Card.Header>Quick Actions</Card.Header>
              <Card.Body>
                <Button as={Link} to="generateMatches" variant="link" className={!user.state.admin && !user.state.permissions.user.matches ? "disabled-link d-inline-block p-0 mb-1" : "d-inline-block p-0 mb-1"}>
                  <FontAwesomeIcon icon={faMagic} /> Generate Matches
                </Button>
                <Button variant="link" as="a" href={`${process.env.REACT_APP_LEAGUES_URL}/league/${id}`} target="_blank" className="d-block text-left p-0 mb-1">
                  <FontAwesomeIcon icon={faEye} /> View on Leagues.gg
                </Button>
              </Card.Body>
            </Card>

            {!loading && <TeamsListCard league={league} setLeague={setLeague} permission={!user.state.admin && !user.state.permissions.user.matches} />}
          </Col>
        </Row>
      </>}
    </div>
  );
};

export default LeagueDashboard;
