import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import moment from "moment";
import Axios from "axios";
import Datetime from "react-datetime";
import { serverLocations } from "../../../../../utils/server-locations";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Firday", "Saturday"];

export const Settings = ({
  selectedStageIndex,
  setSelectedStageIndex
}) => {
  const { id } = useParams();
  const [multiStageLeague, setMultiStageLeague] = useState(false);
  // const [activeStageTab, setActiveStageTab] = useState(0);
  const { register, errors } = useForm({ mode: "all" });
  const [loading, setLoading] = useState(false);
  const [weekDays, setWeekDays] = useState([]);
  const [minDate, setMinDate] = useState(false);
  const [maxDate, setMaxDate] = useState(false);
  const [isCSGO, setIsCSGO] = useState(false);
  const [playersDeadlineHrs, setPlayersDeadlineHrs] = useState([]);
  const [playersDeadlineMins, setPlayersDeadlineMins] = useState([]);
  const [allStageRechedule, setAllStageRechedule] = useState([]);
  const [tomorrow, setTomorrow] = useState(false);

  useEffect(() => {
    setSelectedStageIndex(selectedStageIndex);
    setLoading(true);
    calculateDateRanges();
    calculateWeakDays();
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}`)
      .then(async ({data}) => {
        setMultiStageLeague(data.multiStageLeague);
        setIsCSGO(data.multiStageLeague.season.game?.name === "CS:GO");
        const plyrDeadLineHrs =  data.multiStageLeague.stages.map(stage => stage.stageType === 'league' ? parseInt(stage.league.setPlayersDeadline / 60) : parseInt(stage.tournament.setPlayersDeadline / 60));
        setPlayersDeadlineHrs(plyrDeadLineHrs);
        const plyrDeadLineMins =  data.multiStageLeague.stages.map(stage => stage.stageType === 'league' ? parseInt(stage.league.setPlayersDeadline % 60) : parseInt(stage.tournament.setPlayersDeadline % 60));
        setPlayersDeadlineMins(plyrDeadLineMins);
        const stgRechedule =  data.multiStageLeague.stages.map(stage => {
          if(stage.stageType === 'league'){
            return stage.league.reschedule ? stage.league.reschedule : {
              rescheduleType : 'NO_RESCHEDULE', 
              latestDate : moment(stage.league.startDate).add(7, "days").set({ hour: 23, minute: 59, second: 0, millisecond: 0 }).toISOString(),
              earliestDate : moment(stage.league.startDate).toISOString()
            }
          }else{
            return stage.tournament.reschedule ? stage.tournament.reschedule : {
              rescheduleType : 'NO_RESCHEDULE', 
              latestDate : moment(stage.tournament.startDate).add(7, "days").set({ hour: 23, minute: 59, second: 0, millisecond: 0 }).toISOString(),
              earliestDate : moment(stage.tournament.startDate).toISOString()
            }
          }
        })
        setAllStageRechedule(stgRechedule);
      })
      .catch((err) => {
        console.log("ERROR  : ", err)
        toast.error("There was a problem in getting stages");
      });
    setLoading(false);
  }, []);

  // Calculate range bw current date and next weak end
  const calculateDateRanges = () => {
    let min = moment().subtract(1, "days").set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    let max = moment().add(7, "days").set({ hour: 23, minute: 59, second: 0, millisecond: 0 });
    let tomorrow = moment().subtract(1, "day").set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    setMinDate(min);
    setMaxDate(max);
    setTomorrow(tomorrow);
  };

  const calculateWeakDays = async () => {
    let days = [];
    let currentDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    days.push({ date: currentDate, name: currentDate.format("dddd") });
    for (let i = 1; i <= 7; i++) {
      let date = moment(days[i - 1].date).add(1, "days");
      let date1 = date;
      days.push({ date, name: date1.format("dddd") });
    }
    setWeekDays(days);
  };

  // On submit chagnes
  const onSubmit = async (stage, stageIndex) => {
    setLoading(true);
    try {
      const formData = {
        setPlayersDeadline: stage.stageType === 'league' ? stage.league.setPlayersDeadline : stage.tournament.setPlayersDeadline,
        forfeitTeam: stage.stageType === 'league' ? stage.league.forfeitTeam : stage.tournament.forfeitTeam,
        showOnDate: stage.stageType === 'league' ? stage.league.showOnDate : stage.tournament.showOnDate,
        canPlayersVeto: stage.stageType === 'league' 
          ? (stage.league.csgoServerLocation === null ? false : stage.league.csgoServerLocation)
          : (stage.tournament.csgoServerLocation === null ? false : stage.tournament.csgoServerLocation)
      };
      if(stage.stageType === 'league'){
        formData.earliestDate = allStageRechedule[stageIndex].earliestDate;
        formData.latestDate = allStageRechedule[stageIndex].latestDate;
        formData.rescheduleType = allStageRechedule[stageIndex].rescheduleType;
        formData.leagueFinished = stage.league.leagueFinished;
        formData.hideLeague = stage.league.hideLeague;
      }else{
        formData.reschedule = {
          earliestDate: allStageRechedule[stageIndex].earliestDate,
          latestDate: allStageRechedule[stageIndex].latestDate,
          rescheduleType: allStageRechedule[stageIndex].rescheduleType,
        };
        formData.tournamentFinished = stage.tournament.tournamentFinished;
        formData.hideTournament = stage.tournament.hideTournament;
      }
      if (isCSGO) {
        formData.csgoServerLocation = stage.stageType === 'league' 
          ? (stage.league.csgoServerLocation === null ? false : stage.league.csgoServerLocation)
          : (stage.tournament.csgoServerLocation === null ? false : stage.tournament.csgoServerLocation);
      }
      if(stage.stageType === 'league')
        await Axios.put(`${process.env.REACT_APP_CORE_API}/api/leagues/setting/${stage.league._id}`, formData);
      else
        await Axios.put(`${process.env.REACT_APP_CORE_API}/api/tournaments/setting/${stage.tournament._id}`, formData);

      toast.success("Saved");
    } catch (error) {
      console.error(error);
      toast.error("There was a problem saving");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <Form onSubmit={onSubmit}>
        {/* <Tabs 
          id="controlled-tab-example"
          // defaultActiveKey={selectedStageIndex}
          activeKey={selectedStageIndex}
          className="mb-3 sub-tabs"
          mountOnEnter={false}
          onSelect={e=>setSelectedStageIndex(e)}
        > */}
          {loading ? "Loading..." : multiStageLeague && multiStageLeague.stages.map((stage, stageIndex) => {if(stageIndex < 1){
            return(
              <>
            {/* <Tab key={stageIndex} eventKey={stageIndex} title={stage.name}> */}
              <Row>
                <Col sm={12}>
                  <Form.Group>
                    <Form.Label>Forfeit Teams Failing To Set Player</Form.Label>
                    <div className="d-flex align-items-center">
                      <Form.Control 
                        as="select"
                        ref={register()}
                        id="forfietTeams"
                        name="forfietTeams"
                        defaultValue={stage.stageType === 'league' ? stage.league.forfeitTeam : stage.tournament.forfeitTeam}
                        onBlur={(e) => {
                          stage.stageType === 'league' ? stage.league.forfeitTeam = e.target.value : stage.tournament.forfeitTeam = e.target.value;
                        }}
                      >
                        <option value="doNothing">Do Nothing</option>
                        <option value="useLastPlayers">Use Last Players</option>
                        <option value="forfietTeam">Forfeit Team</option>
                      </Form.Control>
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Set Players Deadline (Hrs)</Form.Label>
                    <Form.Control
                      ref={register()}
                      type="number"
                      id="setPlayersDeadlineHr"
                      name="setPlayersDeadlineHr"
                      value={playersDeadlineHrs[stageIndex]}
                      onChange={(e) => {
                        let playersDeadlineHrsCopy = [...playersDeadlineHrs];
                        playersDeadlineHrsCopy[stageIndex] = e.target.value;
                        setPlayersDeadlineHrs(playersDeadlineHrsCopy);
                      }}
                      onBlur={e => {
                        let val = parseInt(playersDeadlineHrs[stageIndex] * 60) + parseInt(playersDeadlineMins[stageIndex]);
                        stage.stageType === 'league' ? stage.league.setPlayersDeadline = val : stage.tournament.setPlayersDeadline = val;
                      }}
                    />
                    <Form.Control.Feedback type="invalid">{errors.setPlayersDeadline && errors.setPlayersDeadline.message}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Set Players Deadline (Mins)</Form.Label>
                    <Form.Control
                      ref={register()}
                      type="number"
                      id="setPlayersDeadlineMin"
                      name="setPlayersDeadlineMin"
                      value={playersDeadlineMins[stageIndex]}
                      onChange={(e) => {
                        let playersDeadlineMinsCopy = [...playersDeadlineMins];
                        playersDeadlineMinsCopy[stageIndex] = e.target.value;
                        setPlayersDeadlineMins(playersDeadlineMinsCopy);
                      }}
                      onBlur={e => {
                        let val = parseInt(playersDeadlineHrs[stageIndex] * 60) + parseInt(playersDeadlineMins[stageIndex]);
                        stage.stageType === 'league' ? stage.league.setPlayersDeadline = val : stage.tournament.setPlayersDeadline = val;
                      }}
                      max={59}
                      min={0}
                    />
                    <Form.Control.Feedback type="invalid">{errors.setPlayersDeadline && errors.setPlayersDeadline.message}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Match Rescheduling</Form.Label>
                    <Form.Control 
                      as="select" 
                      value={allStageRechedule[stageIndex]?.rescheduleType} 
                      id="matchReschedule" 
                      name="matchReschedule" 
                      onChange={e => {
                        const allStageRecheduleCopy = [...allStageRechedule];
                        allStageRecheduleCopy[stageIndex].rescheduleType = e.target.value;
                        if (e.target.value === "WEEK") {
                          allStageRecheduleCopy[stageIndex].earliestDate = minDate.toISOString();
                        }
                        if (e.target.value === "INTERVAL") {
                          if(stage.stageType === 'league'){
                            allStageRecheduleCopy[stageIndex].earliestDate = stage.league.reschedule && stage.league.reschedule.earliestDate
                              ? moment(stage.league.reschedule.earliestDate)
                              : stage.league.startDate
                              ? moment(stage.league.startDate)
                              : false
                              allStageRecheduleCopy[stageIndex].latestDate =  stage.league.reschedule && stage.league.reschedule.latestDate
                              ? moment(stage.league.reschedule.latestDate).toISOString()
                              : stage.league.startDate
                              ? moment(stage.league.startDate).add(7, "days").set({ hour: 23, minute: 59, second: 0, millisecond: 0 }).toISOString()
                              : false
                          }else{
                            allStageRecheduleCopy[stageIndex].earliestDate = stage.tournament.reschedule && stage.tournament.reschedule.earliestDate
                              ? moment(stage.tournament.reschedule.earliestDate)
                              : stage.tournament.startDate
                              ? moment(stage.tournament.startDate)
                              : false
                              allStageRecheduleCopy[stageIndex].latestDate =  stage.tournament.reschedule && stage.tournament.reschedule.latestDate
                              ? moment(stage.tournament.reschedule.latestDate).toISOString()
                              : stage.tournament.startDate
                              ? moment(stage.tournament.startDate).add(7, "days").set({ hour: 23, minute: 59, second: 0, millisecond: 0 }).toISOString()
                              : false
                          }
                        }
                        setAllStageRechedule(allStageRecheduleCopy);
                      }}
                    >
                      <option value="NO_RESCHEDULE">No Reschduling</option>
                      <option value="WEEK">Within The Week</option>
                      <option value="INTERVAL">Within An Interval</option>
                    </Form.Control>
                  </Form.Group>
                </Col>

                {isCSGO && (
                  <Col sm={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Server Location</Form.Label>
                      <Form.Control
                        as="select"
                        value={stage.stageType === 'league' ? stage.league.csgoServerLocation : stage.tournament.csgoServerLocation}
                        id="csgoServerLocation"
                        name="csgoServerLocation"
                        onChange={(e) => {
                          stage.stageType === 'league' ? stage.league.csgoServerLocation = e.target.value : stage.tournament.csgoServerLocation = e.target.value
                        }}
                      >
                        <option value={false} disabled>
                          Select Location
                        </option>
                        {serverLocations.map((serverLocation, index) => (
                          <option key={index} value={serverLocation.key}>
                            {serverLocation.value}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                )}

                {allStageRechedule[stageIndex]?.rescheduleType && allStageRechedule[stageIndex]?.rescheduleType === "WEEK" && (
                  <Col sm={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Week Start</Form.Label>
                      <Form.Control
                        as="select"
                        value={
                          allStageRechedule[stageIndex]?.latestDate
                        }
                        id="weekStart"
                        name="weekStart"
                        onChange={(e) => {
                          const allStageRecheduleCopy = [...allStageRechedule];
                          allStageRecheduleCopy[stageIndex].latestDate = e.target.value
                          setAllStageRechedule(allStageRecheduleCopy);
                        }}
                      >
                        <option value={false} disabled>
                          Select Weak Day
                        </option>
                        {weekDays.map((day, index) => (
                          <option key={index} value={moment(day.date).toISOString()}>
                            {day.name} ({moment(day.date).format("MMMM Do YYYY")})
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                )}

                {allStageRechedule[stageIndex]?.rescheduleType && allStageRechedule[stageIndex]?.rescheduleType === "INTERVAL" && (
                  <>
                    <Col sm={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Earliest Date</Form.Label>
                        <Datetime
                          utc={true}
                          value={allStageRechedule[stageIndex]?.earliestDate ? moment(allStageRechedule[stageIndex]?.earliestDate).format("DD/MM/YYYY HH:mm:ss") : null}
                          onChange={(date) => {
                            const allStageRecheduleCopy = [...allStageRechedule];
                            allStageRecheduleCopy[stageIndex].earliestDate = new Date(date).toISOString()
                            setAllStageRechedule(allStageRecheduleCopy);
                          }}
                          dateFormat="DD/MM/YYYY"
                          timeFormat="HH:mm:ss"
                          isValidDate={(currentDate) => currentDate.isBetween(minDate, maxDate)}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Latest Date</Form.Label>
                        <Datetime
                          utc={true}
                          value={allStageRechedule[stageIndex]?.latestDate ? moment(allStageRechedule[stageIndex]?.latestDate).format("DD/MM/YYYY HH:mm:ss") : null}
                          onChange={(date) => {
                            const allStageRecheduleCopy = [...allStageRechedule];
                            allStageRecheduleCopy[stageIndex].latestDate = new Date(date).toISOString()
                            setAllStageRechedule(allStageRecheduleCopy);
                          }}
                          dateFormat="DD/MM/YYYY"
                          timeFormat="HH:mm:ss"
                          isValidDate={(currentDate) => currentDate.isBetween(minDate, maxDate)}
                        />
                      </Form.Group>
                    </Col>
                  </>
                )}
              </Row>

              <Row>
                <Col sm={6}>
                  <Form.Group>
                    <Form.Label>{stage.stageType === 'league' ? "League Finished?" : "Tournament Finished?"}</Form.Label>
                    <Form.Control
                      as="select"
                      ref={register({ required: true })}
                      defaultValue={stage.stageType === 'league' ? stage.league.leagueFinished || false : stage.tournament.tournamentFinished || false}
                      id="leagueFinished"
                      name="leagueFinished"
                      onBlur={(e) => {
                        stage.stageType === 'league' ? stage.league.leagueFinished = e.target.value : stage.tournament.tournamentFinished = e.target.value;
                        // onSubmit(stage, stageIndex)
                      }}
                    >
                      <option value={false}>No</option>
                      <option value={true}>Yes</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{errors.leagueFinished && errors.leagueFinished.message}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              {isCSGO && (
                <Row>
                  <Col sm={12}>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      <Form.Check
                        ref={register()}
                        type="checkbox"
                        id="canPlayersVeto"
                        name="canPlayersVeto"
                        label="Allow Players to Veto"
                        checked={stage.stageType === 'league' ? stage.league.canPlayersVeto : stage.tournament.canPlayersVeto}
                        onChange={() => {
                          let multiStageLeagueCopy = {...multiStageLeague};
                          if(stage.stageType === 'league'){
                            multiStageLeagueCopy.stages[stageIndex].league.canPlayersVeto = !multiStageLeagueCopy?.stages[stageIndex]?.league?.canPlayersVeto
                          }else{
                            multiStageLeagueCopy.stages[stageIndex].tournament.canPlayersVeto = !multiStageLeagueCopy?.stages[stageIndex]?.tournament?.canPlayersVeto
                          }
                          setMultiStageLeague(multiStageLeagueCopy); 
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}

              <Row style={{ marginTop: "0.5em" }}>
                <Col md={2}>
                  <Form.Group>
                    <Form.Check
                      ref={register()}
                      type="checkbox"
                      name="hideLeague"
                      checked={stage.stageType === 'league' ? stage.league.hideLeague : stage.tournament.hideTournament}
                      label={stage.stageType === 'league' ? "Hide League" : "Hide Tournament"}
                      onChange={() => {
                        let multiStageLeagueCopy = {...multiStageLeague};
                        if(stage.stageType === 'league'){
                         multiStageLeagueCopy.stages[stageIndex].league.hideLeague = !multiStageLeagueCopy?.stages[stageIndex]?.league?.hideLeague
                        }else{
                          multiStageLeagueCopy.stages[stageIndex].tournament.hideTournament = !multiStageLeagueCopy?.stages[stageIndex]?.tournament?.hideTournament
                        }
                        setMultiStageLeague(multiStageLeagueCopy);
                      }}
                    />
                  </Form.Group>
                </Col>

                {((stage.stageType === 'league' && stage.league.hideLeague) || (stage.stageType === 'tournament' && stage.tournament.hideTournament)) && (
                  <Col md={2}>
                    <Form.Group>
                      <Form.Check
                        type="checkbox"
                        name="showOn"
                        checked={stage.stageType === 'league' ? stage.league.showOn : stage.tournament.showOn}
                        label="Show On"
                        onChange={() => {
                          let multiStageLeagueCopy = {...multiStageLeague};
                          if(stage.stageType === 'league'){
                            multiStageLeagueCopy.stages[stageIndex].league.showOn = !multiStageLeagueCopy.stages[stageIndex].league.showOn;
                          }else{
                            multiStageLeagueCopy.stages[stageIndex].tournament.showOn = !multiStageLeagueCopy.stages[stageIndex].tournament.showOn;
                          }
                          setMultiStageLeague(multiStageLeagueCopy);
                        }}
                      />
                    </Form.Group>
                  </Col>
                )}

                {(stage.stageType === 'league' && stage.league.hideLeague && stage.league.showOn) && 
                    <Row>
                      <Form.Label style={{ marginRight: "1em" }}>Date</Form.Label>
                      <div style={{ marginTop: "-0.4em" }}>
                        <Datetime
                          defaultValue={stage.league.showOnDate || ""}
                          onChange={(date) => {
                            let multiStageLeagueCopy = {...multiStageLeague};
                            multiStageLeagueCopy.stages[stageIndex].league.showOnDate = moment(date).tz(stage.league.timezone)
                            setMultiStageLeague(multiStageLeagueCopy);
                          }}
                          dateFormat="DD/MM/YYYY"
                          timeFormat="HH:mm:ss"
                          isValidDate={(currentDate) => currentDate.isAfter(tomorrow)}
                        />
                      </div>
                    </Row>
                }
                {(stage.stageType === 'tournament' && stage.tournament.hideTournament && stage.tournament.showOn) &&
                   <Row>
                    <Form.Label style={{ marginRight: "1em" }}>Date</Form.Label>
                    <div style={{ marginTop: "-0.4em" }}>
                      <Datetime
                        defaultValue={stage.tournament.showOnDate || ""}
                        onChange={(date) => {
                          let multiStageLeagueCopy = {...multiStageLeague};
                          multiStageLeagueCopy.stages[stageIndex].tournament.showOnDate = moment(date).tz(stage.tournament.timezone)
                          setMultiStageLeague(multiStageLeagueCopy);
                        }}
                        dateFormat="DD/MM/YYYY"
                        timeFormat="HH:mm:ss"
                        isValidDate={(currentDate) => currentDate.isAfter(tomorrow)}
                      />
                    </div>
                  </Row>
                }
              </Row>
              <Button variant="success" disabled={loading} onClick={() => onSubmit(stage, stageIndex)} >
                {loading ? "Saving..." : "Save Changes"}
              </Button>
            {/* </Tab> */}
            </>
          )}
          })}
        {/* </Tabs> */}
      </Form>
    </div>
  );
};

export default Settings;
