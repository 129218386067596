import { faPlus, faTrash, faFileImport as faImport, faArrowsAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import React, { useState} from "react";
import { Button, Form, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { useParams, Link, useHistory } from "react-router-dom";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import EditCreateSegementDataModal from './includes/EditCreateSegementDataModal';
import ImportCloneModal from './includes/ImportCloneModal';
import './styles.scss'

const SegmentManager = ({ pageType, data, setTempraryData, isSaved = true, frontPageAccess, isAdmin }) => {
  const [showupdateSegmentDataModal, setShowupdateSegmentDataModal] = useState(false);
  const [showImportCloneModal, setShowImportCloneModal] = useState(false);
  const [updateSegmentDataModal, setUpdateSegmentDataModal] = useState(null);
  const { id } = useParams();
  const [saved, setSaved] = useState(isSaved);
  const [pageData, setPageData] = useState(data[pageType] || []);
  const [submitting, setSubmitting] = useState(false);
  const arraysEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  };

  const onUpdateSegmentData = (atIndex, value) => {
    setSaved(false);
    if (!pageData[atIndex]) {
      // //console.log('modalData-onUpdateSegmentData',atIndex, value,pageData)
      pageData[atIndex] = value;
      setPageData(pageData)
    } else {
      setPageData((pageData) => {
        return pageData.map((p, i) => (i === atIndex ? value : p));
      });
    }
    setShowupdateSegmentDataModal(false)
    setUpdateSegmentDataModal(null);
  };


  const onDelete = (atIndex) => {
    setPageData(pageData.filter((_, i) => i !== atIndex));
    setSaved(false);
  };

  const onUpdateSegmentDataModal = (i) => {

    if (!pageData[i]) {
      //console.log('Set new Segment')
      setUpdateSegmentDataModal({ pageData: null, index: pageData.length });
    } else {
      //console.log('Used old Segment',pageData[i])
      setUpdateSegmentDataModal({ pageData: pageData[i], index: i });
    }
    //console.log('Actual data-',updateSegmentDataModal)
    setShowupdateSegmentDataModal(true);

  }

  const onImportCloneModal = (value) => {
    setShowImportCloneModal(false); //Show Modal
    setPageData(value);
    setSaved(false);
  }

  const onSave = async () => {
    setSubmitting(true);
    setSaved(true);
    try {
      let frontPageData = {
        ...data,
        [pageType]: pageData,
      };
      console.log("front page -- ", frontPageData);
      const { data: resData } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/games/${id}/frontpage`, frontPageData);

      // setNewData(resData.updatedGame);
      setTempraryData();
      toast.success("Saved");
    } catch (e) {
      toast.error("There was a problem saving");
    }

    setSubmitting(false);
  };

  const DragHandle = sortableHandle(() => <FontAwesomeIcon icon={faArrowsAlt} />);

  const segmentModules = [
    { key: "Game_Selection", name: "Game Selection" },
    { key: "Leagues_Manual", name: "Leagues (Manual)" },
    { key: "Leagues_Season", name: "Leagues (Season)" },
    { key: "Tournaments", name: "Tournaments (Manual)" },
    { key: "Tournaments_Season", name: "Tournaments (Season)" },
    { key: "Upcoming_Matches_Manual", name: "Upcoming Matches (Manual)" },
    { key: "Upcoming_Matches_Season", name: "Upcoming Matches (Season)" },
    { key: "News", name: "News" },
    { key: "Header", name: "Header" },
    { key: "Subscription", name: "Subscription" },
    { key: "Advert", name: "Advert" },
    { key: "Content_With_Image", name: "Content With Image(For Pro & Organisation)" },
  ];

  const getSegmentModuleName = (segmenModName) => {
    return segmentModules.filter(({ key }) => key === segmenModName)[0].name || "Missing Module"
  }

  const SortableItem = sortableElement(({ value, index, i }) => (
    value && value.segmentType && <tr key={i}>
      <td className="place-td" width="10%">#{i + 1}</td>

      <td className="name-td" width="70%">
        {getSegmentModuleName(value.segmentType)}
      </td>
      <td className="btns-td" width="20%">


        <Button variant="link" className="text-danger" onClick={() => onDelete(i)} disabled={!frontPageAccess && !isAdmin}>
          <FontAwesomeIcon icon={faTrash} /> Delete
        </Button>

        <Button variant="link" className="text-success" onClick={() => onUpdateSegmentDataModal(i)} disabled={!frontPageAccess && !isAdmin}>
          <FontAwesomeIcon icon={faEdit} /> Edit
        </Button>

        <DragHandle />
      </td>
    </tr>
  ));

  const SortableContainer = sortableContainer(({ children }) => {
    return <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th width="2" >Place</th>
          <th>Segment Type</th>
          {/* <th>Data</th> */}
          <th></th>
        </tr>
      </thead>
      <tbody>{children} </tbody>
    </Table>;
  });
  // //console.log('pageData=',pageData)
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setPageData(arrayMove(pageData, oldIndex, newIndex));
    setSaved(false);
  };



  return (
    <div>
      {
        <>
          <EditCreateSegementDataModal
            modalData={updateSegmentDataModal}
            show={showupdateSegmentDataModal}
            onHide={() => setShowupdateSegmentDataModal(false)}
            onUpdate={(i, value) => onUpdateSegmentData(i, value)}
          />
          <ImportCloneModal
            // modalData={updateSegmentDataModal}
            show={showImportCloneModal}
            onHide={() => setShowImportCloneModal(false)}
            onUpdate={(value) => onImportCloneModal(value)}
          />
        </>
      }
      <div className="d-flex justify-content-end mb-2">
        <Button variant="success" size="sm" onClick={() => onUpdateSegmentDataModal(pageData.length)} disabled={!frontPageAccess && !isAdmin}>
          <FontAwesomeIcon icon={faPlus} /> Add Segment
        </Button>

        <Button variant="success" className={`ml-4`} size="sm" onClick={() => setShowImportCloneModal(true)} disabled={!frontPageAccess && !isAdmin}>
          <FontAwesomeIcon icon={faImport} /> Import/Clone
        </Button>
      </div>

      {pageData && !pageData.length ? (
        "No segments added. Add one using the button at the top right!"
      ) : (

        <SortableContainer onSortEnd={onSortEnd} helperClass={"drag-item-container-class"}>
          {pageData && pageData.map((value, index) => (
            <SortableItem key={`item-${index}`} index={index} i={index} value={value} />
          ))}
        </SortableContainer>


      )}

      {saved === false && (
        <div className="mt-3">
          <Button type="button" variant="success" onClick={onSave} disabled={submitting || !frontPageAccess && !isAdmin}>
            {submitting ? "Saving" : "Save"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default SegmentManager;
