import React, { createContext, useState, useEffect, useReducer } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Axios from "axios";
import verifyToken from "./utils/verifyToken";
import userReducer from "./reducers/userReducer.jsx";

export const AppContext = createContext();
export const WithAppContext = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [userState, userDispatch] = useReducer(userReducer, false);

  // Check authentication & set user data on load and each page change
  const history = useHistory();
  const { pathname } = useLocation();

  // Authentication setup
  useEffect(() => {
    const validTokenData = verifyToken();

    if (validTokenData) {
      if (!authenticated || !userState) {
        Axios.get(`${process.env.REACT_APP_CORE_API}/api/auth`)
          .then(async ({ data }) => {
            setAuthenticated(true);
            userDispatch({
              type: "load",
              payload: {
                ...validTokenData,
                ...data,
              },
            });
            setLoading(false);
          })
          .catch(() => {
            localStorage.removeItem("sessionToken");
            history.push("/");
            setLoading(false);
          });
      }
    } else {
      // Clear authentication state
      setAuthenticated(false);
      if (Object.keys(userState).length) {
        userDispatch({ type: "load", payload: false });
      }

      setLoading(false);
    }
  }, [history, pathname, authenticated, userState]);

  const context = {
    authenticated,
    setAuthenticated,
    user: { state: userState, dispatch: userDispatch },
  };

  return loading ? "Loading..." : <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};
