export const serverLocations = [
  {
    "key": "amsterdam",
    "value": "Amsterdam, Netherlands"
  },
  {
    "key": "barcelona",
    "value": "Barcelona, Spain"
  },
  {
    "key": "beauharnois",
    "value": "Beauharnois, Canada"
  },
  {
    "key": "bristol",
    "value": "Bristol, UK"
  },
  {
    "key": "chicago",
    "value": "Chicago, IL, USA"
  },
  {
    "key": "dallas",
    "value": "Dallas, TX, USA"
  },
  {
    "key": "dusseldorf",
    "value": "Dusseldorf, Germany"
  },
  {
    "key": "helsinki",
    "value": "Helsinki, Finland"
  },
  {
    "key": "istanbul",
    "value": "Istanbul, Turkey"
  },
  {
    "key": "los_angeles",
    "value": "Los Angeles, CA, USA"
  },
  {
    "key": "moscow",
    "value": "Moscow, Russia"
  },
  {
    "key": "new_york_city",
    "value": "New York City, NY, USA"
  },
  {
    "key": "portland",
    "value": "Portland, OR, USA"
  },
  {
    "key": "sao_paulo",
    "value": "São Paulo, Brazil"
  },
  {
    "key": "singapore",
    "value": "Singapore, Singapore"
  },
  {
    "key": "stockholm",
    "value": "Stockholm, Sweden"
  },
  {
    "key": "strasbourg",
    "value": "Strasbourg, France"
  },
  {
    "key": "sydney",
    "value": "Sydney, Australia"
  },
  {
    "key": "warsaw",
    "value": "Warsaw, Poland"
  }
]