import React from "react";
import Axios from "axios";
const TranslationGetApis = async () => {
  try {
    const resp = await Axios.get(`${process.env.REACT_APP_CORE_API}/api/language/all`);
    return resp.data;
  } catch (err) {
    return {error : true};
  }
};
export default TranslationGetApis;
