import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import Axios from "axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import EditImageModal from "../../../../../_modals/EditImageModal/EditImageModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import "./EditPlaceHolderModal.scss";


const EditPlaceHolderModal = ({ league, setLeague, show, onHide, currentTeam = {} }) => {
  const { handleSubmit, register, setValue, errors } = useForm();
  const [modals, setModals] = useState({});
  const [logoCropped, setLogoCropped] = useState(currentTeam.logoCropped === undefined ? true : currentTeam.logoCropped);
  const [updatedLogo, setUpdatedLogo] = useState(null);

  useEffect(() => setUpdatedLogo(currentTeam.logo), [])

  const onUpdateImage = async (type, image) => {
    try {
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/teams/${currentTeam._id}/image`, { type, image });
      toast.success("Updated");
      setUpdatedLogo(data.updatedTeam.logo);
      setLeague((league) => ({
        ...league, teams: league.teams.map(team => {
          if (team._id === currentTeam._id) {
            return { ...team, logo: data.updatedTeam.logo }
          }
          return team
        })
      }));
      setModals(false);
    } catch (err) {
      toast.error("There was a problem updating league images!");
    }
  };
  const onSubmit = async (data) => {
    try {
      await Axios.put(`${process.env.REACT_APP_CORE_API}/api/teams/${currentTeam._id}?gameId=${currentTeam.game}`, { ...data });
      toast.success("Updated");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (e) {
      const error = ("There was a problem saving");
      toast.error(error);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit PlaceHoler Team</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-12">
              <label htmlFor="name">Team Name:</label>
              <input
                onBlur={(e) => {
                  e.target.value = e.target.value.trim();
                }}
                ref={register({ required: "Required", maxLength: { value: 200, message: "Name too long" } })}
                type="text"
                id="name"
                name="name"
                autoComplete="off"
                className={`form-control col-12 ${errors.name ? "invalid" : ""}`}
                defaultValue={currentTeam.name}
                placeholder="Name"
              />
              {errors.name && <div className="form-control-error">{errors.name.message}</div>}
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <label htmlFor="name">Initials:</label>
              <input
                onBlur={(e) => {
                  e.target.value = e.target.value.trim();
                }}
                ref={register({ required: "Required", maxLength: { value: 4, message: "Initials too long" } })}
                type="text"
                id="initials"
                name="initials"
                autoComplete="off"
                className={`form-control col-12 ${errors.initials ? "invalid" : ""}`}
                defaultValue={currentTeam.initials}
                placeholder="Initials"
              />
              {errors.initials && <div className="form-control-error">{errors.initials.message}</div>}
            </div>
          </div>

          <Form.Check
            ref={register()}
            type="checkbox"
            label="Logo Cropped"
            id="logoCropped"
            name="logoCropped"
            style={{ marginBottom: "1em", marginTop: "1em" }}
            // defaultChecked={!currentTeam.logoCropped ? true : currentTeam.logoCropped}
            onChange={() => setLogoCropped(!logoCropped)} checked={logoCropped}
          />

          <div>
            <div className="d-flex">
              {/* Add new images to the array */}
              {[
                { name: "Logo", width: 250, height: 250, displayWidth: 150, displayHeight: 150, crop: true },
              ].map(({ name, key, width, height, displayWidth, displayHeight, crop }) => (
                <div key={key}>
                  <EditImageModal
                    show={modals[key]}
                    onHide={() => setModals({ ...modals, [key]: false })}
                    name={name}
                    width={width}
                    height={height}
                    afterImageUpload={(file) => onUpdateImage("logo", file)}
                    crop={crop}
                    logoCropped={(value) => {
                      setLogoCropped(value);
                    }}
                  />

                  <Form.Group className="mr-4">
                    <Form.Label>{name}</Form.Label>
                    <div
                      style={{
                        backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${updatedLogo})`,
                        width: displayWidth,
                        height: displayHeight,
                      }}
                      className={` ${!logoCropped ? "upload-image-btn" : "upload-image-btn-cropped"}`}
                      onClick={() => setModals({ ...modals, [key]: true })}
                    >
                      <FontAwesomeIcon icon={faPencilAlt} />
                    </div>
                  </Form.Group>
                </div>
              ))}
            </div>
          </div>


          <div className="row">
            <div className="col-6">
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            </div>
          </div>

        </form>

      </Modal.Body>
    </Modal>
  );
};

export default EditPlaceHolderModal;