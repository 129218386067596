import React, { useEffect, useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  faEye,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SingleMember from "../../OrganisationTabsManager/include/SingleMember";
import AddOrganisationMemberModal from "../include/AddOrganisationMemberModal";
import Axios from "axios";
import { toast } from "react-toastify";

const Members = ({ organisation, setOrganisation }) => {
  const [members, setMembers] = useState(organisation.owners || []);
  const [nationality, setNationality] = useState(organisation.nationality);
  const [existedmembers, setExistedMembers] = useState([]);
  const [showAddMember, setShowAddMember] = useState(false);
  const [pendingInvites, setPendingInvites] = useState();


  useEffect(() => {
    if (organisation.owners) {
      setExistedMembers([...existedmembers, ...organisation.owners]);
    }

    try {
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/organisations/${organisation._id}/invites`).then(({ data }) => {
        setPendingInvites(data.invites)
      });
    } catch (err) {
      const msg = err.response.data ? err.response.data.msg : "Error!";
      toast.error(msg);
    }
  }, [organisation]);

  const onRemoveOwner = async (ownerId) => {
    if (!window.confirm("Are you sure?")) return false;

    try {
      await Axios.post(`${process.env.REACT_APP_CORE_API}/api/organisations/${organisation._id}/owners/removeOwner`, { ownerId });
      setMembers(members.filter(({ user }) => user._id !== ownerId));
      setOrganisation((organisation) => ({ ...organisation, owners: members.filter(({ user }) => user._id !== ownerId) }));

      toast.success("Successfully removed owner");
    } catch (err) {
      const msg = err.response.data ? err.response.data.msg : "Couldn't remove owner";
      toast.error(msg);
    };
  }

  const afterInviteMembers = (invites) => {
    setPendingInvites(invites.map((invite) => ({ ...invite, type: "invite" })));
  };

  const afterAddingMembers = (id) => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/organisations/${id}`).then(({ data }) => {
      setMembers(data.owners ? data.owners: []);
    });
  };

  const onDeleteInvite = async (id) => {
    if (window.confirm("Are you sure?")) {
      try {
        await Axios.delete(`${process.env.REACT_APP_CORE_API}/api/organisations/invites/${id}`);
        setPendingInvites((invites) => invites.filter(({ _id }) => _id !== id));

        toast.success("Successfully deleted invite");
      } catch (e) {
        toast.error("Could not delete invite");
      }
    }
  };

  const memberSections = [
    { name: "Pending Invites", members: pendingInvites && pendingInvites.map((invite) => ({ ...invite, type: "invite" })) },
    {
      name: "Members",
      members:
        [
          ...members.filter(({ role }) => ["Manager", "Owner"].includes(role)),
        ],
    },
  ];

  return (
    <div>
      <Button className="addMember" onClick={() => setShowAddMember(true)}>
        Add Owner
      </Button>
      {memberSections.map(
        ({ name, members = [] }, i) =>
          !!members.length && (
            <>
              <div className="cardHeader">{name}</div>
              <Col md={{ span: 10, offset: 1 }}>
                {members
                  .map((singleMember) => {
                    if (singleMember && singleMember._id) {
                      const { _id, user, role, type } = singleMember;
                      return (
                        <SingleMember
                          key={_id}
                          img={user ? user.avatarImage : false}
                          title={
                            user
                              ? `${user.firstName}${user.nickname ? ` "${user.nickname}" ` : " "}${user.lastName}` : false
                          }
                          pending={type === "invite" ? true : false}
                          role={role ? role : false}
                          nationality={
                            nationality ? nationality : false
                          }
                          actions={
                            type !== "invite" ?
                              (
                                [
                                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">View</Tooltip>}>
                                    <a
                                      className="btn text-secondary"
                                      href={
                                        user
                                          ? `${process.env.REACT_APP_LEAGUES_URL}/players/${user._id}`
                                          : false}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      style={{ padding: "8px" }}
                                    >
                                      <FontAwesomeIcon icon={faEye} />
                                    </a>
                                  </OverlayTrigger>,
                                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Delete</Tooltip>}>
                                    <Button variant="link" className="btn text-danger" onClick={() => onRemoveOwner(user._id)}>
                                      <FontAwesomeIcon icon={faTrashAlt} />
                                    </Button>
                                  </OverlayTrigger>
                                ]
                              ) : (
                                [
                                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Delete</Tooltip>}>
                                    <Button variant="link" className="btn text-danger" onClick={() => onDeleteInvite(_id)}>
                                      <FontAwesomeIcon icon={faTrashAlt} />
                                    </Button>
                                  </OverlayTrigger>
                                ]
                              )
                          }
                        />
                      );
                    }
                  })}
              </Col>
            </>
          )
      )}

      <AddOrganisationMemberModal
        show={showAddMember}
        handleClose={() => setShowAddMember(false)}
        organisation={organisation}
        members={existedmembers}
        pendingInvites={pendingInvites}
        afterAdd={afterInviteMembers}
        afterAddMember= {afterAddingMembers}
      />
    </div>
  );
};

export default Members;
