import { update } from "lodash-es";
import React, { useState, useEffect, createRef } from "react";
import Axios from "axios";
import { Modal, Form, Button, Tooltip, OverlayTrigger } from "react-bootstrap";

const SingleSegmentModule = ({updateComponent,modalData,segData}) => {
    
   const [gameData,setGameData]=useState(modalData.pageData && modalData.pageData.segmentData || {});
   const [games,setGames] = useState([]);
    const update = (key,value) => {
        let data = {...gameData,[key]:value};
        setGameData(data)
        updateComponent(data)
    }

    useEffect(() => {
        Axios.get(`${process.env.REACT_APP_CORE_API}/api/games`).then(({ data }) => {
          setGames(() => {
            return data.filter((game) => game.name !==  'No Game');
          })
         
        });
      }, []);

    return (
    <><br />
       
       <Form>
       
<Form.Group>
            <Form.Label>Select game</Form.Label>
            <div className="d-flex align-items-center">
                <Form.Control
                    as="select"
                    id='game'
                    name='game'
                    value={gameData ? gameData.game : null}
                    onChange={(e) => update('game',e.target.value)}
                >
                    <option value="">Select Game</option>
                    {games.map((code) => (
                        <option value={code._id}>{code.name}</option>
                        ))}
                </Form.Control>
            </div></Form.Group>


            

              <Form.Group>
                <Form.Check
                  type="checkbox"
                  id="ShowAll"
                  label="Show All"
                  defaultChecked={gameData ? gameData.ShowAll : false}
                  onChange={(e) => update("ShowAll",e.target.checked)}
                />
                <small>Checking this checkbox overwrite selected game for advert from the dropdown `Select Game`.</small>
              </Form.Group>
              
            </Form>
    </>)
}

export default SingleSegmentModule;

