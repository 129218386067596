import React, { useState } from "react";
import { Card, Modal, Nav } from "react-bootstrap";
import SetPlayers from "./SetPlayers";

const MatchSetPlayersModal = ({ show, onHide, match, updateMatches, league }) => {
  const [activeTab, setActiveTab] = useState(0);

  if (!match) return null;

  const { t1 = {}, t2 = {} } = match;

  const tabs = [
    {
      name: `${t1?.name}`,
      content: <SetPlayers onClose={onHide} match={match} updateMatches={updateMatches} league={league} teamData={t1} teamSelector="t1" />,
    },
    {
      name: `${t2?.name}`,
      content: <SetPlayers onClose={onHide} match={match} updateMatches={updateMatches} league={league} teamData={t2} teamSelector="t2" />,
    },
  ];

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Manually Set Players</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ padding: 0 }}>
        <Card style={{ borderRadius: 0, border: 0, margin: 0 }}>
          <Card.Header>
            <Nav variant="tabs" activeKey={activeTab} onSelect={(t) => setActiveTab(parseInt(t))}>
              {tabs.map(({ name }, i) => (
                <Nav.Item key={i}>
                  <Nav.Link eventKey={i}>{name}</Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Card.Header>
          <Card.Body>{tabs.map(({ content }, i) => i === activeTab && <div key={i}>{content}</div>)}</Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
};

export default MatchSetPlayersModal;
