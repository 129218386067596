import { update } from "lodash-es";
import React, { useState, useEffect, createRef } from "react";
import Axios from "axios";
import { Modal, Form, Button, Tooltip, OverlayTrigger, FormGroup } from "react-bootstrap";

const SingleSegmentModule = ({updateComponent,modalData,segData}) => {
    
   const [gameData,setGameData]=useState(modalData.pageData && modalData.pageData.segmentData || {});

    const update = (key,value) => {
        let data = {...gameData,[key]:value};
        setGameData(data)
        updateComponent(data)
    }

 
    return (
    <>
        Click on add button to add this segment to stack.
    </>)
}

export default SingleSegmentModule;

