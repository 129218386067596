import React, { useState, useEffect, createRef } from "react";
import { Modal, Form, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faDownload, faKey, faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import GameSelection from './SegmentModules/GameSelection';
import LeaguesManual from './SegmentModules/LeaguesManual';
import LeaguesSeason from './SegmentModules/LeaguesSeason';
import Tournaments from './SegmentModules/Tournaments';
import TournamentsSeason from "./SegmentModules/TournamentsSeason";
import UpcomingMatchesManual from "./SegmentModules/UpcomingMatchesManual";
import UpcomingMatchesSeason from "./SegmentModules/UpcomingMatchesSeason";
import ContentWithImage from "./SegmentModules/ContentWithImage";
import News from "./SegmentModules/News";
import Header from "./SegmentModules/Header";
import Subscription from "./SegmentModules/Subscription";
import Advert from "./SegmentModules/Advert";

const EditCreateSegementDataModal = ({ show, onHide, modalData, onUpdate }) => {
  // //console.log('step-2-modalData-',modalData)
  const [arrayIndex, setArrayIndex] = useState(null);
  const [conditionalComponent, setConditionalComponent] = useState(null);
  const [segData, setSegData] = useState(null);
  const [segDataInner, setSegDataInner] = useState(null);

  const modalClose = () => {
    setSegDataInner(null);
    setConditionalComponent(null);
    onHide();
  };
  useEffect(() => {
    if (modalData) {
      setArrayIndex(modalData.index);
      setSegData(modalData.pageData);
    }
  }, [modalData]);

  const segmentModules = [
    {
      key: "Game_Selection",
      name: "Game Selection",
      component: (
        <GameSelection updateComponent={(value) => updateSegmentDataInner("segmentData", value)} modalData={modalData} segData={segData} />
      ),
    },
    {
      key: "Leagues_Manual",
      name: "Leagues (Manual)",
      component: (
        <LeaguesManual updateComponent={(value) => updateSegmentDataInner("segmentData", value)} modalData={modalData} segData={segData} />
      ),
    },
    {
      key: "Leagues_Season",
      name: "Leagues (Season)",
      component: (
        <LeaguesSeason updateComponent={(value) => updateSegmentDataInner("segmentData", value)} modalData={modalData} segData={segData} />
      ),
    },
    {
      key: "Tournaments",
      name: "Tournaments (Manual)",
      component: (
        <Tournaments updateComponent={(value) => updateSegmentDataInner("segmentData", value)} modalData={modalData} segData={segData} />
      ),
    },
    {
      key: "Tournaments_Season",
      name: "Tournaments (Season)",
      component: (
        <TournamentsSeason
          updateComponent={(value) => updateSegmentDataInner("segmentData", value)}
          modalData={modalData}
          segData={segData}
        />
      ),
    },
    {
      key: "Upcoming_Matches_Manual",
      name: "Upcoming Matches (Manual)",
      component: (
        <UpcomingMatchesManual
          updateComponent={(value) => updateSegmentDataInner("segmentData", value)}
          modalData={modalData}
          segData={segData}
        />
      ),
    },
    {
      key: "Upcoming_Matches_Season",
      name: "Upcoming Matches (Season)",
      component: (
        <UpcomingMatchesSeason
          updateComponent={(value) => updateSegmentDataInner("segmentData", value)}
          modalData={modalData}
          segData={segData}
        />
      ),
    },
    {
      key: "News",
      name: "News",
      component: <News updateComponent={(value) => updateSegmentDataInner("segmentData", value)} modalData={modalData} segData={segData} />,
    },
    {
      key: "Header",
      name: "Header",
      component: (
        <Header updateComponent={(value) => updateSegmentDataInner("segmentData", value)} modalData={modalData} segData={segData} />
      ),
    },
    {
      key: "Subscription",
      name: "Subscription",
      component: (
        <Subscription updateComponent={(value) => updateSegmentDataInner("segmentData", value)} modalData={modalData} segData={segData} />
      ),
    },
    {
      key: "Advert",
      name: "Advert",
      component: (
        <Advert updateComponent={(value) => updateSegmentDataInner("segmentData", value)} modalData={modalData} segData={segData} />
      ),
    },
    {
      key: "Content_With_Image",
      name: "Content With Image(For Pro & Organisation)",
      component: (
        <ContentWithImage
          updateComponent={(value) => updateSegmentDataInner("segmentData", value)}
          modalData={modalData}
          segData={segData}
        />
      ),
    },
  ];

  useEffect(() => {
    setConditionalComponent(null);
    if (modalData !== null && modalData.pageData && modalData.pageData.segmentType) {
      setConditionalComponent(() => {
        return segmentModules.filter((segMentModule) => segMentModule.key === modalData.pageData.segmentType)[0].component;
      });
    }
  }, [modalData]);

  const saveSegment = () => {
    console.log("update segment on saveswgment-", segDataInner);
    onUpdate(modalData.index, { ...segData, ...segDataInner });
  };

  const updateSegmentDataInner = (type, value) => {
    console.log("onUpdateSegmentData-", type, value);
    setSegDataInner({ ...segDataInner, [type]: value });
  };

  const updateSegmentData = (type, value) => {
    type === "segmentType" &&
      setConditionalComponent(() => {
        return segmentModules.filter((segMentModule) => segMentModule.key === value)[0].component;
      });
    setSegData({ ...segData, [type]: value });
  };

  return (
    <Modal show={show} onHide={modalClose} onClose={modalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Configure Segment</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form /*onSubmit={handleSubmit(saveSegment)}*/>
          <Form.Group>
            <Form.Label>Segment Type</Form.Label>
            <Form.Control
              as="select"
              value={(segData && segData.segmentType) || "_DEFAULT_"}
              onChange={(e) => updateSegmentData("segmentType", e.target.value)}
            >
              <option disabled value="_DEFAULT_">
                Select Segment Type
              </option>
              {segmentModules.map((typeOfSegment) => (
                <option key={typeOfSegment.key} value={typeOfSegment.key}>
                  {typeOfSegment.name}
                </option>
              ))}
            </Form.Control>

            {conditionalComponent}

            {/* <Form.Control.Feedback type="invalid">{errors.name && errors.name.message}</Form.Control.Feedback> */}
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer className="d-block p-3">
        <Button variant="success" size="sm" onClick={saveSegment}>
          Add
        </Button>
        <Button variant="danger" size="sm" onClick={modalClose}>
          Discard
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditCreateSegementDataModal;
