import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Alert, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faPlus, faPencilAlt, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import { Link, useParams } from "react-router-dom";
import SingleAdCreateEditModal from "./modal/SingleAdCreateEditModal";
import { toast } from "react-toastify";

const ManageAdvert = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [advert, setAdvert] = useState();
  const [adverts, setAdverts] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [modalData, setModalData] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/adverts/${id}`).then(({ data }) => {
      setAdvert(data);
      setAdverts(data.adverts || []);
      setLoading(false);
    });
  }, [id]);

  // useEffect(() => {
  //   if (adverts.length) {
  //     Axios.post(`${process.env.REACT_APP_CORE_API}/api/adverts/${id}`, { adverts }).then(() => {
  //       toast.success("Saved");
  //     });
  //   }
  // }, [id, adverts]);

  const saveAdverts = (adverts) => {
    // console.log('saveAdverts',adverts)
    if (adverts.length) {
      Axios.post(`${process.env.REACT_APP_CORE_API}/api/adverts/${id}`, { adverts }).then(() => {
        toast.success("Saved");
        Axios.get(`${process.env.REACT_APP_CORE_API}/api/adverts/${id}`).then(({ data }) => {
          setAdvert(data);
          setAdverts(data.adverts || []);
          setLoading(false);
        });
      });
    }
  };

  const onCreateAd = () => {
    setModalData(false); // ensure modal data is always blank!
    setShowModal(true);
  };

  const onEditAd = (systemData) => {
    setModalData(systemData);
    setShowModal(true);
  };

  const onRemoveAd = async (id) => {
    setAdverts((ads) => ads.filter(({ _id }) => _id !== id));
  };

  const columns = [
    { name: "Name", selector: "name", sortable: true },
    { name: "URL", selector: "url", sortable: true },
    { name: "Preview", cell: (row) => <img src={row.img} alt="Ad preview" width="100px" /> },
    { name: "Views (Unique)", cell: (row) => `${row.views} (${row.viewsUnique})` },
    { name: "Clicks (Unique)", cell: (row) => `${row.clicks} (${row.clicksUnique})` },
    { name: "For Games", cell: (row) => `${row.forGames.map((fgm) => fgm.game.shortName)}` },
    {
      right: true,
      cell: (row) => (
        <>
          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Edit</Tooltip>}>
            <Button variant="link" className="text-secondary" onClick={() => onEditAd(row)}>
              <FontAwesomeIcon icon={faPencilAlt} />
            </Button>
          </OverlayTrigger>

          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Delete</Tooltip>}>
            <Button variant="link" className="text-danger" onClick={() => onRemoveAd(row._id)}>
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </OverlayTrigger>
        </>
      ),
    },
  ];

  return (
    !loading && (
      <div>
        <div className="admin-page-title">
          <h2>Manage "{advert.name}"</h2>

          <div>
            <Link className="btn mr-2" to="/adverts">
              <FontAwesomeIcon icon={faChevronLeft} /> Back
            </Link>
            <Button variant="success" onClick={onCreateAd}>
              <FontAwesomeIcon icon={faPlus} /> Create Promotion
            </Button>
          </div>
        </div>

        {!!selectedRows.length && (
          <Alert key="table-actions" variant="secondary">
            <div className="d-flex justify-content-between align-items-center">
              <div>{selectedRows.length} rows selected</div>
              <div>
                <Button size="sm" variant="danger">
                  <FontAwesomeIcon icon={faTrashAlt} />
                  &nbsp;Delete Selected
                </Button>
              </div>
            </div>
          </Alert>
        )}

        <DataTable
          noHeader={true}
          columns={columns}
          data={adverts}
          selectableRows={true}
          selectableRowsHighlight={true}
          onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}
          style={{ overflow: "inherit" }}
        />

        <SingleAdCreateEditModal show={showModal} handleClose={() => setShowModal(false)} data={modalData} setAdverts={(ads) => saveAdverts(ads)} adverts={adverts} />
      </div>
    )
  );
};

export default ManageAdvert;
