import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Axios from "axios";
import Datetime from "react-datetime";
import React, { useState } from "react";
import { Card, Nav, Button, Form, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from 'moment';


const ImportantDates = ({ league, setLeague }) => {
  const [importantDates, setImportantDates] = useState(league.importantDates || []);
  const [submitting, setSubmitting] = useState(false);
  const [activeTab, setActiveTab] = useState("Dates");
  var valid = function( current,i,from ){
    return current.isAfter( from );
  };

  const arraysEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  };

  const onUpdateimportantDates = (atIndex,name, value) => {
    setImportantDates((importantDates) => {
      return importantDates.map((p, i) => (i === atIndex ? {...p,[name]:value} : p));
    });
  };

  const onAddimportantDates = () => {
    setImportantDates([...importantDates, {dateType: activeTab === "Dates" ? 'date' : 'interval'}]);
  };

  const onDeleteimportantDates = (atIndex) => {
    setImportantDates(importantDates.filter((_, i) => i !== atIndex));
  };

  const onSave = async () => {
    setSubmitting(true);

    const newarr = importantDates.sort((a, b) => {
      return moment(a.datetime).diff(b.datetime);
    });


    console.log('sortedArray=',newarr)
    try {
      await Axios.put(`${process.env.REACT_APP_CORE_API}/api/leagues/${league._id}`, 
      { importantDates:newarr, timezone:league.timezone }
      );

      // update league
      setImportantDates(newarr);
      setLeague((league) => ({ ...league, importantDates:newarr }));

      toast.success("Saved");
    
    } catch (e) {
      toast.error("There was a problem saving");
    }

    setSubmitting(false);
  };

  // ADD/REMOVE TABS THROUGH THIS ARRAY = ["date", "interval"]
  const TABS = [
    {
      name: "Dates",
      content: <div>
      <div className="d-flex justify-content-end mb-2">
        <Button variant="success" size="sm" onClick={onAddimportantDates}>
          <FontAwesomeIcon icon={faPlus} /> Add Dates
        </Button>
      </div>

      {!importantDates.length ? (
        "No Important Dates added. Add one using the button at the top right!"
      ) : (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Date and time</th>
              <th>Name</th>
              <th>Description</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {importantDates.map((importantDate, i) => (
              importantDate.dateType === 'date' && <tr key={i}>
                <td>
                  <Form.Group>
                    <div>
                      <Datetime timeFormat={`HH:mm`}  value={moment(importantDate.datetime)} onChange={(e) => onUpdateimportantDates(i,'datetime', e)}  dateFormat="DD/MM/YYYY" />
                    </div>
                  </Form.Group>
                </td>
                
                <td>
                  <Form.Control type="text" defaultValue={importantDate.name} onChange={(e) => onUpdateimportantDates(i,'name', e.target.value)} />
                </td>
                <td>
                  <Form.Control type="text" defaultValue={importantDate.description} onChange={(e) => onUpdateimportantDates(i,'description', e.target.value)} />
                </td>
                <td>
                  <Button variant="link" className="text-danger" onClick={() => onDeleteimportantDates(i)}>
                    <FontAwesomeIcon icon={faTrash} /> Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {!arraysEqual(league.importantDates, importantDates) && (
        <div className="mt-3">
          <Button type="button" variant="success" onClick={onSave} disabled={submitting}>
            {submitting ? "Saving" : "Save"}
          </Button>
        </div>
      )}
    </div>
    },
    {
      name: "Intervals",
      content: <div>
      <div className="d-flex justify-content-end mb-2">
        <Button variant="success" size="sm" onClick={onAddimportantDates}>
          <FontAwesomeIcon icon={faPlus} /> Add Intervals
        </Button>
      </div>

      {!importantDates.length ? (
        "No Intervals added. Add one using the button at the top right!"
      ) : (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>From</th>
              <th>To</th>
              <th>Name</th>
              <th>Description</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          {/* .filter((impDate) => impDate.dateType === 'interval') */}
            {importantDates.map((importantDate, i) => (
              importantDate.dateType === 'interval' && <tr key={i}>
                <td>
                  <Form.Group>
                    <div>
                      <Datetime timeFormat={`HH:mm`}  value={moment(importantDate.datetime)} onChange={(e) => onUpdateimportantDates(i,'datetime', e)}  dateFormat="DD/MM/YYYY" />
                    </div>
                  </Form.Group>
                  
                </td>
                <td>
                <Form.Group>
                    <div>
                      <Datetime isValidDate={(current) => valid(current,i,importantDate.datetime) } timeFormat={`HH:mm`}  value={moment(importantDate.dateTo)} onChange={(e) => onUpdateimportantDates(i,'dateTo', e)}  dateFormat="DD/MM/YYYY" />
                    </div>
                  </Form.Group>
                  
                </td>
                <td>
                  <Form.Control type="text" defaultValue={importantDate.name} onChange={(e) => onUpdateimportantDates(i,'name', e.target.value)} />
                </td>
                <td>
                  <Form.Control type="text" defaultValue={importantDate.description} onChange={(e) => onUpdateimportantDates(i,'description', e.target.value)} />
                </td>
                <td>
                  <Button variant="link" className="text-danger" onClick={() => onDeleteimportantDates(i)}>
                    <FontAwesomeIcon icon={faTrash} /> Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {!arraysEqual(league.importantDates, importantDates) && (
        <div className="mt-3">
          <Button type="button" variant="success" onClick={onSave} disabled={submitting}>
            {submitting ? "Saving" : "Save"}
          </Button>
        </div>
      )}
    </div>
    },
  ];

  return (
    <>
          <Nav variant="tabs" className="sub-nav" activeKey={activeTab} onSelect={(t) => setActiveTab(t)}>
            {TABS.map(({ name }) => (
              <Nav.Item key={name}>
                <Nav.Link eventKey={name} active={activeTab === name}>
                  {name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
          
          {TABS.map(({ name, content }) => name === activeTab && <div className='important-dates-content' key={name}>{content}</div>)}
          
      </>
     );
};

export default ImportantDates;
