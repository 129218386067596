import Axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useHistory,useLocation } from "react-router-dom";
import { formatData,} from "../../../../../../utils/functions";
import { Form } from "react-bootstrap";
import BracketsMap from "./BracketsMap";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { MdZoomOutMap } from "react-icons/md";
import { BsFullscreen, BsFullscreenExit } from "react-icons/bs";
import { AiOutlineZoomOut, AiOutlineZoomIn, AiOutlineFullscreen } from "react-icons/ai";
import Modal from "react-modal";
import './brackets.scss';

const Brackets = ({ id, duel }) => {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const modalFullScreen = query.get('open_fullscreen');
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [bracketsData, setBracketsData] = useState([]);
  const [selectedWinner, setSelectedWinner] = useState(BRACKETS.WINNING);
  const [selectedLoser, setSelectedLoser] = useState(BRACKETS.LOSING);
  const [hilighted, setHilighted] = useState("");

  useEffect(() => {
    
    if(modalFullScreen==="true"){
      enterFullScreen()
    }
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/tournaments/${id}/bracket_data`)
      .then(({ data }) => {
        setBracketsData(formatData(data, duel.options.short, true));
      })
      .finally(() => setLoading(false));
    // connectSocket(id, (data) => setBracketsData(formatData(data, duel?.options?.short, true)));
    // return () => disconnectSocket();
    
  }, []);

  const enterFullScreen = () => {
    setModal(!modal);
  }

  const openFullScreenInTab = ()=>{
    if(!modal){
      const urlLink = `/tournament/${id}/brackets?open_fullscreen=true`;
      const win = window.open(urlLink, "_blank");
      win.focus();
    }
    else{
      enterFullScreen();
    }
    
  }

  const handleMatchClick = (matchData)=>{
    return false;
  }

  const handleWinnersChange = (checked)=>{
     
      setSelectedWinner(checked);
    
  }

  const handleLosersChange = (checked)=>{
     
      setSelectedLoser(checked);
     
  }
  console.log("wineer lossr",selectedWinner,selectedLoser);
  const renderChildContent = (isFullScreen) => (
    <div className="w-100 h-100 brackets-wrapper" style={{ overflow:`${modal?'visible':'hidden'}`, border: isFullScreen ? undefined : "1px solid rgba(0,0,0,.125)", borderRadius: "5px" }}>
      <div className="w-100 row align-items-center justify-content-end p-2 m-0">
        {bracketsData[1] && bracketsData[1].length !== 0 ?
          <>
          <span className="bold text-grey uppercase font-13">Show:</span>
          <Form.Check
            type="checkbox"
            label="Winner's"
            onChange={(e) => handleWinnersChange(e.target.checked)}
            checked={selectedWinner}
            className="mr-2 ml-1"
            
          />

          <Form.Check
            type="checkbox"
            label="Loser's"
            value={selectedLoser}
            onChange={(e) => handleLosersChange(e.target.checked)}
            checked={selectedLoser}
            />
           
          </>
          : null}
      </div>
      <div className={`p-0 m-0 w-100 position-relative ${!modal ? "h-100":"" }`}>
        <TransformWrapper
          wheel={{
            wheelEnabled: false,
            step: 20
          }}
          zoomIn={{
            step: 5
          }}
          zoomOut={{
            step: 5
          }}
          options={{
            limitToBounds: false,
            minScale: 0.25,
          }}
          pan={{
            velocityEqualToMove: true,
            velocity: true,
            lockAxisY:true
          }}
        >
          {({ zoomIn, zoomOut, resetTransform }) => {
            return (
              <>
                <TransformComponent>
                  {selectedWinner && (
                    <BracketsMap
                      hilighted={hilighted}
                      setHilighted={setHilighted}
                      data={bracketsData[0].map((el, index) => ({ title: "Round " + (index + 1), seeds: el }))}
                      handleMatchClick={handleMatchClick}
                    />
                  )}
                  {selectedLoser && (
                    <BracketsMap
                      isShort={duel?.options?.short}
                      hilighted={hilighted}
                      setHilighted={setHilighted}
                      losingTrack={true}
                      data={bracketsData[1].map((el, index) => ({ title: "Round " + (index + 1), seeds: el }))}
                      handleMatchClick={handleMatchClick}
                    />
                  )}
                </TransformComponent>
                <div className="zoom-control-buttons">
                  {/* <button onClick={openFullScreenInTab} className="zoom-control-button">
                    {modal ?
                      <BsFullscreenExit color="#FFFFFF" />
                      :
                      <BsFullscreen color="#FFFFFF" />
                    }
                  </button> */}
                  <button onClick={resetTransform} className="zoom-control-button">
                    <MdZoomOutMap color="#FFFFFF" />
                  </button>
                  <button onClick={zoomIn} className="zoom-control-button">
                    <AiOutlineZoomIn color="#FFFFFF" />
                  </button>
                  <button onClick={zoomOut} className="zoom-control-button">
                    <AiOutlineZoomOut color="#FFFFFF" />
                  </button>
                </div>
              </>
            );
          }}
        </TransformWrapper>
      </div>
    </div >
  )

  return (
    <>
    <div className="brackets-class">
      {loading && !bracketsData.length !== 0 ? (
        <div className="w-100" style={{ textAlign: "center" }}>
          loading...
        </div>
      ) : bracketsData.length === 0 ? (
        <div className="col-12 p-5 text-grey text-center">Nothing to show here!</div>
      ) : renderChildContent()}
      {loading && !bracketsData.length !== 0 ?
        null
        :
        <Modal className="brackets-class bg-theme p-0 m-0" style={{ content: { width: "100%", height: "100%",overflowY:'scroll'} }} isOpen={modal} shouldCloseOnEsc onRequestClose={() => setModal(false)} >
          {renderChildContent(true)}
        </Modal>}
    </div>
    
    </>
  );
};

export default Brackets;

const BRACKETS = {
  WINNING: true,
  LOSING: false,
  BOTH: "BOTH",
};
