'use strict'
import React, { useState, useEffect } from "react";
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'

// added an onBlur function for save call on close
const GroupColorPicker = ({ mainColour, onUpdateColor, onBlur }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState({hex:mainColour.replace('#', '')});

  useEffect(() => {
    setColor({hex:mainColour.replace('#', '')});
  }, [mainColour]);

  const handleClick = () => {
    if(displayColorPicker && onBlur) onBlur();
    setDisplayColorPicker(state => !state);
  }

  const handleClose = () => {
    if(onBlur) onBlur();
    setDisplayColorPicker(state => false);
  }

  const handleChange = (colorss) => {
    setColor({ hex: colorss.hex.replace('#', '') } );
    const clr = onUpdateColor(colorss.hex)
  };

  const styles = reactCSS({
    'default': {
      color: {
        width: '40px',
        height: '30px',
        borderRadius: '2px',
        //   background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
        background: `#${color.hex}`,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });


  return (
  <div>
    <div style={styles.swatch} onClick={handleClick}>
      <div style={styles.color} />
    </div>
    {displayColorPicker ? <div style={styles.popover}>
      <div style={styles.cover} onClick={handleClose} />
      <SketchPicker color={color} onChange={handleChange} disableAlpha={true} />
    </div> : null}
  </div>)
}

// class GroupColorPicker extends React.Component {
//   state = {
//     displayColorPicker: false,
//     color: {
//         hex: this.props.mainColour.replace('#','')
//     },
//   };

//   handleClick = () => {
//     this.setState({ displayColorPicker: !this.state.displayColorPicker })
//   };

//   handleClose = () => {
//     this.setState({ displayColorPicker: false })
//   };

//   handleChange = (colorss) => {
//     this.setState({color:{hex: colorss.hex.replace('#','') }});
//     const clr = this.props.onUpdateColor(colorss.hex)
//     if(clr){
//       this.setState({color:{hex: clr.replace('#','') }});
//     }
//   };


//   render() {
//   console.log('mainCOlor: ',this.props.mainColour);


//     return (

//     )
//   }
// }

export default GroupColorPicker