import React, { useState, useEffect, useRef } from "react";
import { Modal, Form, Button, Dropdown, Card, Media } from "react-bootstrap";
import Axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const SwitchTeamModal = ({ show, onHide, currentTeam = {}, leagueTeams = [], game = null, league, setLeague }) => {
  const { id } = useParams();

  const timeout = useRef();
  const [teamsQuery, setTeamsQuery] = useState("");
  const [teamResults, setTeamResults] = useState([]);
  const [teamResultsLoading, setTeamResultsLoading] = useState(false);

  const [newTeam, setNewTeam] = useState(false);

  // alter team results on query change
  useEffect(() => {
    if (!teamsQuery.length) return setTeamResults([]);
    setTeamResultsLoading(true);

    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      Axios.post(`${process.env.REACT_APP_CORE_API}/api/teams/searchByGame`, { query: teamsQuery, game: game._id || null }).then(
        ({ data }) => {
          const withoutLeagueTeams = data?.filter(({ _id }) => !leagueTeams.map(({ team }) => team?._id).includes(_id));

          setTeamResults(withoutLeagueTeams);
          setTeamResultsLoading(false);
        }
      );
    }, 500);
  }, [teamsQuery, leagueTeams]);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      await Axios.post(`${process.env.REACT_APP_CORE_API}/api/leagues/${id}/matches/switchTeam`, {
        currentTeamId: currentTeam._id,
        newTeamId: newTeam,
      });

      toast.success("Successfully switched team! Refreshing data...");

      // A lot of shit changed. Refreshing the page is the easiest way to avoid glitches here
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (e) {
      toast.error("There was a problem switching team");
    }
  };

  const onSelectNewTeam = (team) => {
    setNewTeam(team);
    setTeamsQuery("");
    setTeamResults([]);
  };

  const addplaceholder = async () => {
    // console.log(currentTeam);
    try {
      const bodyData = {
        name: currentTeam.name,
        initials: currentTeam.initials,
        league: league._id,
        game: currentTeam.game,
        organisation: "61dc169b2a8c7f1cb4d65cd2",
        placeHolder: true,
        logo: currentTeam.logo,
      };
      const { data: dataSwitch } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/leagues/${id}/matches/placeHolderSwitchTeam`, {
        currentTeamId: currentTeam?._id,
        newTeam: bodyData,
      });

      setLeague((league) => ({
        ...league,

        teams: league.teams.map(({ team, ...restData }) =>
          team?._id === currentTeam?._id ? { ...restData, team: dataSwitch.team } : { team, ...restData }),

        matches: league.matches.map((match) => {
          if (match?.t1?._id === currentTeam?._id) {
            return { ...match, t1: dataSwitch.team }
          }
          if (match?.t1?._id === currentTeam?._id) {
            return { ...match, t2: dataSwitch.team }
          }
          return { ...match }
        })

      }));
      toast.success("Successfully switched team! Refreshing data...");
      onHide();
    } catch (e) {
      toast.error(e.response?.data?.msg || "There was a problem switching team");
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Switch Team</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-secondary">
          Switching {currentTeam.name} with another team will replace {currentTeam.name} in all matches within this league.
        </p>

        <Form onSubmit={onSubmit}>
          <Form.Group>
            <Form.Label>Current Team</Form.Label>
            <Form.Control disabled readOnly value={currentTeam.name} />
          </Form.Group>

          <Form.Group>
            <Form.Label>Replacement Team</Form.Label>
            <Form.Control autoFocus placeholder="Search for teams" onChange={(e) => setTeamsQuery(e.target.value)} value={teamsQuery} />
            {(teamResultsLoading || !!teamResults.length) && (
              <Card bg="light">
                <ul className="list-unstyled">
                  {teamResultsLoading ? (
                    <Dropdown.Item className="text-secondary">Loading...</Dropdown.Item>
                  ) : (
                    teamResults.map((team) => (
                      <Dropdown.Item key={team._id} onClick={() => onSelectNewTeam(team)}>
                        {team.name}
                      </Dropdown.Item>
                    ))
                  )}
                </ul>
              </Card>
            )}
          </Form.Group>

          {newTeam && (
            <Media className="mb-3">
              <img width={25} height={25} className="mr-3" src={newTeam.logo} alt="Generic placeholder" />
              <Media.Body>
                <span>{newTeam.name}</span>
              </Media.Body>
            </Media>
          )}

          <Button type="submit" size="lg" variant="success" disabled={!newTeam} block>
            Switch
          </Button>
        </Form>
        {currentTeam.placeHolder === false && (
          <Button type="submit" size="lg" variant="danger" className="mt-2" onClick={addplaceholder} block>
            Switch To PlaceHolder
          </Button>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SwitchTeamModal;
