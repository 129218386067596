import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import Axios from "axios";
import { toast } from "react-toastify";
import EditImageModal from "../../../../../_modals/EditImageModal/EditImageModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";


const General = ({ coach, setCoach }) => {
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const [modals, setModals] = useState({});

  const onUpdateImage = async (type, imageUrl) => {
    try {
      const { data } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/coaches/${coach._id}/image`, { type, image: imageUrl });
      setCoach((coach) => ({ ...coach, ...data.updatedImages }));
      setModals({ ...modals, [type]: false });

      toast.error("Coach Pictures Updated");
    } catch (err) {

      toast.error("There was a problem updating coach images!");
    }
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const { data: newData } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/coaches/${coach._id}`, { payload: data });
      setCoach({ ...coach, ...newData.updatedcoach })
      setLoading(true);

      toast.success("Details updated");
    } catch (e) {
      const msg = e.response.data ? e.response.data.msg : "Fatal error";
      setLoading(true);

      toast.error(msg);
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Coach Name</Form.Label>
              <Form.Control
                ref={register({ required: "Required" })}
                type="text"
                id="name"
                name="name"
                defaultValue={coach.name}
                isInvalid={errors.name}
              />
              <Form.Control.Feedback type="invalid">{errors.name && errors.name.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Form.Group>
          <Form.Label>About</Form.Label>
          <Form.Control
            ref={register({ maxLength: 5000 })}
            as="textarea"
            name="description"
            id="description"
            rows={5}
            placeholder="Write something about yourself"
            defaultValue={coach.description}
            isInvalid={errors.about}
          />
          <Form.Control.Feedback type="invalid">{errors.about && errors.about.message}</Form.Control.Feedback>
        </Form.Group>

        <>
          <div className="d-flex">
            {[
              { name: "Add Avatar", key: "avatarImage", width: 250, height: 250, displayWidth: 150, displayHeight: 150, crop: true },
              { name: "Add Banner", key: "headerImage", width: 800, height: 200, displayWidth: 500, displayHeight: 150, crop: false },
            ].map(({ name, key, width, height, displayWidth, displayHeight, crop }) => (
              <div key={key}>
                <EditImageModal
                  show={modals[key]}
                  onHide={() => setModals({ ...modals, [key]: false })}
                  name={name}
                  width={width}
                  height={height}
                  afterImageUpload={(file) => onUpdateImage(key, file)}
                  crop={crop}
                />

                <Form.Group className="mr-4">
                  <Form.Label>{name}</Form.Label>
                  <div
                    className="edit-image-btn"
                    style={{
                      backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${coach[key]})`,
                      width: displayWidth,
                      height: displayHeight,
                    }}
                    onClick={() => setModals({ ...modals, [key]: true })}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </div>
                </Form.Group>
              </div>
            ))}
          </div>
        </>

        <Button type="submit" variant="success" disabled={loading}>
          {loading ? "Saving..." : "Save Changes"}
        </Button>
      </Form>
    </div>
  );
};

export default General;
