import React, { useState } from "react";
import Reorder, { reorder } from "react-reorder";
import { Card, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripHorizontal } from "@fortawesome/free-solid-svg-icons";
import _ from 'lodash';
import AddTeamsModal from "../../TournamentMatches/TournmentTeams/modals/AddTeamsModal";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Axios from "axios";
import Brackets from '../../TournamentMatches/Tabs/Brackets/Brackets';
const ShuffleTeams = ({tournament}) => {
  const [teams, setTeamOrder ] = useState([]);
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/tournaments/${tournament._id}`).then(({ data }) => {
      setTeamOrder(data.teams)
      setLoading(false)
    });
  },[])

  const onReorder = (e, previousIndex, nextIndex, fromId, toId) => {
    setTeamOrder((os) => {
      let x = reorder(os, previousIndex, nextIndex);
      x = x.map((team, i) => ({...team, seedOrder: i+1 }));
      return x;
    });
  };

  const onShuffle = () => {
    let _order = [];
    let _teams = [];
    let order = 1;
    for (let team in teams){
      _order.push(order);
      order++;
    }

    _order = _.shuffle(_order);

    for(let i = 0; i < teams.length; i++){
      let team = teams[i];
      team.seedOrder = _order[i];
      _teams.push(team)
    }

    setTeamOrder(_teams)

  }

  const onSave = () => {
    Axios.put(`${process.env.REACT_APP_CORE_API}/api/tournaments/${tournament._id}`, { teams }).then(({data}) => {
      // setTeamOrder(data.teams)
      console.log(data)
      toast.success('Seed Order Updated Successfully !');
    })
  }

  if(loading){
    return (<p>Loading...</p>)
  }

  return (
    <>
    <div className="row">
      <div className="col-md-4">
        <div>
          {!AddTeamsModal.length && "No Teams"}
          <Modal.Title>Teams Seed Order</Modal.Title>
          <Reorder
            reorderId="teams" // Unique ID that is used internally to track this list (required)
            className="reorder-list"
            onReorder={onReorder}
            placeholder={
              <Card bg="light" body>
                &nbsp;
              </Card> // Custom placeholder element (optional), defaults to clone of dragged element
            }
          >
            {teams.sort((a,b) => (a.seedOrder > b.seedOrder) ? 1 : ((b.seedOrder > a.seedOrder) ? -1 : 0)).map((item) => (
              <Card key={item?.team?._id} body className="compact-card">
                <div className="d-flex justify-content-between align-items-center">
                  <span>{`${item?.seedOrder}. `}{item?.team?.name}</span>
                  <FontAwesomeIcon icon={faGripHorizontal} className="text-secondary" />
                </div>
              </Card>
            ))}
          </Reorder>

          <Button onClick={() => onShuffle()}>
              Shuffle
            </Button>
            <Button variant="success" className="mx-2" onClick={() => onSave()}>
              Save
            </Button>
          {tournament?.matches?.length ? 
            <>
              <div class="alert alert-warning mt-3" role="alert">
                After saving this you will need to re-generate the matches !!!
              </div>
            </>: null
          }
        </div>
      </div>
      <div className="col-md-8">
        <Modal.Title>Brackets</Modal.Title>
          <div>
            <Brackets id={tournament._id} {...tournament}/>
          </div>
      </div>
    </div>
    
    </>  
  );
};

export default ShuffleTeams;

