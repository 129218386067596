import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import TabsManager from "./Tabs/TabsManager";

const Entities = () => {
  return (
    <div>
      <div className="admin-page-title">
        <h2>Entities</h2>
      </div>
      <Row>
        <Col>
          <TabsManager />
        </Col>
      </Row>
    </div>
  )
}

export default Entities;
