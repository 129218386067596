import React, { useState, useEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const FilterMatches = ({ getScrimsData }) => {
  const [filterQuery, setFilterQuery] = useState("");
  // When query changes, filter scrims
  useEffect(() => {
    getScrimsData(filterQuery);
  }, [filterQuery]);

  return (
    <Form.Group>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text id="inputGroupPrepend">
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control type="search" placeholder="Filter Scrims" value={filterQuery} onChange={(e) => setFilterQuery(e.target.value)} />
      </InputGroup>
    </Form.Group>
  );
};

export default FilterMatches;
