import React, { useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import boostrapTheme from "@fullcalendar/bootstrap";
import { toast } from "react-toastify";
import Axios from "axios";
import {} from "react";

const Calendar = ({ matches, updateMatches, onEditMatch }) => {
  const syncNotification = useRef(null);

  const CALENDAR_EVENTS = matches.map((match) => {
    return {
      id: match._id,
      title: `${match.t1 ? match.t1.name : "[DELETED]"} vs ${match.t2 ? match.t2.name : "[DELETED]"}`,
      start: match.datetime,
      extendedProps: match,
    };
  });

  const onEventClick = ({ event }) => {
    onEditMatch(event.extendedProps);
  };

  const onEventChange = async ({ event }) => {
    updateMatches((matches) => matches.map((match) => (match._id === event.id ? { ...match, datetime: event.startStr } : match)));

    if (syncNotification.current) toast.dismiss(syncNotification.current);
    syncNotification.current = toast.info("Syncing matches...", { autoClose: false });

    try {
      await Axios.put(`${process.env.REACT_APP_CORE_API}/api/matches/${event.id}`, { datetime: event.startStr });

      toast.update(syncNotification.current, { render: "Sync complete", type: toast.TYPE.SUCCESS, autoClose: 2000 });
    } catch (e) {
      toast.update(syncNotification.current, { render: "Problem syncing matches", type: toast.TYPE.ERROR, autoClose: 2000 });
    }
  };

  return (
    <div>
      <FullCalendar
        contentHeight="auto"
        themeSystem="bootstrap"
        plugins={[boostrapTheme, dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        initialView="dayGridMonth"
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        weekends={true}
        events={CALENDAR_EVENTS}
        eventClick={onEventClick}
        eventChange={onEventChange}
      />
    </div>
  );
};

export default Calendar;
