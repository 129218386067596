import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { Card, Nav } from "react-bootstrap";
import General from "../UserTabsManager/Tabs/General"
import Permissions from "./Tabs/Permissions";
import { AppContext } from "./../../../../../context/AppContext";

const UserTabsManager = ({ userData, setUserData }) => {
  const { user } = useContext(AppContext);
  const [activeTab, setActiveTab] = useState("General");

  // ADD/REMOVE TABS THROUGH THIS ARRAY
  const TABS = [
    {
      name: "General",
      content: (
        <General user={userData} setUser={setUserData} />
      ),
    },
    {
      name: "Permissions",
      content: (
        <Permissions user={userData} setUser={setUserData} />
      ),
      disabled: !user.state.admin && !user.state.permissions.user.permissions ? "disabled-link" : ""
    },
  ];

  return (
    <>
      <Card>
        <Card.Header>
          <Nav variant="tabs" activeKey={activeTab} onSelect={(t) => setActiveTab(t)}>
            {TABS.map(({ name, disabled }) => (
              <Nav.Item key={name}>
                <Nav.Link eventKey={name} active={activeTab === name} className={disabled}>
                  {name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Card.Header>
        <Card.Body>
          {TABS.map(({ name, content }) => name === activeTab && <div key={name}>{content}</div>)}
        </Card.Body>
      </Card>
    </>
  );
};

export default UserTabsManager;
