import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { Navbar as BSNavbar, Nav, NavDropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../context/AppContext";
import { Link, useLocation, useHistory } from "react-router-dom";

const Navbar = () => {
  const { authenticated, user } = useContext(AppContext);
  const location = useLocation();
  const history = useHistory();

  const navLinks = [
    { name: "Dashboard", loc: "/" },
    { name: "Leagues and Tournaments", loc: "/games", disabled: !user.state.admin && !user.state.permissions?.user?.leaguesAndTournaments ? "disabled-link" : "" },
    { name: "Games", loc: "/game-settings" },
    { name: "Entities", loc: "/entities", disabled: !user.state.admin && !user.state.permissions?.user?.entityData ? "disabled-link" : "" },
    { name: "Scrims", loc: "/scrim-games", disabled: !user.state.admin && !user.state.permissions?.user?.scrims ? "disabled-link" : "" },
    { name: "Score Systems", loc: "/score-systems", disabled: !user.state.admin && !user.state.permissions?.user?.scoreSystem ? "disabled-link" : "" },
    { name: "Adverts", loc: "/adverts", disabled: !user.state.admin && !user.state.permissions?.user?.advertisements ? "disabled-link" : "" },
    { name: "Error Logs", loc: "/logs", disabled: !user.state.admin && !user.state.permissions?.user?.errorLogs ? "disabled-link" : "" },
    { name: "Roles", loc: "/roles", disabled: !user.state.admin && !user.state.permissions?.user?.roles ? "disabled-link" : "" },
    {name: "Translation", loc: "/translation", disabled: !user.state.admin && !user.state.permissions?.user?.translation ? "disabled-link" : "" }
  ];


  const logout = () => {
    localStorage.removeItem("sessionToken");
    history.push("/login");
  };

  return (
    <BSNavbar bg="dark" variant="dark" expand="lg" sticky="top">
      <BSNavbar.Brand as={Link} to="/">
        Leagues Admin
      </BSNavbar.Brand>
      <BSNavbar.Toggle aria-controls="basic-BSNavbar-nav" />

      {authenticated && (
        <BSNavbar.Collapse id="basic-BSNavbar-nav">
          <Nav className="mr-auto">
            {navLinks.map(({ name, loc, disabled }) => (
              <Nav.Link key={loc} as={Link} to={loc} active={location.pathname.split("/")[1] === loc.split("/")[1]} className={disabled}>
                {name}
              </Nav.Link>
            ))}
          </Nav>
          <Nav inline="true">
            <NavDropdown title={user.state.firstName || "Loading..."} alignRight>
              <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={<FontAwesomeIcon icon={faCog} />} alignRight>
              <NavDropdown.Item href="https://leagues.gg" target="_blank">
                Go to Leagues.gg &nbsp;
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </BSNavbar.Collapse>
      )
      }
    </BSNavbar >
  );
};

export default Navbar;
