import { findIndex } from "lodash";
import { SEEDING } from "../../../actionType";

const initialState = {
  stages: [],
  groups: [],
  teams: [],
  totalSeeds: 0,
  seeds: [],
  saveLoading: false,
  update_seeding: 0,
};

export const seedingReducer = (state = initialState, action) => {
  const new_state = { ...state };
  switch (action.type) {
    case SEEDING.RESET:
      new_state.groups = [];
      new_state.teams = [];
      new_state.seeds = [];
      new_state.stages = [];
      new_state.totalSeeds = 0;
      return new_state;

    case SEEDING.SET_SEEDS:
      // debugger
      new_state.seeds = action.payload;
      return new_state;

    case SEEDING.LOAD_SUCCES:
      let stageIndex = action.stageIndex;
      let total = 0;
      new_state.stages = action.payload;
      new_state.groups = action.payload[stageIndex].groups;
      // debugger
      let teamsArr = [];
      if (action.payload[stageIndex].stageType === "league") {
        new_state.teams = action.payload[stageIndex].league.teams;
        teamsArr = action.payload[stageIndex].league.teams;
      } else {
        new_state.teams = action.payload[stageIndex].tournament.teams;
        teamsArr = action.payload[stageIndex].tournament.teams;
      }
      action.payload[stageIndex].groups.map((item, index) => {
        total = total + item.noOfTeams;
      });
      new_state.totalSeeds = total;

      let temp = [];
      let _indexes = [];
      console.log("tesing ", action);
      teamsArr.map((item, index) => {
        // [...Array(action.payload[stageIndex].groups)].map((_obj, i) => {
        action.payload[stageIndex].groups.map((_obj, i) => {
          if (teamsArr[index] !== undefined) {
            // action.payload[stageIndex].groups.forEach((x, i) => {
            // console.log("testing", _obj, "ind", i);
            if (temp.findIndex((x) => x.team._id === teamsArr[index].team._id) < 0) {
              let existing = _obj.teams.filter((item) => item._id === teamsArr[index].team._id);
              if (existing.length > 0) {
                temp.push({ team: teamsArr[index].team, seed: existing.seedPosition });
                return;
              } else {
                temp.push({ team: teamsArr[index].team, seed: 0 });
                return;
              }
            }
            // });
          } else if (_indexes.findIndex((x) => x === index) < 0) _indexes.push(index);
        });
      });

      if (teamsArr.length > temp.length) {
        // debugger
        _indexes
          .filter((v, i, a) => a.indexOf(v) === i)
          .map((i, index) => {
            let fil = temp.filter((x) => x._id === teamsArr[i].team._id);
            if (fil <= 0) {
              temp.push(teamsArr[i].team);
            }
          });
      }
      new_state.teams = temp;

      return new_state;

    case SEEDING.ELIGIBLE:
      let index = action.stageIndex;
      new_state.stages[index] = { ...action.payload, ...new_state.stages[index] };
      new_state.groups = action.payload.groups;
      if (action.payload.stageType === "league") {
        new_state.teams = action.payload.league.teams;
      } else {
        new_state.teams = action.payload.tournament.teams;
      }
      return new_state;

    case SEEDING.SAVE_SUCCESS:
      new_state.stages = action.payload;
      return new_state;

    case SEEDING.ALL_SUCCESS:
      let seedingStages = [];
      new_state.stages = action.payload;
      let stages = action.payload;
      stages.forEach((stage, index) => {
        let totalseeds = 0;
        let maxTeams = 0;
        stage.leaderBoardName = 'Seeding (initial)';
        let stageTeams = [];
        if (index > 0) {
          let prevStage = { ...stages[index - 1] };
          stage.leaderBoardName = `Leaderboard (${prevStage.name})`;
          let tempTeams = [];
          let pervStageTeamsWrtGroups = [];
          let prevStageMaxTeams = 0;
          if (prevStage.stageType === "league") {
            tempTeams = prevStage.league.teams;
          } else {
            tempTeams = prevStage.tournament.teams;
          }
          prevStage.groups.forEach((group) => {
            // if (group.teams.length > prevStageMaxTeams) prevStageMaxTeams = group.teams.length;
            if (group.noOfTeams > prevStageMaxTeams) prevStageMaxTeams = group.noOfTeams;
          });
          let prevStageLeagueTeams = [...prevStage.league.teams];
          prevStage.groups = prevStage.groups.map((group, _i) => {
            let _group_teams_withScore = [];
            _group_teams_withScore = group.teams.map((team) => {
              let _team = prevStageLeagueTeams.find((x) => x.team && team.team && x.team._id === team.team._id);
              if (_team) {
                team.score = _team.score;
                team.order = _team.order;
                team.isComplete = group.matchCompleted;
                // team.isComplete = group.matchCompleted ?? _i % 2 === 0;
              }
              return { ...team };
            });
            if (group.overviewManualSort) {
              group.teamsWithScores = _group_teams_withScore.sort((a, b) => a.order - b.order);
            } else {
              group.teamsWithScores = _group_teams_withScore.sort((a, b) => b.score - a.score);
            }
            return { ...group };
          });
          Array.from(Array(prevStageMaxTeams)).forEach((item, index) => {
            prevStage.groups.forEach((group, _i) => {
              // group.teams.sort((a, b) => b.score - a.score);
              if (index < group.teams.length) {
                // let _team = { ...stage.league.autoFillTeams ? group.teamsWithScores[index] : group.teams[index] };
                let _team = { ...group.teamsWithScores[index] };
                _team.isComplete = _team.isComplete ?? group.matchCompleted;
                // _team.isComplete = group.matchCompleted ?? _i % 2 === 0;
                if (_team.isComplete) {
                  _team.seedLabel = `${_team?.team?.name} (${group.name} - Seed ${index + 1})`;
                } else {
                  _team.seedLabel = `TBD (${group.name} - Seed ${index + 1})`;
                }

                pervStageTeamsWrtGroups.push(_team);
              } else {
                let _team = { isComplete: false };
                _team.seedLabel = `TBD (${group.name} - Seed ${index + 1})`;
                pervStageTeamsWrtGroups.push(_team);
              }
            });
          });
          // console.log('pervStageTeamsWrtGroups: ALL_', pervStageTeamsWrtGroups)
          let currentStageTeams = [];
          let currentStageMaxTeams = 0;
          stage.groups.forEach((group) => {
            if (group.noOfTeams > currentStageMaxTeams) currentStageMaxTeams = group.noOfTeams;
          });
          let prevStageTeamIndex = 0;
          Array.from(Array(currentStageMaxTeams)).forEach((item, index) => {
            stage.groups.forEach((group, _i) => {
              let _grpTeams = group.teams.filter(team => team.team).map(team => team);
              if (!stage.league.autoFillTeams && _grpTeams) {
                // if (!stage.league.autoFillTeams && group.teams.length > 0) {
                if (index < group.teams.length) {
                  if (group.teams[index]) {
                    if (group.teams[index].team) {
                      let _team = { ...group.teams[index] };
                      let _prevGrpTeam = pervStageTeamsWrtGroups.find((x) => x.team && _team.team && x.team._id === _team.team._id);
                      let isComplete = false;
                      if (_prevGrpTeam) {
                        isComplete = _prevGrpTeam.isComplete;
                        _team.seedLabel = _prevGrpTeam.seedLabel;
                      } else {
                        let seedLabel = `${_team?.team?.name} (${group.name} - Seed ${index + 1})`;
                        _team.seedLabel = seedLabel;
                      }
                      currentStageTeams.push({ group, team: _team ?? "TBD", seedPosition: _team.seedPosition ?? index + 1, isComplete });
                    } else {
                      if (pervStageTeamsWrtGroups[prevStageTeamIndex] && pervStageTeamsWrtGroups[prevStageTeamIndex].team) {
                        // group.teams[index] = pervStageTeamsWrtGroups[prevStageTeamIndex];
                        let _team = pervStageTeamsWrtGroups[prevStageTeamIndex];
                        let isComplete = _team.isComplete;
                        // _team.seedLabel = `${_team?.team?.name} (${group.name} - Seed ${index + 1})`;
                        currentStageTeams.push({ group, team: _team ?? "TBD", seedPosition: index + 1, isComplete });
                      }
                    }
                    // group.teams[index] = pervStageTeamsWrtGroups[prevStageTeamIndex];
                  } else {
                    let _team = { seedLabel: "TBD" }
                    currentStageTeams.push({ group, team: _team, seedPosition: index + 1, isTbd: true, isComplete: false });
                  }
                  prevStageTeamIndex++;
                }
              } else {
                if (index < group.noOfTeams) {
                  if (pervStageTeamsWrtGroups[prevStageTeamIndex] && pervStageTeamsWrtGroups[prevStageTeamIndex].team) {
                    // group.teams[index] = pervStageTeamsWrtGroups[prevStageTeamIndex];
                    let _team = pervStageTeamsWrtGroups[prevStageTeamIndex];
                    let isComplete = _team.isComplete;
                    // _team.seedLabel = `${_team?.team?.name} (${group.name} - Seed ${index + 1})`;
                    currentStageTeams.push({ group, team: _team ?? "TBD", seedPosition: index + 1, isComplete });
                  } else {
                    let _team = pervStageTeamsWrtGroups[prevStageTeamIndex];
                    if (!_team) {
                      _team = { seedLabel: `TBD` };
                    }
                    currentStageTeams.push({ group, team: _team.seedLabel, seedPosition: index + 1, isTbd: true, isComplete: false });
                  }
                  prevStageTeamIndex++;
                }
              }
            });
          });
          if (stage.league.autoFillTeams === undefined || stage.league.autoFillTeams === true) {
            if (stage.league.autoFillTeams === undefined) {
              stage.league.autoFillTeams = true;
            }
            stageTeams = tempTeams.sort((a, b) => b.score - a.score);
          } else {
            stageTeams = tempTeams.sort((a, b) => a.order - b.order);
          }
          console.log("stageTeams", index, stageTeams);
          stage.league.teams = stageTeams;
          stage.seedings = currentStageTeams;
        } else {
          if (stage.stageType === "league") {
            new_state.teams = stage.league.teams;
            stageTeams = stage.league.teams;
          } else {
            new_state.teams = stage.tournament.teams;
            stageTeams = action.payload[stageIndex].tournament.teams;
          }
          let _groupsTeamsAddedLength = 0;
          stage.groups.map((item, index) => {
            totalseeds = totalseeds + item.noOfTeams;
            if (item.noOfTeams > maxTeams) {
              maxTeams = item.noOfTeams;
            }
            _groupsTeamsAddedLength += item.teams.length;
          });
          stage.totalSeeds = totalseeds;
          // ****************************************************** //
          // groupsTeams --> array of objects for seeding;
          let groupsTeams = [];
          // based on maxTeams length create an array of objects containing all the data related to
          // groups, teams, seed positions if available
          Array(maxTeams)
            .fill(0)
            .map((_, i) => {
              stage.groups.forEach((group) => {
                let _item = {
                  group: group,
                };
                if (i < group.noOfTeams) {
                  if (group.teams.length < group.noOfTeams) {
                    let add_pos = true;
                    group.teams.forEach((team, t_index) => {
                      if (team.seedPosition === i + 1) {
                        _item.team = team;
                        // console.log('_team: ', team)
                        // _item.team.seedLabel = team.team.name;
                        _item.seedPosition = team.seedPosition;
                        _item.isComplete = true;
                        add_pos = false;
                      }
                    });
                    if (add_pos) {
                      _item.seedPosition = i + 1;
                    }
                  } else {
                    if (group.teams[i] !== undefined && _groupsTeamsAddedLength <= stageTeams.length) {
                      if (index === 0) _item.team = group.teams[i];
                      else if (index > 0) _item.team = stageTeams[i];
                      _item.seedPosition = group.teams[i].seedPosition;
                      // console.log('_item.team: ', _item.team)
                      _item.team.seedLabel = _item.team.team.name;
                      _item.isComplete = true;
                    } else {
                      _item.seedPosition = i + 1;
                    }
                  }
                  groupsTeams.push(_item);
                }
              });
            });

          // check what is greater the groupsTeams or teams in the stage
          if (stageTeams.length >= groupsTeams.length && index <= 0) {
            stageTeams.forEach((_team, i) => {
              _team.seedLabel = _team?.team?.name;
              if (i < groupsTeams.length) {
                if (groupsTeams[i].team === undefined) {
                  groupsTeams[i].team = _team;
                  console.log('_item.team: w ', _team.team)
                  groupsTeams[i].team.seedLabel = _team.team.name;
                  groupsTeams[i].isComplete = true;
                }
              } else {
                groupsTeams.push({ team: _team, isComplete: true });
              }
            });
          } else {
            groupsTeams = groupsTeams.map((_team, index) => {
              if (index < stageTeams.length) {
                if (_team.team === undefined) {
                  _team.team = stageTeams[index];
                  _team.team.seedLabel = stageTeams[index].team.name;
                }
              } else {
                _team.team = "TBD";
                _team.isTbd = true;
              }
              _team.isComplete = true;
              return _team;
            });
          }
          stage.seedings = groupsTeams;
        }
        // ****************************************************** //
        stage.totalSeeds = totalseeds;
        seedingStages.push(stage);
      });
      new_state.seedingStages = seedingStages;
      return new_state;

    case SEEDING.UPDATE_STAGE_SEEDING:
      let _stageIndex = action.stageIndex;
      let _stage = new_state.seedingStages[_stageIndex];
      let _seedings = action.seedings;
      _stage.seedings = _seedings;
      new_state.seedingStages[_stageIndex] = _stage;
      new_state.update_seeding = new_state.update_seeding + 1;
      return new_state;

    case SEEDING.UPDATE_STAGE_SEEDING_RESET:
      // new_state.update_seeding = false;
      return new_state;

    case SEEDING.SET_SAVE_LOADING:
      new_state.saveLoading = action.payload;
      return new_state;

    case "SEEDING.TOGGLE_AUTO_FILL":
      // let initialAutoFill = new_state.stages[action.stageIndex].league.autoFillTeams;
      let _stgIndex = action.stageIndex;
      let _stg = new_state.seedingStages[_stgIndex];
      let _isAutoFill = action.isAutoFill;
      _stg.league.autoFillTeams = _isAutoFill;
      // new_state.seedingStages[_stageIndex] = _stage;

      let totalseeds = 0;
      let maxTeams = 0;
      // new_state.groups = action.payload[stageIndex].groups;
      console.log("index: ", _stgIndex, "stage: ", _stg);

      let stageTeams = [];
      let groupsTeams2 = [];

      if (_stgIndex > 0) {
        let prevStage2 = { ...new_state.seedingStages[_stgIndex - 1] };
        let prevStageTeams = [...prevStage2.league.teams]
        let pervStageTeamsWrtGroups = [];
        let prevStageMaxTeams = 0;

        prevStage2.groups.forEach((group) => {
          // if (group.teams.length > prevStageMaxTeams) prevStageMaxTeams = group.teams.length;
          if (group.noOfTeams > prevStageMaxTeams) prevStageMaxTeams = group.noOfTeams;
        });

        let prevStageLeagueTeams = [...prevStage2.league.teams];

        console.log("prevStageLeagueTeams: ", prevStageLeagueTeams);
        prevStage2.groups = prevStage2.groups.map((group, _i) => {
          let _group_teams_withScore = [];
          if (group.teams.length > 0) {
            _group_teams_withScore = group.teams.map((team) => {
              let _team = prevStageLeagueTeams.find((x) => x.team._id === team.team._id);
              if (_team) {
                team.score = _team.score;
                team.order = _team.order;
                team.isComplete = group.matchCompleted;
                // team.isComplete = group.matchCompleted ?? _i % 2 !== 0;
              }
              return { ...team };
            });
          } else {
            _group_teams_withScore = Array(group.noOfTeams).fill(0).map((team, _i) => {
              return {
                team: {
                  seedLabel: `TBD (${group.name} - ${_i + 1})`,
                },
                score: 0,
                order: 0,
                isComplete: false,
                isTbd: true,
              }
            })
          }
          if (group.overviewManualSort) {
            group.teamsWithScores = _group_teams_withScore.sort((a, b) => a.order - b.order);
          } else {
            group.teamsWithScores = _group_teams_withScore.sort((a, b) => b.score - a.score);
          }
          return { ...group };
        });
        console.log("prevStage2.groups: ", prevStage2.groups);

        Array.from(Array(prevStageMaxTeams)).forEach((item, index) => {
          prevStage2.groups.forEach((group, _i) => {
            if (index < group.teamsWithScores.length) {
              let _team = { ...group.teamsWithScores[index] };
              _team.isComplete = group.matchCompleted;
              // _team.isComplete = group.matchCompleted ?? _i % 2 !== 0;
              // _team.seedLabel = `${_team?.team?.name} (${group.name} - Seed ${index + 1})`;
              if (_team.isComplete) {
                _team.seedLabel = `${_team?.team?.name} (${group.name} - Seed ${index + 1})`;
              } else {
                _team.seedLabel = `TBD (${group.name} - Seed ${index + 1})`;
              }
              pervStageTeamsWrtGroups.push(_team);
            }
          });
        });

        let currentStageTeams = [];
        let currentStageMaxTeams = 0;

        _stg.groups.forEach((group) => {
          if (group.noOfTeams > currentStageMaxTeams) currentStageMaxTeams = group.noOfTeams;
        });

        let prevStageTeamIndex = 0;
        // console.log('pervStageTeamsWrtGroups: ', pervStageTeamsWrtGroups);
        Array.from(Array(currentStageMaxTeams)).forEach((item, index) => {
          _stg.groups.forEach((group, _i) => {
            if (!_isAutoFill && group.teams.length > 0) {
              if (index < group.teams.length) {
                if (group.teams[index]) {
                  let _team = { ...group.teams[index] };
                  let _prevGrpTeam = pervStageTeamsWrtGroups.find((x) => x.team?._id === _team?.team?._id);
                  let isComplete = false;
                  if (_prevGrpTeam) {
                    isComplete = _prevGrpTeam.isComplete;
                    _team.seedLabel = _prevGrpTeam.seedLabel;
                  } else {
                    let seedLabel = `${_team?.team?.name} (${group.name} - Seed ${index + 1})`;
                    _team.seedLabel = seedLabel;
                  }
                  // group.teams[index] = pervStageTeamsWrtGroups[prevStageTeamIndex];
                  // let teamSeedingPosition = !_isAutoFill ? _team.seedPosition : index + 1
                  currentStageTeams.push({ group, team: _team ?? "TBD", seedPosition: _team.seedPosition ?? index + 1, isComplete });
                } else {
                  let _prevGrpTeam = pervStageTeamsWrtGroups[prevStageTeamIndex];
                  let _team = { seedLabel: "TBD" }
                  if (_prevGrpTeam) {
                    _team = { ..._prevGrpTeam };
                  }
                  currentStageTeams.push({ group, team: _team, seedPosition: index + 1, isTbd: true, isComplete: false });
                }
                prevStageTeamIndex++;
              }
            } else {
              if (index < group.noOfTeams) {
                if (pervStageTeamsWrtGroups[prevStageTeamIndex] && pervStageTeamsWrtGroups[prevStageTeamIndex].team) {
                  let _team = { ...pervStageTeamsWrtGroups[prevStageTeamIndex] };
                  let isComplete = _team.isComplete;
                  // group.teams[index] = _team;
                  currentStageTeams.push({ group, team: _team, seedPosition: index + 1, isComplete });
                } else {
                  let _team = pervStageTeamsWrtGroups[prevStageTeamIndex];
                  if (!_team) {
                    _team = { seedLabel: `TBD` };
                  }
                  currentStageTeams.push({ group, team: _team, seedPosition: index + 1, isTbd: true, isComplete: false });
                }
                prevStageTeamIndex++;
              }
            }
          });
        });
        // if (_stg.league.autoFillTeams === undefined || _stg.league.autoFillTeams === true) {
        //   // stageTeams = tempTeams;
        //   console.log("autoFill: ", _stg.league.autoFillTeams);
        //   if (_stg.league.autoFillTeams === undefined) {
        //     _stg.league.autoFillTeams = true;
        //   }
        //   // console.log("stageTeams", stageTeams);
        //   // stageTeams = tempTeams.filter(team=>team.matches > 0).sort((a, b) => a.score - b.seedPosition);
        //   stageTeams = tempTeams.sort((a, b) => b.score - a.score);
        // } else {
        //   stageTeams = tempTeams.sort((a, b) => a.order - b.order);
        //   console.log("stageTeams on autoFill false", stageTeams);
        // }

        // console.log("stageTeams", _stgIndex, stageTeams);
        _stg.league.teams = currentStageTeams;
        _stg.seedings = currentStageTeams;
        new_state.seedingStages[_stgIndex] = _stg;
      } else {
        if (_stg.stageType === "league") {
          new_state.teams = _stg.league.teams;
          stageTeams = _stg.league.teams;
        } else {
          new_state.teams = _stg.tournament.teams;
          stageTeams = _stg.tournament.teams;
        }
        _stg.groups.forEach((item, index) => {
          totalseeds = totalseeds + item.noOfTeams;
          if (item.noOfTeams > maxTeams) {
            maxTeams = item.noOfTeams;
          }
        });
        _stg.totalSeeds = totalseeds;

        // based on maxTeams length create an array of objects containing all the data related to
        // groups, teams, seed positions if availabled
        Array(maxTeams)
          .fill(0)
          .map((_, i) => {
            _stg.groups.forEach((group) => {
              let _item = {
                group: group,
              };
              if (i < group.noOfTeams) {
                if (group.teams.length < group.noOfTeams) {
                  let add_pos = true;
                  group.teams.forEach((team, t_index) => {
                    if (team.seedPosition === i + 1) {
                      _item.team = team;
                      // console.log('_team: ', team)
                      _item.seedPosition = team.seedPosition;
                      _item.isComplete = true;
                      add_pos = false;
                    }
                  });
                  if (add_pos) {
                    _item.seedPosition = i + 1;
                  }
                } else {
                  if (group.teams[i] !== undefined) {
                    if (_stgIndex == 0) _item.team = group.teams[i];
                    else if (_stgIndex > 0) _item.team = stageTeams[i];
                    _item.seedPosition = group.teams[i].seedPosition;
                    // console.log('_item.team: ', _item)
                    if (_item?.team?.team?.name) {
                      _item.team.seedLabel = _item?.team?.team?.name;
                    } else {
                      _item.team.seedLabel = `TBD`;
                    }
                    _item.isComplete = true;
                  } else {
                    _item.seedPosition = i + 1;
                  }
                }
                groupsTeams2.push(_item);
              }
            });
          });

        // check what is greater the groupsTeams or teams in the stage
        if (stageTeams.length >= groupsTeams2.length && _stgIndex <= 0) {
          stageTeams.forEach((_team, i) => {
            if (i < groupsTeams2.length) {
              if (groupsTeams2[i].team === undefined) {
                groupsTeams2[i].team = _team;
                groupsTeams2[i].team.seedLabel = _team.team.name;
                groupsTeams2[i].isComplete = true;
              }
            } else {
              groupsTeams2.push({ team: _team, isComplete: true });
            }
          });
        } else {
          groupsTeams2 = groupsTeams2.map((_team, index) => {
            if (index < stageTeams.length) {
              if (_team.team === undefined) {
                _team.team = stageTeams[index];
                _team.team.seedLabel = stageTeams[index].team.name;
              }
            } else {
              _team.team = "TBD";
              _team.isTbd = true;
            }
            _team.isComplete = true;
            return _team;
          });
        }
        // if (_isAutoFill) {
        //   groupsTeams2 = groupsTeams2.sort((a, b) => a.team.seedPosition - b.team.seedPosition).map(item => item);
        // }
        _stg.seedings = groupsTeams2;
        new_state.seedingStages[_stgIndex] = _stg;
      }
      new_state.update_seeding = new_state.update_seeding + 1;

      return new_state;

    case "UPDATE_SEEDING_SUCCESS":
      // new_state.update_seeding = false;
      return new_state;

    case "SEEDING_SAVED_UPDATE_GROUPS":
      let _stgId = action.payload.stageId;
      let _dataGroups = action.payload.data;
      let _stgIdx = new_state.seedingStages.findIndex((item) => item._id === _stgId);
      if (_stgIdx >= 0) {
        let _selectedStg = { ...new_state.seedingStages[_stgIdx] };
        let _selectedStageGroups = [..._selectedStg.groups];
        _selectedStageGroups = _selectedStageGroups.map((group, index) => {
          let _group = { ...group };
          let _dGroup = _dataGroups.find(item => item._id === group._id);
          if (_dGroup) {
            _group.teams = _dGroup.teams;
          }
          return _group;
        })
        _selectedStg.groups = [..._selectedStageGroups];
        new_state.seedingStages[_stgIdx] = { ..._selectedStg };
      }
      return new_state;

    default:
      return state;
  }
};
