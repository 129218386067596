import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Form, Button, Row, Col } from "react-bootstrap";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ColorPicker from "../Tabs/GroupColorPicker";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.css";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import Axios from "axios";
import "./Stages.css";
import { set } from "lodash";

export const Stages = ({
  selectedStageIndex,
  setSelectedStageIndex,
  setIsAllStage
}) => {
  const { id } = useParams();
  const [render, rerender] = useState(false);
  // const [activeStageTab, setActiveStageTab] = useState(0);
  const [stageFormat, setStageFormat] = useState([]);
  // const [bracketType, setBracketType] = useState([]);
  const [deleteStageButtonDisabled, setDeleteStageButtonDisabled] = useState();
  const [stageButtonDisabled, setStageButtonDisabled] = useState(false);
  const [groupbuttonDisabled, setGroupButtonDisabled] = useState(false);
  const [allStages, setAllStages] = useState([]);
  const { register, handleSubmit, errors } = useForm({ mode: "all" });
  const [loading, setLoading] = useState(false);
  const [responseloader, setResponseLoading] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [groupColors, setGroupColors] = useState([
    "#19db60",
    "#d5901f",
    "#3eabf0",
    "#f11857",
    "#88d1ff",
    "#d14419",
    "#19d17c",
    "#e23ef0",
    "#a6f118",
    "#7e8fa8",
  ]);
  const [groupName, setGroupName] = useState([
    "Group A",
    "Group B",
    "Group C",
    "Group D",
    "Group E",
    "Group F",
    "Group G",
    "Group H",
    "Group I",
    "Group J",
  ]);
  const [matchesExist, setMatchesExist] = useState(false)
  const [stageMatches, setStageMatches] = useState(false)
  // const [hideLeague, setHideLeague] = useState(true);
  
  // Use Effect
  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/stage/format`)
      .then(({ data }) => {
        setStageFormat(data.data);
      })
      .catch((err) => {
        toast.error("There was a problem in getting stage formats");
      });
    // Axios.get(`${process.env.REACT_APP_CORE_API}/api/stage/bracketType`)
    //   .then(({ data }) => {
    //     setBracketType(data.data);
    //   })
    //   .catch((err) => {
    //     toast.error("There was a problem in getting stage bracket types");
    //   });
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/stage`)

      .then(({ data }) => {
        setAllStages(data.data);
        data.data.length === 1 && setDeleteStageButtonDisabled(true);
        if(selectedStageIndex === data.data.length) {
          setSelectedStageIndex(0);
          setIsAllStage(false);
        }
      })
      .catch((err) => {
        toast.error("There was a problem in getting stages");
      });
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/matches`)
      .then(({data}) => {
        setStageMatches(data.data)
        if(data?.data.allStageMatches[0]?.groups[0].matches?.length > 0){
        setMatchesExist(true)

        }
       })
       .catch((err) => {
         toast.error("There was a problem in getting matches");
       });
  }, []);
  //for checking if the matches exist
  const checkMatchesExist = (index) => {
    if(stageMatches){
      if(stageMatches?.allStageMatches[index]?.groups[0].matches?.length > 0){
    
        setMatchesExist(true)
      }else{
        setMatchesExist(false)
      }
    }
  }
  
   // Use Effect get stages
   useEffect(() => {
    checkMatchesExist(selectedStageIndex);
  }, [selectedStageIndex]);

  // Add Stage Tab
  const onAddStage = () => {
    if (allStages.length === 5) {
      toast.error("Can not add more then 5 stages");
    }
    if (allStages.length < 5) {
      setStageButtonDisabled(true);
      Axios.post(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/stage`, {
        multiStageLeague: id,
      })
        .then(({ data }) => {
          const allStagesCopy = [...allStages];
          allStagesCopy.push(data.data);
          setAllStages(allStagesCopy);
          allStagesCopy.length === 5 ? setStageButtonDisabled(true) : setStageButtonDisabled(false);
          setDeleteStageButtonDisabled(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.msg || "There was a problem in adding stage");
        });
    }
  };

  // Set Stage Name
  const onStageNamehange = (index, type, value) => {
    const allStagesCopy = [...allStages];
    allStagesCopy[index].name = value;
    setAllStages(allStagesCopy);
  };

  // Delete stage
  const onDeleteStage = (stageIndex) => {
    setDeleteLoader(true);
    Axios.delete(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/stage/${allStages[stageIndex]._id}`)
      .then(({ data }) => {
        const allStagesCopy = [...allStages];
        allStagesCopy.splice(stageIndex, 1);
        setAllStages(allStagesCopy);
        // setActiveStageTab(stageIndex - 1);
        setStageButtonDisabled(false);
        allStagesCopy?.length === 1 && setDeleteStageButtonDisabled(true);
        toast.success("Successfully Deleted Stage");
        // activeStageTab - 1 >= 0 ? setActiveStageTab(activeStageTab - 1) : setActiveStageTab(0);
        selectedStageIndex - 1 >= 0 ? setSelectedStageIndex(selectedStageIndex - 1) : setSelectedStageIndex(0);
        setDeleteLoader(false);
      })
      .catch((err) => {
        setDeleteLoader(false);
        toast.error(err?.response?.data?.msg || "There was a problem in deleting stage");
      });
  };

  // Format onChange
  const onFormatChange = (stageIndex, value) => {
    const allStagesCopy = [...allStages];
    allStagesCopy[stageIndex].format = parseInt(value);
    if (parseInt(value) === 1) {
      allStagesCopy[stageIndex].encounters = 1;
    } else {
      allStagesCopy[stageIndex].bracketType = 1;
    }
    setAllStages(allStagesCopy);
  };

  // Set Encounter
  const onEncounterChange = (index, type, value) => {
    const allStagesCopy = [...allStages];
    allStagesCopy[index].encounters = value;
    setAllStages(allStagesCopy);
  };

  const onBracketTypeChange = (stageIndex, value) => {
    const allStagesCopy = [...allStages];
    allStagesCopy[stageIndex].bracketType = parseInt(value);
    setAllStages(allStagesCopy);
  };

  // Add groups
  const onAddGroup = (stageIndex) => {
    const allStagesCopy = [...allStages];
    if (allStages[stageIndex].groups.length < 10) {
      setGroupButtonDisabled(true)
      allStagesCopy[stageIndex].groups.push({
        name: groupName[allStages[stageIndex].groups.length],
        noOfTeams: allStages[stageIndex].groups[allStages[stageIndex].groups.length - 1].noOfTeams,
        color: `${groupColors[allStages[stageIndex].groups.length]}`,
        stage: allStagesCopy[stageIndex]._id,
      });
      saveChanges(allStagesCopy, stageIndex);
    } 
  };

  // Update groups
  const onUpdategroups = (stageIndex, groupIndex, type, value) => {
    const allStagesCopy = [...allStages];
    if (type === "name") {
      allStagesCopy[stageIndex].groups[groupIndex].name = value;
    } else {
      allStagesCopy[stageIndex].groups[groupIndex].noOfTeams = value;
    }
    setAllStages(allStagesCopy);
  };

  // Update Group Colour
  const onUpdateColour = (clr, stageIndex, groupIndex) => {
    const allStagesCopy = [...allStages];
    const exists = allStagesCopy[stageIndex].groups.find((group, index) => index !== groupIndex && group.color === `${clr}`);
    if (exists) {
      allStagesCopy[stageIndex].groups[groupIndex].color = `${groupColors[groupIndex]}`;
      toast.error("Color already chosen by another group");
    } else {
      allStagesCopy[stageIndex].groups[groupIndex].color = `${clr}`;
    }
    setAllStages(allStagesCopy);
    // rerender(!render);
    return exists ? `${groupColors[groupIndex]}` : clr;
  };

  // Delete group
  const onDeletegroups = (stageIndex, groupIndex) => {
    // setResponseLoading(true);
    let allStagesCopy = [...allStages];

    if ("_id" in allStagesCopy[stageIndex].groups[groupIndex]) {
      Axios.delete(
        `${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/stage/${allStages[stageIndex]._id}/group/${allStages[stageIndex].groups[groupIndex]._id}`
      )
        .then(({ data }) => {
          allStagesCopy[stageIndex].groups = allStagesCopy[stageIndex].groups.filter((group, idx) => idx !== groupIndex);
          setAllStages(allStagesCopy);
          setResponseLoading(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.msg || "There was a problem in deleting group");
        });
    } else {
      allStagesCopy[stageIndex].groups.splice(groupIndex, 1);
      console.log('inside else')
    }

  };

  const thirdPlaceOrderChange = (stageIndex) => {
    const allStagesCopy = [...allStages];
    allStagesCopy[stageIndex].playThirdPlaceOrder = !allStagesCopy[stageIndex].playThirdPlaceOrder;
    setAllStages(allStagesCopy);
  };
  const setEvenGroupSize = (stageIndex) => {
    const allStagesCopy = [...allStages];
    allStagesCopy[stageIndex].evenGroupBySize = !allStagesCopy[stageIndex].evenGroupBySize;
    setAllStages(allStagesCopy);
  };
  const setGroupSize = (stageIndex, value) => {
    const allStagesCopy = [...allStages];
    allStagesCopy[stageIndex].groupSize = value;
    setAllStages(allStagesCopy);
  };

  // Update Stage
  const onSaveStage = (stageIndex) => {
    setLoading(true);
    const stageToBeUpdated = allStages[stageIndex];
    if (stageToBeUpdated.evenGroupBySize) {
      stageToBeUpdated.groups.forEach((group) => (group.noOfTeams = stageToBeUpdated.groupSize));
    }
    Axios.put(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/stage/${allStages[stageIndex]._id}`, stageToBeUpdated)
      .then(({ data }) => {
        toast.success("Saved");
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        // toast.error(err?.response?.data?.msg || "There was a problem in updating stage");
      });
  };

  // when adding group, send an API Call
  const saveChanges = (allStagesLocal, stageIndex) => {
    let stageToBeUpdated = allStagesLocal[stageIndex];
    if (stageToBeUpdated.evenGroupBySize) {
      stageToBeUpdated.groups.forEach((group) => (group.noOfTeams = stageToBeUpdated.groupSize));
    }
    Axios.put(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/stage/${stageToBeUpdated._id}`, stageToBeUpdated)
      .then(({ data }) => {
        const respData = data.data;
        if (respData) {
          const { groups } = respData;
          stageToBeUpdated.groups = groups;
          allStagesLocal[stageIndex] = stageToBeUpdated;
          setAllStages(allStagesLocal)
          setGroupButtonDisabled(false)
          toast.success("Saved");
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setGroupButtonDisabled(false)
        // toast.error(err?.response?.data?.msg || "There was a problem in updating stage");
      });
  }

  return (
    <div>
     <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(onSaveStage(selectedStageIndex));
        }}
        className="sub-tabs-form"
      >
        <div className="d-flex justify-content-end mb-0">
          <Button
            variant={`${allStages.length < 5 ? (!stageButtonDisabled ? "success" : "secondary") : "secondary"}`}
            size="sm"
            onClick={onAddStage}
            disabled={stageButtonDisabled}
          >
            <FontAwesomeIcon icon={faPlus} /> Add Stage
          </Button>
        </div>
        <Tabs
          id="controlled-tab-example"
          // defaultActiveKey={activeStageTab}
          activeKey={selectedStageIndex}
          onSelect={(e) => setSelectedStageIndex(parseInt(e))}
          className="mb-3 sub-tabs"
          mountOnEnter={false}
        >
          {responseloader
            ? "Loading..."
            : allStages.map((stage, i) => (
              <Tab eventKey={parseInt(i)} title={stage.name ? stage.name : " Stage 1"}>
                <Row>
                  <Col sm={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Stage name</Form.Label>
                      <Form.Control
                        ref={register()}
                        required
                        type="text"
                        id="setStage"
                        name="setStage"
                        minLength={5}
                        maxLength={30}
                        value={stage.name}
                        onChange={(e) => onStageNamehange(i, "text", e.target.value)}
                        // onBlur={() => onSaveStage(i)}
                      />
                      <Form.Control.Feedback type="invalid">{errors.setStage && errors.setStage.message}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col sm={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>Format</Form.Label>
                      <Form.Control
                        disabled={matchesExist}
                        as="select"
                        name="stageformat"
                        value={stage.format}
                        onChange={(e) => onFormatChange(i, e.target.value)}
                        onBlur={() => onSaveStage(i)}
                      >
                        {stageFormat.map((format) => (
                          <option value={format.typeNo}>{format.name}</option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  {stage.format === 1 ? (
                    <Col sm={3}>
                      <Form.Group>
                        <Form.Label>Encounters</Form.Label>
                        <Form.Control
                          disabled={matchesExist}
                          ref={register()}
                          type="number"
                          id="setEncounter"
                          name="setEncounter"
                          value={stage.encounters}
                          onChange={(e) => e.target.value > 0 && e.target.value <= 100 && onEncounterChange(i, "number", e.target.value)}
                          onBlur={() => onSaveStage(i)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.setPlayersDeadline && errors.setPlayersDeadline.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  ) : (
                    <Col sm={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Bracket type</Form.Label>
                        <Form.Control
                          disabled={matchesExist}
                          as="select"
                          name="brackettype"
                          value={stage.bracketType}
                          onChange={(e) => onBracketTypeChange(i, e.target.value)}
                        >
                          <option value={2}>Double Elimination</option>
                          <option value={3}>King of the Hill</option>
                          <option value={1}>Single Elimination</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  )}
                </Row>
                <Row style={{ marginTop: "0.5em", justifyContent: "space-between" }}>
                  <Col md={2}>
                    <Form.Group>
                      <Form.Check
                        disabled={matchesExist}
                        ref={register()}
                        type="checkbox"
                        name="checkbox"
                        // id="checkEvenGroupSize"
                        checked={stage.evenGroupBySize}
                        label="Even group sizes"
                        onChange={() => setEvenGroupSize(i)}
                      />
                    </Form.Group>
                  </Col>
                  {stage.format === 2 && parseInt(stage.bracketType) === 1 && (
                    <Col md={3}>
                      <Form.Group>
                        <Form.Check
                          disabled={matchesExist}
                          type="checkbox"
                          name="checkbox"
                          checked={stage.playThirdPlaceOrder}
                          label="Play 3rd place decider"
                          onChange={() => thirdPlaceOrderChange(i)}
                        />
                      </Form.Group>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Group Size</Form.Label>
                      <Form.Control
                        type="number"
                        name="GroupSize"
                        value={stage.groupSize}
                        // disabled={!stage.evenGroupBySize}
                        disabled={matchesExist ? matchesExist : !stage.evenGroupBySize}
                        onChange={(e) => e.target.value > 0 && e.target.value <= 1000 && setGroupSize(i, e.target.value)}
                        onBlur={() => onSaveStage(i)}
                      />
                      <Form.Control.Feedback type="invalid">{errors.setGroupSize && errors.setGroupSize.message}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="table-group-row">
                  <Col>
                    <div className="d-flex justify-content-end mb-2">
                      <Button
                        variant={`${stage.groups.length < 10 ? "success" : "secondary"}`}
                        size="sm"
                        onClick={() => onAddGroup(i)}
                        disabled={groupbuttonDisabled}
                      >
                        <FontAwesomeIcon icon={faPlus} /> Add Group
                      </Button>
                    </div>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Number</th>
                          <th>Name</th>
                          <th>Number of teams</th>
                          <th>Group color</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {stage.groups.map((group, groupIndex) => (
                          console.log({group,groupIndex}),
                          <tr key={groupIndex}>
                            <td>#{groupIndex + 1}</td>
                            <td>
                              <Form.Group className="mb-0 group-sizes">
                                <Form.Control
                                  required
                                  ref={register()}
                                  type="text"
                                  name="groups"
                                  value={group.name}
                                  // defaultValue={group.name}
                                  minLength={5}
                                  maxLength={20}
                                  onChange={(e) => onUpdategroups(i, groupIndex, "name", e.target.value)}
                                  // onBlur={() => onSaveStage(i)}
                                />
                              </Form.Group>
                            </td>
                            <td>
                              <Form.Group className="mb-0 group-sizes">
                                <Form.Control
                                  disabled={matchesExist ? matchesExist : stage.evenGroupBySize}
                                  ref={register()}
                                  type="number"
                                  name="groups"
                                  defaultValue={stage.evenGroupBySize ? stage.groupSize : group.noOfTeams}
                                  value={stage.evenGroupBySize ? stage.groupSize : group.noOfTeams}
                                  onChange={(e) =>
                                    e.target.value > 0 &&
                                    e.target.value <= 1000 &&
                                    onUpdategroups(i, groupIndex, "noOfTeams", e.target.value)
                                  }
                                  onBlur={() => onSaveStage(i)}
                                />
                              </Form.Group>
                            </td>
                            <td>
                              <ColorPicker
                                onLoad={register({ name: "mainColour" })}
                                mainColour={`${group.color ? group.color.replace("#", "") : groupColors[groupIndex]}`}
                                onUpdateColor={(clr) => onUpdateColour(clr, i, groupIndex)}
                                onBlur={() => onSaveStage(i)}
                              />
                            </td>
                            <td>
                              <Button
                                variant="link"
                                className="text-danger"
                                onClick={() => onDeletegroups(i, groupIndex)}
                                disabled={matchesExist ? matchesExist : allStages[i].groups.length === 1}
                              >
                                <FontAwesomeIcon icon={faTrash} /> Delete
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <Button
                    type="submit"
                    variant="success"
                  // onClick={() => onSaveStage(i)}
                  >
                    {loading ? "Saving..." : "Save"}
                  </Button>
                  <Button
                    type="button"
                    variant={`${allStages.length > 1 ? "danger" : "secondary"}`}
                    style={{ marginLeft: 10 }}
                    onClick={() => onDeleteStage(i)}
                    disabled={matchesExist ? matchesExist : deleteStageButtonDisabled}
                  >
                    {deleteLoader ? "Deleting..." : "Delete"}
                  </Button>
                </div>
              </Tab>
            ))}
        </Tabs>
      </Form>
    </div>
  );
};
export default Stages;
