import React from "react";
import { Switch } from "react-router-dom";
import { AuthRoute } from "../../../utils/CustomRouteTypes";
import TournamentDashboard from "./TournamentMatches/TournamentDashboard";
import GenerateMatches from "./TournamentMatches/GenerateMatches/GenerateMatches";

const GamesRouter = () => {
  return (
    <Switch>
      <AuthRoute path="/games/tournaments/:id/matches">
        <TournamentDashboard />
      </AuthRoute>
      <AuthRoute path="/games/tournaments/:id/generateMatches">
        <GenerateMatches />
      </AuthRoute>
    </Switch>
  );
};

export default GamesRouter;
