import React, { useState, useEffect } from "react";
import { Table, Form, Button } from "react-bootstrap";
import Axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";

const TableOverview = ({ teams = [], setLeague }) => {
  const { id: leagueId } = useParams();
  const [saving, setSaving] = useState(false);
  const [newTeams, setNewTeams] = useState([]);

  // Set local newTeams array when teams prop changes
  useEffect(() => {
    setNewTeams(teams);
  }, [teams]);

  const onUpdateTeam = (atIndex, key, value) => {
    value = parseFloat(value || 0);

    setNewTeams((teams) => {
      return teams.map((team, i) => (i === atIndex ? { ...team, [key]: value } : team));
    });
  };

  const onSave = async () => {
    setSaving(true);
    const postData = newTeams.map((team) => ({ ...team, team: team.team._id })); // need to cast populated team back to just id

    // update league with new teams array
    try {
      const { data } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/tournaments/${leagueId}`, { teams: postData });

      setLeague((league) => ({ ...league, teams: data.updatedLeague.teams }));
      toast.success("Saved");
    } catch (e) {
      toast.error("There was a problem saving");
    }

    setSaving(false);
  };

  const onRecalculateTableOverview = async () => {
    if (window.confirm("Are you sure you want to do this?")) {
      try {
        const { data } = await Axios.post(`${process.env.REACT_APP_LOL_API}/api/tournaments/${leagueId}/recalculateScoreboard`);

        setLeague((league) => ({ ...league, teams: data.updatedLeague.teams }));
        setNewTeams(data.updatedLeague.teams);
        toast.success("Successfully recalculated scores! ✅");
      } catch (e) {
        return toast.error("There was a problem recalculating scoreboard");
      }
    }
  };

  return (
    <div>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Team Name</th>
            <th>Score</th>
            <th>Matches</th>
            <th>Maps Won</th>
            <th>Maps Lost</th>
          </tr>
        </thead>
        <tbody>
          {newTeams.map(({ team, score, matches, mapsWon, mapsLost }, i) => (
            <tr key={i}>
              <td>{team.name}</td>
              <td>
                <Form.Control
                  type="number"
                  min={0}
                  value={score || 0}
                  onChange={(e) => onUpdateTeam(i, "score", e.target.value)}
                  style={{ maxWidth: 150 }}
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  min={0}
                  value={matches || 0}
                  onChange={(e) => onUpdateTeam(i, "matches", e.target.value)}
                  style={{ maxWidth: 150 }}
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  min={0}
                  value={mapsWon || 0}
                  onChange={(e) => onUpdateTeam(i, "mapsWon", e.target.value)}
                  style={{ maxWidth: 150 }}
                />
              </td>
              <td>
                <Form.Control
                  type="number"
                  min={0}
                  value={mapsLost || 0}
                  onChange={(e) => onUpdateTeam(i, "mapsLost", e.target.value)}
                  style={{ maxWidth: 150 }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="d-flex justify-content-between">
        <Button variant="success" onClick={onSave} disabled={saving}>
          {saving ? "Saving..." : "Save Changes"}
        </Button>

        <Button variant="secondary" onClick={onRecalculateTableOverview}>
          <FontAwesomeIcon icon={faRedoAlt} /> Recalculate Table Overview
        </Button>
      </div>
    </div>
  );
};

export default TableOverview;
