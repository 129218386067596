export const hilightTracks = (hovered, data) => {
    let possibleMatches = [];
    for (let i = hovered.roundIndex; i > 0; i--) {
        if (i === hovered.roundIndex && hovered.place === "upper") {
            if (data[i].seeds.length !== data[i - 1].seeds.length) possibleMatches.push(hovered.matchIndex * 2);
            else possibleMatches.push(hovered.matchIndex);
        }
        else if (i === hovered.roundIndex && hovered.place === "lower") {
            if (data[i].seeds.length !== data[i - 1].seeds.length) possibleMatches.push((hovered.matchIndex * 2) + 1);
            else possibleMatches.push(hovered.matchIndex);
        }
        else {
            const _possibleMatches = [];
            possibleMatches.forEach(matchIndex => {
                if (data[i].seeds.length !== data[i - 1].seeds.length) _possibleMatches.push((matchIndex * 2), (matchIndex * 2) + 1);
                else _possibleMatches.push(matchIndex);
            });
            possibleMatches.splice(0, possibleMatches.length);
            possibleMatches = _possibleMatches;
        }
        possibleMatches.forEach(matchIndex => {
            if (data[i - 1].seeds[matchIndex]) data[i - 1].seeds[matchIndex].forceHilight = true;
        });
    }
}

export const unhilightTracks = (data) => {
    data.forEach(round => round.seeds.forEach(match => match ? match.forceHilight = false : null));
}