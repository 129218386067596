import React, { useState, useEffect } from "react";
import { Table, Form, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import { toast } from "react-toastify";

const Coaches = ({ coachTypes = [], data: gameData = {}, setGame }) => {
  const [coaches, setCoaches] = useState([]);

  useEffect(() => {
    if (!coachTypes.length) setCoaches([{ name: "No Specification" }]);
    else setCoaches(coachTypes);
  }, [coachTypes]);

  const onAddCoach = () => {
    setCoaches((coaches) => [...coaches, { name: "Coach" }]);
  };

  const onUpdateRow = (index, key, value) => {
    setCoaches((coaches) => coaches.map((coach, i) => (index === i ? { ...coach, [key]: value } : coach)));
  };

  const onRemoveCoach = (index) => {
    if (coaches.length > 1) {
      setCoaches((coaches) => coaches.filter((_, i) => i !== index));
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/games/${gameData._id}/config`,
        { ...gameData.configuration, coachTypes: coaches }
      );
      data && data.updatedGame && setGame(data.updatedGame);
      toast.success("Successful");
    } catch (e) {
      toast.error("Could not update coach types");
    }
  };

  return (
    <>
      <Form onSubmit={onSubmit}>
        <div className="d-flex justify-content-end mb-2">
          <Button size="sm" variant="link" onClick={onAddCoach} className="p-0">
            <FontAwesomeIcon icon={faPlus} /> Add Coach Type
          </Button>
        </div>

        <Table striped bordered>
          <thead>
            <tr>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {coaches.length && coaches.map(({ name }, i) => (
              <tr key={i}>
                <td className="d-flex justify-content-between">
                  <Form.Control type="text" value={name} onChange={(e) => onUpdateRow(i, "name", e.target.value)} style={{ width: 900 }} disabled={name === "No Specification" ? true : false} />
                  {coaches.length > 1 && name !== "No Specification" && (
                    <Button variant="link" className="text-danger" onClick={() => onRemoveCoach(i)}>
                      <FontAwesomeIcon icon={faTrashAlt} /> Delete
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>

        </Table>
        <Button type="submit" size="lg" block variant="success">
          Save Coach Types
        </Button>
      </Form>
    </>
  );
};

export default Coaches;
