import React, { useState, useEffect, createRef } from "react";
import Axios from "axios";
import { Modal, Form, Button, Tooltip, OverlayTrigger } from "react-bootstrap";

const SingleSegmentModule = ({updateComponent,modalData,segData}) => {
    
   const [gameData,setGameData]=useState(modalData.pageData && modalData.pageData.segmentData || {});
   const [games,setGames] = useState([]);
    const update = (key,value) => {
        let data = {...gameData,[key]:value};
        setGameData(data)
        updateComponent(data)
    }

    useEffect(() => {
        Axios.get(`${process.env.REACT_APP_CORE_API}/api/games`).then(({ data }) => {
          setGames(() => {
            return data.filter((game) => game.name !==  'No Game');
          })
         
        });
      }, []);

    return (
    <>
        <small>Select upto three games that you want to show.</small>
       <Form>
       <Form.Group>
            <Form.Label>Game 1</Form.Label>
            <div className="d-flex align-items-center">
                <Form.Control
                    as="select"
                    id='game_1'
                    name='game_1'
                    value={gameData ? gameData.game_1 : null}
                    onChange={(e) => update('game_1',e.target.value)}
                >
                    <option value="">Select Game</option>
                    {games.map((code) => (
                        <option value={code._id}>{code.name}</option>
                        ))}
                </Form.Control>
            </div></Form.Group>

            <Form.Group>
            <Form.Label>Game 2</Form.Label>
            <div className="d-flex align-items-center">
                <Form.Control
                    as="select"
                    id='game_2'
                    name='game_2'
                    value={gameData ? gameData.game_2 : null}
                    onChange={(e) => update('game_2',e.target.value)}
                >
                    <option value="">Select Game</option>
                        {games.map((code) => (
                        <option value={code._id}>{code.name}</option>
                        ))}
                </Form.Control>
            </div></Form.Group>

<Form.Group>
            <Form.Label>Game 3</Form.Label>
            <div className="d-flex align-items-center">
                <Form.Control
                    as="select"
                    id='game_3'
                    name='game_3'
                    value={gameData ? gameData.game_3 : null}
                    onChange={(e) => update('game_3',e.target.value)}
                >
                    <option value="">Select Game</option>
                    {games.map((code) => (
                        <option value={code._id}>{code.name}</option>
                        ))}
                </Form.Control>
            </div></Form.Group>


            

              <Form.Group>
                <Form.Check
                  type="checkbox"
                  id="needawheel"
                  label="Games wheel"
                  defaultChecked={gameData ? gameData.needAWheel : false}
                  onChange={(e) => update("needAWheel",e.target.checked)}
                />
              </Form.Group>
              
            </Form>
    </>)
}

export default SingleSegmentModule;

