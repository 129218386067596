import React, { useState, useCallback, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Nav, Card, Form, Row, Col, Button } from "react-bootstrap";
import { faGripHorizontal } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import "bootstrap/dist/css/bootstrap.css";
import "./MultiStageSeeding.css";
import "bootstrap/dist/css/bootstrap.css";
import Group from "./Group";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { getSeedingData, resetSeedingUpdate, setStageLeaderboardData } from "../action";
import { useDispatch, useSelector } from "react-redux";
import { SEEDING } from "../../../../actionType";
import store from "../../../../../store/index";
import { fetchSeedingDetailsAutoSaveOnChange, fetchSeedingDetailsWithSave } from "../Utils";
import SelectCustom from "./SelectCustom";

export const MultiStageSeeding = (props) => {
  const { selectedStageIndex: key, setSelectedStageIndex, setIsAllStage } = props;
  const [keyVal, setKeyVal] = useState(0);
  const { register, handleSubmit, errors } = useForm({ mode: "all" });
  const [subTabs, setSubTabs] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [isAutofill, setAutofill] = useState(false);
  const [firstRender, setFirstRender] = useState(true);
  const dragItem = useRef();
  const dragOverItem = useRef();
  const [seeds, setSeeds] = useState([]);
  const stages = useSelector((state) => state.seeding.stages);
  const groups = useSelector((state) => state.seeding.groups);
  const teams = useSelector((state) => state.seeding.teams);
  const totalSeeds = useSelector((state) => state.seeding.totalSeeds);
  const seedingStages = useSelector((state) => state.seeding.seedingStages);
  const updateSeeding = useSelector((state) => state.seeding.update_seeding);
  const saveLoading = useSelector((state) => state.seeding.saveLoading);
  // const selectedGroupsTeam = seedingStages && seedingStages[key] !== undefined ? seedingStages[key].seedings : [];
  const [selectedGroupsTeam, setSelectedGroupsTeam] = useState([]);
  const [dropDownValues, setDropdownValues] = useState([]);

  const dispatch = useDispatch();

  // debugger
  const { id } = useParams();
  localStorage.setItem("_id", id);

  const dragStart = (e, position) => {
    if (Number(key) === 0) {
      dragItem.current = position;
    }
  };

  useEffect(() => {
    if (seedingStages) {
      if (seedingStages[key] !== undefined) {
        setSelectedGroupsTeam(seedingStages[key]?.seedings);
        setSubTab(seedingStages[key].groups, seedingStages[key]?.seedings);

        let _seedings = seedingStages[key].seedings;
        _seedings = _seedings
          .filter((item) => item.group !== undefined)
          .map((item) => item)
          .sort((a, b) => a.seedPosition - b.seedPosition);
        setDropdownValues(_seedings);
      }
    }
  }, [seedingStages, updateSeeding, key]);

  const dragEnter = (e, position) => {
    if (Number(key) === 0) {
      dragOverItem.current = position;
    }
  };

  const dropNew = (e) => {
    if (Number(key) === 0) {
      let _teamsArray = [...selectedGroupsTeam];
      let dragItemContent = _teamsArray[dragItem.current];
      const dragItemIndex = dragItem.current;

      let dOverItem = _teamsArray[dragOverItem.current];
      const dragOverItemIndex = dragOverItem.current;

      let temp = { ...dOverItem };
      dOverItem.team = dragItemContent.team;
      dragItemContent.team = temp.team;
      _teamsArray[dragItemIndex] = dragItemContent;
      _teamsArray[dragOverItemIndex] = dOverItem;
      store.dispatch({
        type: SEEDING.UPDATE_STAGE_SEEDING,
        stageIndex: key,
        seedings: _teamsArray,
      });
      fetchSeedingDetailsAutoSaveOnChange(seedingStages[key]._id, seedingStages[key].league.autoFillTeams, _teamsArray);
      resetSeedingUpdate();
    }
  };

  const setSubTab = (groups, _seeds, reset) => {
    if (groups !== undefined) {
      let _seeding = reset ? [] : _seeds ? [..._seeds] : [];
      _seeding = _seeding?.length > 0 ? _seeding.filter((x) => x.stage_id === seedingStages[key]._id) : [];
      const _subtabs = groups.map((item, index) => ({
        key: index + 1,
        name: item.name,
        content: (
          <Group
            totalSeeds={totalSeeds}
            groupId={item._id}
            allGroup={activeTab === 0 ? true : false}
            groups={seedingStages[key].groups}
            seeds={_seeding}
            teams={seedingStages[key].stageType === "league" ? seedingStages[key].league.teams : seedingStages[key].tournament.teams}
            stageSeedings={selectedGroupsTeam}
            updateSeeding={updateSeeding}
          />
        ),
      }));
      setSubTabs(_subtabs);
      // debugger
    }
  };

  const changeTeamHandler = (value, itemId) => {
    const current = value;
    const groupName = current.split("-")[0].trim();
    const seed = parseInt(current.split("-")[1].trim().split(" ")[1]);
    const teamId = itemId.split("-")[1];

    // console.log("selectedGroupsTeam", [...selectedGroupsTeam]);
    // getting the source index
    let sourceIndex = -1;
    if (teamId.startsWith("TBD") || teamId.includes("#+#")) {
      let _srcGrp = teamId.split("#+#")[1];
      let _srcSp = teamId.split("#+#")[2];
      sourceIndex = selectedGroupsTeam.findIndex((x) => x?.group?.name == _srcGrp && x?.seedPosition == _srcSp);
    } else {
      sourceIndex = selectedGroupsTeam.findIndex((x) => x?.team && !x?.isTbd && x?.team?.team?._id == teamId);
    }
    let sourceObject = { ...selectedGroupsTeam[sourceIndex] };

    //  get destination index
    let destinationIndex = selectedGroupsTeam.findIndex((x) => x.group && x.group.name === groupName && x.seedPosition === seed);
    let destinationObject = { ...selectedGroupsTeam[destinationIndex] };

    // swap the objects
    let tempGroup = { ...sourceObject.group };
    let tempSP = sourceObject.seedPosition;

    // assign destination to source
    sourceObject.group = { ...destinationObject.group };
    sourceObject.seedPosition = destinationObject.seedPosition;
    // assign source to destination
    destinationObject.group = { ...tempGroup };
    destinationObject.seedPosition = tempSP;

    // updating seedings
    let _seedings = [...selectedGroupsTeam];
    _seedings[sourceIndex] = sourceObject;
    _seedings[destinationIndex] = destinationObject;

    // console.log("selectedGroupsTeam _seedings", [..._seedings]);
    // dispatching the action
    store.dispatch({
      type: SEEDING.UPDATE_STAGE_SEEDING,
      stageIndex: key,
      seedings: _seedings,
    });
    fetchSeedingDetailsAutoSaveOnChange(seedingStages[key]._id, seedingStages[key].league.autoFillTeams, _seedings);
    resetSeedingUpdate();
  };

  useEffect(() => {
    getSeedingData(id);
    return () => {
      store.dispatch({
        type: SEEDING.RESET,
      });
    };
  }, []);

  useEffect(() => {
    if (stages?.length > 0) {
      // let _sds = seeds.filter((x) => x.stage_id === stages[key]._id);
      setSubTab(seedingStages[key].groups, seedingStages[key].seedings);
    }
  }, [activeTab]);

  useEffect(() => {
    if (seedingStages?.length > 0) {
      if (key > 0) {
        if (seedingStages[key]) {
          let _team = [];
          if (seedingStages[key].stageType === "league") {
            _team = seedingStages[key]?.league?.teams;
          } else {
            _team = seedingStages[key]?.tournament?.teams;
          }
          if (_team?.length <= 0) {
            setStageLeaderboardData(id, seedingStages[key]._id, key);
          }
        }
      }
      setActiveTab(0);
      if (seedingStages[key] && seedingStages[key]._id) {
        localStorage.setItem("_stageId", seedingStages[key]._id);
      }
      if (seedingStages[key] && seedingStages[key].groups) {
        localStorage.setItem("_groups", JSON.stringify(seedingStages[key]?.groups.length > 0 ? seedingStages[key].groups : []));
      }
    }
  }, [key]);

  const resetAll = () => {
    setSeeds([]);

    store.dispatch({
      type: SEEDING.LOAD_SUCCES,
      payload: stages,
      stageIndex: key,
    });
  };

  useEffect(() => {
    if (stages?.length > 0 && isAutofill) {
      resetAll();
    }
  }, [isAutofill]);

  useEffect(() => {
    if (stages?.length > 0) {
      if (stages.length >= key) {
        // setSelectedStageIndex(0)
        setIsAllStage(false);
      }
      localStorage.setItem("_stageId", stages[key]?._id);
      localStorage.setItem("_groups", JSON.stringify(stages[key]?.groups?.length > 0 ? stages[key]?.groups : []));
    }
  }, [groups, teams, stages]);

  useEffect(() => {
    // console.log("useEffect[seedingStages,key]", key, seedingStages);
    if (
      seedingStages &&
      seedingStages.length > 0 &&
      seedingStages[key] !== undefined &&
      seedingStages[key].league.autoFillTeams !== undefined
    ) {
      let leagueId = seedingStages[key].league._id;
      let autoFillTeams = seedingStages[key].league.autoFillTeams;
      // console.log("useEffect[autoFillTeams]", autoFillTeams);
      setAutofill(!!autoFillTeams);
    }
  }, [seedingStages, key]);

  // useEffect(() => {
  //   if (stages?.length > 0) {
  //     let _obj = checkGroupsMapping(true);
  //     console.log(_obj);
  //     // debugger
  //     setSeeds(_obj);
  //     setSubTab(
  //       stages[key]?.groups,
  //       _obj.filter((x) => x.stage_id === stages[key]._id)
  //     );
  //   }
  // }, [selectedGroupsTeam]);

  // debugger
  return (
    <Wrapper>
      {/* <>{JSON.stringify(seedingStages, null, 2)}</> */}
      <Form className="sub-tabs-form-seeding">
        {stages !== undefined && stages.length > 0 ? (
          <Tabs
            id="controlled-tab-example"
            // defaultActiveKey={key}
            activeKey={Number(key)}
            className="mb-3 sub-tabs"
            mountOnEnter={false}
            onSelect={(t) => setSelectedStageIndex(t)}
          >
            {stages.map((stage, index) => (
              <Tab eventKey={index} title={stage.name}>
                <Row>
                  <Col md={2}>
                    <Form.Group>
                      <Form.Check
                        ref={register()}
                        type="checkbox"
                        checked={isAutofill}
                        name="checkbox"
                        label="Autofill groups"
                        onChange={() => {
                          dispatch({
                            type: "SEEDING.TOGGLE_AUTO_FILL",
                            stageIndex: key,
                            isAutoFill: !isAutofill,
                          });
                          setAutofill(!isAutofill);
                          setTimeout(() => {
                            dispatch({ type: "UPDATE_SEEDING_SUCCESS" });
                          }, 1000);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="table-group-row">
                  <Col lg={6}>
                    {/* <h6>{key > 0 ? `Ranking (${stage.name})` : "Seeding (initial)"}</h6> */}
                    <h6>{stage.leaderBoardName}</h6>
                    <Row className={`row-seeding ${stage._id}`} key={index}>
                      {selectedGroupsTeam
                        // .filter((x) => x.stage_id === stage._id)
                        .map((x, i) => {
                          let isTBD = x.team === "TBD" || x.isTbd;
                          let _team = isTBD ? x.team : x.team && x.team.team ? x.team.team : "TBD";
                          let _teamId = x.team && x.team.team ? x.team?.team?._id : null;
                          let _teamName = isTBD ? _team : _team && _team.name ? _team.name : "TBD";
                          let _teamLabel = isTBD
                            ? _team.seedLabel
                              ? _team.seedLabel
                              : _team
                            : x.team && x.team.seedLabel
                            ? x.team.seedLabel
                            : _team
                            ? _team
                            : "TBD";
                          let isGroup = x.group !== undefined;
                          let _group = isGroup ? x.group : "";
                          let _groupId = isGroup ? _group._id : "";
                          let _groupName = isGroup ? _group.name : "";
                          let _seedPosition = x?.seedPosition;
                          let dropdownValueObject = dropDownValues.find(
                            (v) => _groupId === v.group._id && x.seedPosition === v.seedPosition
                          );
                          let dropdownValue = "NO SPOT";
                          if (dropdownValueObject && i <= dropDownValues.length) {
                            dropdownValue = `${dropdownValueObject.group.name} - Seed ${dropdownValueObject.seedPosition}`;
                          }
                          let isNoSpot = false;
                          if (dropdownValue === "NO SPOT") {
                            isNoSpot = true;
                          }
                          return (
                            <Row className="team-card" key={i} stage_id={stage._id}>
                              <Col>
                                <Card
                                  onDragStart={(e) => dragStart(e, i)}
                                  onDragEnter={(e) => dragEnter(e, i)}
                                  onDragEnd={dropNew}
                                  draggable
                                  item-index={isTBD ? _team : _teamId}
                                  body
                                  className={`compact-card ${isAutofill ? "disabled" : ""}`}
                                  index={_teamId}
                                  key={i}
                                  id={isTBD ? (_team.seedLabel ? "TBD" : _team) : _team._id ? _team._id : "TBD"}
                                  name={_teamName}
                                  stage_id={stage._id}
                                >
                                  <div className="d-flex justify-content-between align-items-center" style={{ width: "220px" }}>
                                    {_groupName === "" || x.seedPosition === 0 ? (
                                      <span id="team-name">
                                        #{i + 1}-{_teamLabel}
                                      </span>
                                    ) : (
                                      <span id="team-name">
                                        #{i + 1}-{_teamLabel}
                                        {/* #{i + 1}-{_teamLabel} ({_groupName} - seed {x.seedPosition === 0 ? 1 : x.seedPosition}) */}
                                      </span>
                                    )}
                                    <FontAwesomeIcon icon={faGripHorizontal} className="text-secondary" />
                                  </div>
                                </Card>
                              </Col>
                              <span className="line">---</span>
                              <Col className="w250">
                                <Form.Group className="mb-3">
                                  {isNoSpot ? (
                                    <SelectCustom
                                      noSpot
                                      value={dropdownValue}
                                      itemId={"select-" + (_teamId ?? `TBD #+#${_groupName}#+#${_seedPosition}`)}
                                    />
                                  ) : (
                                    <SelectCustom
                                      value={dropdownValue}
                                      options={dropDownValues?.map((item, y) => `${item.group.name} - Seed ${item.seedPosition}`)}
                                      itemId={"select-" + (_teamId ?? `TBD #+#${_groupName}#+#${_seedPosition}`)}
                                      onClick={changeTeamHandler}
                                      disabled={isAutofill}
                                    />
                                  )}
                                  {/* <Form.Control
                                    // disabled={isAutofill}
                                    className={isAutofill || i >= dropDownValues.length ? "disabled" : ""}
                                    as="select"
                                    name="brackettype"
                                    id={"select-" + (_teamId ?? `TBD #+#${_groupName}#+#${_seedPosition}`)}
                                    onChange={(e) => {
                                      changeTeamHandler(e);
                                    }}
                                  >
                                    {i >= dropDownValues.length ? (
                                      <option
                                        key=""
                                        id=""
                                        selected="true"
                                        defaultValue=""
                                        seedvalue="0"
                                        groupName=""
                                        groupId=""
                                        teamName="TBD"
                                        teamId={_teamId}
                                        className="w250 disabled"
                                        disabled
                                      >
                                        No spot
                                      </option>
                                    ) : (
                                      dropDownValues?.map((item, y) => (
                                        <>
                                          <option
                                            key={item._id + "-" + (y + 1)}
                                            id={item._id}
                                            defaultValue={
                                              _groupId === item.group._id && x.seedPosition === item.seedPosition ? true : false
                                            }
                                            selected={_groupId === item.group._id && x.seedPosition === item.seedPosition ? true : false}
                                            groupName={item.name}
                                            groupId={item._id}
                                            teamId={_teamId}
                                            // value={item.name}
                                          >
                                            {item.group.name} - Seed {item.seedPosition}
                                          </option>
                                        </>
                                      ))
                                    )}
                                  </Form.Control> */}
                                </Form.Group>
                              </Col>
                            </Row>
                          );
                        })}
                    </Row>
                  </Col>

                  <Col lg={6}>
                    <h6>Distribution ({stage.name})</h6>
                    <div className="sub-tabs-from-seeding">
                      <Row className="sub-tabs-style">
                        <Nav variant="tabs" activeKey={activeTab} onSelect={(t) => setActiveTab(parseInt(t))}>
                          <Nav.Item>
                            <Nav.Link eventKey={0} active={activeTab === 0}>
                              All groups
                            </Nav.Link>
                          </Nav.Item>
                          {subTabs !== undefined &&
                            subTabs.map((item) => (
                              <Nav.Item key={item.name}>
                                <Nav.Link eventKey={item.key} active={activeTab === item.key}>
                                  {item.name}
                                </Nav.Link>
                              </Nav.Item>
                            ))}
                        </Nav>
                      </Row>
                      {activeTab === 0 && (
                        <div key={0} className="subtabs-content">
                          {stage.groups !== undefined && stage.groups.length > 0 && (
                            <Group
                              groupId={0}
                              allGroup={activeTab === 0 || undefined ? true : false}
                              groups={stage.groups}
                              seeds={seeds.filter((x) => x.stage_id === stage._id)}
                              totalSeeds={totalSeeds}
                              teams={teams}
                              stageSeedings={selectedGroupsTeam}
                              updateSeeding={updateSeeding}
                            />
                          )}
                        </div>
                      )}
                      {subTabs !== undefined &&
                        subTabs.map(
                          ({ key, content }) =>
                            key === activeTab && (
                              <div key={key} className="subtabs-content">
                                {content}
                              </div>
                            )
                        )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="d-flex justify-content-end">
                      <Button
                        variant="primary"
                        className={`btn btn-primary`}
                        onClick={() => {
                          fetchSeedingDetailsWithSave(seedingStages[key]._id, isAutofill);
                        }}
                        disabled={saveLoading}
                      >
                        {saveLoading ? "Saving" : "Save"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Tab>
            ))}
          </Tabs>
        ) : null}
      </Form>
    </Wrapper>
  );
};
export default MultiStageSeeding;

const Wrapper = styled.div`
  .reorder-list {
    width: 100% !important;
  }
  .compact-card {
    width: 250px !important;
  }
  .sub-tabs-style {
    margin: 0px 0px 7px 0px !important;
    justify-content: space-between;
    padding: 5px 5px 5px 5px;
  }
  .line {
    font-weight: 900;
    magin: 4px 4px 4px 4px !important;
  }
  .subtabs-content {
    border: 1px solid gainsboro !important;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin: 5px 5px 5px -5px !important;
  }
  .row-seeding {
    margin: auto;
    justify-content: "space-between";
    border: 1px solid gainsboro;
    border-radius: 5px;
    padding: 5px 5px 5px 5px;
  }
`;
