/**
 * Sort the teams on the basis of score
 * @param {Array} teams All the teams to sort
 * @returns Array of teams sorted in descending order on basis of score
 */
export const sortDescendingScore = (teams) => {
  return teams.sort((a, b) => b.score - a.score);
};

export const sortTeams = (teams = [], league, isManualSort = false) => {
  const {
    matches,
    game: { name: gameName },
  } = league;
  teams = teams.filter((team) => team && !!team.team);
  return isManualSort
    ? teams.sort((a, b) => a.order - b.order)
    : sortOnHeadToHeadWon(getTeamsWithSameScore(sortDescendingScore(teams)), matches, gameName);
};

export const getTeamsWithSameScore = (teams = []) => {
  let i = 0;
  let brackets = [];
  while (i < teams.length) {
    let pairs = [];
    for (let j = i; j < teams.length; j++) {
      if (teams[i].score === teams[j].score) {
        pairs = [...pairs, teams[j]];
      } else {
        break;
      }
    }
    brackets = [...brackets, pairs];
    i += pairs.length;
  }
  return brackets;
};

export const sortOnHeadToHeadWon = (teams, matches, gameName) => {
  return teams.reduce(
    (acc, teamPair) =>
      teamPair.length === 1 ? [...acc, ...teamPair] : [...acc, ...calculateWonOnScoreSystem(teamPair, matches, "best of", gameName)],
    []
  );
};

export const calculateWonOnScoreSystem = (teamPair, matches, scoreSystem, gameName) => {
  const teamIds = teamPair.map(({ team }) => team?._id);
  const teams = teamPair.map(({ team }, index) => ({ _id: team?._id, index }));
  let score = teams.map((_) => 0);
  const scoreSystemMatches = matches.filter(
    (match) =>
      teamIds.includes(match.t1?._id.toString()) &&
      teamIds.includes(match.t2?._id.toString()) &&
      match.scoreSystem.name?.toLowerCase() === scoreSystem
  );
  scoreSystemMatches.forEach((match) => {
    let winnerTeam = null;
    if (match.t1Score > match.t2Score) {
      winnerTeam = teams.find((team) => team._id?.toString() === match.t1._id.toString());
    } else if (match.t2Score > match.t1Score) {
      winnerTeam = teams.find((team) => team._id?.toString() === match.t1._id.toString());
    } else {
      winnerTeam = null;
    }
    if (winnerTeam) score[winnerTeam.index] += 1;
  });
  let sortedTeams = sortDescendingScore(teams.map((team, index) => ({ ...team, score: score[index] })));
  if (scoreSystem !== "tiebreaker") {
    const tiebreakerTeams = getTeamsWithSameScore(sortedTeams).map((_teamPair) =>
      _teamPair.map((_team) => teamPair.find(({ team }) => team?._id?.toString() === _team?._id?.toString()))
    );
    return sortOnTiebreaker(tiebreakerTeams, matches, gameName);
  } else if (scoreSystem === "tiebreaker" && gameName.toLowerCase() === "cs:go") {
    const teamsWithSameTiebreakerScore = getTeamsWithSameScore(sortedTeams);
    return teamsWithSameTiebreakerScore.reduce(
      (acc, _teamPair) =>
        _teamPair.length === 1
          ? [...acc, teamPair.find((team) => team._id.toString === teamPair[0]._id.toString())]
          : [
              ...acc,
              ...calculateWonOnRd(
                _teamPair.reduce(
                  (acc, _team) => [...acc, { ...teamPair.find(({ team }) => team?._id?.toString() === _team?._id?.toString()) }],
                  []
                ),
                matches
              ),
            ],
      []
    );
  } else {
    return sortedTeams.map((_sortedTeam) => teamPair.find(({ team }) => team._id.toString() === _sortedTeam._id.toString()));
  }
};

export const sortOnTiebreaker = (teams, matches, gameName) => {
  return teams.reduce(
    (acc, teamPair) =>
      teamPair.length === 1 ? [...acc, ...teamPair] : [...acc, ...calculateWonOnScoreSystem(teamPair, matches, "tiebreaker", gameName)],
    []
  );
};

const getCalculatedRD = (latestMatches = [], teamId = null) => {
  let sumOfRD = Number(0);
  if (latestMatches.length && teamId !== null) {
    latestMatches.map((lmatch) => {
      lmatch.csgoData &&
        lmatch.csgoData.mapData.map((singMap) => {
          singMap &&
            Object.values(singMap.processedteamData).map((singleTeamMap) => {
              // if(singleTeamMap.roundDiffrence > 0 && singleTeamMap.teamDbId === teamId){ // Uncomment if only calculate positive numbers
              if (singleTeamMap.teamDbId === teamId) {
                sumOfRD = sumOfRD + singleTeamMap.roundDiffrence;
              }
            });
        });
    });
  }
  return sumOfRD;
};

const calculateWonOnRd = (teams, matches) => {
  const calculatedRds = teams.map((team) => {
    return getCalculatedRD(matches, team._id);
  });
  return sortOnRD(teams.map((team, index) => ({ ...team, rd: calculatedRds[index] })));
};

const sortOnRD = (teams) => teams.sort((a, b) => b.rd - a.rd);
