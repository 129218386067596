import React from "react";
import { Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagic } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const GenMatchesPromptCard = () => {
  return (
    <Card bg="dark" text="light">
      <Card.Header>Generate Matches</Card.Header>
      <Card.Body>
        <p>Generate matches using the match generation tool</p>

        <Button as={Link} to="generateMatches">
          <FontAwesomeIcon icon={faMagic} /> Get Started
        </Button>
      </Card.Body>
    </Card>
  );
};

export default GenMatchesPromptCard;
