import React, { useState, useEffect, useRef } from "react";
import Axios from "axios";
import { Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import TranslationDropdown from "./DropdownButton";
import { Col } from "react-bootstrap";
const FilterTranslation = ({ data, setFilter }) => {
  const [dropdownSelection, setDropdownSelection] = useState();
  const [filterQuery, setFilterQuery] = useState();
  const timeout = useRef();

  useEffect(() => {
    filterResult()
  }, [dropdownSelection, filterQuery]);

  const filterResult = async () => {
    const query = { query: filterQuery?.toLowerCase(), selection: dropdownSelection };
    setFilter(query)
  };

  return (
    <>
      <Col lg={8}>
        <Form.Group>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text id="inputGroupPrepend">
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control type="search" placeholder="Filter Roles" value={filterQuery} onChange={(e) => setFilterQuery(e.target.value)} />
          </InputGroup>
        </Form.Group>
      </Col>
      <Col sm={3}>
        <TranslationDropdown apidata={data} onSelection={setDropdownSelection} />
      </Col>
    </>
  );
};

export default FilterTranslation;
