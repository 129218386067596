import React, { useState, useEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const FilterUsers = ({ setFilteredUsers, setLoading, rowsPerPage, setTotalRows, filterResult, filterQuery }) => {
  
  return (
    <Form.Group>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text id="inputGroupPrepend">
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control type="search" placeholder="Filter Users" value={filterQuery} onChange={(e) => filterResult(e.target.value) } />
      </InputGroup>
    </Form.Group>
  );
};

export default FilterUsers;
