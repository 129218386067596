import React, { useState, useEffect, useContext } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Axios from "axios";
import TabsManager from "./Tabs/TabsManager";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "./../../../../context/AppContext";

const SingleGameEditFrontPage = () => {
  const { user } = useContext(AppContext);
  const { id } = useParams();
  const [game, setGame] = useState({});
  const [loading, setLoading] = useState(true);
  const { watch } = useForm();
  let frontPage = watch("frontPage", game.frontpage);
  const [saved, setSaved] = useState(true);

  // const onSubmit = async (gameData) => {
  //     setLoading(true);
  //     // let formData = {
  //     // ...gameData,
  //     // frontPage: gameData.frontPage
  //     // };
  //     let frontPageData = {
  //         Default: [{"test":"ASda"}]
  //     };

  //     if (game) {
  //         // Edit
  //         const { data: resData } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/games/${game._id}/frontpage`, frontPageData);
  //         history.push("/game-settings");
  //     }

  //     setLoading(false);
  // };

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/games/${id}`).then(({ data }) => {
      setGame(data);
      setLoading(false);
    });
  }, [id]);

  const setTempraryData = () => {
    // console.log("setTempraryData");
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/games/${id}`).then(({ data }) => {
      setGame(data);
      setSaved(true);
    });
  };

  return loading ? (
    "Loading..."
  ) : (
    <div>
      <div className="admin-page-title">
        <h2>
          {game ? "Edit Front Page " : "Create Front Page "} {game.name}
        </h2>
        <Button variant="success" className="space-right btn-success" as={Link} to={`/game-settings`}>
          <FontAwesomeIcon icon={faBackward} /> Go Back
        </Button>
      </div>
      <Row>
        {/* LEFT/main column */}
        <Col>
          <TabsManager frontPage={frontPage} setTempraryData={(temData) => setTempraryData(temData)} isSaved={saved} frontPageAccess={user.state.permissions?.user?.frontPage} isAdmin={user.state.admin} />
        </Col>

        {/* Right column */}
        {/* <Col xl="3">
                    Right Collumn
                </Col> */}
      </Row>
    </div>
  );
};

export default SingleGameEditFrontPage;
