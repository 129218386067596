import React, { useState } from "react";
import { Row, Col, Modal, Form, Button } from "react-bootstrap";
import Axios from "axios";
import { toast } from "react-toastify";

const MoveMatchModal = ({ show, onHide, selectedRows, updateMatches }) => {
  const [selectedDirection, setSelectedDirection] = useState("Earlier");
  const [selectedTime, setSelectedTime] = useState("Weeks");
  const [selectedValue, setSelectedValue] = useState(1);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/matches/updateMultiple`, {
        direction: selectedDirection,
        time: selectedTime,
        value: selectedValue,
        matches: selectedRows.map(({ _id }) => _id),
      });
      const { success: movedMatches } = data;
      if ("failed" in data) {
        toast.warn("Some matches moved successfully and failed to move some matches");
      } else {
        toast.success("Successfully moved matches");
      }
      if (movedMatches.length) {
        updateMatches((matches) =>
          matches.map((match) =>
            movedMatches.some((movedMatch) => movedMatch._id.toString() === match._id.toString())
              ? movedMatches.find((movedMatch) => movedMatch._id.toString() === match._id.toString())
              : match
          )
        );
      }
      onHide();
    } catch (error) {
      toast.error(error?.response?.data?.msg || "Failed to move multiple matches");
      onHide();
    }
  };
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <h2>Move Match</h2>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Direction</Form.Label>
                <Form.Control as="select" value={selectedDirection} onChange={(e) => setSelectedDirection(e.target.value)}>
                  <option value="Earlier">Earlier</option>
                  <option value="Later">Later</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Time</Form.Label>
                <Form.Control as="select" value={selectedTime} onChange={(e) => setSelectedTime(e.target.value)}>
                  <option value="Weeks">Weeks</option>
                  <option value="Days">Days</option>
                  <option value="Hours">Hours</option>
                  <option value="Minutes">Minutes</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Value</Form.Label>
                <Form.Control type="number" value={selectedValue} onChange={(e) => setSelectedValue(e.target.value)} min={1} max={100} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button className="m-1" type="submit">
                Submit
              </Button>
              <Button className="m-1" variant="danger" onClick={onHide}>
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default MoveMatchModal;
