import React, { useContext, useMemo } from "react";
import DataTable from "react-data-table-component";
import moment from "moment-timezone/builds/moment-timezone-with-data";
import { Button, Alert, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faEye,
  faPencilAlt,
  faTrashAlt,
  faUsers,
  faWrench,
  faExchangeAlt,
  faGamepad,
  faCheck,
  faLock,
  faUnlock
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import MatchScoreModal from "./includes/MatchScoreModal";
import MatchScoreModalCSGO from "./includes/MatchScoreModalCSGO";
import MatchSetPlayersModal from "./includes/MatchSetPlayersModal";
import MatchVetoModalCSGO from "../../../Leagues/LeagueMatches/Tabs/includes/MatchVetoModalCSGO";
import { AppContext } from "../../../../../context/AppContext";
import { GrCode } from "react-icons/gr";
import { BsFillCameraVideoFill, BsSquareHalf } from "react-icons/bs";
import { TIMEZONES } from "../../../../../utils/timezones";
import { useEffect } from "react";
import Swal from "sweetalert2";

const Matches = ({ teams = [], matches, updateMatches, onEditMatch, league, setLeague, prefferedTimezone, setPrefferedTimezone }) => {
  const { user } = useContext(AppContext);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showScoreModal, setShowScoreModal] = useState(false);
  const [showSetPlayersModal, setShowSetPlayersModal] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [showEditVeto, setShowEditVeto] = useState(false);
  const [vetoModalData, setVetoModalData] = useState(false);
  const [generateCodeloaderSpinner, setGenerateCodeloaderSpinner] = useState(false);
  const [clearSelectedRows, setClearSelectedRows] = useState(true);

  const getLeagueTeamData = (id) => {
    return teams.find(({ team }) => team?._id === id) || {};
  };

  const onSetPlayers = (match) => {
    setModalData(match);
    setShowSetPlayersModal(true);
  };

  const onSetScore = (match) => {
    setModalData(match);
    setShowScoreModal(true);
  };

  const onEditVeto = (match) => {
    setVetoModalData(match);
    setShowEditVeto(true);
  };

  const contextAction = useMemo(() => {
    const onDeleteMatches = async () => {
      const ids = selectedRows.map(({ _id }) => _id);

      if (window.confirm(`Are you sure you want to delete ${ids.length} matches?`)) {
        try {
          await Axios.post(`${process.env.REACT_APP_CORE_API}/api/matches/deleteMulti`, { ids });

          updateMatches((matches) => matches.filter(({ _id }) => !ids.includes(_id)));
          setSelectedRows([]);
          setClearSelectedRows((prevState) => !prevState);
          toast.success(`Successfully deleted ${ids.length} matches`);
        } catch (e) {
          toast.error("There was a problem deleting the selected matches");
        }
      }
    };
    return (
      <div>
        <Button size="sm" variant="danger" onClick={onDeleteMatches}>
          <FontAwesomeIcon icon={faTrashAlt} />
          &nbsp;Delete Selected
        </Button>
      </div>
    );
  }, [selectedRows, updateMatches]);

  const onReschedulingLock = async (match) => {
    try {
      Swal.fire({
        text: `Do you want to ${match.isLockReschedule ? "Unlock" : "Lock"} rescheduling of this match`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#28a745",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/matches/${match._id}/lock`, {
            lock: !match.isLockReschedule,
          });
          updateMatches((matches) => {
            const index = matches.findIndex(({ _id }) => _id.toString() === data.updatedMatch._id.toString());
            matches[index].isLockReschedule = !matches[index].isLockReschedule;
            return matches;
          });
          toast.success(`Successfully match rescheduling locked!`);
        }
      });
    } catch (error) {
      toast.error("There was a problem processing rescheduling locked 😞");
    }
  };


  // const getDataManually = async (match) => {
  //   try{
  //     await Axios.get(`${process.env.REACT_APP_LOL_API}/api/matches/${match._id}/manuallyGetData?type=2`);
  //     const matchList = await Axios.get(`${process.env.REACT_APP_CORE_API}/api/tournaments/${match.tournament}/matches`);
  //     console.log(matchList.data[0].matches)

  //     updateMatches(() => { return matchList.data[0].matches});
  //     toast.success("Successfully Got Data from Roit");
  //   }catch(e){
  //     console.log(e);
  //     toast.error("There was a problem updating with Roit");
  //   }
  // }

  // const swapTeams = async (match) => {
  //   try {
  //     const { data } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/matches/${match._id}/swapTeams`);

  //     updateMatches((matches) => matches.map((m) => (m._id === match._id ? data.updatedMatch : m)));
  //     toast.success("Successfully swaped Teams");
  //   } catch (e) {
  //     console.log(e);
  //     toast.error("There was a problem swaping the team");
  //   }
  // };

  const generateTournamentCodes = async (match) => {
    try {
      setGenerateCodeloaderSpinner && setGenerateCodeloaderSpinner(match._id);
      const { data } = await Axios.post(
        `${process.env.REACT_APP_LOL_API}/api/matches/${match._id}/generateTournamentCodes?type=tournament`
      );
      updateMatches((matches) =>
        matches.map((match) => (match._id.toString() === data.updatedMatch._id.toString() ? data.updatedMatch : match))
      );
      setGenerateCodeloaderSpinner && setGenerateCodeloaderSpinner(false);
    } catch (error) {
      setGenerateCodeloaderSpinner && setGenerateCodeloaderSpinner(false);
      toast.error("Failed to generate tournament codes");
    }
  };
  const editMatch = async (matchId, body) => {
    try {
      const { data } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/matches/${matchId}?type=Tournament`, body);
      updateMatches((matches) =>
        matches.map((match) => (match._id.toString() === data.updatedMatch._id.toString() ? data.updatedMatch : match))
      );
    } catch (error) {
      toast.error("There was problem in updating match");
    }
  };

  const customStyles = {
    headCells: {
      style: {
        paddingLeft: "0px", // override the cell padding for head cells
        paddingRight: "0px",
        maxWidth: "80px",
      },
    },
    cells: {
      style: {
        paddingLeft: "0px", // override the cell padding for data cells
        paddingRight: "0px",
        maxWidth: "80px",
      },
    },
  };
  const columns = [
    {
      name: "Date",
      sortable: true,
      sortFunction: (a, b) => moment(a).isBefore(b),
      // cell: ({datetime}) => moment(datetime).tz(league.timezone).format("DD/MM/YYYY HH:mm"),
      cell: function ({ datetime }) {
        const zonedDatetime = moment.tz(datetime, null, true, prefferedTimezone);
        const zone = zonedDatetime.zoneAbbr();
        return `${zonedDatetime.format("DD/MM/YYYY HH:mm")} (${zone.includes("+") || zone.includes("-") ? "UTC" + zone : zone})`;
      },
      grow: 2,
    },
    { name: "Bracket / Round", sortable: true, cell: ({ duelMatchId }) => `B: ${duelMatchId.s} / R: ${duelMatchId.r}`, maxWidth: "120px" },
    { name: "Best of", sortable: true, selector: "bestOf", maxWidth: "80px" },
    {
      name: "Team 1",
      sortable: true,
      cell: ({ t1, lolData, t2Score, bestOf }) => (
        <div style={{ opacity: t1 && getLeagueTeamData(t1._id).kicked ? 0.25 : 1 }}>
          {t1 ? (
            <>
              {t1.name} {getLeagueTeamData(t1._id).kicked && "(Kicked)"}{" "}
              {lolData && !!(lolData?.players?.t1 || []).length && (
                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Players Set</Tooltip>}>
                  <FontAwesomeIcon className="text-success" icon={faCheckCircle} />
                </OverlayTrigger>
              )}
            </>
          ) : t2Score === bestOf ? (
            "BYE"
          ) : (
            "TBD"
          )}
        </div>
      ),
      maxWidth: "130px",
    },
    {
      name: "Team 2",
      sortable: true,
      cell: ({ t2, lolData, t1Score, bestOf }) => (
        <div style={{ opacity: t2 && getLeagueTeamData(t2._id).kicked ? 0.25 : 1 }}>
          {t2 ? (
            <>
              {t2.name} {getLeagueTeamData(t2._id).kicked && "(Kicked)"}{" "}
              {lolData && !!(lolData?.players?.t2 || []).length && (
                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Players Set</Tooltip>}>
                  <FontAwesomeIcon className="text-success" icon={faCheckCircle} />
                </OverlayTrigger>
              )}
            </>
          ) : t1Score === bestOf ? (
            "BYE"
          ) : (
            "TBD"
          )}
        </div>
      ),
      maxWidth: "130px",
    },
    {
      name: "Score",
      cell: ({ t1Score, t2Score, t2, t1 }) => `${t1Score === undefined ? "" : t1Score} - ${t2Score === undefined ? "" : t2Score}`,
      maxWidth: "80px",
    },
    {
      name: "Status",
      cell: ({ status }) =>
        status === "complete" ? (
          <span className="text-success">Completed</span>
        ) : status === "inProgress" ? (
          <span className="text-warning">In Progress</span>
        ) : (
          <span className="text-secondary">Upcoming</span>
        ),
    },
    {
      right: true,
      width: "20%",
      cell: (match) => (
        <>
          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">View Match</Tooltip>}>
            <a
              className="btn text-secondary"
              href={`https://leagues.gg/match/${match._id}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ padding: "8px" }}
            >
              <FontAwesomeIcon icon={faEye} />
            </a>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Set Players</Tooltip>}>
            <Button
              variant="link"
              className="text-secondary"
              onClick={() => onSetPlayers(match)}
              style={{ padding: "8px" }}
              disabled={!user.state.admin && !user.state.permissions.user.matches}
            >
              <FontAwesomeIcon icon={faUsers} />
            </Button>
          </OverlayTrigger>
          {match.veto && (
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Edit Veto</Tooltip>}>
              <Button
                variant="link"
                className="text-secondary"
                onClick={() => onEditVeto(match)}
                style={{ padding: "8px" }}
                disabled={!user.state.admin && !user.state.permissions.user.matches}
              >
                <FontAwesomeIcon icon={faGamepad} />
              </Button>
            </OverlayTrigger>
          )}
          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Set Score Manually</Tooltip>}>
            <Button
              variant="link"
              className="text-secondary"
              onClick={() => onSetScore(match)}
              style={{ padding: "8px" }}
              disabled={!user.state.admin && !user.state.permissions.user.matches}
            >
              <FontAwesomeIcon icon={faWrench} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Edit Match</Tooltip>}>
            <Button
              variant="link"
              className="text-secondary"
              onClick={() => onEditMatch(match)}
              style={{ padding: "8px" }}
              disabled={!user.state.admin && !user.state.permissions.user.matches}
            >
              <FontAwesomeIcon icon={faPencilAlt} />
            </Button>
          </OverlayTrigger>
          {/* <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Swap Teams</Tooltip>}>
            <Button variant="link" className="text-secondary" onClick={() => swapTeams(match)} style={{ padding: "8px" }} disabled={!user.state.admin && !user.state.permissions.user.matches}>
              <FontAwesomeIcon icon={faExchangeAlt} />
            </Button>
          </OverlayTrigger> */}
           <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip">{match.isLockReschedule ? "Unlock" : "Lock"} rescheduling</Tooltip>}
            >
              <Button
                variant="link"
                className="text-secondary"
                style={{ opacity: match.isLockReschedule ? 1 : 0.5 }}
                onClick={() => onReschedulingLock(match)}
                disabled={match.status === "complete"}
              >
                <FontAwesomeIcon icon={ match.isLockReschedule ? faLock: faUnlock} />
              </Button>
            </OverlayTrigger>
        </>
      ),
    },
    {
      right: true,
      cell: (filteredMatches) => {
        return filteredMatches._id ? (
          <>
            <div className="vertical-divider" />
            <div className="match-icons-grid">
              {league?.game?.name.toLowerCase() === "league of legends" ? (
                generateCodeloaderSpinner !== filteredMatches._id ||
                (typeof generateCodeloaderSpinner === "boolean" && generateCodeloaderSpinner === false) ? (
                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Tournament Codes</Tooltip>}>
                    <Button
                      variant="link"
                      onClick={() => generateTournamentCodes(filteredMatches)}
                      className={
                        !user.state.admin && !user.state.permissions.user.matches ? "disabled-link text-secondary" : "text-secondary"
                      }
                    >
                      <GrCode className={filteredMatches.lolData?.tournamentCodes?.length ? "" : "faded"} />
                    </Button>
                  </OverlayTrigger>
                ) : (
                  <>
                    <Spinner animation="border" className="m-auto" size="sm" role="status"></Spinner>
                  </>
                )
              ) : (
                <></>
              )}
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Broadcasting</Tooltip>}>
                <Button
                  variant="link"
                  className={!user.state.admin && !user.state.permissions.user.matches ? "disabled-link text-secondary" : "text-secondary"}
                  onClick={() => editMatch(filteredMatches._id, { broadcasted: !filteredMatches.broadcasted })}
                >
                  <BsFillCameraVideoFill style={{ opacity: filteredMatches.broadcasted !== true ? 0.5 : 1 }} />
                </Button>
              </OverlayTrigger>
              {league.game.name.toLowerCase() === "league of legends" && (
                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Side Selection</Tooltip>}>
                  <Button
                    variant="link"
                    className={
                      !user.state.admin && !user.state.permissions.user.matches ? "disabled-link text-secondary" : "text-secondary"
                    }
                    onClick={() => editMatch(filteredMatches._id, { sideSelection: !filteredMatches.sideSelection })}
                  >
                    <BsSquareHalf style={{ opacity: filteredMatches.sideSelection !== true ? 0.5 : 1 }} />
                  </Button>
                </OverlayTrigger>
              )}
            </div>
          </>
        ) : null;
      },
    },
  ];

  return (
    <div>
      <DataTable
        title={
          <div className="matches-header">
            <span className="matches-header__title">Matches</span>
            <div className="matches-header__timezone-dropdown">
              <span className="matches-header__timezone-dropdown-title">Show in time zone:</span>
              <select className="form-control" name="timezone" value={prefferedTimezone} onChange={(e) => setPrefferedTimezone(e.target.value)}>
                {TIMEZONES.map(({ name, value }, index) => (
                  <option value={value} key={index}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        }
        columns={columns}
        data={matches}
        selectableRows
        contextActions={contextAction}
        onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}
        clearSelectedRows={clearSelectedRows}
        pagination
        paginationPerPage={100}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
      />

      {league && league.game && league.game.shortName === "CSGO" ? (
        <MatchScoreModalCSGO
          show={showScoreModal}
          onHide={() => setShowScoreModal(false)}
          match={modalData}
          tournament={league}
          updateMatches={updateMatches}
          setLeague={setLeague}
        />
      ) : (
        <MatchScoreModal
          show={showScoreModal}
          onHide={() => setShowScoreModal(false)}
          match={modalData}
          tournament={league}
          updateMatches={updateMatches}
          setLeague={setLeague}
        />
      )}

      <MatchSetPlayersModal
        show={showSetPlayersModal}
        onHide={() => setShowSetPlayersModal(false)}
        match={modalData}
        updateMatches={updateMatches}
        league={league}
      />
      {league?.game?.shortName === "CSGO" && (
        <MatchVetoModalCSGO
          show={showEditVeto}
          onHide={() => setShowEditVeto(false)}
          match={vetoModalData}
          league={league}
          vetoInProgress={new Date(vetoModalData.datetime) < new Date() || vetoModalData.veto?.completed ? false : true}
        />
      )}
    </div>
  );
};

export default Matches;
