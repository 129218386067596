import React from "react";
import { Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagic } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";


const MultiStageGenMatchesPromptCard = ({stageIndex}) => {
  return (
    <Card bg="dark" text="light">
      <Card.Header>Generate Matches</Card.Header>
      <Card.Body>
        <p>Generate matches using the match generation tool</p>
        <Link to={{pathname: "generateMatches", query:{ stageIndex }}}> 
        <Button 
        // as={Link} 
        // to="generateMatches"
        >
          <FontAwesomeIcon icon={faMagic} /> Get Started
        </Button>
        </Link>
      </Card.Body>
    </Card>
  );
};

export default MultiStageGenMatchesPromptCard;
