import React, { useState, useRef, useEffect } from "react";
import { Modal, Button, Form, Dropdown, Card } from "react-bootstrap";
import Axios from "axios";
import { RiCloseLine } from "react-icons/ri";
import { toast } from "react-toastify";

const AddTeamMeberModal = ({ show, handleClose, team = {}, members = [], pendingInvites = [], afterAdd, afterAddMembers }) => {
  const timeout = useRef();
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [query, setQuery] = useState("");
  const [memberResults, setMemberResults] = useState([]);
  const [memberResultsLoading, setMemberResultsLoading] = useState(false);
  const [membersRoles, setMembersRoles] = useState([]);
  const [inviting, setInviting] = useState(false);
  const [adding, setAdding] = useState(false);

  useEffect(() => {
    if (!query.length) return setMemberResults([]);
    setMemberResultsLoading(true);

    clearTimeout(timeout.current);
    timeout.current = setTimeout(async () => {
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/globalSearch/inviteMembers`, {
        query,
        include: "users, players, coaches",
        selectedGameId: team.game._id,
      });

      const searchResults = data
        ?.filter(({ _id }) => !selectedMembers.map(({ _id }) => _id).includes(_id))
        .filter(
          ({ _id }) =>
            !members.find(({ user, player, coach }) => [user && user._id, player && player._id, coach && coach._id].includes(_id))
        )
        .filter(
          ({ _id }) =>
            !pendingInvites.find(({ user, player, coach }) => [user && user._id, player && player._id, coach && coach._id].includes(_id))
        )
        .map(({ _id, img, name, user, type, player, coach }) => ({
          _id,
          type,
          img,
          player,
          user,
          coach,
          title: name,
          subtitle: user ? `${user.firstName} ${user.lastName}` : false,
        }));

      setMemberResults(searchResults);
      setMemberResultsLoading(false);
    }, 500);
  }, [query, team, selectedMembers]);

  const addMember = (player) => {
    setMemberResults(false);

    const gamePlayerTypes = team.game.configuration.playerTypes
      .map(({ name }) => "Player:" + name)
      .filter((playerType) => playerType.toLowerCase().includes("sub") || !pendingInvites.find(({ role }) => role === playerType))
      .filter((playerType) => playerType.toLowerCase().includes("sub") || !members.find(({ role }) => role === playerType));

    let roles = [];
    switch (player.type) {
      case "User":
        roles = ["Manager", "Owner"];
        roles = player.coach ? [...roles, "Coach"] : roles;
        roles = player.player ? [...roles, ...gamePlayerTypes] : roles;
        break;
      case "Player":
        roles = ["Manager", "Owner"];
        roles = player.coach ? [...roles, "Coach"] : roles;
        roles = [...gamePlayerTypes, ...roles];
        break;
      case "Coach":
        roles = ["Coach"];
        break;
      default:
    }

    setSelectedMembers([...selectedMembers, { ...player, role: roles[0], roles }]);
  };

  const onSelectMember = (result) => {
    addMember(result);
    setQuery("");
  };

  const editMember = (playerId, role) => {
    let selectedPlayer = selectedMembers.find((player) => player._id === playerId);
    if (selectedPlayer.type === "Player") {
      if (["Manager", "Owner"].includes(role)) {
        let data = membersRoles.filter((player) => player._id !== selectedPlayer.coach?._id);
        setMembersRoles([...data, { ...selectedPlayer.user, role }]);
        setSelectedMembers(selectedMembers.map((player) => (player._id === playerId ? { ...player, role, remove: true } : player)));
      } else if (["Coach"].includes(role)) {
        let data = membersRoles.filter((player) => player._id !== selectedPlayer.user?._id);
        setMembersRoles([...data, { ...selectedPlayer.coach, role }]);
        setSelectedMembers(selectedMembers.map((player) => (player._id === playerId ? { ...player, role, remove: true } : player)));
      } else {
        setMembersRoles(
          membersRoles.filter((player) => player._id !== selectedPlayer.user?._id && player._id !== selectedPlayer.coach?._id)
        );
        setSelectedMembers(selectedMembers.map((player) => (player._id === playerId ? { ...player, role, remove: false } : player)));
      }
    } else if (selectedPlayer.type === "User") {
      if (["Player"].includes(role.split(":")[0])) {
        let data = membersRoles.filter((player) => player._id !== selectedPlayer.coach?._id);
        setMembersRoles([...data, { ...selectedPlayer.player, role }]);
        setSelectedMembers(selectedMembers.map((player) => (player._id === playerId ? { ...player, role, remove: true } : player)));
      } else if (["Coach"].includes(role)) {
        let data = membersRoles.filter((player) => player._id !== selectedPlayer.player?._id);
        setSelectedMembers(selectedMembers.map((player) => (player._id === playerId ? { ...player, role, remove: true } : player)));
        setMembersRoles([...data, { ...selectedPlayer.coach, role }]);
      } else {
        setMembersRoles(
          membersRoles.filter((player) => player._id !== selectedPlayer.player?._id && player._id !== selectedPlayer.coach?._id)
        );
        setSelectedMembers(selectedMembers.map((player) => (player._id === playerId ? { ...player, role, remove: false } : player)));
      }
    } else {
      setSelectedMembers(selectedMembers.map((player) => (player._id === playerId ? { ...player, role } : player)));
    }
  };

  const removeMember = (idToRemove) => {
    let selectedPlayer = selectedMembers.find((player) => player._id === idToRemove);
    setMembersRoles(
      membersRoles.filter(
        (player) =>
          player._id !== selectedPlayer.player?._id && player._id !== selectedPlayer.coach?._id && player._id !== selectedPlayer.user?._id
      )
    );
    setSelectedMembers(selectedMembers.filter(({ _id }) => _id !== idToRemove));
  };

  const inviteMembers = async (e) => {
    e.preventDefault();
    setInviting(true);

    try {
      let body = [...membersRoles, ...selectedMembers.filter((el) => !el.remove)];
      let error = false;
      body.filter((el) => {
        let members = body.filter((member) => member._id === el._id);
        if (members.length >= 2) {
          error = true;
          return;
        }
      });
      if (error) {
        toast.error("Duplicate Members Found");
        return
      };
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/teams/invites`, {
        members: body,
        team: team._id,
      });

      toast.success(`Successfully invited ${selectedMembers.length} members`);
      afterAdd(data.invites);
      setSelectedMembers([]);
      handleClose();
    } catch (e) {
      toast.error("There was a problem inviting selected members");
    }

    setInviting(false);
  };

  const addMembers = async (e) => {
    e.preventDefault();
    setAdding(true);

    try {
      let addedMemberData = []
      let body = [...membersRoles, ...selectedMembers.filter((el) => !el.remove)];
      let error = false;
      body.filter((el) => {
        let members = body.filter((member) => member._id === el._id);
        if (members.length >= 2) {
          error = true;
          return;
        }
        let payload = {
          role: el.role,
          _id: el._id,
          type: el.type
        }
        addedMemberData.push(payload)
      });
      if (error) {
        toast.error("Duplicate Members Found");
        return
      };
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/teams/${team._id}/directmembers`, {
        members: addedMemberData,
        team: team._id,
      });
      toast.success(`Successfully Added ${selectedMembers.length} Members in a Team`);
      afterAddMembers(team._id);
      setSelectedMembers([]);
      handleClose();
    } catch (e) {
      console.log(e)
      toast.error("There was a problem adding selected members");
    }

    setAdding(false);
  }

  if (!team._id) return null;

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Members to <strong>{team.name}</strong></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={addMembers}>
          <Form.Group>
            <Form.Control autoFocus placeholder="Search for members" onChange={(e) => setQuery(e.target.value)} value={query} />
            {(memberResultsLoading || !!memberResults.length) && (
              <Card bg="light">
                <ul className="list-unstyled">
                  {memberResultsLoading ? (
                    <Dropdown.Item className="text-secondary">Loading...</Dropdown.Item>
                  ) : (
                    memberResults.map(({ _id, img, title, subtitle, type, user, player, coach }, i) => (
                      <Dropdown.Item key={_id} onClick={() => onSelectMember({ _id, type, img, title, subtitle, user, player, coach })}>
                        <div className="flex-sb">
                          {img ? (
                            <div className="search-result-img" style={{ backgroundImage: `url(${img})` }} />
                          ) : (
                            <div className="profile-img-with-inital-1-letter">{title.charAt(0)}</div>
                          )}

                          <div className="title-subtitle">
                            <p className="title">{title}</p>
                            {subtitle && <small className="subtitle">{subtitle}</small>}
                          </div>
                        </div>
                        <div className="type">{type}</div>
                      </Dropdown.Item>
                    ))
                  )}
                </ul>
              </Card>
            )}
          </Form.Group>

          <div className="dynamic-search-selected-items">
            {selectedMembers.map(({ _id, img, title, subtitle, role, roles }) => (
              <div key={_id} className="selected-item">
                <div className="item-details">
                  {img ? (
                    <div className="item-img" style={{ backgroundImage: `url(${img})` }} />
                  ) : (
                    <div className="profile-img-with-inital-1-letter">{title.charAt(0)}</div>
                  )}
                  <div className="title-subtitle">
                    <p className="title">{title}</p>
                    {subtitle && <small className="subtitle">{subtitle}</small>}
                  </div>
                </div>
                <select name="role" id="role" className="form-input" value={role} onChange={(e) => editMember(_id, e.target.value)}>
                  {roles.map((role) => {
                    const roleSplit = role.split(":");
                    const roleStr = roleSplit[1] || roleSplit[0];

                    return <option value={role}>{roleStr}</option>;
                  })}
                </select>
                <button onClick={() => removeMember(_id)}>
                  <RiCloseLine size={30} />
                </button>
              </div>
            ))}
          </div>

          <Button type="submit" variant="primary" className="inviteMember" disabled={inviting || selectedMembers.length < 1}>
            {adding ? "Adding Members..." : "Add Members"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal >
  );
};

export default AddTeamMeberModal;
