import React, { useState, useEffect, createRef } from "react";
import { Modal, Form, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faUpload, faDownload, faKey, faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import { toast } from "react-toastify";
import UploadMapData from "./MicroModules/UploadMapData";
import ResetMapData from "./MicroModules/ResetMapData";
import { csgoDataProcessor } from "./../../../../../../utils/functions";

const MatchScoreModal = ({ show, onHide, match, updateMatches, setLeague, isScrim}) => {
  const elRefs = React.useRef([]);
  const [t1Score, setT1Score] = useState(0);
  const [t2Score, setT2Score] = useState(0);
  const [t1FF, setT1FF] = useState(false);
  const [t2FF, setT2FF] = useState(false);
  const [scoreReason, setScoreReason] = useState("");
  const [tournamentCodes, setTournamentCodes] = useState([]);

  const [submitting, setSubmitting] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [resettingMatch, setResettingMatch] = useState(false);
  const [genCodes, setGenCodes] = useState(false);

  const [uploadingMatchData, setUploadingMatchData] = useState(false);
  const [resettingMatchData, setResettingMatchData] = useState(false);
  const [mapSections, setMapSections] = useState([]);
  const [csgoMapdata, setCsgoMapdata] = useState([]);
  const [csgodata, setCsgodata] = useState({});

  const [teamDetails, setTeamDetails] = useState(null);

  const [uploadDataInModal, setUploadDataInModal] = useState(null);

  useEffect(() => {
    if (match.t1 && match.t2) {
      setTeamDetails({ t1: match.t1, t2: match.t2 });
    }

    setT1Score(match.t1Score);
    setT2Score(match.t2Score);
    setT1FF(match.t1FF);
    setT2FF(match.t2FF);
    setScoreReason(match.scoreReason || "");
    setTournamentCodes(match.csgoData && match.csgoData.tournamentCodes ? match.csgoData.tournamentCodes : []);
    setCsgoMapdata((match.csgoData && match.csgoData.mapData) || []);
    setCsgodata((match.csgoData && match.csgoData) || {});
  }, [match]);

  useEffect(() => {
    makeMapUpFields();
  }, [csgoMapdata]);

  const getWinnerTeamNameFromId = (teamData) => {
    const winTeam = Object.values(teamData).filter(({ winner }) => winner === true);
    let winTeamObj = [];
    if (winTeam.length) {
      let winTeamId = winTeam[0].teamDbId;
      winTeamObj = Object.values(teamDetails).filter(({ _id }) => _id === winTeamId);
      if (winTeamObj.length) {
        return winTeamObj[0].name;
      } else {
        return "Loading...";
      }
    } else {
      return "Loading...";
    }
  };

  const makeMapUpFields = () => {
    if (match && match.bestOf) {
      let datamapSections = [];
      for (let index = 0; index < match.bestOf; index++) {
        datamapSections.push(
          <Form.Group key={index}>
            <Form.Label className="d-block text-secondary">
              <small>Map {index + 1}:</small>
            </Form.Label>
            <div className="d-flex">
              {csgoMapdata.length && csgoMapdata[index] ? (
                <>
                  <Button onClick={() => resetMatchMapData(index)} variant="danger" className="d-block ml-2">
                    <FontAwesomeIcon icon={faRedoAlt} /> {resettingMatch ? "Reseting" : "Reset"} Match Data
                  </Button>
                  <span className="ml-4">Winner : {getWinnerTeamNameFromId(csgoMapdata[index].processedteamData)}</span>
                </>
              ) : (
                <Button onClick={() => uploadDataModal(index)} variant="success" className="d-block ">
                  <FontAwesomeIcon icon={faUpload} /> {uploadingMatchData ? "Uploading" : "Upload"} Match Data
                </Button>
              )}
            </div>
          </Form.Group>
        );
      }
      setMapSections(datamapSections);
    }
  };

  const onSubmitScore = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    try {
      let params = isScrim ? "?type=Scrim" : ''
      const { data } = await Axios.put(`${process.env.REACT_APP_CSGO_API}/api/matches/${match._id}${params}`, {
        t1Score,
        t2Score,
        t1FF,
        t2FF,
        scoreReason,
      });
      if (isScrim) {
        updateMatches(match._id, data.updatedMatch)
      } else {
        updateMatches((matches) => matches.map((m) => (m._id === match._id ? data.updatedMatch : m)));
        setLeague((league) => ({ ...league, teams: data.updatedLeague.teams }));
      }
      toast.success("Saved score");
      onHide();
    } catch (e) {
      toast.error("There was a problem saving score 😞");
    }

    setSubmitting(false);
  };

  const onManuallyFetch = async () => {
    setFetchingData(true);

    try {
      const { data } = await Axios.get(`${process.env.REACT_APP_CSGO_API}/api/matches/${match._id}/manuallyGetData`);

      updateMatches((matches) => matches.map((m) => (m._id === match._id ? data.updatedMatch : m)));
      setLeague((league) => ({ ...league, teams: data.updatedLeague.teams }));
      setT1Score(data.updatedMatch.t1Score);
      setT2Score(data.updatedMatch.t2Score);

      toast.success("Manually fetched match data from DATHOST");
    } catch (e) {
      toast.error("DATHOST could not get data/find any data from the tournament codes");
    }

    setFetchingData(false);
  };

  const onGenTournamentCodes = async () => {
    setGenCodes(true);
    // console.log('ssss',process.env.REACT_APP_CSGO_API)
    try {
      console.log("process.env.REACT_APP_CSGO_API=", process.env.REACT_APP_CSGO_API);
      const { data } = await Axios.post(`${process.env.REACT_APP_CSGO_API}/api/matches/${match._id}/generateTournamentCodes`);

      updateMatches((matches) => matches.map((m) => (m._id === match._id ? data.updatedMatch : m)));
      setTournamentCodes(data.updatedMatch.csgoData.tournamentCodes);

      toast.success("Manually generated tournament codes 😍");
    } catch (e) {
      console.log("eee-", e);
      toast.error("DATHOST could not generate tournament codes");
    }

    setGenCodes(false);
  };

  const onResetMatch = async () => {
    setResettingMatch(true);

    try {
      const { data } = await Axios.put(`${process.env.REACT_APP_CSGO_API}/api/matches/${match._id}/resetData`);

      updateMatches((matches) => matches.map((m) => (m._id === match._id ? data.updatedMatch : m)));
      setLeague((league) => ({ ...league, teams: data.updatedLeague.teams }));
      setT1Score(0);
      setT2Score(0);
      setT1FF(false);
      setT2FF(false);
      setScoreReason("");

      toast.success("Successfully reset match!");
    } catch (e) {
      toast.error("There was a problem resetting this match 😞");
    }

    setResettingMatch(false);
  };

  if (elRefs.current.length !== tournamentCodes.length) {
    // add or remove refs
    elRefs.current = Array(tournamentCodes.length)
      .fill()
      .map((_, i) => elRefs.current[i] || createRef());
  }

  const uploadDataModal = (mapIndex) => {
    setUploadDataInModal(mapIndex);
  };

  const setMapData = async (data, mapIdIndex, winnerTeamSelected) => {
    setUploadDataInModal(null);
    let csgoMapdataModul = csgoMapdata;

    let data_ProcessedData = await csgoDataProcessor(data, winnerTeamSelected, teamDetails);
    let t1ModiScore = null;
    let t2ModiScore = null;

    Object.keys(teamDetails).map((mapKey) => {
      if (teamDetails[mapKey]._id === winnerTeamSelected) {
        if (mapKey === "t1") {
          t1ModiScore = t1Score + 1;
          setT1Score(t1ModiScore);
        } else {
          t2ModiScore = t2Score + 1;
          setT2Score(t2ModiScore);
        }
      }
    });

    csgoMapdataModul[mapIdIndex] = data_ProcessedData;
    setCsgoMapdata(csgoMapdataModul);
    onupdateMatch(t1ModiScore, t2ModiScore);
    makeMapUpFields();
  };

  const resetMatchMapData = async (mapIdIndex) => {
    setUploadDataInModal(null);
    let csgoMapdataModul = csgoMapdata;

    // let winnerteamPdataObj = null;
    // let winnerteamKeyname = null;
    let winnerteamId = null;
    if (csgoMapdataModul[mapIdIndex].processedteamData.team1.winner) {
      // winnerteamKeyname = 't1';
      // winnerteamPdataObj = csgoMapdataModul[mapIdIndex].processedteamData.team1;
      winnerteamId = csgoMapdataModul[mapIdIndex].processedteamData.team1.teamDbId;
    } else {
      // winnerteamKeyname = 't2';
      // winnerteamPdataObj = csgoMapdataModul[mapIdIndex].processedteamData.team2;
      winnerteamId = csgoMapdataModul[mapIdIndex].processedteamData.team2.teamDbId;
    }

    let t1ModiScorer = null;
    let t2ModiScorer = null;
    console.log("t1Score before remo-", t1Score);
    console.log("t2Score before remo-", t2Score);
    Object.keys(teamDetails).map((mapKey) => {
      if (teamDetails[mapKey]._id === winnerteamId) {
        if (mapKey === "t1") {
          t1ModiScorer = t1Score - 1;
          setT1Score(t1ModiScorer);
          console.log("t1Score setting remo-");
        } else {
          t2ModiScorer = t2Score - 1;
          setT2Score(t2ModiScorer);
          console.log("t2Score setting remo-");
        }
      }
    });

    delete csgoMapdataModul[mapIdIndex];
    setCsgoMapdata(csgoMapdataModul);

    makeMapUpFields();

    onupdateMatch(t1ModiScorer, t2ModiScorer);
  };

  const onupdateMatch = async (t1Score = null, t2Score = null) => {
    console.log("onupdateMatch--score", t1Score, t2Score);
    setResettingMatchData(true);
    let needScoreToUpdate = {};
    if (t1Score !== null) {
      needScoreToUpdate.t1Score = t1Score;
    }
    if (t2Score !== null) {
      needScoreToUpdate.t2Score = t2Score;
    }
    console.log("onupdateMatch--", needScoreToUpdate);
    try {
      const { data } = await Axios.put(`${process.env.REACT_APP_CSGO_API}/api/matches/updateMapData/${match._id}`, {
        csgoData: { ...match.csgoData, mapData: csgoMapdata },
        ...needScoreToUpdate,
      });

      updateMatches((matches) => matches.map((m) => (m._id === match._id ? data.updatedMatch : m)));

      toast.success("Map Data Uploaded");
      onHide();
    } catch (e) {
      console.log("eee-", e);
      toast.error("There was a problem uploading Map Data 😞");
    }

    setResettingMatchData(false);
  };

  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Manually Set Score</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Score</p>

          <Form onSubmit={onSubmitScore}>
            <div className="d-flex">
              <Form.Group style={{ width: "100%" }}>
              <div className="input-group">
                <span class="input-group-addon prefix" onClick={() => setT1Score(t1Score + 1)} >+</span>
                <Form.Control type="number" value={t1Score} onChange={(e) => setT1Score(parseFloat(e.target.value))} required />
                <span class="input-group-addon suffix" onClick={() => setT1Score(t1Score - 1)}>-</span>
              </div>

                <small className="text-secondary d-flex justify-content-between">
                  <span>{match.t1 ? match.t1.name : "BYE"}</span>
                  <Form.Check label="FF" checked={t1FF} onChange={(e) => setT1FF(e.target.checked)} />
                </small>
              </Form.Group>

              <h4 className="ml-2 mr-2">-</h4>

              <Form.Group style={{ width: "100%" }}>
              <div className="input-group">
                <span class="input-group-addon prefix" onClick={() => setT2Score(t2Score + 1)} >+</span>
                <Form.Control type="number" value={t2Score} onChange={(e) => setT2Score(parseFloat(e.target.value))} required />
                <span class="input-group-addon suffix" onClick={() => setT2Score(t2Score - 1)}>-</span>
              </div>  

                <small className="text-secondary d-flex justify-content-between">
                  <span>{match.t2 ? match.t2.name : "BYE"}</span>
                  <Form.Check label="FF" checked={t2FF} onChange={(e) => setT2FF(e.target.checked)} />
                </small>
              </Form.Group>
            </div>

            <Form.Group>
              <Form.Label>Reason</Form.Label>
              <Form.Control type="text" value={scoreReason} onChange={(e) => setScoreReason(e.target.value)} />
            </Form.Group>

            <div className="d-flex justify-content-end">
              <Button type="submit" size="sm" variant="success" disabled={submitting}>
                {submitting ? "Saving..." : "Save"}
              </Button>
            </div>
          </Form>
        </Modal.Body>

       {!isScrim && <Modal.Footer className="d-block p-3">
          <p>Map(s) Data</p>
          {resettingMatchData ? "Saving..." : mapSections.map((element, i) => element)}
        </Modal.Footer>}

        {/* <Modal.Footer className="d-block p-3">
        <p>Quick Actions</p>

        {t1Score || t2Score ? (
          <Button variant="link" className="d-block p-0 mb-1 text-danger" onClick={onResetMatch} 
          // disabled={resettingMatch}
          disabled={true}
          >
            <FontAwesomeIcon icon={faRedoAlt} /> {resettingMatch ? "Resetting" : "Reset"} Match (Coming Soon)
          </Button>
        ) : (
          match.csgoData &&
          !!tournamentCodes.length && (
            <Button variant="link" className="d-block p-0 mb-1" onClick={onManuallyFetch}
            //  disabled={fetchingData}
            disabled={true}
             >
              <FontAwesomeIcon icon={faDownload} /> {fetchingData ? "Fetching" : "Manually Fetch"} Data (Coming Soon)
            </Button>
          )
        )}
        <Button variant="link" className="d-block p-0 mb-1" onClick={onGenTournamentCodes} disabled={genCodes}>
          <FontAwesomeIcon icon={faKey} /> {genCodes ? "Generating" : "Generate"} Match
        </Button>
      </Modal.Footer> */}

        {match.csgoData && !!tournamentCodes.length && !isScrim && (
          <Modal.Footer className="d-block p-3">
            <p>Tournament Codes</p>

            {tournamentCodes.map((code, i) => (
              <Form.Group key={i}>
                <Form.Label className="d-block text-secondary">
                  <small>Match {i + 1}:</small>
                </Form.Label>
                <div className="d-flex">
                  <Form.Control
                    ref={elRefs.current[i]}
                    readOnly
                    style={{ fontFamily: "monospace", fontSize: "12px" }}
                    size="sm"
                    type="text"
                    value={code}
                  />
                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Copy</Tooltip>}>
                    <Button
                      variant="link"
                      size="sm"
                      className="text-primary"
                      onClick={() => {
                        elRefs.current[i].current.focus();
                        elRefs.current[i].current.select();
                        document.execCommand("copy");
                        toast.success("Copied to clipboard ✅");
                      }}
                    >
                      <FontAwesomeIcon icon={faCopy} />
                    </Button>
                  </OverlayTrigger>
                </div>
              </Form.Group>
            ))}
          </Modal.Footer>
        )}
      </Modal>

      {uploadDataInModal !== null && !isScrim && (
        <UploadMapData
          show={uploadDataInModal !== null}
          onHide={() => setUploadDataInModal(null)}
          mapId={Number(uploadDataInModal)}
          teams={teamDetails}
          setMapData={setMapData}
        />
      )}
    </>
  );
};

export default MatchScoreModal;
