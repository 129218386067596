import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import Axios from "axios";
import { useForm } from "react-hook-form";
import ViewRole from "../include/ViewRole"
import { toast } from "react-toastify";
import _ from 'lodash';

const Permissions = ({ organisation, setOrganisation }) => {
  const [organisationRoles, setOrganisationRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [unassignLoading, setUnassignLoading] = useState(false);
  const [selectedOrganisationRole, setSelectedOrganisationRole] = useState({});
  const [organisationPermission, setOrganisationPermission] = useState({});
  const { register, handleSubmit, errors, setValue } = useForm();

  useEffect(() => {
    getOrganisationRoles();
  }, [organisation])

  const getOrganisationRoles = async () => {
    const { data } = await Axios.get(`${process.env.REACT_APP_CORE_API}/api/roles/`);
    let roles = data.roles.filter((el) => (el.type === "Organisation"));
    setOrganisationRoles(roles);
    setOrganisationPermission(roles.find((el) => (organisation.permissions === el._id)));
  }

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      const { data: newData } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/organisations/${organisation._id}/assignRole`, { data });
      setOrganisation({ ...organisation, permissions: newData.assignedRole.permissions })
      setLoading(false);
      setSelectedOrganisationRole({});
      setValue("permissions", "");

      toast.success("Successfuly assign role to organisation");
    } catch (e) {
      setLoading(false);

      const msg = e.response.data ? e.response.data.msg : "Error with assigning role to organisation";
      toast.error(msg);
    }
  }

  const unAssignRole = async () => {
    try {
      setUnassignLoading(true);

      const { data } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/organisations/${organisation._id}/unassignRole`);
      setOrganisation({ ...organisation, permissions: data.unassignedRole.permissions })
      setSelectedOrganisationRole([]);
      setUnassignLoading(false);
      setSelectedOrganisationRole({});
      setValue("permissions", "");

      toast.success("Successfuly Unassign role to user");
    } catch (e) {
      setUnassignLoading(false);

      const msg = e.response.data ? e.response.data.msg : "Error with Unassigning role to user";
      toast.error(msg);
    }
  }

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={6}>
            <h5>Own Permission</h5>
            <div className="ownPermission">
              <Form.Group>
                <Form.Label>Organisation Roles</Form.Label>
                <div className="d-flex align-items-center">
                  <Form.Control
                    as="select"
                    ref={register({ required: true })}
                    id="permissions"
                    name="permissions"
                    defaultValue=""
                    onChange={(e) => setSelectedOrganisationRole(organisationRoles.find((el) => (e.target.value === el._id)))}
                    isInvalid={errors.permissions}
                  >
                    <option value="" disabled>
                      Choose Permission
                    </option>
                    {organisationRoles.length && organisationRoles.map((role) => (
                      <option value={role._id} key={role?._id}>
                        {role?.name}
                      </option>
                    ))}
                  </Form.Control>
                </div>
              </Form.Group>

              {selectedOrganisationRole?.organisation ?
                <ViewRole roleName={selectedOrganisationRole.name} organisationRole={selectedOrganisationRole.organisation} /> : ""
              }

              {organisationPermission?.organisation && !selectedOrganisationRole?.organisation ?
                <ViewRole roleName={organisationPermission.name} organisationRole={organisationPermission.organisation} /> : ""
              }
            </div>
          </Col>
        </Row>


        <Button type="submit" variant="primary" disabled={loading || _.isEmpty(selectedOrganisationRole)}>
          {loading ? "Saving Role..." : "Save Role"}
        </Button>

        {organisation?.permissions &&
          <Button variant="danger" disabled={unassignLoading} onClick={unAssignRole} style={{ marginLeft: "0.5em" }}>
            {unassignLoading ? "Unassigning Role..." : "Unassign Role"}
          </Button>
        }

      </Form>

    </div>
  );
};

export default Permissions;


