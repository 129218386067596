import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-bootstrap";
import TabsManager from "./Tabs/TabsManager";
import { useForm } from "react-hook-form";

const SingleGameEdit = () => {
  const { id } = useParams();
  const [game, setGame] = useState({});
  const { watch } = useForm();
  let frontPage = watch("frontPage", game.frontpage);
  const [loading, setLoading] = useState(true);
  const [saved, setSaved] = useState(true);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/games/${id}`).then(({ data }) => {
      let modifiedData = data;
      setGame(modifiedData);
      setLoading(false);
    });
  }, [id]);

  const setTempraryData = () => {
    console.log("setTempraryData");
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/games/${id}`).then(({ data }) => {
      setGame(data);
      setSaved(true);
    });
  };

  return loading ? (
    "Loading..."
  ) : (
    <div>
      <div className="admin-page-title">
        <h2>
          {game ? "Edit Game " : "Create Game "} {game.name}
        </h2>
        <Button variant="success" className="space-right btn-success" as={Link} to={`/game-settings`}>
          <FontAwesomeIcon icon={faBackward} /> Go Back
        </Button>
      </div>
      <Row>
        <Col>
          <TabsManager game={game} frontPage={frontPage} setGame={setGame} setTempraryData={(temData) => setTempraryData(temData)} isSaved={saved} />
        </Col>
      </Row>
    </div>
  );
};

export default SingleGameEdit;
