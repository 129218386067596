/**
 * Sort the teams on the basis of score
 * @param {Array} teams All the teams to sort
 * @returns Array of teams sorted in descending order on basis of score
 */
 export const sortDescendingScore = (teams) => {
  return teams.sort((a, b) => b.score - a.score);
};

export const sortTeams = (teams = [], league, isManualSort = false) => {
  const {
    matches,
    game: { name: gameName },
  } = league;
  teams = teams.filter((team) => team && !!team.team);
  return isManualSort
    ? teams.sort((a, b) => a.order - b.order)
    : sortDescendingScore(teams);
};
