import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import Axios from "axios";
import { toast } from "react-toastify";
import EditImageModal from "../../../../../_modals/EditImageModal/EditImageModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";


const General = ({ organisation, setOrganisation }) => {
  const [loading, setLoading] = useState(false);
  const [modals, setModals] = useState({});
  const [logoCropped, setLogoCropped] = useState(organisation.logoCropped);
  const { register, handleSubmit, errors } = useForm();

  const onUpdateImage = async (type, imageUrl) => {
    try {
      const { data } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/organisations/${organisation._id}/image`, { type, image: imageUrl });
      setOrganisation((organisation) => ({ ...organisation, ...data.updatedImages }));
      setModals({ ...modals, [type]: false });

      toast.success("Organisation Pictures updated");
    } catch (err) {
      toast.error("There was a problem updating Organisation images!");
    }
  };

  const onUpdateLogoCheck = async (value) => {
    setLogoCropped(value)
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      const { data: newData } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/organisations/${organisation._id}`, { ...data });
      setOrganisation({ ...organisation, ...newData.updatedOrganisation })
      setLoading(false);

      toast.success("Details updated");
    } catch (e) {
      setLoading(false);
      const msg = e.response.data ? e.response.data.msg : "Fatal error";
      toast.error(msg);
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                ref={register({ required: "Required", maxLength: { value: 200, message: "Name too long" } })}
                type="text"
                id="name"
                name="name"
                autoComplete="off"
                placeholder="Name"
                defaultValue={organisation.name}
                isInvalid={errors.name}
              />
              <Form.Control.Feedback type="invalid">{errors.name && errors.name.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Tagline</Form.Label>
              <Form.Control
                ref={register({ maxLength: { value: 50, message: "Tagline too long" } })}
                type="text"
                id="tagline"
                name="tagline"
                maxLength={50}
                placeholder="Tagline"
                autoComplete="off"
                isInvalid={errors.tagline}
                defaultValue={organisation.tagline}
              />
              <Form.Control.Feedback type="invalid">{errors.tagline && errors.tagline.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Public Contact Email</Form.Label>
              <Form.Control
                ref={register({
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
                type="text"
                id="email"
                name="email"
                autoComplete="off"
                placeholder="Organisation@mail.com"
                defaultValue={organisation.email}
                isInvalid={errors.email}
              />
              <Form.Control.Feedback type="invalid">{errors.email && errors.email.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md={2}>
            <Form.Group>
              <Form.Label>Phone Number</Form.Label>
              <div className="d-flex align-items-center">
                <Form.Control
                  as="select"
                  ref={register()}
                  name="phoneExt" id="phoneExt"
                  defaultValue={organisation.phoneExt ? organisation.phoneExt : "+45"}
                >
                  <option value="" disabled>
                    Choose Code
                  </option>
                  {[
                    "+30",
                    "+31",
                    "+32",
                    "+33",
                    "+34",
                    "+36",
                    "+39",
                    "+40",
                    "+43",
                    "+44",
                    "+45",
                    "+46",
                    "+47",
                    "+48",
                    "+49",
                    "+350",
                    "+351",
                    "+352",
                    "+353",
                    "+354",
                    "+356",
                    "+357",
                    "+358",
                    "+359",
                    "+370",
                    "+371",
                    "+372",
                    "+385",
                    "+386",
                    "+420",
                    "+421",
                    "+423",
                  ].map((ext, index) => (
                    <option key={index} value={ext}>{ext}</option>
                  ))}
                </Form.Control>
              </div>
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Control
              ref={register({ maxLength: { value: 13, message: "Phone number too long" } })}
              type="number"
              id="phone"
              name="phone"
              autoComplete="off"
              defaultValue={organisation.phone}
              placeholder="Phone number"
              style={{ marginTop: "2em" }}
              isInvalid={errors.phone}
            />
            <Form.Control.Feedback type="invalid">{errors.phone && errors.phone.message}</Form.Control.Feedback>
          </Col>
        </Row>

        <Form.Group>
          <Form.Label>About</Form.Label>
          <Form.Control
            ref={register({ maxLength: 5000 })}
            as="textarea"
            name="about"
            id="about"
            rows={5}
            placeholder="Write something about organisation"
            defaultValue={organisation.description}
            isInvalid={errors.about}
          />
          <Form.Control.Feedback type="invalid">{errors.about && errors.about.message}</Form.Control.Feedback>
        </Form.Group>

        <Row>
          <div className="d-flex">
            {[
              { name: "Logo", key: "logo", width: 250, height: 250, displayWidth: 150, displayHeight: 150, crop: true },
            ].map(({ name, key, width, height, displayWidth, displayHeight, crop }) => (
              <div key={key}>
                <EditImageModal
                  show={modals[key]}
                  onHide={() => setModals({ ...modals, [key]: false })}
                  name={name}
                  width={width}
                  height={height}
                  afterImageUpload={(file) => onUpdateImage(key, file)}
                  crop={crop}
                />
                <Form.Group className="mr-4">
                  <Form.Label>{name}</Form.Label>
                  <div
                    className={` ${!logoCropped ? "upload-image-btn" : "upload-image-btn-cropped"}`}
                    style={{
                      backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${organisation[key]})`,
                      width: displayWidth,
                      height: displayHeight,
                    }}
                    onClick={() => setModals({ ...modals, [key]: true })}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </div>
                </Form.Group>
              </div>
            ))}
          </div>
          <div className="d-flex">
            {[
              { name: "Banner", key: "banner", width: 800, height: 200, displayWidth: 500, displayHeight: 150, crop: false },
            ].map(({ name, key, width, height, displayWidth, displayHeight, crop }) => (
              <div key={key}>
                <EditImageModal
                  show={modals[key]}
                  onHide={() => setModals({ ...modals, [key]: false })}
                  name={name}
                  width={width}
                  height={height}
                  afterImageUpload={(file) => onUpdateImage(key, file)}
                  crop={crop}
                />
                <Form.Group className="mr-4">
                  <Form.Label>{name}</Form.Label>
                  <div
                    className="edit-image-btn"
                    style={{
                      backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${organisation[key]})`,
                      width: displayWidth,
                      height: displayHeight,
                    }}
                    onClick={() => setModals({ ...modals, [key]: true })}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </div>
                </Form.Group>
              </div>
            ))}
          </div>
        </Row>

        <Form.Check
          ref={register()}
          type="checkbox"
          label="Logo Cropped"
          id="logoCropped"
          name="logoCropped"
          style={{ marginBottom: "1em" }}
          defaultChecked={logoCropped}
          onClick={() => onUpdateLogoCheck(!logoCropped ? true : false)}
        />

        <Button type="submit" variant="success" disabled={loading}>
          {loading ? "Saving..." : "Save Changes"}
        </Button>
      </Form>
    </div>
  );
};

export default General;
