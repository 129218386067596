import React, { useState, useEffect } from "react";
import { Modal, Form, FormGroup, Button } from "react-bootstrap";
import Axios from "axios";

const AdvertCreateEditModal = ({ show, handleClose, data, setAdverts }) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data) setName(data.name);
  }, [data]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (data) {
      // Edit
      await Axios.post(`${process.env.REACT_APP_CORE_API}/api/adverts/${data._id}`, {
        name,
      });

      // update score systems in parent state
      setAdverts((adverts) => adverts.map((advert) => (advert._id === data._id ? { ...advert, name } : advert)));
    } else {
      // Create
      const { data: resData } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/adverts`, { name });

      // Update parent state
      setAdverts((adverts) => [...adverts, resData.advert]);
    }

    setLoading(false);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{data ? "Edit" : "Create"} Advert</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <FormGroup>
            <Form.Label>Ref Name</Form.Label>
            <Form.Control type="text" id="size" name="size" value={name} onChange={(e) => setName(e.target.value)} />
          </FormGroup>

          <div className="d-flex justify-content-end">
            <Button type="submit" variant="success" disabled={loading}>
              {loading ? "Processing..." : data ? "Edit" : "Create"}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AdvertCreateEditModal;
