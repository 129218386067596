import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import moment from "moment";
import Axios from "axios";
import Datetime from "react-datetime";
import { serverLocations } from "../../../../../utils/server-locations";

const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Firday", "Saturday"];

export const Settings = ({ tournament, setTournament }) => {
  const { handleSubmit, register, errors, getValues, setValue } = useForm();
  const [loading, setLoading] = useState(false);
  const [hideTournament, setHideTournament] = useState(true);
  const [showOn, setShowOn] = useState(false);
  const [location, setLocation] = useState(tournament.csgoServerLocation);
  const [showOnDate, setShowOnDate] = useState(false);
  const [isCSGO, setIsCSGO] = useState(false);
  const [tomorrow, setTomorrow] = useState(moment().subtract(1, "day").set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
  const [rescheduleType, setRescheduleType] = useState(false);
  const [weekDays, setWeekDays] = useState([]);
  const [earliestDate, setEarliestDate] = useState(false);
  const [latestDate, setLatestDate] = useState(false);
  const [minDate, setMinDate] = useState(false);
  const [canPlayerVetoCheckbox, setCanPlayerVetoCheckbox] = useState(tournament.canPlayersVeto);
  
  // Use Effect
  useEffect(() => {
    calculateDateRanges();
    calculateWeakDays();
    setHideTournament(tournament.hideTournament);
    setShowOnDate(tournament.showOnDate ? moment(tournament.showOnDate) : null);
    setShowOn(tournament.showOnDate ? true : false);
    setRescheduleType(tournament?.reschedule?.rescheduleType || "NO_RESCHEDULE");
    setEarliestDate(
      tournament.reschedule && tournament.reschedule.earliestDate
        ? moment(tournament.reschedule.earliestDate)
        : tournament.startDate
        ? moment(tournament.startDate)
        : false
    );
    setLatestDate(
      tournament.reschedule && tournament.reschedule.latestDate
        ? moment(tournament.reschedule.latestDate).toISOString()
        : tournament.startDate
        ? moment(tournament.startDate).add(7, "days").set({ hour: 23, minute: 59, second: 0, millisecond: 0 }).toISOString()
        : false
    );
    setIsCSGO(tournament.game?.name == "CS:GO");
  }, [tournament]);
 
  // Calculate range bw current date and next weak end
  const calculateDateRanges = () => {
    let tomorrow = moment().subtract(1, "day").set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    let today = moment().subtract(1, "days").set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    let min = moment.max(today, moment(tournament.startDate));

    setMinDate(min);
    setTomorrow(tomorrow);
  };

  function sortDays(array) {
    let daysSort = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday',
                  'Saturday', 'Sunday'];
    let sortedDays = []
    daysSort.forEach(async (value) => {
       sortedDays.push(array.find((e) => e.name == value))
    });
    return sortedDays
  }
  
  const calculateWeakDays = async () => {
    let days = [];
    let currentDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    days.push({ date: currentDate, name: currentDate.format("dddd") });
    for (let i = 1; i <= 7; i++) {
      let date = moment(days[i - 1].date).add(1, "days");
      let date1 = date;
      days.push({ date, name: date1.format("dddd") });
    }
    let sortedArray = sortDays(days)
    setWeekDays(sortedArray);
  };

  // On match reschudule type selection
  const onMatchReschedule = (e) => {
    const { value } = e.target;
    if (value === "WEEK") {
      setEarliestDate(minDate.toISOString());
    }
    if (value === "INTERVAL") {
      setEarliestDate(
        tournament.reschedule && tournament.reschedule.earliestDate
          ? moment(tournament.reschedule.earliestDate)
          : tournament.startDate
          ? moment(tournament.startDate)
          : false
      );
      setLatestDate(
        tournament.reschedule && tournament.reschedule.latestDate
          ? moment(tournament.reschedule.latestDate).toISOString()
          : tournament.startDate
          ? moment(tournament.startDate).add(7, "days").set({ hour: 23, minute: 59, second: 0, millisecond: 0 }).toISOString()
          : false
      );
    }
    setRescheduleType(value);
  };

  // // On match reschudule type selection
  // const onMatchReschedule = (e) => {
  //   const { value } = e.target;
  //   setRescheduleType(value);
  // };

  // On submit chagnes
  const onSubmit = async (data) => {
    if (data?.setPlayersDeadlineHr < 0 && data?.setPlayersDeadlineMin < 0) {
      toast.error("Invalid Set Player Deadline Time");
      return;
    }
    const { setPlayersDeadlineHr, setPlayersDeadlineMin, forfeitTeams, ...restData } = data;
    if (showOn && hideTournament) {
      if (moment(showOnDate).format("DD/MM/YYYY HH:mm:ss").valueOf() <= moment().format("DD/MM/YYYY HH:mm:ss").valueOf() || !showOnDate) {
        toast.error("Invalid Time");
        return;
      }
    }

    setLoading(true);

    try {
      const formData = {
        ...restData,
        hideTournament,
        showOnDate: showOn && hideTournament ? moment(showOnDate).tz(tournament.timezone) : null,
        setPlayersDeadline: +setPlayersDeadlineHr * 60 + +setPlayersDeadlineMin,
        reschedule: {rescheduleType, earliestDate , latestDate},
        forfeitTeam: data.forfeitTeams,
        canPlayersVeto: data.canPlayersVeto
      };
      if (isCSGO) {
        formData.csgoServerLocation = location;
      }
      await Axios.put(`${process.env.REACT_APP_CORE_API}/api/tournaments/setting/${tournament._id}`, formData);

      setTournament((tournament) => ({ ...tournament, ...formData, reschedule: { rescheduleType, earliestDate, latestDate }}));

      toast.success("Saved");
    } catch (error) {
      toast.error("There was a problem saving");
    } finally {
      setLoading(false);
    }
  };

  const formValid = !!(rescheduleType == "NO_RESCHEDULE" ? true : earliestDate && latestDate);
  
  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row style={{ marginTop: "0.5em" }}>
          <Col md={3}>
            <Form.Group>
              <Form.Check
                type="checkbox"
                name="hideLeague"
                checked={hideTournament}
                label="Hide Tournament"
                onChange={() => {
                  setHideTournament(!hideTournament);
                }}
              />
            </Form.Group>
          </Col>

          {hideTournament && (
            <Col md={2}>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  name="showOn"
                  checked={showOn}
                  label="Show On"
                  onChange={() => {
                    setShowOn(!showOn);
                  }}
                />
              </Form.Group>
            </Col>
          )}

          {hideTournament && showOn && (
            <Row>
              <Form.Label style={{ marginRight: "1em" }}>Date</Form.Label>
              <div style={{ marginTop: "-0.4em" }}>
                <Datetime
                  defaultValue={showOnDate ? showOnDate : ""}
                  onChange={setShowOnDate}
                  dateFormat="DD/MM/YYYY"
                  timeFormat="HH:mm:ss"
                  isValidDate={(currentDate) => currentDate.isAfter(tomorrow)}
                />
              </div>
            </Row>
          )}
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>Server Location</Form.Label>
              <Form.Control
                as="select"
                value={location}
                id="csgoServerLocation"
                name="csgoServerLocation"
                onChange={(e) => {
                  setLocation(e.target.value);
                }}
                onBlur={handleSubmit(onSubmit)}
              >
                <option value={false} disabled>
                  Select Location
                </option>
                {serverLocations.map((serverLocation, index) => (
                  <option key={index} value={serverLocation.key}>
                    {serverLocation.value}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Set Players Deadline (Hrs)</Form.Label>
              <Form.Control
                ref={register()}
                type="number"
                id="setPlayersDeadlineHr"
                name="setPlayersDeadlineHr"
                defaultValue={parseInt(tournament.setPlayersDeadline / 60)}
                onBlur={handleSubmit(onSubmit)}
              />
              <Form.Control.Feedback type="invalid">{errors.setPlayersDeadline && errors.setPlayersDeadline.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Set Players Deadline (Min)</Form.Label>
              <Form.Control
                ref={register()}
                type="number"
                id="setPlayersDeadlineMin"
                name="setPlayersDeadlineMin"
                defaultValue={tournament.setPlayersDeadline % 60}
                onBlur={handleSubmit(onSubmit)}
                max={59}
                min={0}
              />
              <Form.Control.Feedback type="invalid">{errors.setPlayersDeadline && errors.setPlayersDeadline.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group className="mb-3">
              <Form.Label>Match Rescheduling</Form.Label>
              <Form.Control as="select" value={rescheduleType} id="matchReschedule" name="matchReschedule" onChange={onMatchReschedule}>
                <option value="NO_RESCHEDULE">No Reschduling</option>
                <option value="WEEK">Within The Week</option>
                <option value="INTERVAL">Within An Interval</option>
              </Form.Control>
            </Form.Group>
          </Col>
          {isCSGO && (
            <Col sm={6}>
              <Form.Group className="mb-3">
                <Form.Label>Server Location</Form.Label>
                <Form.Control
                  as="select"
                  value={location}
                  id="csgoServerLocation"
                  name="csgoServerLocation"
                  onChange={(e) => {
                    setLocation(e.target.value);
                  }}
                  onBlur={handleSubmit(onSubmit)}
                >
                  <option value={false} disabled>
                    Select Location
                  </option>
                  {serverLocations.map((serverLocation, index) => (
                    <option key={index} value={serverLocation.key}>
                      {serverLocation.value}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          )}

          {rescheduleType && rescheduleType === "WEEK" && (
            <Col sm={6}>
              <Form.Group className="mb-3">
                <Form.Label>Week Start</Form.Label>
                <Form.Control
                  as="select"
                  value={tournament.reschedule.rescheduleType === "WEEK" ? latestDate : null}
                  id="weekStart"
                  name="weekStart"
                  onChange={(e) => {
                    console.log(e.target.value)
                    setLatestDate(moment(e.target.value).toISOString());
                    setEarliestDate(minDate.toISOString());
                  }}
                >
                  <option value={false} disabled>
                    Select Week Day
                  </option>
                  {weekDays.map((day, index) => (
                    <option key={index} value={moment(day.date).toISOString()}>
                      {day.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          )}
          <Col sm={6}>
            <Form.Group>
              <Form.Label>Tournament Finished?</Form.Label>
              <Form.Control
                as="select"
                ref={register({ required: true })}
                defaultValue={tournament.tournamentFinished || false}
                id="tournamentFinished"
                name="tournamentFinished"
                onBlur={() => {
                  if (
                    tournament.matches.length !== 0 &&
                    tournament.matches.some((match) => moment(match.datetime) >= moment()) &&
                    getValues("tournamentFinished") === "true"
                  ) {
                    if (
                      window.confirm("There are some upcoming matches in the tournament. Are you sure to make this tournament finished?")
                    ) {
                      handleSubmit(onSubmit)();
                    } else {
                      setValue("tournamentFinished", false);
                    }
                  } else {
                    handleSubmit(onSubmit)();
                  }
                }}
              >
                <option value={false}>No</option>
                <option value={true}>Yes</option>
              </Form.Control>
              {tournament.matches.length !== 0 &&
              tournament.matches.some((match) => moment(match.datetime) >= moment()) &&
              tournament.tournamentFinished === "true" ? (
                <span className="text-danger">There are some upcoming matches in the tournament</span>
              ) : null}
              <Form.Control.Feedback type="invalid">{errors.tournamentFinished && errors.tournamentFinished.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
        {rescheduleType && rescheduleType === "INTERVAL" && (
            <>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Earliest Date</Form.Label>
                  <Datetime
                    value={earliestDate ? moment(earliestDate).format("DD/MM/YYYY HH:mm:ss") : null}
                    onChange={setEarliestDate}
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm:ss"
                    isValidDate={(currentDate) => currentDate.isAfter(
                      tournament.startDate
                      ? moment(tournament.startDate) :
                      tournament.reschedule && tournament.reschedule.earliestDate
                      ? moment(tournament.reschedule.earliestDate)
                      : false
                    )}
                  />
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Latest Date</Form.Label>
                  <Datetime
                    value={latestDate ? moment(latestDate).set({ hour: 23, minute: 59, second: 0, millisecond: 0 }).format("DD/MM/YYYY HH:mm:ss") : null}
                    onChange={setLatestDate}
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm:ss"
                    isValidDate={(currentDate) => currentDate.isAfter(earliestDate)}
                    name="latestDate"
                  />
                  <span className="text-danger">{moment(earliestDate).isAfter(moment(latestDate)) ? "Latest Date should be after the Earliest Date" : null}</span>
                </Form.Group>
              </Col>
            </>
          )}
        </Row>
        <Row>
          <Col sm={12}>
            <Form.Group>
              <Form.Label>Forfeit Teams Failing To Set Player</Form.Label>
              <div className="d-flex align-items-center">
                <Form.Control as="select" ref={register()} id="forfeitTeams" name="forfeitTeams" defaultValue={tournament.forfeitTeam}>
                  <option value="doNothing">Do Nothing</option>
                  <option value="useLastPlayers">Use Last Players</option>
                  <option value="forfietTeam">Forfeit Team</option>
                </Form.Control>
              </div>
            </Form.Group>
          </Col>
        </Row>
        {isCSGO && (
          <Row>
            <Col sm={12}>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check
                  ref={register()}
                  type="checkbox"
                  id="canPlayersVeto"
                  name="canPlayersVeto"
                  label="Allow Players to Veto"
                  checked={canPlayerVetoCheckbox}
                  onChange={() => setCanPlayerVetoCheckbox(!canPlayerVetoCheckbox)}
                />
              </Form.Group>
            </Col>
          </Row>
        )}

        <Button type="submit" variant="success" disabled={loading}>
          {loading ? "Saving..." : "Save Changes"}
        </Button>
      </Form>
    </div>
  );
};

export default Settings;
