import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Axios from "axios";
import { useParams } from "react-router-dom";

const LeagueCreateEditModal = ({ show, handleClose, data = {}, setLeagues }) => {
  const { id: season } = useParams();
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (formData) => {
    setLoading(true);
    
    if (data) {
      // Edit
      const { data: resData } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/leagues/${data._id}`, {...formData,timezone:data.timezone});

      // update season in parent state
      setLeagues((leagues) => leagues.map((league) => (league._id === data._id ? resData.updatedLeague : league)));
    } else {
      // Create
      const { data: resData } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/leagues`, { season, ...formData });

      // Update parent state
      setLeagues((leagues) => [...leagues, resData.league]);
    }

    setLoading(false);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{data ? "Edit" : "Create"} League</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              ref={register({ required: "Required" })}
              type="text"
              id="name"
              name="name"
              defaultValue={data.name}
              isInvalid={errors.name}
            />
            <Form.Control.Feedback type="invalid">{errors.name && errors.name.message}</Form.Control.Feedback>
          </Form.Group>

          <Button type="submit" variant="success" className="float-right" disabled={loading}>
            {loading ? "Processing..." : data ? "Edit" : "Create"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default LeagueCreateEditModal;
