import React, { useState, useEffect } from "react";
import Axios from "axios";
import MapStepForm from "./MapStepForm";
import { Form, Row, Col, Accordion, Button, Spinner, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { serverLocations } from "../../../../../../utils/server-locations";

export const StepMapSelection = ({ match, onHide, vetoData, setSubmitError, setUpdatedSteps, serverLocation }) => {
  const [maps, setMaps] = useState([]);
  const [vetoSteps, setVetoSteps] = useState([]);
  const [connectTime, setConnectTime] = useState(900);
  const [isStartServer, setIsStartServer] = useState(false);
  const [isStepsSet, setIsStepsSet] = useState(false);
  const [isServerRunning, setIsServerRunning] = useState(false);
  const [serverInfo, setServerInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataFetch, setIsDataFetch] = useState(false);
  const [location, setLocation] = useState(serverLocation)

  useEffect(() => {
    if (match) {
      Axios.get(`${process.env.REACT_APP_CSGO_API}/api/matches/${match._id}/veto/maps`).then(({ data }) => {
        setMaps(data.csgoMaps);
        setServerInfo(data.serverInfo);
        setIsServerRunning(data.isServerStarted);
        setIsDataFetch(true);
      });
      setVetoSteps(vetoData);
      setIsStepsSet(vetoData.every((el) => el.map !== null));
      startServerValidation(vetoData);
    }
  }, [match, vetoData]);

  useEffect(() => {
    console.log("serverInfo : ", serverInfo)
  }, [serverInfo])
  const onVetoStepChange = (selectedStep) => {
    let _vetoSteps = [...vetoSteps];
    let currentStepIndex = _vetoSteps.findIndex((step) => step.no == selectedStep.no);
    _vetoSteps[currentStepIndex].map = selectedStep.map;

    if (selectedStep.action === "PICK") {
      let nextStepIndex = _vetoSteps.findIndex((step) => step.no == selectedStep.no + 1);
      _vetoSteps[nextStepIndex].map = selectedStep.map;
      setUpdatedSteps(_vetoSteps);
      setVetoSteps(_vetoSteps);
    } else if (selectedStep.action === "SIDE_PICK") {
      _vetoSteps[currentStepIndex].ct = selectedStep.ct;
      _vetoSteps[currentStepIndex].t = selectedStep.t;
      setUpdatedSteps(_vetoSteps);
      setVetoSteps(_vetoSteps);
    } else {
      setUpdatedSteps(_vetoSteps);
      setVetoSteps(_vetoSteps);
    }
    startServerValidation(_vetoSteps);
  };

  const startServerValidation = (steps) => {
    const _isStartServer = steps.every((el) => el.map !== null);
    setIsStartServer(_isStartServer);
  };

  const startServer = () => {
    try {
       Swal.fire({
         text: "Do you want to start the server ?",
         icon: "warning",
         showCancelButton: true,
         confirmButtonText: "Yes",
         confirmButtonColor: "#28a745",
         cancelButtonText: "No",
       }).then(async (result) => {
         if (result.isConfirmed) {
            setIsLoading(true);
            Axios.post(`${process.env.REACT_APP_CSGO_API}/api/matches/${match._id}/veto/startServer`, { location }).then(({ data }) => {
              toast.success("Successfully Started Server");
              setIsLoading(false);
              setServerInfo(data.data.serverInfo);
              setIsServerRunning(true);
            }).catch((err) => {
              const msg = err.response.data ? err.response.data.msg : "There was a problem starting the server";
              toast.error(msg);
              setIsLoading(false);
              setIsServerRunning(false);
            });
         }
       });
    } catch (error) {
      toast.error("Error Starting Server");
    }
  };

  const stopServer = () => {
    try {
      Swal.fire({
        text: "Do you want to stop this server ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#28a745",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setIsLoading(true);
          Axios.post(`${process.env.REACT_APP_CSGO_API}/api/matches/${match._id}/veto/stopServer`, {}).then(({ data }) => {
            toast.success("Successfully Stopped Server");
            setIsLoading(false);
            setIsServerRunning(false);
          });
        }
      });
    } catch (error) {
      toast.error("Error Stopped Server");
    }
  };

  return (
    <div>
      {isDataFetch ? (
        <Accordion defaultActiveKey={2}>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey={1} style={{ cursor: "pointer" }}>
              Veto Steps Settings
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={1}>
              <Card.Body>
                <Accordion defaultActiveKey={1}>
                  {vetoSteps?.map((step, key) => (
                    <MapStepForm
                      key={key}
                      match={match}
                      maps={maps}
                      currentStep={step}
                      vetoSteps={vetoSteps}
                      setSubmitError={setSubmitError}
                      onVetoStepChange={onVetoStepChange}
                    />
                  ))}
                </Accordion>
              </Card.Body>
            </Accordion.Collapse>
          </Card>

          <Card>
            <Accordion.Toggle as={Card.Header} eventKey={2} style={{ cursor: "pointer" }}>
              Server Information
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={2}>
              <Card.Body>
                {isStepsSet && !isServerRunning && (
                  <Form className="w-100">
                    <p className="text-danger">
                      NOTE: To start the game server for this match please complete veto steps first then start server.
                    </p>
                    <Row>
                      <Col xl="9">
                        <Form.Group>
                          <Form.Label>Server Location</Form.Label>
                          <Form.Control
                            as="select"
                            value={location}
                            id="csgoServerLocation"
                            name="csgoServerLocation"
                            onChange={(e) => {
                              setLocation(e.target.value);
                            }}
                          >
                            <option value={false} disabled>
                              Select Location
                            </option>
                            {serverLocations.map((serverLocation, index) => (
                              <option key={index} value={serverLocation.key}>
                                {serverLocation.value}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col xl="3" className="pt-4" style={{ marginTop: "7px" }}>
                        <Button disabled={!isStartServer} type="button" variant="success" onClick={startServer}>
                          {!isLoading ? (
                            <>Start Server</>
                          ) : (
                            <>
                              Starting Server <Spinner animation="border" size="sm" />
                            </>
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}

                {!isStepsSet && <p className="text-danger">NOTE: To start match server first set choices in veto steps settings tab.</p>}
                {isServerRunning && (
                  <div>
                    <div className="mb-3">
                    <Row className="mb-2">
                        <Col xl="3">
                          {" "}
                          <strong> ID: </strong>{" "}
                        </Col>
                        <Col xl="6">
                          <span> {serverInfo.id} </span>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col xl="3">
                          {" "}
                          <strong> Server Name: </strong>{" "}
                        </Col>
                        <Col xl="6">
                          <span> {serverInfo.name} </span>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col xl="3">
                          {" "}
                          <strong> Server ID: </strong>{" "}
                        </Col>
                        <Col xl="6">
                          <span> {serverInfo.serverId} </span>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col xl="3">
                          {" "}
                          <strong> Match Server IP: </strong>{" "}
                        </Col>
                        <Col xl="6">
                          <span>
                            {" "}
                            {serverInfo?.ip}{" "}
                          </span>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col xl="3">
                          {" "}
                          <strong> IP Connect: </strong>{" "}
                        </Col>
                        <Col xl="9">
                          <span>
                            {" "}
                            {serverInfo?.ipConnect}{" "}
                          </span>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col xl="3">
                          {" "}
                          <strong> GOTV: </strong>{" "}
                        </Col>
                        <Col xl="6">
                          <span> {serverInfo?.GOTV}{" "} </span>
                        </Col>
                      </Row>
                      <Row className="mb-2">
                        <Col xl="3">
                          {" "}
                          <strong> GOTV Connect: </strong>{" "}
                        </Col>
                        <Col xl="9">
                          <span> {serverInfo?.gotvConnect}{" "} </span>
                        </Col>
                      </Row>
                    </div>

                    <Button type="button" variant="danger" onClick={stopServer}>
                      Stop Server
                    </Button>
                  </div>
                )}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      ) : (
        <div className="text-center">
          <Spinner animation="border" size="lg" />
        </div>
      )}
    </div>
  );
};

export default StepMapSelection;
