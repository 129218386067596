import React, { useState, useEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import Axios from "axios";

const MultiStageLeagueFilterMatches = ({ setFilteredMatches, stageIndex, groupIndex, allStageMatches}) => {
  // console.log("get filter matches ==>>",setFilteredMatches);
  const { id } = useParams();
  const [filterQuery, setFilterQuery] = useState("");
  const [isSearch, setIsSearch] = useState(false)
  // When query changes, filter matches
  useEffect(() => {
    const q = filterQuery.toLowerCase();
    const newMatches = [];
    if(isSearch){
    if (stageIndex < 0) {
      // allStages.map((stage, index)=>{
      //   stage.groups.map((group, i)=>{
        let filtered = allStageMatches.stage.groups.matches.filter((match) => {

        //  let filtered =  group.matches.filter((match) => {
          const { t1, t2, datetime, tournament, isSeedingMatch, sp1, sp2 } = match;

          const t1n = t1 ? t1.name : tournament ?  "TBD" : isSeedingMatch ? `Seed ${sp1.seedPosition}` : "";
            const t2n = t1 ? t2.name : tournament ?  "TBD" : isSeedingMatch ? `Seed ${sp2.seedPosition}` : "";
   
            // If the search contains [x vs x] search for those teams
            const vsQuery = q.match(/(.+)\s+v.?s.?\s+(.+)/i);
            if (vsQuery) {
              return (
                (t1n.toLowerCase().includes(vsQuery[1].trim()) && t2n.toLowerCase().includes(vsQuery[2].trim())) ||
                (t2n.toLowerCase().includes(vsQuery[1].trim()) && t1n.toLowerCase().includes(vsQuery[2].trim()))
              );
            }
   
            // Add data to this that you want to search
            const searchFields = [t1n, t2n, moment(datetime).format("DD/MM/YYYY HH:mm")];
   
            return !!searchFields.filter((field) => field.toLowerCase().includes(q)).length;
          });
          if(filtered.length > 0) newMatches.push(...filtered);
        // };
      //  });
    } else {
      let filtered = allStageMatches[stageIndex].groups[groupIndex].matches.filter((match) => {
        const { t1, t2, datetime, tournament, isSeedingMatch, sp1, sp2 } = match;
        const t1n = t1 ? t1.name : tournament ?  "TBD" : isSeedingMatch ? `Seed ${sp1.seedPosition}` : "";
        const t2n = t2 ? t2.name : tournament ?  "TBD" : isSeedingMatch ? `Seed ${sp2.seedPosition}` : "";

          // If the search contains [x vs x] search for those teams
          const vsQuery = q.match(/(.+)\s+v.?s.?\s+(.+)/i);
          if (vsQuery) {
            return (
              (t1n.toLowerCase().includes(vsQuery[1].trim()) && t2n.toLowerCase().includes(vsQuery[2].trim())) ||
              (t2n.toLowerCase().includes(vsQuery[1].trim()) && t1n.toLowerCase().includes(vsQuery[2].trim()))
            );
          }
          // Add data to this that you want to search
          const searchFields = [t1n, t2n, moment(datetime).format("DD/MM/YYYY HH:mm")];
          return !!searchFields.filter((field) => field.toLowerCase().includes(q)).length;
        });
        if(filtered.length > 0) newMatches.push(...filtered);
      }
      setIsSearch(false);
      setFilteredMatches(stageIndex, groupIndex, newMatches);
    }
  }, [filterQuery]);

  return (
    <Form.Group>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text id="inputGroupPrepend">
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control type="search" placeholder="Filter matches" value={filterQuery} onChange={(e) => {setFilterQuery(e.target.value);setIsSearch(true);}} />
      </InputGroup>
    </Form.Group>
  );
};

export default MultiStageLeagueFilterMatches;
