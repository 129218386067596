import React, {useState,useEffect} from 'react';
import {render} from 'react-dom';
import Axios from "axios";
import { Modal, Form, Button, Tooltip, OverlayTrigger, FormGroup } from "react-bootstrap";
import moment from "moment";

const SelectSingleSeason = ({ update, currentSeason = null, incAllWithSameName }) => {
  const [selectedSeason, setSelectedSeason] = useState(currentSeason);
  const [allSeason, setAllSeason] = useState([]);

  const [selectedGroup, setSelectedGroup] = useState(null);
  const [allGroup, setAllGroup] = useState([]);

  const [selectedGame, setSelectedGame] = useState(null);
  const [allGame, setAllGame] = useState([]);

  const [includeAllWithSameName, setIncludeAllWithSameName] = useState(incAllWithSameName);

  const setSelectedGameUp = (value) => {
    setAllGroup([]);
    setSelectedGroup(null);
    setAllSeason([]);
    setSelectedSeason(null);
    setSelectedGame(value);
  };
  const setSelectedGroupUp = (value) => {
    setAllSeason([]);
    setSelectedSeason(null);
    setSelectedGroup(value);
  };

  const updateSeason = (selectedSeasonID) => {
    let selectedSeasonObj = allSeason.filter((singSess) => singSess._id === selectedSeasonID);
    setSelectedSeason(selectedSeasonObj);
    update("season", selectedSeasonObj);
  };

  const updateAllSamaName = (key, value) => {
    setIncludeAllWithSameName(value);
    update(key, value);
  };

  // Fetching all games,groups,seasons and leagues for edit
  useEffect(() => {
    if (currentSeason !== null && !allSeason.length && !allGroup.length && !allGame.length) {
      // setSelectedLeague(currentLeague);

      fetchGames();

      fetchGroupsByGame(currentSeason.game);
      setSelectedGame(currentSeason.game);

      fetchSeasonsByGroup(currentSeason.group);
      setSelectedGroup(currentSeason.group);
    }
  }, [currentSeason]);

  // Fetching all games
  const fetchGames = () => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/games`).then(({ data }) => {
      setAllGame(() => {
        return data.filter((game) => game.name !== "No Game");
      });
    });
  };
  useEffect(() => {
    fetchGames();
  }, []);

  // Fetching all Groups of Selected Game
  const fetchGroupsByGame = (selectedGame) => {
    selectedGame !== null &&
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/seasons/frontpage/groupsByGame/${selectedGame}`).then(({ data }) => {
        setAllGroup(data);
      });
  };
  useEffect(() => {
    selectedGame !== null && fetchGroupsByGame(selectedGame);
  }, [selectedGame]);

  // Fetching all Seasons of Selected Group
  const fetchSeasonsByGroup = (selectedGroup) => {
    selectedGroup !== null &&
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/seasons/frontpage/seasonByGroup/${selectedGroup}`).then(({ data }) => {
        setAllSeason(
          data.sort(
            (season1, season2) => (season1.startDate && season2.startDate && moment(season1.startDate).diff(moment(season2.startDate))) || 1
          )
        );
      });
  };
  useEffect(() => {
    selectedGroup !== null && fetchSeasonsByGroup(selectedGroup);
  }, [selectedGroup]);

  return (
    <>
      <Form>
        <Form.Group>
          <Form.Label>Game</Form.Label>
          <div className="d-flex align-items-center">
            <Form.Control
              as="select"
              id="game"
              name="game"
              value={selectedGame ? selectedGame : null}
              // onChange={(e) => update('game',e.target.value)}
              onChange={(e) => setSelectedGameUp(e.target.value)}
            >
              <option value="">Select Game</option>
              {allGame.map((code) => (
                <option value={code._id}>{code.name}</option>
              ))}
            </Form.Control>
          </div>
        </Form.Group>

        <Form.Group>
          <Form.Label>Group</Form.Label>
          <div className="d-flex align-items-center">
            <Form.Control
              as="select"
              id="group"
              name="group"
              value={selectedGroup ? selectedGroup : null}
              // onChange={(e) => update('group',e.target.value)}
              onChange={(e) => setSelectedGroupUp(e.target.value)}
            >
              <option value="">Select Group</option>
              {allGroup.map((code) => (
                <option value={code._id}>{code.name}</option>
              ))}
            </Form.Control>
          </div>
        </Form.Group>

        <Form.Group>
          <Form.Label>Season</Form.Label>
          <div className="d-flex align-items-center">
            <Form.Control
              as="select"
              id="season"
              name="season"
              value={selectedSeason ? selectedSeason._id : null}
              // onChange={(e) => update('season',e.target.value)}
              onChange={(e) => updateSeason(e.target.value)}
            >
              <option value="">Select Season</option>
              {allSeason.map((code) => (
                <option value={code._id}>
                  {code.name} - {code.year}
                </option>
              ))}
            </Form.Control>
          </div>
        </Form.Group>

        <Form.Group>
          <Form.Check
            type="checkbox"
            id="includeAllWithSameName"
            label="Include all groups of same season?"
            defaultChecked={includeAllWithSameName}
            onChange={(e) => updateAllSamaName("includeAllWithSameName", e.target.checked)}
          />
        </Form.Group>
      </Form>
    </>
  );
};

export default SelectSingleSeason;