import { update } from "lodash-es";
import React, { useState, useEffect, createRef } from "react";
import Axios from "axios";
import { Modal, Form, Button, Tooltip, OverlayTrigger, FormGroup } from "react-bootstrap";
import SelectMultipleLeagues from "./MicroModules/SelectMultipleLeagues"
const SingleSegmentModule = ({updateComponent,modalData,segData}) => {
    
   const [gameData,setGameData]=useState(modalData.pageData && modalData.pageData.segmentData || {});

    const update = (key,value) => {
        //console.log('_gameData valus',key,value);
        // let gamedataleages = gameData.leagues || [];
        // gamedataleages.push(value);
        setGameData({...gameData,[key]:value})
        updateComponent({...gameData,[key]:value})
    }

    ////console.log('_gameData',gameData)

 
    return (
    <>
    <br />
       <Form>
       <FormGroup>
            <Form.Label>White Text</Form.Label>
            <Form.Control type="text" id="WhiteText" name="WhiteText" value={gameData ? gameData.WhiteText : null} onChange={(e) => update('WhiteText',e.target.value)} />
        </FormGroup>
        <FormGroup>
            <Form.Label>Blue Text</Form.Label>
            <Form.Control type="text" id="BlueText" name="BlueText" value={gameData ? gameData.BlueText : null} onChange={(e) => update('BlueText',e.target.value)} />
        </FormGroup>

        

              <SelectMultipleLeagues gameData={gameData} updatec={(leaguesData) => update('leagues',leaguesData)} />
            </Form>
    </>)
}

export default SingleSegmentModule;

