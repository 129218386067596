import React from "react";
import DataTable from "react-data-table-component";
import moment from "moment-timezone/builds/moment-timezone-with-data";
import { Button, Alert, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faEye,
  faPencilAlt,
  faNewspaper,
  faLock,
  faTrashAlt,
  faUsers,
  faWrench,
  faGamepad,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import MatchSetPlayersModal from "../../Leagues/LeagueMatches/Tabs/includes/MatchSetPlayersModal";
import MatchScoreModal from "../../Leagues/LeagueMatches/Tabs/includes/MatchScoreModal";
import MatchScoreModalCSGO from "../../Leagues/LeagueMatches/Tabs/includes/MatchScoreModalCSGO";
// import MatchVetoModalCSGO from "./includes/MatchVetoModalCSGO";
import Swal from "sweetalert2";

const Matches = ({ teams = [], scrims, setScrims, onEditScrim, totalRows, handlePerPageChange, getData, game, loading }) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [showScoreModal, setShowScoreModal] = useState(false);
  const [showSetPlayersModal, setShowSetPlayersModal] = useState(false);
  const [showEditVeto, setShowEditVeto] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [vetoModalData, setVetoModalData] = useState(false);
  console.log("scrims -- ", scrims);
  const onSetPlayers = (match) => {
    setModalData(match);
    setShowSetPlayersModal(true);
  };

  const onSetScrimMatch = (matchid, matchData) => {
    scrims.map((scrim) => {
      if (scrim.match && scrim.match._id == matchid) {
        scrim.match = matchData;
      }
      return scrim;
    });
  };

  // const onEditVeto = (match) => {
  //   setVetoModalData(match);
  //   setShowEditVeto(true);
  // };

  const onSetScore = (match) => {
    setModalData(match);
    setShowScoreModal(true);
  };

  const onDeleteScrims = async () => {
    const ids = selectedRows.map(({ _id }) => _id);

    if (window.confirm(`Are you sure you want to delete ${ids.length} scrims?`)) {
      try {
        await Axios.post(`${process.env.REACT_APP_CORE_API}/api/scrims/deleteMulti`, { ids });
        getData();
        setSelectedRows([]);

        toast.success(`Successfully deleted ${ids.length} scrims`);
      } catch (e) {
        toast.error("There was a problem deleting the selected scrims");
      }
    }
  };

  const columns = [
    {
      name: "Date",
      sortable: true,
      sortFunction: (a, b) => moment(a).isBefore(b),
      cell: ({ date, timeZone }) => moment.tz(date, timeZone || moment.tz.guess()).format("DD/MM/YYYY HH:mm z"),
    },
    { name: "Games", sortable: true, selector: "numberOfMatches" },
    {
      name: "Team 1",
      sortable: true,
      cell: ({ team, match }) => (
        <div>
          {team ? (
            <>
              {team.name}
              {((match?.lolData && !!(match?.lolData?.players?.t1 || []).length) ||
                (match?.csgoData && !!(match?.csgoData?.players?.t1 || []).length)) && (
                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Players Set</Tooltip>}>
                  <FontAwesomeIcon className="text-success" icon={faCheckCircle} />
                </OverlayTrigger>
              )}
            </>
          ) : (
            "[DELETED]"
          )}
        </div>
      ),
    },
    {
      name: "Team 2",
      sortable: true,
      cell: ({ acceptedMatchUp, match }) => (
        <div>
          {acceptedMatchUp && acceptedMatchUp.team ? (
            <>
              {acceptedMatchUp.team.name}
              {((match?.lolData && !!(match?.lolData?.players?.t2 || []).length) ||
                (match?.csgoData && !!(match?.csgoData?.players?.t2 || []).length)) && (
                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Players Set</Tooltip>}>
                  <FontAwesomeIcon className="text-success" icon={faCheckCircle} />
                </OverlayTrigger>
              )}
            </>
          ) : (
            "[NOT ACCEPTED]"
          )}
        </div>
      ),
    },
    { name: "Score", cell: ({ match }) => `${(match && match.t1Score) || 0} - ${(match && match.t2Score) || 0}` },
    {
      name: "Status",
      cell: ({ match, acceptedMatchUp }) =>
        match && match.status ? (
          match.status === "complete" ? (
            <span className="text-success">Completed</span>
          ) : match.status === "inProgress" ? (
            <span className="text-warning">In Progress</span>
          ) : (
            <span className="text-secondary">Upcoming</span>
          )
        ) : !acceptedMatchUp ? (
          "Scrim"
        ) : (
          "Accepted"
        ),
    },
    {
      right: true,
      width: "20%",
      cell: (scrim) => (
        <>
          {!scrim.match ? (
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">View Scrim Page</Tooltip>}>
              <a
                className="btn text-secondary"
                href={`${process.env.REACT_APP_LEAGUES_URL}/scrim/${scrim._id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faNewspaper} />
              </a>
            </OverlayTrigger>
          ) : (
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">View Match</Tooltip>}>
              <a
                className="btn text-secondary"
                href={`${process.env.REACT_APP_LEAGUES_URL}/match/${scrim.match._id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faEye} />
              </a>
            </OverlayTrigger>
          )}

          {scrim.match && (
            <OverlayTrigger disabled={!scrim.acceptedMatchup} placement="top" overlay={<Tooltip id="tooltip">Set Players</Tooltip>}>
              <Button variant="link" className="text-secondary" onClick={() => onSetPlayers(scrim.match)}>
                <FontAwesomeIcon icon={faUsers} />
              </Button>
            </OverlayTrigger>
          )}

          {/* {match.veto && (
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Edit Veto</Tooltip>}>
              <Button variant="link" className="text-secondary" onClick={() => onEditVeto(match)}>
                <FontAwesomeIcon icon={faGamepad} />
              </Button>
            </OverlayTrigger>
          )} */}

          {scrim.match && (
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Set Score Manually</Tooltip>}>
              <Button variant="link" className="text-secondary" onClick={() => onSetScore(scrim.match)}>
                <FontAwesomeIcon icon={faWrench} />
              </Button>
            </OverlayTrigger>
          )}

          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Edit Scrim</Tooltip>}>
            <Button variant="link" className="text-secondary" onClick={() => onEditScrim(scrim)}>
              <FontAwesomeIcon icon={faPencilAlt} />
            </Button>
          </OverlayTrigger>
          {/* code commenting temporarily will be added in next deployments*/}
          {/* <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">lock rescheduling</Tooltip>}>
            <Button
              variant="link"
              className="text-secondary"
              onClick={() => onReschedulingLock(match)}
              disabled={(match && match.isLockReschedule) || match.status === "complete"}
            >
              <FontAwesomeIcon icon={faLock} />
            </Button>
          </OverlayTrigger> */}
        </>
      ),
    },
  ];

  return (
    <div>
      {!!selectedRows.length && (
        <Alert key="table-actions" variant="secondary">
          <div className="d-flex justify-content-between align-items-center">
            <div>{selectedRows.length} rows selected</div>
            <div>
              <Button size="sm" variant="danger" onClick={onDeleteScrims}>
                <FontAwesomeIcon icon={faTrashAlt} />
                &nbsp;Delete Selected
              </Button>
            </div>
          </div>
        </Alert>
      )}
      {!!loading ? (
        <div className="text-center"> Loading... </div>
      ) : (
        <DataTable
          noHeader={true}
          columns={columns}
          data={scrims}
          selectableRows={true}
          selectableRowsHighlight={true}
          onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}
          style={{ overflow: "inherit" }}
          paginationTotalRows={totalRows}
          pagination={true}
          paginationServer={true}
          onChangeRowsPerPage={handlePerPageChange}
          onChangePage={getData}
        />
      )}

      {game && game.shortName === "CSGO" ? (
        <MatchScoreModalCSGO
          show={showScoreModal}
          onHide={() => setShowScoreModal(false)}
          match={modalData}
          isScrim={true}
          updateMatches={onSetScrimMatch}
        />
      ) : (
        <MatchScoreModal
          show={showScoreModal}
          onHide={() => setShowScoreModal(false)}
          match={modalData}
          isScrim={true}
          updateMatches={onSetScrimMatch}
        />
      )}

      {/* {league?.game?.shortName === "CSGO" && (
        <MatchVetoModalCSGO
          show={showEditVeto}
          onHide={() => setShowEditVeto(false)}
          match={vetoModalData}
          league={league}
          vetoInProgress={new Date(vetoModalData.datetime) < new Date() || vetoModalData.veto?.completed ? false : true}
        />
      )} */}

      <MatchSetPlayersModal
        show={showSetPlayersModal}
        onHide={() => setShowSetPlayersModal(false)}
        match={modalData}
        isScrim={true}
        updateMatches={onSetScrimMatch}
        flag={true}
        game={game}
      />
    </div>
  );
};

export default Matches;
