import store from "../../../../store";
import { setSeedingData } from "./action";

export const fetchSeedingDetails2 = () => {
  let _id = localStorage.getItem("_id");
  let _stageId = localStorage.getItem("_stageId");
  let _groups = JSON.parse(localStorage.getItem("_groups"));
  let _seeds = JSON.parse(localStorage.getItem("_seeds"));

  let _data = [];
  _groups.map((group, index) => {
    let _obj = _seeds.filter((x) => x.groupId === group._id && x.teamId !== "");
    let _group = {
      _id: group._id,
    };
    let teams = [];
    _obj.map((item, index) => {
      teams.push({
        team: item.teamId,
        seedPosition: item.seed,
      });
      _group["teams"] = teams;
    });
    _data.push(_group);
  });

  setSeedingData(_id, _stageId, _data);
};

export const fetchSeedingDetails = () => {
  // let _id = localStorage.getItem("_id");
  // let _stageId = localStorage.getItem("_stageId");

  // let _seedingStages = store.getState().seeding.seedingStages;
  // let _active_stage = _seedingStages.filter((x) => x._id === _stageId);

  // let _data = [];

  // _active_stage.forEach((element) => {
  //   let { groups, seedings } = element;
  //   groups.forEach((group, index) => {
  //     let _obj = seedings.filter((x) => x.group && x.group._id === group._id && x.team !== "TBD");
  //     let _group = {
  //       _id: group._id,
  //     };
  //     let teams = [];
  //     _obj.map((item, index) => {
  //       teams.push({
  //         team: item.team.team._id,
  //         seedPosition: item.seedPosition,
  //       });
  //       _group["teams"] = teams;
  //     });
  //     _data.push(_group);
  //   });
  // });

  // console.log("_data: ", _data);

  // setSeedingData(_id, _stageId, _data);
};

export const fetchSeedingDetailsWithSave = (stageId, autoFillTeams) => {
  let _id = localStorage.getItem("_id");
  let _stageId = stageId;

  let _seedingStages = store.getState().seeding.seedingStages;
  let _active_stage = _seedingStages.filter((x) => x._id === _stageId);

  let _data = [];

  _active_stage.forEach((element) => {
    let { groups, seedings } = element;
    groups.forEach((group, index) => {
      let _obj = seedings.filter((x) => x.group && x.group._id === group._id && x.team !== "TBD");
      let _group = {
        _id: group._id,
      };
      let teams = [];
      _obj.map((item, index) => {
        // if (item.isComplete) {
        teams.push({
          team: item?.team?.team?._id,
          seedPosition: item.seedPosition,
        });
        // }
        _group["teams"] = teams;
      });
      _data.push(_group);
    });
  });

  console.log("_data: ", _data);

  setSeedingData(_id, _stageId, _data, autoFillTeams);
};


export const fetchSeedingDetailsForAll = () => {
  let _id = localStorage.getItem("_id");

  let _seedingStages = store.getState().seeding.seedingStages;
  console.log("_seedingStages: ", _seedingStages);

  _seedingStages.forEach((_active_stage, _idx) => {
    let _stageId = _active_stage._id;
    let autoFillTeams = _active_stage.autoFillTeams;
    let _data = [];
    // _active_stage.forEach((element) => {
    let { groups, seedings } = _active_stage;
    groups.forEach((group, index) => {
      let _obj = seedings.filter((x) => x.group && x.group._id === group._id && x.team !== "TBD");
      let _group = {
        _id: group._id,
      };
      let teams = [];
      _obj.map((item, index) => {
        // if (item.isComplete) {
        teams.push({
          team: item?.team?.team?._id,
          seedPosition: item.seedPosition,
        });
        // }
        _group["teams"] = teams;
      });
      _data.push(_group);
      // });
    });

    console.log("_data: ", _data);

    setSeedingData(_id, _stageId, _data, autoFillTeams, true, _seedingStages.length === _idx + 1);
  })


};

export const fetchSeedingDetailsAutoSaveOnChange = (stageId, autoFillTeams, seedings) => {
  let _id = localStorage.getItem("_id");
  let _stageId = stageId;

  let _seedingStages = store.getState().seeding.seedingStages;
  let _active_stage = _seedingStages.filter((x) => x._id === _stageId);

  let _data = [];

  _active_stage.forEach((element) => {
    let { groups } = element;
    groups.forEach((group, index) => {
      let _obj = seedings.filter((x) => x.group && x.group._id === group._id && x.team !== "TBD");
      let _group = {
        _id: group._id,
      };
      let teams = [];
      _obj.map((item, index) => {
        // if (item.isComplete) {
        teams.push({
          team: item?.team?.team?._id,
          seedPosition: item.seedPosition,
        });
        // }
        _group["teams"] = teams;
      });
      _data.push(_group);
    });
  });

  console.log("_data: ", _data);

  setSeedingData(_id, _stageId, _data, autoFillTeams);
};