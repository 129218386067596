import React, {useState} from 'react';
import {render} from 'react-dom';
import {sortableContainer, sortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { Modal, Form, Button, Tooltip, OverlayTrigger, FormGroup } from "react-bootstrap";
import SelectSingleLeague from "./SelectSingleLeague";
import { faArrowsAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../../../styles.scss'

const SortableItem = sortableElement(({value, deleteItem, index}) => 
<li style = {{zIndex: 100000}}>
  <div className="row">
    <div className="col-8">
      {value} 
    </div>
    <div className="col-4">
      <FontAwesomeIcon icon={faArrowsAlt} />
      <Button variant="link" className="text-danger p-0 ml-2" 
        onClick={() => deleteItem()}
      >
        <FontAwesomeIcon icon={faTrash} /> Delete
      </Button>
    </div>
  </div>
  
</li>);

const SortableContainer = sortableContainer(({children}) => {
  return <ul className="ul-list pt-4" style={{listStyleType:`auto`}}>{children}</ul>;
});

const SelectMultipleLeagues = ({gameData,updatec,isTournament=false}) => {
  console.log('gameData=',gameData)
    const [leagues,setLeagues] = useState(gameData && (isTournament ? gameData.tournaments : gameData.leagues) || []);
    
    const [showModalToSelectLeague,setShowModalToSelectLeague] = useState(false);
    const onSortEnd = ({oldIndex, newIndex}) => {
      setLeagues(arrayMove(leagues, oldIndex, newIndex));
      updatec(arrayMove(leagues, oldIndex, newIndex));
      };


      const addToSegment = () => {
        updatec(leagues);
        modalClose()
      }

      const modalClose = () => {
        setShowModalToSelectLeague(false)
      }
      
      const updateCurrentSegment = (value) => {
        //console.log('sad-',value)
        setLeagues([...leagues,value])
        // let leaguesD = leagues;
        // leaguesD.push(value);
        // setLeagues(leaguesD);
    }

    const deleteItems = (index) => {
      console.log('deleteItem index',index)
      setLeagues(leagues.filter((_, i) => i !== index))
      updatec(leagues.filter((_, i) => i !== index))
    }
      

    return (<>
        <Button onClick={() => setShowModalToSelectLeague(true)}>+ Add  {isTournament ? 'Tournament' : 'League'}</Button>

        <SortableContainer  onSortEnd={onSortEnd} helperClass={"drag-item-container-li-class"}>
          {leagues.map((value, index) => (
            <SortableItem key={`item-${value._id}`} index={index} value={value.name} deleteItem={() => deleteItems(index)} />
          ))}
        </SortableContainer>
        

        {
        showModalToSelectLeague && <>
        <Modal show={showModalToSelectLeague} onHide={modalClose} onClose={modalClose} >
          <Modal.Header closeButton>
            <Modal.Title>Select {isTournament ? 'Tournament' : 'League'} to add to segment</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <SelectSingleLeague updaten={(val) => updateCurrentSegment(val)} isTournament={isTournament} />
          </Modal.Body>

          <Modal.Footer className="d-block p-3">
            <Button variant="success" size="sm" onClick={addToSegment}>
              Add to list
              </Button>
              <Button variant="danger" size="sm" onClick={modalClose}>
              Discard
              </Button>
          </Modal.Footer>

          
        </Modal></>
        }
      </>
    );
  

}

export default SelectMultipleLeagues;