import React, { useState, useEffect, createRef } from "react";
import Axios from "axios";
import { Modal, Form, Button, Tooltip, OverlayTrigger, FormGroup } from "react-bootstrap";
import SelectSingleLeague from './MicroModules/SelectSingleLeague';
import SelectSingleSeason from "./MicroModules/SelectSingleSeason";
const SingleSegmentModule = ({updateComponent,modalData,segData}) => {
    
   const [gameData,setGameData]=useState(modalData.pageData && modalData.pageData.segmentData || {});

    const update = (key,value) => {
        let data = {...gameData,[key]:value};
        setGameData(data)
        updateComponent(data)
    }

 
    return (
    <>
    <br />
       <Form>
       <FormGroup>
            <Form.Label>White Text</Form.Label>
            <Form.Control type="text" id="WhiteText" name="WhiteText" value={gameData ? gameData.WhiteText : null} onChange={(e) => update('WhiteText',e.target.value)} />
        </FormGroup>
        <FormGroup>
            <Form.Label>Blue Text</Form.Label>
            <Form.Control type="text" id="BlueText" name="BlueText" value={gameData ? gameData.BlueText : null} onChange={(e) => update('BlueText',e.target.value)} />
        </FormGroup>

        

        <SelectSingleSeason update={update} currentSeason={gameData.season && gameData.season[0] || null} incAllWithSameName={gameData.includeAllWithSameName || false} />

              
            </Form>
    </>)
}

export default SingleSegmentModule;

