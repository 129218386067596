import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import Axios from "axios";
import { toast } from "react-toastify";
import EditImageModal from "../../../../../_modals/EditImageModal/EditImageModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { countries } from "../../../../../../utils/countries";

const General = ({ team, setTeam, organisations }) => {
  const [loading, setLoading] = useState(false);
  const [modals, setModals] = useState({});
  const [country, setCountry] = useState(team.nationality.code);
  const [currentOrganisation, setCurrentOrganisation] = useState(team.organisation._id);
  const [logoCropped, setLogoCropped] = useState(team.logoCropped);
  const [nationalities, setNationalities] = useState(countries
    .filter(({ demonym }) => demonym)
    .filter((v, i, a) => a.findIndex((t) => t.demonym === v.demonym) === i)
    .sort((a, b) => a.demonym.localeCompare(b.demonym)));
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      nationality: team.nationality.code,
      organisation: currentOrganisation
    }
  });

  const onUpdateImage = async (type, imageUrl) => {
    try {
      const { data } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/teams/${team._id}/image`, { type, image: imageUrl });
      setTeam((team) => ({ ...team, ...data.updatedImages }));
      setModals({ ...modals, [type]: false });

      toast.success("Team Pictures updated");
    } catch (err) {
      toast.error("There was a problem updating team images!");
    }
  };

  const onUpdateLogoCheck = async (value) => {
    setLogoCropped(value)
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      if (country) {
        const nationality = nationalities.find((data) => data.alpha2Code === country);
        data = {
          ...data,
          nationality: { code: country, name: nationality.demonym },
        };
      }

      const { data: newData } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/teams/${team._id}`, { ...data });
      setTeam(newData.team)
      setLoading(false);

      toast.success("Details updated");
    } catch (e) {
      setLoading(false);
      const msg = e.response.data ? e.response.data.msg : "Fatal error";
      toast.error(msg);
    }
  };

    return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md={5}>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                ref={register({ required: "Required", maxLength: { value: 200, message: "Name too long" } })}
                type="text"
                id="name"
                name="name"
                autoComplete="off"
                placeholder="Name"
                defaultValue={team.name}
                isInvalid={errors.name}
              />
              <Form.Control.Feedback type="invalid">{errors.name && errors.name.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group>
              <Form.Label>Initials (Tricode)</Form.Label>
              <Form.Control
                ref={register({ required: "Required", maxLength: { value: 4, message: "Initials too long" } })}
                type="text"
                id="initials"
                name="initials"
                autoComplete="off"
                defaultValue={team.initials}
                isInvalid={errors.initials}
              />
              <Form.Control.Feedback type="invalid">{errors.initials && errors.initials.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={5}>
            <Form.Group>
              <Form.Label>Tagline</Form.Label>
              <Form.Control
                ref={register({ maxLength: { value: 50, message: "Tagline too long" } })}
                type="text"
                id="tagline"
                name="tagline"
                maxLength={50}
                placeholder="Tagline"
                autoComplete="off"
                isInvalid={errors.tagline}
                defaultValue={team.tagline}
              />
              <Form.Control.Feedback type="invalid">{errors.tagline && errors.tagline.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={5}>
            <Form.Group>
              <Form.Label>Public Contact Email</Form.Label>
              <Form.Control
                ref={register({
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  },
                })}
                type="text"
                id="email"
                name="email"
                autoComplete="off"
                placeholder="Organisation@mail.com"
                defaultValue={team.email}
                isInvalid={errors.email}
              />
              <Form.Control.Feedback type="invalid">{errors.email && errors.email.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group>
              <Form.Label>Nationality</Form.Label>
              <div className="d-flex align-items-center">
                <Form.Control
                  as="select"
                  ref={register({ required: "Required" })}
                  id="nationality"
                  name="nationality"
                  onChange={(e) => setCountry(e.target.value)}
                  defaultValue={team.nationality}
                  isInvalid={errors.nationality}
                >
                  <option value="" disabled>
                    Choose nationality
                  </option>
                  {nationalities.map(({ alpha2Code, demonym }) => (
                    <option key={alpha2Code} value={alpha2Code}>
                      {demonym}
                    </option>
                  ))}
                </Form.Control>
              </div>
              <Form.Control.Feedback type="invalid">{errors.nationality && errors.nationality.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md={5}>
            <Form.Group>
              <Form.Label>Website</Form.Label>
              <Form.Control
                ref={register({
                  pattern: {
                    value: /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi,
                    message: "Invalid URL",
                  },
                })}
                type="text"
                id="website"
                name="website"
                autoComplete="off"
                placeholder="Leagues.gg"
                isInvalid={errors.website}
                defaultValue={team.website}
              />
              <Form.Control.Feedback type="invalid">{errors.website && errors.website.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Form.Group>
          <Form.Label>Organisation</Form.Label>
          <div className="d-flex align-items-center">
            <Form.Control
              as="select"
              ref={register({ required: "Required" })}
              id="organisation"
              name="organisation"
              onChange={(e) => setCurrentOrganisation(e.target.value)}
              defaultValue={currentOrganisation}
              isInvalid={errors.organisation}
            >
              <option value="" disabled>
                Choose Organisation
              </option>
              {organisations.map(({ _id, name }) => (
                <option key={_id} value={_id}>
                  {name}
                </option>
              ))}
            </Form.Control>
          </div>
          <Form.Control.Feedback type="invalid">{errors.organisation && errors.organisation.message}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>About</Form.Label>
          <Form.Control
            ref={register({ maxLength: 5000 })}
            as="textarea"
            name="about"
            id="about"
            rows={5}
            placeholder="Write something about team"
            defaultValue={team.description}
            isInvalid={errors.about}
          />
          <Form.Control.Feedback type="invalid">{errors.about && errors.about.message}</Form.Control.Feedback>
        </Form.Group>
        
        <Row>
          <div className="d-flex">
            {[
              { name: "Logo", key: "logo", width: 250, height: 250, displayWidth: 150, displayHeight: 150, crop: true },
            ].map(({ name, key, width, height, displayWidth, displayHeight, crop }) => (
              <div key={key}>
                <EditImageModal
                  show={modals[key]}
                  onHide={() => setModals({ ...modals, [key]: false })}
                  name={name}
                  width={width}
                  height={height}
                  afterImageUpload={(file) => onUpdateImage(key, file)}
                  crop={crop}
                />
                <Form.Group className="mr-4">
                  <Form.Label>{name}</Form.Label>
                  <div
                    className={` ${!logoCropped ? "upload-image-btn" : "upload-image-btn-cropped"}`}
                    style={{
                      backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${team[key]})`,
                      width: displayWidth,
                      height: displayHeight,
                    }}
                    onClick={() => setModals({ ...modals, [key]: true })}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </div>
                </Form.Group>
              </div>
            ))}
          </div>
          <div className="d-flex">
            {[
              { name: "Banner", key: "banner", width: 800, height: 200, displayWidth: 500, displayHeight: 150, crop: false },
            ].map(({ name, key, width, height, displayWidth, displayHeight, crop }) => (
              <div key={key}>
                <EditImageModal
                  show={modals[key]}
                  onHide={() => setModals({ ...modals, [key]: false })}
                  name={name}
                  width={width}
                  height={height}
                  afterImageUpload={(file) => onUpdateImage(key, file)}
                  crop={crop}
                />
                <Form.Group className="mr-4">
                  <Form.Label>{name}</Form.Label>
                  <div
                    className="edit-image-btn"
                    style={{
                      backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${team[key]})`,
                      width: displayWidth,
                      height: displayHeight,
                    }}
                    onClick={() => setModals({ ...modals, [key]: true })}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </div>
                </Form.Group>
              </div>
            ))}
          </div>
        </Row>

        <Form.Check
          ref={register()}
          type="checkbox"
          label="Logo Cropped"
          id="logoCropped"
          name="logoCropped"
          style={{ marginBottom: "1em" }}
          defaultChecked={logoCropped}
          onClick={() => onUpdateLogoCheck(!logoCropped ? true : false)}
        />

        <Button type="submit" variant="success" disabled={loading}>
          {loading ? "Saving..." : "Save Changes"}
        </Button>
      </Form>
    </div>
  );
};

export default General;
