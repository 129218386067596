import React, { useState, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";
import { Alert, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faPlus, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import SystemCreateEditModal from "./modal/SystemCreateEditModal";
import Axios from "axios";
import { AppContext } from "../../context/AppContext";

const ScoreSystems = () => {
  const { user } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [scoreSystems, setScoreSystems] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [modalData, setModalData] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/scoreSystems`).then(({ data }) => {
      setScoreSystems(data);
      setLoading(false);
    });
  }, []);

  const onCreateSystem = () => {
    setModalData(false); // ensure modal data is always blank!
    setShowModal(true);
  };

  const onEditSystem = (systemData) => {
    setModalData(systemData);
    setShowModal(true);
  };

  const onRemoveSystem = async (id) => {
    if (window.confirm("Are you sure you want to delete this score system? This action is irreversible!")) {
      await Axios.delete(`${process.env.REACT_APP_CORE_API}/api/scoreSystems/${id}`);
      setScoreSystems((systems) => systems.filter(({ _id }) => _id !== id));
    }
  };

  const columns = [
    { name: "Name", selector: "name", sortable: true },
    { name: "Configuration", sortable: true, cell: (row) => row.configuration.map(({ bestOf }) => `Best Of ${bestOf}`).join(", ") },
    {
      right: true,
      cell: (row) => (
        <>
          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Edit</Tooltip>}>
            <Button variant="link" className="text-secondary" onClick={() => onEditSystem(row)}>
              <FontAwesomeIcon icon={faPencilAlt} />
            </Button>
          </OverlayTrigger>

          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Delete</Tooltip>}>
            <Button
              variant="link"
              className="text-danger"
              onClick={() => onRemoveSystem(row._id)}
              disabled={row.name === "Tiebreaker" ? true : false}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
          </OverlayTrigger>
        </>
      ),
    },
  ];

  return (
    <div>
      <div className="admin-page-title">
        <h2>Score Systems</h2>
        <Button variant="success" onClick={onCreateSystem} disabled={!user.state.admin && !user.state.permissions.user.scoreSystem}>
          <FontAwesomeIcon icon={faPlus} /> Create
        </Button>
      </div>

      {loading
        ? "Loading..."
        : !!selectedRows.length && (
          <Alert key="table-actions" variant="secondary">
            <div className="d-flex justify-content-between align-items-center">
              <div>{selectedRows.length} rows selected</div>
              <div>
                <Button size="sm" variant="danger">
                  <FontAwesomeIcon icon={faTrashAlt} />
                  &nbsp;Delete Selected
                </Button>
              </div>
            </div>
          </Alert>
        )}

      <DataTable
        noHeader={true}
        columns={columns}
        data={scoreSystems}
        selectableRows={true}
        selectableRowsHighlight={true}
        onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}
        style={{ overflow: "inherit" }}
      />

      <SystemCreateEditModal show={showModal} handleClose={() => setShowModal(false)} data={modalData} setScoreSystems={setScoreSystems} />
    </div>
  );
};

export default ScoreSystems;
