import React, { useEffect, useState } from "react";
import { useClickOutside } from "react-click-outside-hook";
import { faGripHorizontal, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./SelectCustom.css";

const SelectCustom = ({ value, options, itemId, onClick, noSpot, disabled }) => {
  const [ref, hasClickedOutside] = useClickOutside();
  const [open, setOpen] = useState(false);
  const [hoverIndex, setHoverIndex] = useState(0);

  const handleSelect = (value) => {
    if (onClick) {
      onClick(value, itemId);
      setOpen(false);
    }
  };

  const handleHoverDefault = () => {
    const index = options.findIndex((option) => option === value);
    setHoverIndex(index);
  };

  const handleOpenClose = () => {
    setOpen((state) => !state);
    handleHoverDefault();
  };

  useEffect(() => {
    if (hasClickedOutside) {
      setOpen(false);
    }
  }, [hasClickedOutside]);

  useEffect(() => {
    if (options?.length > 0) {
      handleHoverDefault();
    }
  }, [value, options]);

  return (
    <div ref={ref} className={`${noSpot || disabled ? "disabled-option-wrapper" : ""}`}>
      <div className={`dropdown ${open ? "open" : ""}`} onClick={handleOpenClose}>
        {value}
        <FontAwesomeIcon icon={faChevronDown} className="down-arrow-opt" />
      </div>
      {open && (
        <div className="dropdown-content">
          {options.map((option, index) => (
            <div
              className={`option-item ${index === hoverIndex ? "hovered" : ""}`}
              onMouseEnter={() => setHoverIndex(index)}
              key={index}
              onClick={() => handleSelect(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectCustom;
