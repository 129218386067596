import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
const Group = (props) => {
  const { groupId, allGroup, groups, seeds, totalSeeds, teams, updateSeeding, stageSeedings } = props;
  const [newGroupSeeds, setNewGroupSeeds] = useState([]);
  // const [groupSeeds, setGroupSeeds] = useState(seeds !== undefined ? seeds : []);
  const [selectedGroup, setSelectedGroup] = useState(groups !== undefined ? groups.filter((item) => item._id === groupId) : []);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);

  // console.log("Group: update_seeding ", updateSeeding);

  useEffect(() => {
    if (!allGroup) {
      let _selectedGroup = seeds.filter((item) => item.groupId === groupId);
      setSelectedGroup(_selectedGroup);
    }
  }, [seeds]);

  useEffect(() => {
    // console.log("selectedGroupsTeam  stageSeedings: ", stageSeedings);
    if (stageSeedings) {
      let _groupSeeds = [];
      groups.forEach((group) => {
        let _group = { ...group };
        let _groupTeams = stageSeedings.filter((item) => item.group && item.group._id === group._id).map((li) => li);
        _groupTeams = _groupTeams.sort((a, b) => a.seedPosition - b.seedPosition);
        _group.teams = _groupTeams;
        _groupSeeds.push(_group);
      });
      setNewGroupSeeds(_groupSeeds);
    }
  }, [stageSeedings, updateSeeding]);

  useEffect(() => {
    if (selectedGroup) {
      let _selectedGroupIndex = groups.findIndex((item) => item._id === groupId);
      setSelectedGroupIndex(_selectedGroupIndex);
    }
  }, [selectedGroup]);

  // console.log("newGroupSeeds", newGroupSeeds[selectedGroupIndex]);
  // console.log("selectedGroup", selectedGroup);
  // console.log("groupSeeds", groupSeeds);

  return (
    <Wrapper>
      <div>
        {allGroup ? (
          // groups?.length > 0 &&
          // groups?.map((groupItem, groupIndex) =>
          //   groupIndex % 2 === 0 ? (
          <>
            {newGroupSeeds
              .reduce(function (accumulator, currentValue, currentIndex, array) {
                if (currentIndex % 2 === 0) accumulator.push(array.slice(currentIndex, currentIndex + 2));
                return accumulator;
              }, [])
              .map((groupItem, groupIndex) => (
                <Row className="group-row">
                  {groupItem[0] && (
                    <Col className="group-container">
                      <Row className="header-row">
                        <span className="group-name">{groupItem[0].name}</span>
                      </Row>
                      <hr />
                      {groupItem[0].teams.map((_team, index) => {
                        return (
                          <Row className="group-row" key={index}>
                            <div className={`m5 ${_team.seedPosition + 1 > 9 ? "w57" : "w56"}`}>Seed {_team.seedPosition}</div>
                            {/* <div className={`m5 ${_team.seedPosition + 1 > 9 ? "w57" : "w56"}`}>Seed {index + 1}</div> */}
                            <span className="line m4"> --- </span>
                            <div className="input m5 w145">
                              {/* {_team.team === "TBD" || _team.isTbd || !_team.isComplete ? "TBD" : _team.team && _team.team.team ? _team.team.team.name : "TBD"} */}
                              {_team.team === "TBD" || _team.isTbd || !_team.isComplete ? "TBD" : _team?.team?.team?.name}
                            </div>
                          </Row>
                        );
                      })}
                    </Col>
                  )}
                  {groupItem[1] && (
                    <Col className="group-container">
                      <Row className="header-row">
                        <span className="group-name">{groupItem[1].name}</span>
                      </Row>
                      <hr />
                      {groupItem[1].teams.map((_team, index) => {
                        return (
                          <Row className="group-row" key={index}>
                            <div className={`m5 ${_team.seedPosition + 1 > 9 ? "w57" : "w56"}`}>Seed {_team.seedPosition}</div>
                            {/* <div className={`m5 ${_team.seedPosition + 1 > 9 ? "w57" : "w56"}`}>Seed {index + 1}</div> */}
                            <span className="line m4"> --- </span>
                            <div className="input m5 w145">
                              {_team.team === "TBD" || _team.isTbd || !_team.isComplete ? "TBD" : _team?.team?.team?.name}
                            </div>
                          </Row>
                        );
                      })}
                    </Col>
                  )}
                </Row>
              ))}
            {/* <Row className="group-row">             
              {newGroupSeeds.map((groupItem, groupIndex) =>
                groupIndex % 2 === 0 ? (
                  <Col className="group-container">
                    <Row className="header-row">
                      <span className="group-name">{groupItem.name}</span>
                    </Row>
                    <hr />
                    {groupItem.teams.map((_team, index) => {
                      return (
                        <Row className="group-row" key={index}>
                          <div className={`m5 ${_team.seedPosition + 1 > 9 ? "w57" : "w56"}`}>Seed {_team.seedPosition}</div>
                          <span className="line m4"> --- </span>
                          <div className="input m5 w145">{_team.team === "TBD" ? _team.team : _team.team.team.name}</div>
                        </Row>
                      );
                    })}
                  </Col>
                ) : null
              )}
            </Row> */}
          </>
        ) : (
          <Row className="group-row-single">
            {newGroupSeeds[selectedGroupIndex]?.teams.map((_team, index) => {
              return (
                <Row className="group-row" key={index}>
                  <Col className="m25">
                    <div>
                      <span>Seed {_team.seedPosition}</span>
                      {/* <span>Seed {index + 1}</span> */}
                    </div>
                  </Col>
                  <span className="line m4">---</span>
                  <Col className="input w300">
                    <div>{_team.team === "TBD" || _team.isTbd || !_team.isComplete ? "TBD" : _team?.team?.team?.name}</div>
                  </Col>
                </Row>
              );
            })}
          </Row>
        )}
      </div>
    </Wrapper>
  );
};

export default Group;

const Wrapper = styled.div`
  .team-card-dist {
    margin: auto !important;
  }
  .header-row {
    margin: 0px 0px 2px 0px !important;
  }
  .m4 {
    margin: 4px 0px 0px 0px !important;
  }
  .m5 {
    margin: 5px 4px 5px 5px !important;
  }
  .m25 {
    border: 1px solid gainsboro !important;
    border-radius: 5px;
    margin: 5px 5px 5px 5px !important;
    width: 300px !important;
  }
  .w300 {
    border: 1px solid gainsboro !important;
    border-radius: 5px;
    margin: 5px 5px 5px 5px !important;
    width: 300px !important;
  }
  .w250 {
    width: 250px !important;
  }
  .w56 {
    width: 56px !important;
  }
  .w57 {
    width: 57px !important;
  }
  .w145 {
    width: 150px !important;
  }
  .group-name {
    margin: auto !important;
  }
  .input {
    border: 1px dashed !important;
    overflow: hidden !important;
    white-space: nowrap !important;
  }
  .line {
    font-weight: 900;
    magin: 4px 4px 4px 4px !important;
  }
  .container {
    padding: 5px 5px 5px 5px;
  }
  .group-container {
    margin: 5px 5px 5px 5px !important;
    border: 1px solid gainsboro !important;
    padding: 0px 0px 0px 0px !important;
    border-radius: 5px !important;
    height: min-content;
  }
  .group-empty {
    margin: 5px 5px 5px 5px !important;
    padding: 0px 0px 0px 0px !important;
  }
  .group-row {
    margin: 0px 0px 2px 0px !important;
  }
  .group-row-single {
    margin: 5px 5px 5px 5px !important;
  }
  .row-seeding {
    margin: auto;
    justify-content: space-between;
    border: 1px solid gainsboro;
    border-radius: 5px;
    padding: 5px 5px 5px 5px;
  }
`;
