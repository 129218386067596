import React, { useEffect, useState } from 'react';
import { Bracket } from 'react-brackets';
import CustomSeed from './CustomSeed';
import { hilightTracks, unhilightTracks } from './hilight-tracks';

const BracketsMap = ({ data, losingTrack, hilighted, setHilighted, isShort,handleMatchClick }) => {
    const [hovered, setHovered] = useState();
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        unhilightTracks(data);
        if (hovered) hilightTracks(hovered, data);
        setRefresh(!refresh);
    }, [hovered]);

    if (data.length === 0) return (
        <div className="col-12 p-5 text-center text-grey">
            There is not data in this section
        </div>
    )

    return (
        <Bracket
            bracketClassName="single-bracket"
            mobileBreakpoint={0}
            rounds={data}
            roundTitleComponent={(title, roundIndex) => {
                let _title = roundIndex === data.length - 1 ? "Final"
                    : roundIndex === data.length - 2 ? "Semi Final2"
                        : roundIndex === data.length - 3 ? "Quarter Final"
                            : title
                if (losingTrack) {
                    if (roundIndex === data.length - 1 && !isShort) _title = "Third place decider";
                   // else _title = " "
                }
                return (
                    <div className=" bold text-center" style={{ minHeight: 30 }} >
                        {_title}
                    </div>
                );
            }}
            renderSeedComponent={(seed, breakpoint, roundIndex) => {
                let singleLine = false;
                if (roundIndex !== data.length - 1) if (data[roundIndex]?.seeds?.length === data[roundIndex + 1]?.seeds?.length) {
                    singleLine = true;
                }
                const matchIndex = seed && !seed.hidden ? data[roundIndex]?.seeds.findIndex(el => el?.matchData?._id === seed?.matchData?._id) : undefined;
                return CustomSeed({
                    seed,
                    breakpoint,
                    roundIndex,
                    hilighted,
                    setHilighted,
                    singleLine,
                    losingTrack,
                    hovered,
                    setHovered,
                    matchIndex,
                    handleMatchClick
                })
            }}
        />
    )
}

export default BracketsMap;
