import React, { useState, useEffect } from "react";
import { Modal, Form, Col, Row, Button } from "react-bootstrap";
import Axios from "axios";
import Datetime from "react-datetime";
import moment from "moment-timezone/builds/moment-timezone-with-data";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { TIMEZONES } from "../../../../../../utils/timezones";

const CreateEditMatchModal = ({ show, onHide, match, updateMatches, leagues, prefferedTimezone }) => {
  const { id } = useParams();
  const [loadingSS, setLoadingSS] = useState(true);
  const [scoreSystems, setScoreSystems] = useState([]);
  const [league, setLeague] = useState(false);

  const [team1, setTeam1] = useState(false);
  const [team2, setTeam2] = useState(false);
  const [vetoFlag, setVetoFlag] = useState(true);
  const [showVeto, setShowVeto] = useState(false);
  const [tournamentType, setSelectedScoreSystem] = useState(false);
  const [bestOf, setBestOf] = useState(false);
  const [switchSides, setSwitchSides] = useState(true);
  const [date, setDate] = useState(false);
  const [isdatechange, setIsdatechange] = useState(false);
  const [week, setWeek] = useState(1);
  const [round, setRound] = useState(1);
  const [sideSelection, setSideSelection] = useState(false);
  const [broadcasted, setBroadcasted] = useState(false);
  const [localPrefferedTimezone, setLocalPrefferedTimezone] = useState(prefferedTimezone);

  const [customReschdule, setCustomReschdule] = useState(match && match.reschedule ? true : false);
  const [earliestDate, setEarliestDate] = useState(leagues.startDate);
  const [latestDate, setLatestDate] = useState(moment(leagues.startDate).add(7, "days").set({ hour: 23, minute: 59, second: 0, millisecond: 0 }).toISOString());
  const [minDate, setMinDate] = useState(false);
  // const [maxDate, setMaxDate] = useState(false);
  
  useEffect(() => {
    setLocalPrefferedTimezone(prefferedTimezone);
  }, [prefferedTimezone]);

  const changeDateTime = (dt) => {
    setIsdatechange(true);
    setDate(dt);
  };

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/scoreSystems`).then(({ data }) => {
      setScoreSystems(data);
      setLoadingSS(false);
    });

    Axios.get(`${process.env.REACT_APP_CORE_API}/api/tournaments/${id}?withMatches=true`).then(({ data }) => {
      setLeague(data);
    });
  }, [id]);

  useEffect(() => {
    setTeam1(match ? match.t1 && match.t1._id : false);
    setTeam2(match ? match.t2 && match.t2._id : false);
    setSelectedScoreSystem(match ? match.tournamentType : false);
    setBestOf(match ? match.bestOf : false);
    setShowVeto(match && league?.game?.shortName === "CSGO" && [1, 3, 5].includes(match.bestOf) ? true : false);
    setSwitchSides(match ? match.switchSides : true);
    setBroadcasted(match ? match.broadcasted : false);
    setSideSelection(match ? match.sideSelection : false);
    setDate(match ? moment(match.datetime).tz(localPrefferedTimezone || league.timezone) : false);
    setCustomReschdule(match && match.reschedule ? true : false);
    let today = moment().subtract(1, "days").set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    let min = moment.max(today, moment(league.startDate));
    setMinDate(min);
    if (match && match.reschedule) {
      setEarliestDate(
        match && match.reschedule
          ? moment(match.reschedule.earliestDate).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISOString()
          : false
      );
      setLatestDate(
        match && match.reschedule
          ? moment(match.reschedule.latestDate).set({ hour: 23, minute: 59, second: 0, millisecond: 0 }).toISOString()
          : false
      );
    }
    // if (league.timezone !== null && league.timezone !== "" && league.timezone != undefined) {
    //   setDate(match ? moment(match.datetime).tz(league.timezone).format("DD/MM/YYYY HH:mm") : false);
    // } else {
    //   setDate(match ? moment(match.datetime).format("DD/MM/YYYY HH:mm") : false);
    // }

    setWeek(match ? match.week : 1);
    setRound(match ? match.round : 1);
  }, [match, show]);

  const getScoreSystemData = () => {
    if (tournamentType) {
      return scoreSystems.find((x) => x === tournamentType);
    }

    return false;
  };

  const areMatchesOverlapping = (currentMatch) => {
    const {
      duelMatchId: { s: section, r: round, m: _match },
    } = currentMatch;
    /**
     * * Developer Notes
     * current match is dependent on previous two matches as the teams of current match are from two different matches.
     *
     *  For Losing Bracket
     *
     *                     {s:1,r:1,m:1}
     * [{s:2,r:1,m:1}] ==>
     *                     {s:1,r:1,m:2}
     *
     * For Winning Bracket
     *
     *                     {s:1,r:1,m:1}
     * [{s:1,r:2,m:1}] ==>
     *                     {s:1,r:1,m:2}
     *
     * So the formula below calculates all the scenarios possible to find previous matches for winning and losing bracket match
     *
     */
    const prevMatchIds = [
      { s: section - 1, r: round, m: Math.ceil(_match / 2) },
      { s: section - 1, r: round, m: Math.ceil(_match / 2) + 1 },
      { s: section, r: round - 1, m: Math.ceil(_match / 2) },
      { s: section, r: round - 1, m: Math.ceil(_match / 2) + 1 },
    ];

    /**
     * As explained above the current formula finds next two matches depending upon current match.
     */
    const upcomingMatchIds = [
      { s: section + 1, r: round, m: Math.ceil(_match / 2) },
      { s: section, r: round + 1, m: Math.ceil(_match / 2) },
    ];

    const { matches } = league;

    const prevMatches = matches.filter(({ duelMatchId }) =>
      prevMatchIds.some(({ s, r, m }) => s === duelMatchId.s && r === duelMatchId.r && m === duelMatchId.m)
    );

    const nextMatches = matches.filter(({ duelMatchId }) =>
      upcomingMatchIds.some(({ s, r, m }) => s === duelMatchId.s && r === duelMatchId.r && m === duelMatchId.m)
    );

    const isClashWithPrevMatches = !prevMatches.every(({ datetime, bestOf }) =>
      // ? calculating match end time with respect to the best of by assuming 60 min per round
      moment(datetime)
        .add(bestOf * 60, "minutes")
        .isBefore(moment(currentMatch.datetime), "[]")
    );

    const isClashWithUpcomingMatches = !nextMatches.every(({ datetime }) =>
      moment(datetime).isAfter(moment(currentMatch.datetime).add(currentMatch.bestOf * 60, "minutes"))
    );

    return isClashWithUpcomingMatches || isClashWithPrevMatches;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (areMatchesOverlapping({ ...match, datetime: date })) {
      toast.error("The new is clashing with one or more matches in tournament");
      return;
    }

    try {
      if (formValid) {
        const ifDateChange = isdatechange ? { datetime: moment(date, "DD/MM/YYYY HH:mm:ss").tz(localPrefferedTimezone, true) } : {};
        const postData = {
          t1: team1,
          t2: team2,
          switchSides,
          sideSelection,
          broadcasted,
          tournamentType,
          bestOf,
          ...ifDateChange, //Added datetime only if changed
          week,
          round,
          reschedule: { earliestDate, latestDate },
        };
        let vetoEnable = showVeto ? vetoFlag : false;
        if (match) {
          const { data } = await Axios.put(
            `${process.env.REACT_APP_CORE_API}/api/matches/${match._id}?type=Tournament&isVeto=${vetoEnable}`,
            postData
          );
          updateMatches((matches) => matches.map((m) => (m._id === match._id ? data.updatedMatch : m)));
        } else {
          const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/matches`, { league: id, ...postData });
          updateMatches((matches) => [...matches, data.newMatch]);
        }

        toast.success(`Successfully ${match ? "edited" : "created"} match!`);
        onHide();
        setIsdatechange(false);
      }
    } catch (e) {
      toast.error("There was a problem processing this request 😞");
    }
  };

  const formValid = !!(tournamentType && bestOf && week && round && date);
  const modalHeaderDate =
    league.timezone !== null && league.timezone !== "" && league.timezone != undefined
      ? moment(match.datetime).tz(league.timezone).format("DD/MM/YYYY @ HH:mm")
      : moment(match.datetime).format("DD/MM/YYYY @ HH:mm");
  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        {match ? (
          <Modal.Title>
            {match.t1 ? match.t1.name : "BYE"} vs {match.t2 ? match.t2.name : "BYE"} ({modalHeaderDate})
          </Modal.Title>
        ) : (
          <Modal.Title>Create Match</Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          {league && (
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Team 1</Form.Label>
                  <Form.Control disabled as="select" value={team1 || "_DEFAULT_"} onChange={(e) => setTeam1(e.target.value)}>
                    <option value="_DEFAULT_" disabled>
                      Select team 1
                    </option>
                    {league.teams.map(
                      ({ team }) =>
                        team && (
                          <option key={team._id} value={team._id}>
                            {team.name}
                          </option>
                        )
                    )}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Team 2</Form.Label>
                  <Form.Control disabled as="select" value={team2 || "_DEFAULT_"} onChange={(e) => setTeam2(e.target.value)}>
                    <option value="_DEFAULT_" disabled>
                      Select team 2
                    </option>
                    {league.teams.map(
                      ({ team }) =>
                        team && (
                          <option key={team._id} value={team._id}>
                            {team.name}
                          </option>
                        )
                    )}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          )}

          <Form.Group>
            <Form.Label>Tournament Formate </Form.Label>
            <Form.Control
              disabled
              as="select"
              value={tournamentType || "_DEFAULT_"}
              onChange={(e) => setSelectedScoreSystem(e.target.value)}
            >
              <option disabled value="_DEFAULT_">
                {loadingSS ? "Loading..." : "Select score system"}
              </option>
              <option value={1}>Single Elimination</option>
              <option value={2}>Double Elimination</option>
            </Form.Control>
          </Form.Group>

          {!loadingSS && tournamentType && (
            <>
              <Form.Group>
                <Form.Label>Best Of</Form.Label>
                <Form.Control as="select" value={bestOf || "_DEFAULT_"} onChange={(e) => setBestOf(parseFloat(e.target.value))}>
                  <option disabled value="_DEFAULT_">
                    Select best of
                  </option>
                  <option value={1}>Best Of {1}</option>
                  <option value={3}>Best Of {3}</option>
                  <option value={5}>Best Of {5}</option>
                </Form.Control>
              </Form.Group>

              <Form.Group>
                <Form.Check
                  label="Switch Sides Every Other Map"
                  id="switch-sides"
                  checked={switchSides}
                  onChange={(e) => setSwitchSides(e.target.checked)}
                />
              </Form.Group>

              <Row>
                <Col sm={4}>
                  <Form.Group className="mb-3" controlId="formBasicCheckbox1">
                    <Form.Check
                      type="checkbox"
                      name="sideselection"
                      checked={sideSelection}
                      label="Side Selection"
                      onChange={(e) => {
                        setSideSelection((prevState) => !prevState);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group className="mb-3" controlId="formBasicCheckbox2">
                    <Form.Check
                      type="checkbox"
                      name="broadcasted"
                      checked={broadcasted}
                      label="Broadcasted"
                      onChange={(e) => {
                        setBroadcasted((prevState) => !prevState);
                      }}
                    />
                  </Form.Group>
                </Col>
                {showVeto && (
                  <Col sm={4}>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox3">
                      <Form.Check
                        type="checkbox"
                        name="vetoFlagEnable"
                        checked={vetoFlag}
                        label="Enable Veto"
                        onChange={(e) => {
                          setVetoFlag(!vetoFlag);
                        }}
                        disabled={match && match.status == "complete"}
                      />
                    </Form.Group>
                  </Col>
                )}
              </Row>
              {match && (
                <p className="text-danger">
                  NOTE: If you are changing the switch sides setting after match data has already been received, you MUST reset the match
                  first, come back here and change the switch sides setting, then manually fetch data again.
                </p>
              )}
            </>
          )}

          <Row>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>Round</Form.Label>
                <Form.Control disabled type="number" min={1} value={round} onChange={(e) => setRound(parseFloat(e.target.value))} />
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group>
                <Form.Label>Week</Form.Label>
                <Form.Control disabled type="number" min={1} value={week} onChange={(e) => setWeek(parseFloat(e.target.value))} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Date</Form.Label>
                <div>
                  <Datetime value={date} onChange={changeDateTime} dateFormat="DD/MM/YYYY" timeFormat="HH:mm:ss" />
                </div>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Preffered Time zone</Form.Label>
                <Form.Control
                  as="select"
                  name="prefferedTimezone"
                  defaultValue={localPrefferedTimezone}
                  value={localPrefferedTimezone}
                  onChange={(e) => {
                    setLocalPrefferedTimezone(e.target.value);
                    setIsdatechange(true);
                  }}
                >
                  {TIMEZONES.map(({ name, value }, index) => (
                    <option key={index} value={value}>
                      {name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          {/* Code commented temporarily and will be added in future deployments */}
          {!match.isLockReschedule && match.status !== "complete" && (
            <>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Check
                      type="checkbox"
                      name="customRescduling"
                      checked={customReschdule}
                      label="Use custom match rescheduling"
                      onChange={(e) => {
                        setCustomReschdule(!customReschdule);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Earliest Date</Form.Label>
                    <Datetime
                      value={earliestDate ? moment(earliestDate).format("DD/MM/YYYY HH:mm:ss") : null}
                      onChange={setEarliestDate}
                      dateFormat="DD/MM/YYYY"
                      timeFormat="HH:mm:ss"
                      inputProps={{ disabled: !customReschdule }}
                      // isValidDate={(currentDate) => currentDate.isBetween(minDate, maxDate)}
                      isValidDate={(currentDate) => currentDate.isAfter(league.startDate
                        ? moment(league.startDate) :
                        league.reschedule && league.reschedule.earliestDate
                        ? moment(league.reschedule.earliestDate)
                        : false)}
                    />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Latest Date</Form.Label>
                    <Datetime
                      value={latestDate ? moment(latestDate).set({ hour: 23, minute: 59, second: 0, millisecond: 0 }).format("DD/MM/YYYY HH:mm:ss") : null}
                      onChange={setLatestDate}
                      dateFormat="DD/MM/YYYY"
                      timeFormat="HH:mm:ss"
                      inputProps={{ disabled: !customReschdule }}
                      // isValidDate={(currentDate) => currentDate.isBetween(minDate, maxDate)}
                      isValidDate={(currentDate) => currentDate.isAfter(earliestDate)}
                      name="latestDate"
                    />
                     <span className="text-danger">{moment(earliestDate).isAfter(moment(latestDate)) ? "Latest Date should be after the Earliest Date" : null}</span>
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}

          <div className="d-flex">
            <Button style={{ width: "100%" }} type="submit" variant="success" size="lg" disabled={!formValid}>
              {match ? "Edit" : "Create"} Match
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateEditMatchModal;
