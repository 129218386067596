import React from "react";
import Axios from "axios";
const UpdateSettingsApi = async (data) => {
  try {
    const resp = await Axios({
      method: "put",
      url: `${process.env.REACT_APP_CORE_API}/api/language/setting/${data._id}`,
      data: data,
    });
    return resp.data;
  } catch (err) {
    return {error : true};
  }
};
export default UpdateSettingsApi;
