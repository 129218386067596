import React, { useState, useEffect } from "react";
import { Card, Nav } from "react-bootstrap";
import Matches from "./MultiStageLeagueMatches/Tabs/Matches";
import LeaderBoard from "./MultiStageLeagueMatches/Tabs/LeaderBoard";
import Participants from "./MultiStageLeagueMatches/Tabs/Participants";
import Information from "./MultiStageLeagueMatches/Tabs/Information";
import Prizes from "./MultiStageLeagueMatches/Tabs/Prizes";
import ImportantDates from "./MultiStageLeagueMatches/Tabs/ImportantDates";
import Settings from "./MultiStageLeagueMatches/Tabs/Settings";
import Stages from "./MultiStageLeagueMatches/Tabs/Stages";
import Seeding from "./MultiStageLeagueMatches/Tabs/MultiStageSeeding";
import { TIMEZONES } from "./MultiStageLeagueMatches/Tabs/timezones";
import { fetchSeedingDetails, fetchSeedingDetailsForAll } from "./MultiStageLeagueMatches/Utils";
import { SEEDING } from "../../actionType";
import store from "../../../store/index";

const MultiStageTabsManager = ({ league, setLeague, user }) => {
  const [activeTab, setActiveTab] = useState("Matches");
  const [filteredMatches] = useState([]);
  const [prefferedTimezone, setPrefferedTimezone] = useState(league.timezone || "Etc/UTC");
  const [prevTab, setPrevTab] = useState("Matches");
  const [selectedStageIndex, setSelectedStageIndex] = useState(0);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);
  const [isAllStage, setIsAllStage] = useState(true);
  const [isAllGroup, setIsAllGroup] = useState(true);

  useEffect(() => {
    setPrefferedTimezone(league.timezone || "Etc/UTC");
  }, [league.timezone]);

  const updateMatches = (callback) => {
    // setLeague((l) => ({ ...l, matches: callback(l.matches) }));
  };

  useEffect(() => {
    if (!(league && league.timezone && TIMEZONES.find((tz) => tz.value === league.timezone))) {
      setPrefferedTimezone("Etc/UTC");
    }
  }, [league]);

  useEffect(() => {
    if (activeTab !== "Matches") {
      if (isAllStage) setSelectedStageIndex(0);
      if (isAllGroup) setSelectedGroupIndex(0);
    }
  }, [activeTab]);

  // ADD/REMOVE TABS THROUGH THIS ARRAY
  const TABS = [
    {
      name: "Matches",
      content: (
        <Matches
          selectedGroupIndex={selectedGroupIndex}
          setSelectedGroupIndex={setSelectedGroupIndex}
          selectedStageIndex={selectedStageIndex}
          setSelectedStageIndex={setSelectedStageIndex}
          teams={league.teams}
          matches={filteredMatches}
          updateMatches={updateMatches}
          league={league}
          setLeague={setLeague}
          prefferedTimezone={prefferedTimezone}
          setPrefferedTimezone={setPrefferedTimezone}
          isAllGroup={isAllGroup}
          setIsAllGroup={setIsAllGroup}
          isAllStage={isAllStage}
          setIsAllStage={setIsAllStage}
        />
      ),
    },
    {
      name: "Stages",
      content: (
        <Stages
          // selectedGroupIndex={selectedGroupIndex}
          // setSelectedGroupIndex={setSelectedGroupIndex}
          selectedStageIndex={selectedStageIndex}
          setSelectedStageIndex={setSelectedStageIndex}
          league={league}
          setLeague={setLeague}
          setIsAllStage={setIsAllStage}
        />
      ),
      disabled: !user.state.admin && !user.state.permissions.user.settings ? "disabled-link" : "",
    },
    {
      name: "Seeding",
      content: (
        <Seeding
          selectedGroupIndex={selectedGroupIndex}
          setSelectedGroupIndex={setSelectedGroupIndex}
          selectedStageIndex={selectedStageIndex}
          setSelectedStageIndex={setSelectedStageIndex}
          setIsAllStage={setIsAllStage}
          setLeague={setLeague}
          league={league}
        />
      ),
      disabled: (!user.state.admin && !user.state.permissions.user.matches) || activeTab === "Seeding" ? "disabled-link" : "",
    },
    {
      name: "Participants",
      content: <Participants teams={league.teams} setLeague={setLeague} league={league} />,
      disabled: !user.state.admin && !user.state.permissions.user.matches ? "disabled-link" : "",
    },
    {
      name: "Leaderboard",
      content: (
        <LeaderBoard
          selectedGroupIndex={selectedGroupIndex}
          setSelectedGroupIndex={setSelectedGroupIndex}
          selectedStageIndex={selectedStageIndex}
          setSelectedStageIndex={setSelectedStageIndex}
          setIsAllStage={setIsAllStage}
          setIsAllGroup={setIsAllGroup}
          teams={league.teams}
          setLeague={setLeague}
          league={league}
        />
      ),
      disabled: !user.state.admin && !user.state.permissions.user.matches ? "disabled-link" : "",
    },
    {
      name: "Important Dates",
      content: <ImportantDates league={league} setLeague={setLeague} />,
      disabled: !user.state.admin && !user.state.permissions.user.settings ? "disabled-link" : "",
    },
    {
      name: "Prizes",
      content: (
        <Prizes
          selectedGroupIndex={selectedGroupIndex}
          setSelectedGroupIndex={setSelectedGroupIndex}
          selectedStageIndex={selectedStageIndex}
          setSelectedStageIndex={setSelectedStageIndex}
          league={league}
          setLeague={setLeague}
        />
      ),
      disabled: !user.state.admin && !user.state.permissions.user.settings ? "disabled-link" : "",
    },
    {
      name: "Information",
      content: <Information league={league} setLeague={setLeague} />,
      disabled: !user.state.admin && !user.state.permissions.user.settings ? "disabled-link" : "",
    },
    {
      name: "Settings",
      content: (
        <Settings
          selectedGroupIndex={selectedGroupIndex}
          setSelectedGroupIndex={setSelectedGroupIndex}
          selectedStageIndex={selectedStageIndex}
          setSelectedStageIndex={setSelectedStageIndex}
          setIsAllGroup={setIsAllGroup}
          setIsAllStage={setIsAllStage}
          league={league}
          setLeague={setLeague}
        />
      ),
      disabled: !user.state.admin && !user.state.permissions.user.settings ? "disabled-link" : "",
    },
  ];
  return (
    <>
      <Card>
        <Card.Header>
          <Nav
            variant="tabs"
            activeKey={activeTab}
            onSelect={(t) => {
              if (t === "Seeding" && activeTab !== "Seeding") {
                store.dispatch({
                  type: SEEDING.RESET,
                });
              }
              if (t !== "Seeding" && activeTab === "Seeding") {
                // fetchSeedingDetails();
                fetchSeedingDetailsForAll();
              }
              setActiveTab(t);
            }}
          >
            {TABS.map(({ name, disabled }) => (
              <Nav.Item key={name}>
                <Nav.Link eventKey={name} active={activeTab === name} className={!user.state.admin && disabled && activeTab === name}>
                  {name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Card.Header>
        <Card.Body>{TABS.map(({ name, content }) => name === activeTab && <div key={name}>{content}</div>)}</Card.Body>
      </Card>
    </>
  );
};

export default MultiStageTabsManager;
