import React from "react";
import Axios from "axios";
import { Card, Button, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrashAlt, faPencilAlt, faWrench } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import reactCSS from 'reactcss'
import userEvent from "@testing-library/user-event";

const SingleGame = ({ gameData, onEditGame, onConfigureGame, deletingGame, onRemoveGame, settingFeature, scrims, user }) => {
  const { _id, name, shortName } = gameData;
  const game = {
    ...gameData,
    gameRanks: gameData.gameRanks?.join("\n"),
  };

  const styles = reactCSS({
    'default': {
      color: {
        width: '36px',
        height: '24px',
        borderRadius: '2px',
        //   background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
        background: `${gameData.mainColour}`,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      }
    },
  });

  return (
    <Card body>
      <h5>
        {name} ({shortName})
      </h5>
      <div className="float-right" title="Main Colour" style={styles.swatch}>
        <div style={styles.color} />
      </div>
      {settingFeature ? (
        <>
          {/* <Button size="sm" variant="outline-success" className="space-right" onClick={() => onEditGame(game)}>
          <FontAwesomeIcon icon={faPencilAlt} /> Edit
        </Button> */}

          <Button
            size="sm"
            variant="outline-success"
            className={!user.state.admin && !user.state.permissions?.user?.game ? "disabled-link space-right mb-2" : "space-right mb-2"}
            as={Link}
            to={`/game-settings/${_id}/edit`}
          >
            <FontAwesomeIcon icon={faPencilAlt} /> Edit
          </Button>

          <Button
            size="sm"
            variant="outline-success"
            className={
              !user.state.admin && !user.state.permissions?.user?.frontPage ? "disabled-link space-right mb-2" : "space-right mb-2"
            }
            as={Link}
            to={`/game-settings/${_id}/edit-front-page`}
          >
            <FontAwesomeIcon icon={faPencilAlt} /> Front Page
          </Button>

          <Button
            size="sm"
            className="space-right mb-2"
            variant="outline-danger"
            onClick={() => onRemoveGame(_id)}
            disabled={deletingGame === _id || game.defaultGame || (!user.state.admin && !user.state.permissions?.user?.game)}
          >
            {deletingGame === _id ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <>
                <FontAwesomeIcon icon={faTrashAlt} /> Delete
              </>
            )}
          </Button>
        </>
      ) : (
        <>
          {!scrims ? (
            <Button size="sm" variant="primary" className="space-right" as={Link} to={`/games/${_id}/groups`}>
              <FontAwesomeIcon icon={faEye} /> View Groups
            </Button>
          ) : (
            <Button size="sm" variant="primary" className="space-right" as={Link} to={`/scrim-games/${_id}/scrims`}>
              <FontAwesomeIcon icon={faEye} /> View Scrims
            </Button>
          )}
        </>
      )}
    </Card>
  );
};

export default SingleGame;
