import React from "react";
import { Switch } from "react-router-dom";
import { AuthRoute } from "../../../utils/CustomRouteTypes";
import LeagueDashboard from "./LeagueMatches/LeagueDashboard";
import GenerateMatches from "./LeagueMatches/GenerateMatches/GenerateMatches";

const GamesRouter = () => {
  return (
    <Switch>
      <AuthRoute path="/games/leagues/:id/matches">
        <LeagueDashboard />
      </AuthRoute>
      <AuthRoute path="/games/leagues/:id/generateMatches">
        <GenerateMatches />
      </AuthRoute>
    </Switch>
  );
};

export default GamesRouter;
