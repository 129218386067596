import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import moment from "moment-timezone";
import DataTable from "react-data-table-component";
import { OverlayTrigger, Tooltip, Button, Form, Row, Col } from "react-bootstrap";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ShowViewModel from "./includes/ShowViewModel";
import Datetime from "react-datetime";


const Logs = () => {
  const [logs, setLogs] = useState([]);
  const [selectedError, setSelectedError] = useState([]);
  const [showViewModal, setShowViewModal] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLaoding] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [query, setQuery] = useState({});

  useEffect(() => {
    getData();
  }, [rowsPerPage])


  const getData = async (page = 1) => {
    const _query = {
      limit: rowsPerPage,
      offset: (page-1)*rowsPerPage,
      ...query
    }
    const quertStr = new URLSearchParams(_query).toString()
    const { data } = await Axios.get(`${process.env.REACT_APP_CORE_API}/api/logs?${quertStr}`)
    if (data) {
      setLogs(data.list);
      setTotalRows(data.total);
    }

    setLaoding(false);
  }


  const handlePerPageChange = async (newPerPage, page) => {
    try {
      setRowsPerPage(newPerPage);
    } catch (error) {
      setLaoding(false);
      console.log("[LOG_DATA_FETCH_ERROR]", error);
    }
  }

  const columns = [
    {
      name: "Date",
      sortable: true,
      sortFunction: (a, b) => moment(a).isBefore(b),
      cell: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
    },
    { name: "Type", sortable: false, selector: 'type' },
    { name: "Game", sortable: false, selector: "gameName" },
    { name: "Priority", sortable: false, cell: ({ priority }) => {
      return <p className={priority}>{priority}</p>
    } },
    { name: "User", sortable: false, cell: ({ user:{firstName, lastName} }) => `${firstName} ${lastName}`},
    { name: "Action", right: true, cell: (data) => {
      return (
        <>
          <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">View Detail</Tooltip>}>
            <Button variant="link" className="text-secondary" onClick={() => {
              setSelectedError(data)
              setShowViewModal(true)
            }}>
              <FontAwesomeIcon icon={faEye} />
            </Button>
          </OverlayTrigger>
        </>
      )
    }}
  ];

  const onSubmit = async () => {
    getData();
  }

  return ( 
    <>
      <h4 className="ml-3">Error Logs</h4>
      <div className="mx-3">
        <Row>
          <Col lg="3">
            <Form.Group>
              <Form.Label>Type</Form.Label>
              <Form.Control
                type="text"
                name="type"
                id="type"
                defaultValue={query.type}
                onChange={(e) => {
                  setQuery({
                    ...query,
                    type: e.target.value
                  })
                }}
              />
            </Form.Group>
          </Col>
          <Col lg="2">
            <Form.Group>
              <Form.Label>Priority</Form.Label>
              <Form.Control
                as="select"
                name="priority"
                id="priority"
                value={query.priority}
                onChange={(e) => {
                  setQuery({
                    ...query,
                    priority: e.target.value
                  })
                }}   
              >
                <option value={""}>{"--Select--"}</option>
                <option value={"HIGH"}>HIGH</option>
                <option value={"MEDIUM"}>MEDIUM</option>
                <option value={"LOW"}>LOW</option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col lg="2">
            <Form.Label>Game</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => {
                setQuery({
                  ...query,
                  game: e.target.value
                })
              }}
            >
              <option value={""}>{"--Select--"}</option>
              <option value="606701ed58d20c0e47d95fee">CS:GO</option>
              <option value="5f342371f52fd80836260de8">LOL</option>
            </Form.Control>
          </Col>
          <Col lg="2">
            <Form.Group>
              <Form.Label>Start Date</Form.Label>
              <Datetime
                value={query.startDate ? moment(query.startDate).format("DD/MM/YYYY HH:mm:ss") : null}
                onChange={(e) => {
                  if (e === typeof(Date)) {
                    setQuery({
                      ...query,
                      startDate: e.toISOString()
                    })
                  }
                }}
                dateFormat="DD/MM/YYYY"
                timeFormat="HH:mm:ss"
              />
            </Form.Group>
          </Col>
          <Col lg="2">
            <Form.Group>
              <Form.Label>End Date</Form.Label>
              <Datetime
                value={query.endDate ? moment(query.endDate).format("DD/MM/YYYY HH:mm:ss") : null}
                onChange={(e) => {
                  if (e === typeof(Date)) {
                    setQuery({
                      ...query,
                      endDate: e.toISOString()
                    })
                  }
                }}
                dateFormat="DD/MM/YYYY"
                timeFormat="HH:mm:ss"
              />
            </Form.Group>
          </Col>
          <Col lg="1">
            <Button onClick={() => onSubmit()} style={{ marginTop: "1.9rem" }}>Filter</Button>
          </Col>
        </Row>

      </div>
      <div className="mx-3 border">
        <DataTable
          noHeader={true}
          columns={columns}
          progressPending={loading}
          paginationTotalRows={totalRows}
          pagination={true}
          paginationServer={true}
          onChangeRowsPerPage={handlePerPageChange}
          onChangePage={getData}
          data={logs}
          selectableRows={false}
          selectableRowsHighlight={true}
          style={{ overflow: "inherit" }}
        />
      </div>

      <ShowViewModel
        show={showViewModal}
        onHide={() => setShowViewModal(false)}
        data={selectedError}
      />
    </>
  );
}
 
export default Logs;