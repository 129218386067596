import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

const TranslationDropdown = ({ apidata,onSelection }) => {
  return (
    <Form.Group className="mb-3">
      <Form.Control
        // disabled={langExist}
        as="select"
        name="select"
        // value={stage.format}
        onChange={(e) => onSelection( e.target.value)}
        // onBlur={() => onSaveStage(i)}
      >
        <option value="">Select Translation</option>
        <option value={"ID"}>{"ID"}</option>
        {apidata.isDefault === true ? null : <option value={"Source"}>{"Source"}</option>}
        <option value={"Translation"}>{"Translation"}</option>
        <option value={"Category"}>{"Category"}</option>
      </Form.Control>
    </Form.Group>
  );
};

export default TranslationDropdown;
