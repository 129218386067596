
import React, {useEffect } from 'react';
import { useParams } from "react-router-dom";
import useState from 'react-usestateref' 
import { toast } from "react-toastify";
import {  Row, Col,Card,ListGroup,Form,Button } from "react-bootstrap";
import Axios from "axios";

const Participants = () => {
  const { id } = useParams();
  const [allTeams, setAllTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState({});
  const [updatedPlayers,setUpdatedPlayers,updatedPlayerRef] = useState({});
  
  useEffect(() => {
    // Axios.get(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/allTeams`)
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/group/allTeams`)
    .then(({ data }) => {
      setAllTeams(data.data);
    })
    .catch((err) => {
      toast.error("There was a problem in getting teams");
    });
  }, []);


  const handleSelectTeam = (team)=>{
    setSelectedTeam(team);
  }

  return (
    <div className="relative">
      {/* {loading && <div className="abs-loader">Processing...</div>} */}
      <Row>
        <Col md={6}>
          <h5>Teams</h5>
          <Card className="roster-card">
            <Card.Body>
              <ListGroup variant="flush">
                  {/* <ListGroup.Item
                    className="participant-team"
                    action
                    style={{border:"1px solid",borderColor:"gainsboro",borderRadius:4}}
                  >
                    ERN ROAR
                  </ListGroup.Item> */}
                {allTeams.length > 0 &&
                  allTeams.map((team) => (
                    <ListGroup.Item
                      className="roster-team mb-2"
                      key={team?._id}
                      active={team?._id === selectedTeam?._id}
                      action
                      style={{border:"1px solid",borderColor:"gainsboro",borderRadius:4}}
                      onClick={() => {
                        handleSelectTeam(team);
                      }}
                    >
                      {team?.name}
                    </ListGroup.Item>
                  ))}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <h5>Players</h5>
          <Card className="roster-card">
            <Card.Body>
              <ListGroup variant="flush">
                {selectedTeam?.members &&
                  selectedTeam.members.map((member) => (
                    <ListGroup.Item
                      className="roster-team mb-2"
                      key={member?._id}
                      action
                      style={{border:"1px solid",borderColor:"gainsboro",borderRadius:4}}
                      
                    >
                      { member.player ?
                          member?.player?.user?.firstName + " " + member?.player?.user?.lastName + " - " + member?.player?.user?.nationality?.name + "(" + member?.player?.user?.nationality?.code + ")"
                        :
                        member?.user?.firstName + " " + member?.user?.lastName + " - " + member?.user?.nationality?.name + "(" + member?.user?.nationality?.code + ")"
                      }
                    </ListGroup.Item>
                  ))}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
 
export default Participants;