import React, { useState, useEffect, useCallback, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { Breadcrumb, Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faListAlt, faTaxi } from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import LeagueCreateEditModal from "./includes/LeagueCreateEditModal";
import MultiStageSingleLeague from "./includes/MultiStageSingleLeague";
import LeaguesReorderModal from "./includes/LeaguesReorderModal";
import { AppContext } from "../../../context/AppContext";
import { toast } from "react-toastify";

const MultiStage = () => {
  const { user } = useContext(AppContext);
  const { id } = useParams();
  const [multistages, setMultiStages] = useState([]);
  const [render, rerender] = useState(false);
  const [season, setSeason] = useState({});
  const [game, setGame] = useState({});
  const [group, setGroup] = useState({});
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [showReorderModal, setShowReorderModal] = useState(false);
  useEffect(() => {
    Axios.post(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/all`,
    {
      season: id
    }
    )
    .then(({ data }) => {
      setMultiStages(data.data.multiStageLeagues);
      setSeason(data.data.season);
      setGame(data.data.season.game);
      setGroup(data.data.season.group);
      setLoading(false);
    }).catch(() => {
      setLoading(false);
    });
  }, [id]);
  

  const addMultiStageLeague = (multistage) => {
    setMultiStages([...multistages, multistage]);
  }

  const onCreateLeague = () => {
    setModalData(false); // ensure modal data is always blank!
    setShowModal(true);
  };

  const onEditLeague = (leagueData) => {
    setModalData(false);
    setShowEditModal(true);
  };

  const onRemoveLeague = async (id) => {
    const index = multistages.findIndex(multiStage => multiStage._id === id);
    if(index || index === 0){
      try{
        await Axios.delete(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}`);
        const copyMultiStages = multistages;
        copyMultiStages.splice(index, 1);
        setMultiStages(copyMultiStages);
        toast.success("Successfully deleted multi stage league")
      }catch(e){
        toast.error("Multi stage league not deleted");
      }
      rerender(!render);
    }else{
      toast.error("Something went wrong")
    }
  };

  return (
    <div>
      <Breadcrumb>
        {!loading ? (
          <>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/games" }}>
              {game.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/games/${game._id}/groups` }}>
              {group.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/games/groups/${group._id}/seasons` }}>
              {group.name} {season?.year}
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Multi Stage Leagues</Breadcrumb.Item>
          </>
        ) : (
          <Breadcrumb.Item active>Loading...</Breadcrumb.Item>
        )}
      </Breadcrumb>

      <div className="admin-page-title">
        <h2>Multi Stage Leagues</h2>

        <div>
          {!loading && !!multistages.length && (
            <Button variant="secondary" className="mr-2" onClick={() => setShowReorderModal(true)}>
              <FontAwesomeIcon icon={faListAlt} /> Reorder
            </Button>
          )}

          <Button variant="success" onClick={onCreateLeague} disabled={!user.state.admin && !user.state.permissions.user.creation}>
            <FontAwesomeIcon icon={faPlus} /> Create
          </Button>
        </div>
      </div>

      {loading ? (
        "Loading"
      ) : multistages.length === 0 ? (
        'No multi stage leagues to show. Click the "Create" button to create one.'
      ) : (
        <div>
          <Row>
            {multistages.map((multiStageLeague, index) => (
              <>
                <Col xl={4} lg={6} md={6} sm={12} key={multiStageLeague._id}>
                  <MultiStageSingleLeague multiStageLeague={multiStageLeague} league={multiStageLeague.stages[0]} onEditLeague={onEditLeague} deletingLeague={multiStageLeague._id} onRemoveLeague={onRemoveLeague} creationPermission={!user.state.admin ? user.state.permissions.user.creation : true} />
                </Col>
                <LeagueCreateEditModal show={showEditModal} handleClose={() => setShowEditModal(false)} data={multiStageLeague} addMultiStageLeague={addMultiStageLeague} />
              </>
            ))}
          </Row>
          <LeaguesReorderModal
            show={showReorderModal}
            onHide={() => setShowReorderModal(false)}
            leagues={multistages}
            // setLeagues={(g) => setLeagues(g)}
          />
        </div>
      )}
      <LeagueCreateEditModal show={showModal} handleClose={() => setShowModal(false)} data={false} addMultiStageLeague={addMultiStageLeague} />
    </div>
  );
};

export default MultiStage;
