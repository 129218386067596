import React, { useState, useRef, useEffect } from "react";
import { Button, Modal, Form, Dropdown, Card, Media } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Axios from "axios";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
 
const AddTeamsModal = ({ show, onHide, teams, setLeague, game=null }) => {
  const { id } = useParams();
  const timeout = useRef();
  const [teamsQuery, setTeamsQuery] = useState("");
  const [teamResults, setTeamResults] = useState([]);
  const [teamResultsLoading, setTeamResultsLoading] = useState(false);
  const [selectedTeams, setSelectedTeams] = useState([]);

  // alter team results on query change
  useEffect(() => {
    if (!teamsQuery.length) return setTeamResults([]);
    setTeamResultsLoading(true);

    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      Axios.post(`${process.env.REACT_APP_CORE_API}/api/teams/searchByGame`, { query: teamsQuery, game: game._id || null }).then(({ data }) => {
        const withoutSelectedTeams = data.filter(
          ({ _id }) => !teams.map(({ team }) => team?._id).includes(_id) && !selectedTeams.map((team) => team?._id).includes(_id)
        );
        const withoutSelectedTeamName = withoutSelectedTeams.filter(
          ({ name }) => !teams.map(({ team }) => team?.name).includes(name) && !selectedTeams.map((team) => team?.name).includes(name)
        );
        setTeamResults(withoutSelectedTeamName);
        setTeamResultsLoading(false);
      });
    }, 500);
  }, [teamsQuery, teams, selectedTeams]);

  const onSelectTeam = (team) => {
    setSelectedTeams((teams) => [...teams, team]);
    setTeamsQuery("");
    setTeamResults([]);
  };

  const onAddTeams = async (e) => {
    e.preventDefault();

    try {
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/leagues/${id}/teams`, {
        teams: selectedTeams.map(({ _id }) => _id),
      });
      toast.success("Successfully added teams");
      setLeague((league) => ({ ...league, ...data.updatedLeague }));
      setSelectedTeams([]);
      onHide();

    } catch (e) {
      toast.error("There was a problem adding the teams");
    }
  };
 
  const onRemoveTeam = (id) => {
    setSelectedTeams((teams) => teams.filter(({ _id }) => _id !== id));
  };

  const teamSelectedNumberContent = () => {
    var teamsInNumber = selectedTeams.length;
    if(teamsInNumber){
      if(teamsInNumber > 1){
        return `(${teamsInNumber} teams selected)`
      }else{
        return `(${teamsInNumber} team selected)`
      }
    }
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add Team <small>{teamSelectedNumberContent()}</small></Modal.Title>
        
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onAddTeams}>
          <Form.Group>
            <Form.Control autoFocus placeholder="Search for teams" onChange={(e) => setTeamsQuery(e.target.value)} value={teamsQuery} />
            {(teamResultsLoading || !!teamResults.length) && (
              <Card bg="light">
                <ul className="list-unstyled">
                  {teamResultsLoading ? (
                    <Dropdown.Item className="text-secondary">Loading...</Dropdown.Item>
                  ) : (
                    teamResults.map((team) => (
                      <Dropdown.Item key={team._id} onClick={() => onSelectTeam(team)}>
                        {team.name}
                      </Dropdown.Item>
                    ))
                  )}
                </ul>
              </Card>
            )}
          </Form.Group>

          <div>
            {selectedTeams.map(({ _id, logo, name }) => (
              <Media key={_id} className="mb-3">
                <img width={25} height={25} className="mr-3" src={logo} alt={name} />
                <Media.Body>
                  <span>{name}</span>
                  <Button variant="link" className="text-danger" size="sm" onClick={() => onRemoveTeam(_id)}>
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                </Media.Body>
              </Media>
            ))}
          </div>

          <Button type="submit" variant="success" className="float-right">
          Add
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};


export default AddTeamsModal;
