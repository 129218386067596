import React, { useState, useEffect, useRef } from "react";
import Axios from "axios";
import { Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const FilterOrganisations = ({ setFilterOrganisations, setLoading }) => {
  const [filterQuery, setFilterQuery] = useState("");
  const timeout = useRef();

  // When query changes, filter organisations
  useEffect(() => {
    filterResult();
  }, [filterQuery, setFilterOrganisations]);

  const filterResult = async () => {
    clearTimeout(timeout.current);
    setFilterOrganisations([]);

    const query = filterQuery.toLowerCase();
    setLoading(true);

    timeout.current = setTimeout(async () => {
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/organisations/search`, { query });
      setFilterOrganisations(data);
      setLoading(false);
    }, 250);
  }

  return (
    <Form.Group>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text id="inputGroupPrepend">
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control type="search" placeholder="Filter Organisations" value={filterQuery} onChange={(e) => setFilterQuery(e.target.value)} />
      </InputGroup>
    </Form.Group>
  );
};

export default FilterOrganisations;
