import React from "react";
import { Row, Col } from "react-bootstrap";

const SingleMember = ({ img, title, subtitle, pending, position, role, nationality, actions }) => {
  return (
    <div className="playerInfo" style={{ opacity: pending ? 0.6 : 1 }}>
      <Row>
        {img ? (
          <div className="team-member-img" style={{ backgroundImage: `url(${img})` }} alt={title} />
        ) : (
          <div className="no_profile">{title ? title.charAt(0) + title.charAt(1).toUpperCase() : ""}</div>
        )}

        <Col md={3} className="playerTitle">
          {title || "[Deleted]"}
          <div className="playerSubtitle">
            {subtitle}
          </div>
        </Col>

        <Col md={3} className="playerPosition">
          {position.name ? position.name : role}
        </Col>
        <Col md={3} className="playerPosition">
          {nationality && (
            <>
              <img src={`https://flagcdn.com/w20/${nationality.code.toLowerCase()}.png`} alt={nationality.name} width="20" />
              <span className="playerNationality">{nationality.name}</span>
            </>
          )}
        </Col>
        <Col className="playerActions">
          {actions && title && actions.map((action, i) => (
            <React.Fragment key={i}>{action}</React.Fragment>
          ))}
        </Col>
      </Row>
    </div >
  )

}

export default SingleMember;