import React from "react";
import { Card, Button, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrashAlt, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { ReactCSS } from "reactcss";

const SingleGroup = ({ group, color, onEditGroup, deletingGroup, onRemoveGroup, user }) => {
  const { _id, name, country } = group;
 
  const styles = ReactCSS({
    default: {
      color: {
        width: "36px",
        height: "24px",
        borderRadius: "2px",
        //   background: `rgba(${ this.state.color.r }, ${ this.state.color.g }, ${ this.state.color.b }, ${ this.state.color.a })`,
        background: `${color}`,
      },
      swatch: {
        padding: "5px",
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      },
    },
  });

  return (
    <Card body>
      <h5>{name} {!!country && !!country.code && <img src={`https://www.flagcdn.com/128x96/${country.code.toLowerCase()}.png`} width={25} height={25} alt={country} className="ml-2" />}</h5>
      <div className="float-right" title="Main Colour" style={styles.swatch}>
        <div style={styles.color} />
      </div>
      <Button size="sm" variant="primary" className="space-right" as={Link} to={`/games/groups/${_id}/seasons`}>
        <FontAwesomeIcon icon={faEye} /> View Seasons
      </Button> 
      <Button size="sm" variant="outline-success" className="space-right" disabled = {!user.state.admin && !user.state.permissions?.user?.setting ? "disabled-link" : "" } onClick={() => onEditGroup(group)}>
        <FontAwesomeIcon icon={faPencilAlt} /> Edit
      </Button>
      <Button
        size="sm"
        className="space-right"
        variant="outline-danger"
        onClick={() => onRemoveGroup(_id)}
        disabled={!user.state.admin && !user.state.permissions?.user?.setting ?  "disabled-link" : deletingGroup === _id}
      >
        {deletingGroup === _id ? (
          <Spinner animation="border" size="sm" />
        ) : (
          <>
            <FontAwesomeIcon icon={faTrashAlt} /> Delete
          </>
        )}
      </Button>
    </Card>
  );
};

export default SingleGroup;
