import React, {useState,useEffect} from 'react';
import {render} from 'react-dom';
import Axios from "axios";
import { Modal, Form, Button, Tooltip, OverlayTrigger, FormGroup } from "react-bootstrap";
import moment from "moment";

const SelectASeason = ({ updaten, currentLeague = null, isTournament = false }) => {
  const [selectedLeague, setSelectedLeague] = useState(currentLeague);
  const [allLeague, setAllLeague] = useState([]);

  const [selectedSeason, setSelectedSeason] = useState(null);
  const [allSeason, setAllSeason] = useState([]);

  const [selectedGroup, setSelectedGroup] = useState(null);
  const [allGroup, setAllGroup] = useState([]);

  const [selectedGame, setSelectedGame] = useState(null);
  const [allGame, setAllGame] = useState([]);

  const setSelectedGameUp = (value) => {
    setAllGroup([]);
    setSelectedGroup(null);
    setAllSeason([]);
    setSelectedSeason(null);
    setAllLeague([]);
    setSelectedLeague(null);
    setSelectedGame(value);
  };
  const setSelectedGroupUp = (value) => {
    setAllSeason([]);
    setSelectedSeason(null);
    setAllLeague([]);
    setSelectedLeague(null);
    setSelectedGroup(value);
  };
  const setSelectedSeasonUp = (value) => {
    setAllLeague([]);
    setSelectedLeague(null);
    setSelectedSeason(value);
  };

  const updateLeague = (selectedLeagueID) => {
    //console.log('sadasdasdasdasd',selectedLeagueID)
    let selectedLeagueObj = allLeague.filter((singLeague) => singLeague._id === selectedLeagueID);
    setSelectedLeague(selectedLeagueObj);
    //console.log('sadasdasdasdasd',selectedLeagueObj)
    updaten(selectedLeagueObj[0]);
  };

  // Fetching all games,groups,seasons and leagues for edit
  useEffect(() => {
    if (currentLeague !== null && !allLeague.length && !allSeason.length && !allGroup.length && !allGame.length) {
      // setSelectedLeague(currentLeague);

      fetchGames();

      fetchGroupsByGame(currentLeague.game);
      setSelectedGame(currentLeague.game);

      fetchSeasonsByGroup(currentLeague.group);
      setSelectedGroup(currentLeague.group);

      fetchLeaguesBySeason(currentLeague.season);
      setSelectedSeason(currentLeague.season);
    }
  }, [currentLeague]);

  // Fetching all games
  const fetchGames = () => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/games`).then(({ data }) => {
      setAllGame(() => {
        return data.filter((game) => game.name !== "No Game");
      });
    });
  };
  useEffect(() => {
    fetchGames();
  }, []);

  // Fetching all Groups of Selected Game
  const fetchGroupsByGame = (selectedGame) => {
    selectedGame !== null &&
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/seasons/frontpage/groupsByGame/${selectedGame}`).then(({ data }) => {
        setAllGroup(data);
        console.log("game groups 3 : ", data);
      });
  };
  useEffect(() => {
    selectedGame !== null && fetchGroupsByGame(selectedGame);
  }, [selectedGame]);

  // Fetching all Seasons of Selected Group
  const fetchSeasonsByGroup = (selectedGroup) => {
    selectedGroup !== null &&
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/seasons/frontpage/seasonByGroup/${selectedGroup}`).then(({ data }) => {
        // console.log("season by group --- ",data);
        setAllSeason(
          data.sort(
            (season1, season2) => (season1.startDate && season2.startDate && moment(season1.startDate).diff(moment(season2.startDate))) || 1
          )
        );
      });
  };
  useEffect(() => {
    selectedGroup !== null && fetchSeasonsByGroup(selectedGroup);
  }, [selectedGroup]);

  // Fetching all Leagues of Selected Season
  const fetchLeaguesBySeason = (selectedSeason) => {
    selectedSeason !== null &&
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/seasons/frontpage/leagueBySeason/${selectedSeason}`).then(({ data }) => {
        setAllLeague(data);
      });
  };
  // Fetching all Tournaments of Selected Season
  const fetchTournamentsBySeason = (selectedSeason) => {
    selectedSeason !== null &&
      Axios.get(`${process.env.REACT_APP_CORE_API}/api/seasons/frontpage/tournamentBySeason/${selectedSeason}`).then(({ data }) => {
        setAllLeague(data);
      });
  };

  useEffect(() => {
    selectedSeason !== null && (isTournament ? fetchTournamentsBySeason(selectedSeason) : fetchLeaguesBySeason(selectedSeason));
  }, [selectedSeason]);

  return (
    <>
      <Form>
        <Form.Group>
          <Form.Label>Game</Form.Label>
          <div className="d-flex align-items-center">
            <Form.Control
              as="select"
              id="game"
              name="game"
              value={selectedGame ? selectedGame : null}
              // onChange={(e) => updaten('game',e.target.value)}
              onChange={(e) => setSelectedGameUp(e.target.value)}
            >
              <option value="">Select Game</option>
              {allGame.map((code) => (
                <option value={code._id}>{code.name}</option>
              ))}
            </Form.Control>
          </div>
        </Form.Group>

        <Form.Group>
          <Form.Label>Group</Form.Label>
          <div className="d-flex align-items-center">
            <Form.Control
              as="select"
              id="group"
              name="group"
              value={selectedGroup ? selectedGroup : null}
              // onChange={(e) => updaten('group',e.target.value)}
              onChange={(e) => setSelectedGroupUp(e.target.value)}
            >
              <option value="">Select Group</option>
              {allGroup.map((code) => (
                <option value={code._id}>{code.name}</option>
              ))}
            </Form.Control>
          </div>
        </Form.Group>

        <Form.Group>
          <Form.Label>Season</Form.Label>
          <div className="d-flex align-items-center">
            <Form.Control
              as="select"
              id="season"
              name="season"
              value={selectedSeason ? selectedSeason : null}
              // onChange={(e) => updaten('season',e.target.value)}
              onChange={(e) => setSelectedSeasonUp(e.target.value)}
            >
              <option value="">Select Season</option>
              {allSeason.map((code) => (
                <option value={code._id}>
                  {code.name} - {code.year}
                </option>
              ))}
            </Form.Control>
          </div>
        </Form.Group>

        <Form.Group>
          <Form.Label>{isTournament ? "Tournament" : "League"}</Form.Label>
          <div className="d-flex align-items-center">
            <Form.Control
              as="select"
              id="league"
              name="league"
              value={selectedLeague ? selectedLeague._id : null}
              onChange={(e) => updateLeague(e.target.value)}
            >
              <option value="">Select {isTournament ? "Tournament" : "League"}</option>
              {allLeague.map((code) => (
                <option value={code._id}>{code.name}</option>
              ))}
            </Form.Control>
          </div>
        </Form.Group>
      </Form>
    </>
  );
};

export default SelectASeason;