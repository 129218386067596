import React from "react";
import { Switch } from "react-router-dom";
// import { AuthRoute } from "../../../utils/CustomRouteTypes";
import { AuthRoute } from "../../../utils/CustomRouteTypes";
import MultiStageLeagueDashboard from "./MultiStageLeagueDashboard";
import MultiStageLeagueGenerateMatches from "./MultiStageLeaguesGenerateMatches";

const MultiStageLeaguesRouter = () => {
  return (
    <Switch>
      <AuthRoute path="/games/multi-stage-league/:id/matches">
        <MultiStageLeagueDashboard />
      </AuthRoute>
      <AuthRoute path="/games/multi-stage-league/:id/generateMatches">
        <MultiStageLeagueGenerateMatches />
      </AuthRoute>
    </Switch>
  );
};

export default MultiStageLeaguesRouter;
