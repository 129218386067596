import React, { useRef } from 'react';
import JoditEditor from '../../../../../lib/JoditEditor';

const RichTextbox = ({ content, onBlur }) => {
	const editor = useRef(null)

	const config = {
		readonly: false // all options from https://xdsoft.net/jodit/doc/
	}

	return (
		<JoditEditor
			ref={editor}
			value={content}
			config={config}
			tabIndex={1} // tabIndex of textarea
			onBlur={onBlur} // preferred to use only this option to update the content for performance reasons
			// onChange={(c) => setContent(c)}
		/>
	);
}

export default RichTextbox;