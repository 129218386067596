import React, { useState, useEffect } from "react";
import { Form, Row, Col, Card, Accordion } from "react-bootstrap";

const mapActions = {
  BAN: "BAN 1 MAP",
  PICK: "PICK 1 MAP",
  SIDE_PICK: "PICK SIDE",
};

export const MapStepForm = ({ match, maps, currentStep, vetoSteps, setSubmitError, onVetoStepChange }) => {
  const [selectedMap, setSelectedMap] = useState(currentStep.map ? currentStep.map.id : null);
  const [counterTerrorist, setCounterTerrorist] = useState(currentStep.ct);
  const [terrorist, setTerrorist] = useState(currentStep.t);
  const [error, setError] = useState(false);
  const [currentStepData, setCurrentStepData] = useState(currentStep);
  const [stepsData, setStepsData] = useState(vetoSteps);

  useEffect(() => {
    setSelectedMap(currentStep.map ? currentStep.map.id : null);
    setCounterTerrorist(currentStep.ct);
    setTerrorist(currentStep.t);
    setCurrentStepData(currentStep);
    setStepsData(vetoSteps);
  }, [match, currentStep, vetoSteps]);

  useEffect(() => {
    error ? setSubmitError({ id: currentStep.no, error: true }) : setSubmitError({ id: currentStep.no, error: false });
  }, [error]);

  const onChange = async ({ target }) => {
    const { value, name } = target;
    let err = false;
    setSelectedMap(value);

    if (value === "_DEFAULT_") {
      updateStepFn(null, "_DEFAULT_");
      return;
    }

    if (name === "map" && currentStepData.action !== "SIDE_PICK") {
      let mapNo = stepsData.findIndex((step) => step.map && step.map.id === Number(value));
      err =
        mapNo === -1
          ? false
          : currentStep.map && currentStepData.map.id == value
          ? false
          : `This map is already selected in step ${stepsData[mapNo].no}`;
      err ? setError(err) : updateStepFn(value, "Pick & Map");
    } else {
      if (name == "ct") {
        let t = match.t1._id.toString() === value.toString() ? match.t2._id : match.t1._id;
        setCounterTerrorist(value);
        setTerrorist(t);
        updateStepFn({ t, ct: value }, "Side_pick");
      } else {
        let ct = match.t1._id.toString() === value.toString() ? match.t2._id : match.t1._id;
        setCounterTerrorist(ct);
        setTerrorist(value);
        updateStepFn({ ct, t: value }, "Side_pick");
      }
    }
  };

  const updateStepFn = (data, action) => {
    setError(false);
    let _currentStepData = currentStepData;
    if (action === "Pick & Map") {
      _currentStepData.map = maps.find((map) => map.id == data);
      onVetoStepChange(_currentStepData);
    } else if (action === "Side_pick") {
      _currentStepData.ct = data.ct;
      _currentStepData.t = data.t;
      onVetoStepChange(_currentStepData);
    } else {
      _currentStepData.map = data;
      onVetoStepChange(_currentStepData);
    }
  };

  return (
    <div>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey={currentStepData.no} style={{ cursor: "pointer" }}>
          {currentStepData.no}
          {" - "}
          {mapActions[currentStepData.action]} {error && <small className="ml-2 text-danger">*Error</small>}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={currentStepData.no}>
          <Card.Body>
            <Form>
              <Row>
                <Col xl="12">
                  <Form.Group className="w-100">
                    <Form.Label>Map</Form.Label>
                    <Form.Control
                      as="select"
                      name="map"
                      disabled={currentStepData.action === "SIDE_PICK"}
                      value={selectedMap ? selectedMap : "_DEFAULT_"}
                      onChange={onChange}
                    >
                      <option value="_DEFAULT_">Select Map</option>
                      {maps.map(
                        (mapObj) =>
                          mapObj && (
                            <option key={mapObj.id} value={mapObj.id}>
                              {mapObj.name}
                            </option>
                          )
                      )}
                    </Form.Control>
                  </Form.Group>
                  {error && <small className="text-danger">{error}</small>}
                </Col>
              </Row>
              {currentStepData.action === "SIDE_PICK" && (
                <Row>
                  <Col xl="6">
                    <Form.Group className="w-100">
                      <Form.Label>Counter Terrorist</Form.Label>
                      <Form.Control as="select" name="ct" value={counterTerrorist || "_DEFAULT_"} onChange={onChange}>
                        <option value="_DEFAULT_" disabled>
                          Select Counter Terrorist
                        </option>
                        <option value={match.t1._id}>{match.t1.name}</option>
                        <option value={match.t2._id}>{match.t2.name}</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col xl="6">
                    <Form.Group className="w-100">
                      <Form.Label>Terrorist</Form.Label>
                      <Form.Control name="t" as="select" value={terrorist || "_DEFAULT_"} onChange={onChange}>
                        <option value="_DEFAULT_" disabled>
                          Select Terrorist
                        </option>
                        <option value={match.t1._id}>{match.t1.name}</option>
                        <option value={match.t2._id}>{match.t2.name}</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              )}
            </Form>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </div>
  );
};

export default MapStepForm;
