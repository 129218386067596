import React, { useState } from "react";
import Players from "../../includes/GameConfigureModal/tabs/Players";
import Substitutes from "../../includes/GameConfigureModal/tabs/Substitutes";
import Coaches from "../../includes/GameConfigureModal/tabs/Coaches";
import ItemsList from "../../includes/GameConfigureModal/tabs/ItemsList";
import { Accordion, Card } from "react-bootstrap";
import Axios from "axios";
import { Form, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const PlayerRanksAndTeams = ({ game, setGame }) => {
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = async (data) => {
    try {
      const { data: resData } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/games/${game._id}/setLimit`, data);
      setGame({ ...game, ...resData.updatedGame });
      toast.success("Player / Coach Limit Set Successfully");
    } catch (error) {
      toast.error("Player Updating Failed");
    }
  }

  return (
    <>
      <Form>
        <Row>
          <Col md="6">
            <Form.Group>
              <Form.Label>Players Account Limit</Form.Label>
              <Form.Control
                ref={register({ required: "Required" })}
                type="number"
                id="playerLimit"
                name="playerLimit"
                defaultValue={game.playerLimit}
                isInvalid={errors.playerLimit}
                onBlur={handleSubmit(onSubmit)}
              />
              <Form.Control.Feedback type="invalid">{errors.playerLimit && errors.playerLimit.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md="6">
            <Form.Group>
              <Form.Label>Coaches Account Limit</Form.Label>
              <Form.Control
                ref={register({ required: "Required" })}
                type="number"
                id="coachLimit"
                name="coachLimit"
                defaultValue={game.coachLimit ? game.coachLimit : 1}
                isInvalid={errors.coachLimit}
                onBlur={handleSubmit(onSubmit)}
              />
              <Form.Control.Feedback type="invalid">{errors.coachLimit && errors.coachLimit.message}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Form>

      <Accordion>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">
            Acceptable Ranks in Hierarchical Order (Highest Rank First)
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <ItemsList game={game} setGame={setGame} fieldName={"gameRanks"} displayName="Rank" />
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="3">
            Player Types
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              <Players playerTypes={game.configuration.playerTypes} data={game} setGame={setGame} />
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="7">
            Substitutes Types
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="7">
            <Card.Body>
              <Substitutes subTypes={game.configuration.subTypes ? game.configuration.subTypes : []} data={game} setGame={setGame} />
            </Card.Body>
          </Accordion.Collapse>
        </Card>

        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="4">
            Coach Types
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="4">
            <Card.Body>
              <Coaches coachTypes={game.configuration.coachTypes ? game.configuration.coachTypes : []} data={game} setGame={setGame} />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  );
};

export default PlayerRanksAndTeams;
