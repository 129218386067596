import React, { useState } from "react";
import { Card, Nav } from "react-bootstrap";
import General from "./Tabs/General"

const PlayerTabsManager = ({ player, setPlayer }) => {
  const [activeTab, setActiveTab] = useState("General");

  // ADD/REMOVE TABS THROUGH THIS ARRAY
  const TABS = [
    {
      name: "General",
      content: (
        <General player={player} setPlayer={setPlayer} />
      ),
    },
  ];

  return (
    <>
      <Card>
        <Card.Header>
          <Nav variant="tabs" activeKey={activeTab} onSelect={(t) => setActiveTab(t)}>
            {TABS.map(({ name }) => (
              <Nav.Item key={name}>
                <Nav.Link eventKey={name} active={activeTab === name}>
                  {name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Card.Header>
        <Card.Body>
          {TABS.map(({ name, content }) => name === activeTab && <div key={name}>{content}</div>)}
        </Card.Body>
      </Card>
    </>
  );
};

export default PlayerTabsManager;
