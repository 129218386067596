import React, { useState, useEffect, useContext, useMemo } from "react";
import DataTable from "react-data-table-component";
import moment from "moment-timezone/builds/moment-timezone-with-data";
import { Button, Alert, OverlayTrigger, Tooltip, Spinner, Row, Col, Form, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faEye,
  faPencilAlt,
  faLock,
  faTrashAlt,
  faUsers,
  faWrench,
  faCheck,
  faPlus,
  faMagic,
} from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import MatchScoreModal from "./includes/MatchScoreModal";
import MatchScoreModalCSGO from "./includes/MatchScoreModalCSGO";
import MatchSetPlayersModal from "./includes/MatchSetPlayersModal";
import MatchVetoModalCSGO from "./includes/MatchVetoModalCSGO";
import MoveMatchModal from "./includes/MoveMatchModal";
import Swal from "sweetalert2";
import { GrCode } from "react-icons/gr";
import { BsFillCameraVideoFill, BsSquareHalf } from "react-icons/bs";
import "./matches.scss";
import { AppContext } from "../../../../../context/AppContext";
import { TIMEZONES } from "../../../../../utils/timezones";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
// import { Link } from "react-router-dom";
import MultiStageLeagueCreateEditMatchModal from "./includes/MultiStageLeagueCreateEditMatchModal";
import MultiStageTournamentCreateEditMatchModal from "./includes/MultiStageTournamentCreateEditMatchModal";
import MultiStageLeagueFilterMatches from "./includes/MultiStageLeagueFilterMatches";
import MultiStageGenMatchesPromptCard from "../../MultiStageGenMatchesPromptCard";
import Calendar from "../Tabs/Calendar";
// import MultiStageLeaguesGenerateMatches from '../../MultiStageLeaguesGenerateMatches'

const statuses = ["", "complete", "inProgress", "upcoming"];

const Matches = ({
  teams = [],
  matches,
  updateMatches,
  activeTab,
  league,
  setLeague,
  setPrefferedTimezone,
  prefferedTimezone,
  selectedGroupIndex,
  setSelectedGroupIndex,
  selectedStageIndex,
  setSelectedStageIndex,
  isAllGroup,
  setIsAllGroup,
  isAllStage,
  setIsAllStage,
}) => {
  const { id } = useParams();
  const { user } = useContext(AppContext);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showScoreModal, setShowScoreModal] = useState(false);
  const [showSetPlayersModal, setShowSetPlayersModal] = useState(false);
  const [showEditVeto, setShowEditVeto] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [vetoModalData, setVetoModalData] = useState(false);
  const [count, setCount] = useState(0);
  const [showMoveMatchModal, setShowMoveMatchModal] = useState(false);
  const [generateCodeloaderSpinner, setGenerateCodeloaderSpinner] = useState(false);
  const [clearSelectedRows, setClearSelectedRows] = useState(true);
  const [allStages, setAllStages] = useState([]);
  const [allStageMatches, setAllStageMatches] = useState([]);
  const [stgType, setStgType] = useState();
  const [stgIndex, setStgIndex] = useState(false);
  // const [grpIndex, setGrpIndex] = useState(selectedGroupIndex);
  const [responseloader, setResponseLoading] = useState(false);
  const [showMatchModal, setShowMatchModal] = useState(false);
  const [showTournamentMatchModal, setShowTournamentMatchModal] = useState(false);
  const [game, setGame] = useState({});
  const [showCalendar, setShowCalendar] = useState(false);
  const [stageTypeForModal, setStageTypeForModal] = useState("");
  const [gameData, setGameData] = useState(null);
  const [selectedStage, setSelectedStage] = useState(0);

  const [allStagesTabs, setAllStagesTabs] = useState([]);
  const [allGroupsTabs, setAllGroupsTabs] = useState([]);
  // console.log({selectedGroupIndex,selectedStageIndex,allStages,count,})
  /**
   * 0 => all matches including completed and upcoming
   * 1 => only upcoming matches
   * 2 => only completed matches
   */
  // const fetchMatches = () => {
  //   setAllStages([]);
  //   Axios.get(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/matches`)
  //     .then(({ data }) => {
  //       setGame(data.data.game);
  //       setAllStages(data.data.allStageMatches);
  //       setStgType(data.data.allStageMatches[0]);
  //       Axios.get(`${process.env.REACT_APP_CORE_API}/api/games/${data.data.game._id}`).then(({ data }) => setGameData(data));
  //     })
  //     .catch((err) => {
  //       toast.error("There was a problem in getting matches");
  //     });
  // };
  const updateAllStageMatches = (match)=>{
    let copiedArr = [...allStagesTabs]
    if(allStagesTabs){
      let ind = copiedArr.findIndex(el=> el._id === match._id)
      copiedArr[ind].status = match.status
      copiedArr[ind].t1Score = match.t1Score
      copiedArr[ind].t2Score = match.t2Score
      setAllStagesTabs(copiedArr);
    }
  }
  const getUniqueMatches = (arr) => {
    return arr
      .filter((value, index) => {
        const _value = JSON.stringify(value);
        return (
          index ===
          arr.findIndex((obj) => {
            return JSON.stringify(obj) === _value;
          })
        );
      })
      .sort((a, b) => new Date(a.datetime) - new Date(b.datetime));
  };
  const fetchAllStageTabs = () => {
    setAllStagesTabs([]);
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/matches/allStages`)
      .then(({ data, msg }) => {
        if (msg !== "Matches not found") {
          // console.log(data?.data);
          if (data?.data?.allStagesMatches) {
            const uniqueArray = getUniqueMatches(data?.data?.allStagesMatches);
            setAllStagesTabs(uniqueArray);
          }
        }
        // setStgType(data.data.allStageMatches[0]);
        // Axios.get(`${process.env.REACT_APP_CORE_API}/api/games/${data.data.game._id}`)
        //   .then(({data}) => setGameData(data))
      })
      .catch((err) => {
        console.log(err);
        toast.error("There was a problem in getting matches");
      });
  };

  const onStageTabChange = (stageId) => {
    setResponseLoading(true);
    setAllGroupsTabs([]);
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/${stageId}/matches/all`)
      .then(({ data }) => {
        if (data?.data?.allGroupsMatches) {
          const uniqueArray = getUniqueMatches(data.data.allGroupsMatches);
          setAllGroupsTabs(uniqueArray);
        }
        // if(data?.data?.allGroupsMatches?.length > 1) {
        //   setSelectedGroupIndex(data?.data?.allGroupsMatches?.length)
        // }
        setResponseLoading(false);
      })
      .catch((err) => {
        toast.error("There was a problem in getting matches 2");
        console.log("error: ", err);
      });
  };
  const setAllStagesData = (data) => {
    let allMatchesArr = [];
    data.map((stage, index) => {
      stage.groups.map((group, index) => {
        allMatchesArr.push(...group.matches);
      });
    });
    setAllStageMatches({
      stage: {
        name: "all",
        groups: {
          name: "all",
          matches: [...allMatchesArr],
        },
      },
    });
  };
  const fetchMatches = () => {
    setResponseLoading(true);
    setAllStages([]);
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/matches`)
      .then(({ data }) => {
        setGame(data.data.game);
        setAllStagesData(data.data.allStageMatches.sort((a, b) => new Date(a.datetime) - new Date(b.datetime)));
        setAllStages(data.data.allStageMatches.sort((a, b) => new Date(a.datetime) - new Date(b.datetime)));
        if (data.data.allStageMatches.length > 1 && isAllStage) {
          setSelectedStageIndex(data.data.allStageMatches.length);
        }
        setStgType(data.data.allStageMatches[0]);
        Axios.get(`${process.env.REACT_APP_CORE_API}/api/games/${data.data.game._id}`).then(({ data }) => setGameData(data));
      })
      .catch((err) => {
        setResponseLoading(false);
        toast.error("There was a problem in getting matches");
      });
  };

  const updateMatchRecords = (data) => {
    let matches = [];
    if (selectedStage === 0) {
      data.map((x) =>
        x.groups.map((group) =>
          group.matches.map((match) => {
            matches.push(match);
          })
        )
      );
      setAllStagesTabs(matches);
    }
  };

  // Use Effect get stages
  useEffect(() => {
    fetchMatches();
    fetchAllStageTabs();
  }, []);

  useEffect(() => {
    gettingAllGroups();
  }, [selectedStageIndex, selectedGroupIndex]);

  const gettingAllGroups = () => {
    if (selectedStageIndex > -1 && selectedStage < allStages.length) {
      if (allStages[selectedStageIndex]) {
        onStageTabChange(allStages[selectedStageIndex]._id);
        if (allStages[selectedStageIndex].groups.length > 1 && isAllGroup) {
          setSelectedGroupIndex(allStages[selectedStageIndex].groups.length);
        }
      }
    }
  };
  const onEditMatch = (data) => {
    let stg,
      stgIndex = null;
    allStages.forEach((s, i) => {
      if (s._id === data.groups[0].stage) {
        stgIndex = i;
        stg = s;
      }
    });
    setStgType(stg);
    setStgIndex(stgIndex);
    // setSelectedStageIndex(stgIndex);
    setShowMatchModal(true);
    setShowTournamentMatchModal(true);
    setModalData(data);
  };

  const onCreateMatch = (stage_index) => {
    setSelectedStageIndex(stage_index);
    setStgIndex(stage_index);
    setShowMatchModal(true);
    setShowTournamentMatchModal(true);
    setModalData(false);
  };

  const searchFilteredMatches = (stageIndex, groupIndex, matches) => {
    const allStagesCopy = [...allStages];
    if (stageIndex >= 0) {
      allStagesCopy[stageIndex].groups[groupIndex].matches = matches;
      setAllStages(allStagesCopy);
    } else {
      setAllStagesTabs(matches);
    }
    console.log("matches", [...matches]);
    console.log("allStagesCopy", [...allStagesCopy]);

    setResponseLoading(false);
  };

  const addUpdatedMatch = () => {
    setShowMatchModal(false);
    setShowTournamentMatchModal(false);
    fetchMatches();
    fetchAllStageTabs();
  };

  const getLeagueTeamData = (id) => {
    return teams.find(({ team }) => team?._id === id) || {};
  };

  const onReschedulingLock = async (match) => {
    try {
      Swal.fire({
        text: `Do you want to ${match.isLockReschedule ? "Unlock" : "Lock"} rescheduling of this match`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#28a745",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/matches/${match._id}/lock`, {
            lock: !match.isLockReschedule,
          });
          updateMatches((matches) => {
            const index = matches.findIndex(({ _id }) => _id.toString() === data.updatedMatch._id.toString());
            matches[index].isLockReschedule = !matches[index].isLockReschedule;
            return matches;
          });
          toast.success(`Successfully match rescheduling locked!`);
        }
      });
    } catch (error) {
      toast.error("There was a problem processing rescheduling locked 😞");
    }
  };

  const onSetPlayers = (match) => {
    setModalData(match);
    setShowSetPlayersModal(true);
  };

  const onEditVeto = (match) => {
    setVetoModalData(match);
    setShowEditVeto(true);
  };

  const onSetScore = (match) => {
    setModalData(match);
    setShowScoreModal(true);
  };

  const editMatch = async (matchId, body) => {
    try {
      const { data } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/matches/${matchId}`, body);
      updateMatches((matches) =>
        matches.map((match) => (match._id.toString() === data.updatedMatch._id.toString() ? data.updatedMatch : match))
      );
    } catch (error) {
      toast.error("There was problem in updating match");
    }
  };

  const generateTournamentCodes = async (match) => {
    try {
      setGenerateCodeloaderSpinner && setGenerateCodeloaderSpinner(match._id);
      const { data } = await Axios.post(`${process.env.REACT_APP_LOL_API}/api/matches/${match._id}/generateTournamentCodes`);
      updateMatches((matches) =>
        matches.map((match) => (match._id.toString() === data.updatedMatch._id.toString() ? data.updatedMatch : match))
      );
      setGenerateCodeloaderSpinner && setGenerateCodeloaderSpinner(false);
    } catch (error) {
      setGenerateCodeloaderSpinner && setGenerateCodeloaderSpinner(false);
      toast.error("Failed to generate tournament codes");
    }
  };
  const columns = [
    {
      name: "Date",
      sortable: true,
      className: "last-column",
      sortFunction: (a, b) => moment(a).isBefore(b),
      cell: ({ datetime }) => {
        const zonedDatetime = moment.tz(datetime, null, true, prefferedTimezone);
        const zone = zonedDatetime?.zoneAbbr();
        return zonedDatetime.format("DD/MM/YYYY HH:mm") + ` (${zone?.includes("+") || zone?.includes("-") ? "UTC" + zone : zone})`;
      },
      grow: 2,
    },
    {
      name: !responseloader && allStages?.length !== selectedStageIndex ? "" : "Stages",
      sortable: true,
      hide: !responseloader && allStages?.length !== selectedStageIndex,
      cell: ({ stage }) => (
        <div>
          <span>{stage?.name}</span>
        </div>
      ),
    },
    {
      name: "Groups",
      sortable: true,
      cell: ({ groups }) => {
        return groups.length === 1 ? (
          <div>
            <span>{groups[0].name}</span>
          </div>
        ) : groups.length === 2 ? (
          <div>
            <span>{`${groups[0].name}, `}</span>
            <span>{groups[1].name}</span>
          </div>
        ) : (
          <div>
            <span>{`${groups[0].name}, `}</span>
            <span>{`${groups[1].name}, `}</span>
            <span>{groups[2].name}</span>
          </div>
        );
      },
    },
    { name: "Round / Week", sortable: true, cell: ({ round, week }) => (round && week ? `R: ${round} / W: ${week}` : "") },
    { name: "Best of", sortable: true, selector: "bestOf" },
    {
      name: "Team 1",
      sortable: true,
      cell: ({ t1, lolData, csgoData, tournament, isSeedingMatch, sp1 }) =>
        !t1 && !lolData && !csgoData ? (
          ""
        ) : (
          <div style={{ opacity: t1 && getLeagueTeamData(t1._id).kicked ? 0.25 : 1 }}>
            {t1 ? (
              <>
                {t1.name} {getLeagueTeamData(t1._id).kicked && "(Kicked)"}{" "}
                {((lolData && !!(lolData?.players?.t1 || []).length) || (csgoData && !!(csgoData?.players?.t1 || []).length)) && (
                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Players Set</Tooltip>}>
                    <FontAwesomeIcon className="text-success" icon={faCheckCircle} />
                  </OverlayTrigger>
                )}
              </>
            ) : tournament ? (
              "TBD"
            ) : isSeedingMatch ? (
              `Seed ${sp1.seedPosition}`
            ) : (
              "[DELETED]"
            )}
          </div>
        ),
    },
    {
      name: "Team 2",
      sortable: true,
      cell: ({ t2, lolData, csgoData, tournament, isSeedingMatch, sp2 }) =>
        !t2 && !lolData && !csgoData ? (
          ""
        ) : (
          <div style={{ opacity: t2 && getLeagueTeamData(t2._id).kicked ? 0.25 : 1 }}>
            {t2 ? (
              <>
                {t2.name} {getLeagueTeamData(t2._id).kicked && "(Kicked)"}{" "}
                {((lolData && !!(lolData?.players?.t2 || []).length) || (csgoData && !!(csgoData?.players?.t2 || []).length)) && (
                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Players Set</Tooltip>}>
                    <FontAwesomeIcon className="text-success" icon={faCheckCircle} />
                  </OverlayTrigger>
                )}
              </>
            ) : tournament ? (
              "TBD"
            ) : isSeedingMatch ? (
              `Seed ${sp2.seedPosition}`
            ) : (
              "[DELETED]"
            )}
          </div>
        ),
    },
    {
      name: "Score",

      cell: ({ t1Score, t2Score, t2, t1 }) => (
        <span>
          {t1Score === undefined ? (
            ""
          ) : getLeagueTeamData(t2?._id).kicked || getLeagueTeamData(t1?._id).kicked ? (
            <span style={{ color: "red" }}>{t1Score} -</span>
          ) : (
            <span>{t1Score} -</span>
          )}{" "}
          {t2Score === undefined ? (
            ""
          ) : getLeagueTeamData(t1?._id).kicked || getLeagueTeamData(t2?._id).kicked ? (
            <span style={{ color: "red" }}>{t2Score}</span>
          ) : (
            t2Score
          )}
        </span>
      ),
    },
    {
      name: (
        <div
          onClick={() => {
            if (count === 0) {
              setCount(1);
            } else if (count === 1) {
              setCount(2);
            } else if (count === 2) {
              setCount(3);
            } else if (count === 3) {
              setCount(0);
            }
          }}
        >
          {statuses[count] !== "" ? `Status (${statuses[count]})` : `Status (All)`}
        </div>
      ),
      cell: ({ status }) =>
        status === "complete" ? (
          <span className="text-success">Completed</span>
        ) : status === "inProgress" ? (
          <span className="text-warning">In Progress</span>
        ) : status === "upcoming" ? (
          <span className="text-secondary">Upcoming</span>
        ) : (
          ""
        ),
    },
    {
      right: true,
      width: "20%",
      cell: (filteredMatches) =>
        filteredMatches._id ? (
          <>
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">View Match</Tooltip>}>
              <a
                className="btn text-secondary"
                href={`${process.env.REACT_APP_LEAGUES_URL}/match/${filteredMatches._id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faEye} />
              </a>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Set Players</Tooltip>}>
              <Button
                variant="link"
                className="text-secondary"
                onClick={() => onSetPlayers(filteredMatches)}
                disabled={!user.state.admin && !user.state.permissions.user.matches}
              >
                <FontAwesomeIcon icon={faUsers} />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Set Score Manually</Tooltip>}>
              <Button
                variant="link"
                className="text-secondary"
                onClick={() => {
                  setStageTypeForModal(filteredMatches.tournament ? "Tournament" : "League");
                  onSetScore(filteredMatches);
                }}
                disabled={!user.state.admin && !user.state.permissions.user.matches}
              >
                <FontAwesomeIcon icon={faWrench} />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Edit Match</Tooltip>}>
              <Button
                variant="link"
                className="text-secondary"
                onClick={() => onEditMatch(filteredMatches)}
                disabled={!user.state.admin && !user.state.permissions.user.matches}
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip">{filteredMatches.isLockReschedule ? "Unlock" : "Lock"} rescheduling</Tooltip>}
            >
              <Button
                variant="link"
                className="text-secondary"
                style={{ opacity: filteredMatches.isLockReschedule ? 1 : 0.5 }}
                onClick={() => onReschedulingLock(filteredMatches)}
                disabled={filteredMatches.status === "complete"}
              >
                <FontAwesomeIcon icon={faLock} />
              </Button>
            </OverlayTrigger>
          </>
        ) : (
          ""
        ),
    },
    {
      right: true,
      cell: (filteredMatches) => {
        return filteredMatches._id ? (
          <>
            <div className="vertical-divider" />
            <div className="match-icons-grid">
              {game?.name.toLowerCase() === "league of legends" ? (
                generateCodeloaderSpinner !== filteredMatches._id ||
                (typeof generateCodeloaderSpinner === "boolean" && generateCodeloaderSpinner === false) ? (
                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Tournament Codes</Tooltip>}>
                    <Button
                      variant="link"
                      onClick={() => generateTournamentCodes(filteredMatches)}
                      className={
                        !user.state.admin && !user.state.permissions.user.matches ? "disabled-link text-secondary" : "text-secondary"
                      }
                    >
                      <GrCode className={filteredMatches.lolData?.tournamentCodes?.length ? "" : "faded"} />
                    </Button>
                  </OverlayTrigger>
                ) : (
                  <>
                    <Spinner animation="border" className="m-auto" size="sm" role="status"></Spinner>
                  </>
                )
              ) : (
                <></>
              )}
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Broadcasting</Tooltip>}>
                <Button
                  variant="link"
                  className={!user.state.admin && !user.state.permissions.user.matches ? "disabled-link text-secondary" : "text-secondary"}
                  onClick={() => editMatch(filteredMatches._id, { broadcasted: !filteredMatches.broadcasted })}
                >
                  <BsFillCameraVideoFill style={{ opacity: filteredMatches.broadcasted !== true ? 0.5 : 1 }} />
                </Button>
              </OverlayTrigger>
              {game.name.toLowerCase() === "league of legends" && (
                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Side Selection</Tooltip>}>
                  <Button
                    variant="link"
                    className={
                      !user.state.admin && !user.state.permissions.user.matches ? "disabled-link text-secondary" : "text-secondary"
                    }
                    onClick={() => editMatch(filteredMatches._id, { sideSelection: !filteredMatches.sideSelection })}
                  >
                    <BsSquareHalf style={{ opacity: filteredMatches.sideSelection !== true ? 0.5 : 1 }} />
                  </Button>
                </OverlayTrigger>
              )}
              <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Match Completion</Tooltip>}>
                <Button
                  variant="link"
                  className={!user.state.admin && !user.state.permissions.user.matches ? "disabled-link text-secondary" : "text-secondary"}
                  onClick={() =>
                    editMatch(filteredMatches._id, { status: filteredMatches.status === "upcoming" ? "complete" : "upcoming" })
                  }
                >
                  <FontAwesomeIcon icon={faCheck} className={filteredMatches.status === "upcoming" ? "faded" : ""} />
                </Button>
              </OverlayTrigger>
            </div>
          </>
        ) : null;
      },
    },
  ];

  const contextAction = useMemo(() => {
    const onDeleteMatches = async () => {
      const ids = selectedRows.map(({ _id }) => _id);
      if (window.confirm(`Are you sure you want to delete ${ids.length} matches?`)) {
        try {
          await Axios.post(`${process.env.REACT_APP_CORE_API}/api/matches/deleteMulti`, { ids });
          let allStagesCopy = [...allStages];
          allStagesCopy[selectedStageIndex].groups[selectedGroupIndex].matches = allStagesCopy[selectedStageIndex].groups[
            selectedGroupIndex
          ].matches.filter((m) => !ids.includes(m._id));
          setAllStagesData(allStagesCopy);
          updateMatches((matches) => matches.filter(({ _id }) => !ids.includes(_id)));
          setSelectedRows([]);
          setClearSelectedRows((prevState) => !prevState);
          fetchMatches();
          toast.success(`Successfully deleted ${ids.length} matches`);
        } catch (e) {
          toast.error("There was a problem deleting the selected matches");
        }
      }
    };

    const broadcastMutlipleMatches = async (broadcasted = false) => {
      try {
        const ids = selectedRows.map(({ _id }) => _id);
        const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/matches/broadcastMulti?broadcasted=${broadcasted}`, {
          ids,
        });
        updateMatches((matches) => matches.map((match) => (ids.includes(match._id.toString()) ? { ...match, broadcasted } : match)));
        setSelectedRows([]);
        setClearSelectedRows((prevState) => !prevState);
      } catch (error) {
        toast.error("Failed to broadcast Multiple Matches");
      }
    };
    return (
      <div>
        <Button size="sm" variant="danger" onClick={onDeleteMatches}>
          <FontAwesomeIcon icon={faTrashAlt} />
          &nbsp;Delete Selected
        </Button>
        <Button size="sm" variant="primary" className="ml-1" onClick={() => setShowMoveMatchModal(true)}>
          Move Match
        </Button>
        <Button size="sm" variant="primary" className="ml-1" onClick={() => broadcastMutlipleMatches(true)}>
          Broadcasted
        </Button>
        <Button size="sm" variant="primary" className="ml-1" onClick={() => broadcastMutlipleMatches(false)}>
          Not Broadcasted
        </Button>
      </div>
    );
  }, [selectedRows, updateMatches]);

  return (
    <div>
      <Form className="sub-tabs-form-matches">
        <Tabs
          id="controlled-tab-example"
          activeKey={selectedStageIndex}
          className="mb-3 sub-tabs"
          mountOnEnter={false}
          onSelect={(e) => {
            setStgType(allStages[e]);
            setSelectedStageIndex(parseInt(e));
            setSelectedGroupIndex(0);
            setIsAllGroup(false);
            if (parseInt(e) === allStages.length) {
              setIsAllStage(true);
            } else if (isAllStage) {
              setIsAllStage(false);
            }
          }}
        >
          {allStages?.length > 1 ? (
            <Tab key={allStages?.length} eventKey={allStages?.length} title={"All Stages"}>
              <>
                <MultiStageLeagueFilterMatches
                  setFilteredMatches={searchFilteredMatches}
                  stageIndex={-1}
                  groupIndex={-1}
                  allStageMatches={allStageMatches}
                />
                <div className="matches-header" style={{ paddingBottom: "1vh" }}>
                  <div className="matches-header__timezone-matches">
                    <span className="matches-header__title">Matches</span>
                    <div className="matches-header__timezone-dropdown">
                      <span className="matches-header__timezone-dropdown-title">Show in time zone:</span>
                      <select
                        className="form-control"
                        name="timezone"
                        value={prefferedTimezone}
                        onChange={(e) => {
                          setPrefferedTimezone(e.target.value);
                        }}
                      >
                        {TIMEZONES.map(({ name, value }, index) => (
                          <option value={value} key={index}>
                            {name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <Col lg={2} className="calendar_list_view">
                    <Button
                      block
                      variant="primary"
                      onClick={() => setShowCalendar(!showCalendar)}
                      disabled={!user.state.admin && !user.state.permissions.user.matches}
                    >
                      {!showCalendar ? "Calendar View" : "List View"}
                    </Button>
                  </Col>
                </div>
                {!showCalendar && (
                  <DataTable
                    title={selectedRows.length > 0 && <div></div>}
                    columns={columns}
                    data={!showCalendar ? allStagesTabs : ""}
                    selectableRows
                    contextActions={contextAction}
                    onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}
                    clearSelectedRows={clearSelectedRows}
                    pagination
                    paginationPerPage={100}
                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                  />
                )}
                {showCalendar ? (
                  <Calendar
                    selectedGroupIndex={selectedGroupIndex}
                    selectedStageIndex={selectedStageIndex}
                    isAllStage={isAllStage}
                    isAllGroup={isAllGroup}
                    key={prefferedTimezone}
                    matches={allStagesTabs}
                    prefferedTimezone={prefferedTimezone}
                    onEditMatch={onEditMatch}
                  />
                ) : null}
                {/* {!showCalendar && allStagesTabs?.length === 0 ? <MultiStageGenMatchesPromptCard stageIndex={ allStagesTabs?.length} /> : ""} */}
              </>
            </Tab>
          ) : null}
          {allStages.map((stage, stageIndex) => (
            <Tab key={stageIndex} eventKey={stageIndex} title={stage.stageName}>
              <Tabs
                id="controlled-tab-example"
                // defaultActiveKey={activeStageTab}
                activeKey={selectedGroupIndex}
                className="mb-3 sub-tabs"
                mountOnEnter={false}
                onSelect={(e) => {
                  setSelectedGroupIndex(e);
                  if (allStages[stageIndex].groups.length == e) {
                    setIsAllGroup(true);
                  } else if (isAllGroup) {
                    setIsAllGroup(false);
                  }
                }}
              >
                {stage?.groups?.length > 1 ? (
                  <Tab key={stage?.groups?.length} eventKey={stage?.groups?.length} title={"All Groups"}>
                    <Row>
                      <Col lg={2}>
                        {allStages[0].stageType === "league" ? (
                          <Button
                            block
                            variant="success"
                            onClick={() => onCreateMatch(stageIndex)}
                            disabled={!user.state.admin && !user.state.permissions.user.matches}
                          >
                            <FontAwesomeIcon icon={faPlus} /> Create
                          </Button>
                        ) : (
                          <Button block variant="secondary" disabled>
                            <FontAwesomeIcon icon={faPlus} /> Create
                          </Button>
                        )}
                      </Col>
                    </Row>
                    <div className="matches-header" style={{ paddingBottom: "1vh" }}>
                      <div className="matches-header__timezone-matches">
                        <span className="matches-header__title">Matches</span>
                        <div className="matches-header__timezone-dropdown">
                          <span className="matches-header__timezone-dropdown-title">Show in time zone:</span>
                          <select
                            className="form-control"
                            name="timezone"
                            value={prefferedTimezone}
                            onChange={(e) => {
                              setPrefferedTimezone(e.target.value);
                            }}
                          >
                            {TIMEZONES.map(({ name, value }, index) => (
                              <option value={value} key={index}>
                                {name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <Col lg={2} className="calendar_list_view">
                        <Button
                          block
                          variant="primary"
                          onClick={() => setShowCalendar(!showCalendar)}
                          disabled={!user.state.admin && !user.state.permissions.user.matches}
                        >
                          {!showCalendar ? "Calendar View" : "List View"}
                        </Button>
                      </Col>
                    </div>
                    {!showCalendar && (
                      <DataTable
                        title={selectedRows.length > 0 && <div></div>}
                        columns={columns}
                        data={!showCalendar ? allGroupsTabs : ""}
                        selectableRows
                        contextActions={contextAction}
                        onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}
                        clearSelectedRows={clearSelectedRows}
                        pagination
                        paginationPerPage={100}
                        paginationRowsPerPageOptions={[10, 25, 50, 100]}
                      />
                    )}
                    {showCalendar ? (
                      <Calendar
                        selectedGroupIndex={selectedGroupIndex}
                        selectedStageIndex={selectedStageIndex}
                        isAllStage={isAllStage}
                        isAllGroup={isAllGroup}
                        key={prefferedTimezone}
                        matches={allGroupsTabs}
                        prefferedTimezone={prefferedTimezone}
                        onEditMatch={onEditMatch}
                      />
                    ) : null}
                  </Tab>
                ) : null}

                {stage?.groups.map((group, groupIndex) => (
                  <Tab key={groupIndex} eventKey={groupIndex} title={group.groupName}>
                    <Row>
                      <Col lg={10}>
                        <MultiStageLeagueFilterMatches
                          setFilteredMatches={searchFilteredMatches}
                          stageIndex={stageIndex}
                          groupIndex={groupIndex}
                          allStageMatches={allStages}
                        />
                      </Col>
                      <Col lg={2}>
                        {stage.stageType === "league" ? (
                          <Button
                            block
                            variant="success"
                            onClick={() => onCreateMatch(stageIndex)}
                            disabled={!user.state.admin && !user.state.permissions.user.matches}
                          >
                            <FontAwesomeIcon icon={faPlus} /> Create
                          </Button>
                        ) : (
                          <Button block variant="secondary" disabled>
                            <FontAwesomeIcon icon={faPlus} /> Create
                          </Button>
                        )}
                      </Col>
                    </Row>
                    <>
                      <div className="matches-header" style={{ paddingBottom: "5vh" }}>
                        <div className="matches-header__timezone-matches">
                          <span className="matches-header__title">Matches</span>
                          <div className="matches-header__timezone-dropdown">
                            <span className="matches-header__timezone-dropdown-title">Show in time zone:</span>
                            <select
                              className="form-control"
                              name="timezone"
                              value={prefferedTimezone}
                              onChange={(e) => {
                                setPrefferedTimezone(e.target.value);
                              }}
                            >
                              {TIMEZONES.map(({ name, value }, index) => (
                                <option value={value} key={index}>
                                  {name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <Col lg={2} className="calendar_list_view">
                          <Button
                            block
                            variant="primary"
                            onClick={() => setShowCalendar(!showCalendar)}
                            disabled={!user.state.admin && !user.state.permissions.user.matches}
                          >
                            {!showCalendar ? "Calendar View" : "List View"}
                          </Button>
                        </Col>
                      </div>
                    </>
                    {!showCalendar && (
                      <DataTable
                        title={selectedRows.length > 0 && <div></div>}
                        columns={columns}
                        data={!showCalendar ? group?.matches : ""}
                        selectableRows
                        contextActions={contextAction}
                        onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}
                        clearSelectedRows={clearSelectedRows}
                        pagination
                        paginationPerPage={100}
                        paginationRowsPerPageOptions={[10, 25, 50, 100]}
                      />
                    )}
                    {/* {showCalendar ? <Calendar matches={group.matches} updateMatches={""} onEditMatch={onEditMatch()} /> : ""} */}
                    {showCalendar ? (
                      <Calendar
                        selectedGroupIndex={selectedGroupIndex}
                        selectedStageIndex={selectedStageIndex}
                        isAllStage={isAllStage}
                        isAllGroup={isAllGroup}
                        key={prefferedTimezone}
                        matches={group?.matches}
                        prefferedTimezone={prefferedTimezone}
                        onEditMatch={onEditMatch}
                      />
                    ) : null}
                    {!showCalendar && group?.matches?.length === 0 ? <MultiStageGenMatchesPromptCard stageIndex={stageIndex} /> : ""}
                  </Tab>
                ))}
              </Tabs>
            </Tab>
          ))}
        </Tabs>
      </Form>
      {console.log({ modalData })}
      {stgType && stgType?.stageType === "league" ? (
        <MultiStageLeagueCreateEditMatchModal
          show={showMatchModal}
          updateMatchRecords={fetchMatches}
          onHide={() => setShowMatchModal(false)}
          match={modalData}
          allStages={allStages}
          updateMatch={addUpdatedMatch}
          prefferedTimezone={prefferedTimezone}
          stageIndex={stgIndex}
          groupIndex={selectedGroupIndex}
        />
      ) : (
        <MultiStageTournamentCreateEditMatchModal
          show={showTournamentMatchModal}
          updateMatchRecords={fetchMatches}
          onHide={() => setShowTournamentMatchModal(false)}
          match={modalData}
          allStages={allStages}
          updateMatch={addUpdatedMatch}
          prefferedTimezone={prefferedTimezone}
          stageIndex={stgIndex}
        />
      )}
      {game.shortName === "CSGO" ? (
        <MatchScoreModalCSGO
          stageType={stageTypeForModal}
          updateMatchRecords={updateMatchRecords}
          show={showScoreModal}
          onHide={() => setShowScoreModal(false)}
          match={modalData}
          updateMatches={updateMatches}
          setLeague={setLeague}
          allStages={allStages}
          stageIndex={selectedStageIndex}
          groupIndex={selectedGroupIndex}
        />
      ) : (
        <MatchScoreModal
          stageType={stageTypeForModal}
          show={showScoreModal}
          updateMatchRecords={updateMatchRecords}
          onHide={() => setShowScoreModal(false)}
          match={modalData}
          codesLoading={generateCodeloaderSpinner}
          setGenerateCodeloaderSpinner={setGenerateCodeloaderSpinner}
          updateMatches={updateMatches}
          updateAllStageMatches= {updateAllStageMatches}
          setLeague={setLeague}
          user={user}
          allStages={allStages}
          stageIndex={selectedStageIndex}
          groupIndex={selectedGroupIndex}
        />
      )}

      {game?.shortName === "CSGO" && (
        <MatchVetoModalCSGO
          show={showEditVeto}
          onHide={() => setShowEditVeto(false)}
          match={vetoModalData}
          league={league}
          vetoInProgress={new Date(vetoModalData.datetime) < new Date() || vetoModalData.veto?.completed ? false : true}
        />
      )}

      <MatchSetPlayersModal
        stageType={stageTypeForModal}
        updateMatchRecords={fetchMatches}
        show={showSetPlayersModal}
        onHide={() => setShowSetPlayersModal(false)}
        match={modalData}
        updateMatches={updateMatches}
        league={league}
        flag={game?.shortName === "CSGO"}
        game={gameData}
      />

      <MoveMatchModal
        show={showMoveMatchModal}
        onHide={() => {
          setShowMoveMatchModal(false);
          setClearSelectedRows((prevState) => !prevState);
        }}
        selectedRows={selectedRows}
        updateMatches={updateMatches}
      />
    </div>
  );
};

export default Matches;
