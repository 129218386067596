import { SEEDING } from "../../../actionType";
import store from "../../../../store/index";
import Axios from "axios";
import { toast } from "react-toastify";

export const getSeedingData = (id) => {
  store.dispatch({ type: SEEDING.RESET });
  Axios.get(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/stage`)
    .then(({ data }) => {
      // console.log({data});
      console.log("get seedingData response", data);
      store.dispatch({
        type: SEEDING.LOAD_SUCCES,
        stageIndex: 0,
        payload: data.data,
      });
      store.dispatch({
        type: SEEDING.ALL_SUCCESS,
        payload: data.data,
      });
    })
    .catch((err) => {
      console.log("error in getSeedingData  ", err);
    });
};
export const setSeedingData = (id, stageId, _data, autoFillTeams, isAll, isLast) => {
  let data = {};
  data.groups = _data;
  if (autoFillTeams !== undefined) {
    data.autoFillTeams = autoFillTeams;
  }
  store.dispatch({ type: SEEDING.SET_SAVE_LOADING, payload: true });
  Axios.post(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/stage/${stageId}/group/seeding/`, {
    ...data,
  })
    .then(({ data }) => {
      console.log("response", data);
      store.dispatch({ type: SEEDING.SET_SAVE_LOADING, payload: false });
      if (data.data) {
        store.dispatch({ type: "SEEDING_SAVED_UPDATE_GROUPS", payload: { stageId: stageId, data: data.data } });
      }
      if (isAll) {
        if (isLast) {
          toast.success("Seeding saved successfully");
        }
      } else {
        toast.success("Seeding saved successfully");
      }

      // store.dispatch({
      //   type: SEEDING.SAVE_SUCCESS,
      //   payload: data?.data,
      // });
    })
    .catch((err) => {
      console.log("error in setSeedingData  ", err);
      store.dispatch({ type: SEEDING.SET_SAVE_LOADING, payload: false });
      toast.error("Cannot update seeding, please retry");

    });
};

export const setStageLeaderboardData = (id, stageId, key) => {
  Axios.post(`${process.env.REACT_APP_CORE_API}/api/multiStageLeague/${id}/stage/${stageId}/stage/eligibleSeedingTeams/`)
    .then(({ data }) => {
      console.log("response", data);
      store.dispatch({
        type: SEEDING.ELIGIBLE,
        payload: data?.stage,
        stageIndex: key,
      });
    })
    .catch((err) => {
      console.log("error in setSeedingData  ", err);
    });
};

export const resetSeedingUpdate = () => {
  store.dispatch({ type: SEEDING.UPDATE_STAGE_SEEDING_RESET });
};
