import React, { useState, useContext } from "react";
import { Card, Nav } from "react-bootstrap";
import Users from "./Users";
import Players from "./Players";
import Coaches from "./Coaches";
import Organisations from "./Organisations";
import Teams from "./Teams";
import { AppContext } from "../../../context/AppContext";

const TabsManager = () => {
  const { user } = useContext(AppContext);
  const [activeTab, setActiveTab] = useState("Users");

  const TABS = [
    {
      name: "Users",
      content: <Users />,
      disabled: !user.state.admin && !user.state.permissions?.user?.entityData ? "disabled-link" : ""
    },
    {
      name: "Players",
      content: <Players />,
      disabled: !user.state.admin && !user.state.permissions?.user?.entityData ? "disabled-link" : ""
    },
    {
      name: "Coaches",
      content: <Coaches />,
      disabled: !user.state.admin && !user.state.permissions?.user?.entityData ? "disabled-link" : ""
    },
    {
      name: "Teams",
      content: <Teams />,
      disabled: !user.state.admin && !user.state.permissions?.user?.entityData ? "disabled-link" : ""
    },
    {
      name: "Organisations",
      content: <Organisations />,
      disabled: !user.state.admin && !user.state.permissions?.user?.entityData ? "disabled-link" : ""
    },
  ];

  return (
    <>
      <Card>
        <Card.Header>
          <Nav variant="tabs" activeKey={activeTab} onSelect={(t) => setActiveTab(t)}>
            {TABS.map(({ name, disabled }) => (
              <Nav.Item key={name}>
                <Nav.Link eventKey={name} active={activeTab === name} className={disabled}>
                  {name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Card.Header>
        <Card.Body>
          {TABS.map(({ name, content }) => name === activeTab && <div key={name}>{content}</div>)}
        </Card.Body>
      </Card>
    </>
  );
};

export default TabsManager;
