import React, { useContext, useState } from "react";
import { Card, Button, Row, Col, Form } from "react-bootstrap";
import { AppContext } from "../../context/AppContext";
import Axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const Login = () => {
  const { setAuthenticated } = useContext(AppContext);
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);

  const login = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { data } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/auth/login`, {
        email,
        password,
        rememberMe,
        admin: true,
      });

      setLoading(false);
      localStorage.setItem("sessionToken", data);
      setAuthenticated(true);
      history.push("/");
    } catch (e) {
      setLoading(false);
      const msg = e.response && e.response.data ? e.response.data.msg : "Fatal error";
      toast.error(msg);
    }
  };

  return (
    <Row>
      <Col xs={{ span: 6, offset: 3 }}>
        <Card>
          <Card.Header as="h5">Login</Card.Header>
          <Card.Body>
            <Form onSubmit={login}>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} autoFocus />
              </Form.Group>

              <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>

              <Form.Group>
                <Form.Check
                  type="checkbox"
                  id="rememberMe"
                  label="Remember me"
                  value={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
              </Form.Group>

              <Button variant="primary" type="submit" disabled={loading}>
                {loading ? "Logging in" : "Log in"}
              </Button>
            </Form>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Login;
