import React from "react";
import Reorder, { reorder } from "react-reorder";
import { Modal, Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripHorizontal } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useEffect } from "react";
import Axios from "axios";
import { toast } from "react-toastify";

const TournamentsReorderModal = ({ show, onHide, leagues, setLeagues }) => {
  const [orderedLeagues, setOrderedLeagues] = useState([]);

  useEffect(() => {
    setOrderedLeagues(leagues);
  }, [leagues]);

  const onReorder = (e, previousIndex, nextIndex, fromId, toId) => {
    setOrderedLeagues((os) => reorder(os, previousIndex, nextIndex));
  };

  const onSave = async () => {
    const newOrder = orderedLeagues.map(({ _id }, i) => ({ _id, order: i }));
    const newLeagues = orderedLeagues.map((season, i) => ({ ...season, order: i }));

    try {
      await Axios.post(`${process.env.REACT_APP_CORE_API}/api/tournaments/setOrder`, { newOrder });
      setLeagues(() => newLeagues);
      toast.success("Successfully set new order");
    } catch (e) {
      toast.error("Error setting order");
    }

    onHide();
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>Reorder Leagues</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!leagues.length && "No leagues"}

        <Reorder
          reorderId="leagues" // Unique ID that is used internally to track this list (required)
          className="reorder-list"
          holdTime={0} // Default hold time before dragging begins (mouse & touch) (optional), defaults to 0
          onReorder={onReorder} // Callback when an item is dropped (you will need this to update your state)
          placeholder={
            <Card bg="light" body>
              &nbsp;
            </Card> // Custom placeholder element (optional), defaults to clone of dragged element
          }
        >
          {orderedLeagues.map((item) => (
            <Card key={item.name} body>
              <div className="d-flex justify-content-between align-items-center">
                <span>{item.name}</span>
                <FontAwesomeIcon icon={faGripHorizontal} className="text-secondary" />
              </div>
            </Card>
          ))}
        </Reorder>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={onSave}>
          Save Order
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TournamentsReorderModal;
