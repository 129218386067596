import React, { useState, useEffect, createRef } from "react";
import Axios from "axios";
import { Modal, Form, Button, Tooltip, OverlayTrigger, FormGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditImageModal from "../../../../../../_modals/EditImageModal/EditImageModal";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
const SingleSegmentModule = ({updateComponent,modalData,segData}) => {
    
   const [gameData,setGameData]=useState(modalData.pageData && modalData.pageData.segmentData || {});
const [imageModal,setImageModal] = useState(false);

const updateImage = (key,value) => {
    setImageModal(false);
    update(key,value);
}
    const update = (key,value) => {
        let data = {...gameData,[key]:value};
        setGameData(data)
        updateComponent(data)
    }


 
    return (
    <>
    
       <Form>
       
        <FormGroup>
            <Form.Label>Sub Heading</Form.Label>
            <Form.Control type="text" id="SubHeading" name="SubHeading" value={gameData ? gameData.SubHeading : null} onChange={(e) => update('SubHeading',e.target.value)} />
        </FormGroup>

        <FormGroup>
            <Form.Label>Heading</Form.Label>
            <Form.Control type="text" id="Heading" name="Heading" value={gameData ? gameData.Heading : null} onChange={(e) => update('Heading',e.target.value)} />
        </FormGroup>

        <FormGroup>
            <Form.Label>Description</Form.Label>
            <Form.Control as="textarea" id="Description" name="Description" value={gameData ? gameData.Description : null} onChange={(e) => update('Description',e.target.value)} />
        </FormGroup>

        <div>
          <div className="d-flex">
            {/* Add new images to the array */}
            {[
              { name: "Thumbnail", key: "thumbnailImage", width: 640, height: 360, displayWidth: 320, displayHeight: 180, crop: true }
            ].map(({ name, key, width, height, displayWidth, displayHeight, crop }) => (
              <>
                <EditImageModal
                  show={imageModal}
                  onHide={() => setImageModal(false)}
                  name={name}
                  width={width}
                  height={height}
                  afterImageUpload={(file) => updateImage('image', file)}
                  crop={crop}
                />

                <Form.Group className="mr-4">
                  <Form.Label>{name}</Form.Label>
                  <div
                    className="edit-image-btn"
                    style={{
                      backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${gameData ? gameData.image : null})`,
                      width: displayWidth,
                      height: displayHeight,
                    }}
                    onClick={() => setImageModal(true)}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </div>
                </Form.Group>
              </>
            ))}
          </div>
        </div>

            
              
            </Form>
    </>)
}

export default SingleSegmentModule;

