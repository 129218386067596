import React, { useState, useEffect } from "react";
import { Modal, Form, FormGroup, Button, Nav, Card } from "react-bootstrap";
import Axios from "axios";
import SystemOutcomes from "./SystemOutcomes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";

const maximumBestOf = 10;

const SystemCreateEditModal = ({ show, handleClose, data = {}, setScoreSystems }) => {
  const [name, setName] = useState("");
  const [configuration, setConfiguration] = useState([{ bestOf: 1, outcomes: [] }]); // default tab
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (data) {
      setName(data.name);
      setConfiguration(data.configuration);
    }
  }, [data]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (data) {
      // Edit
      const { data: resData } = await Axios.put(`${process.env.REACT_APP_CORE_API}/api/scoreSystems/${data._id}`, {
        name,
        configuration,
      });

      // update score systems in parent state
      setScoreSystems((scoreSystems) => scoreSystems.map((system) => (system._id === data._id ? resData.updatedSystem : system)));
    } else {
      // Create
      const { data: resData } = await Axios.post(`${process.env.REACT_APP_CORE_API}/api/scoreSystems`, { name, configuration });

      // Update parent state
      setScoreSystems((scoreSystems) => [...scoreSystems, resData.scoreSystem]);
    }

    setLoading(false);
    handleClose();
  };

  const isBestOfInUse = (bestOf) => {
    for (let config of configuration) {
      if (config.bestOf === bestOf) {
        return true;
      }
    }

    return false;
  };

  const addConfiguration = () => {
    let bestOf = 1;
    // Check not already in use
    while (isBestOfInUse(bestOf)) bestOf++;

    setConfiguration((config) => [...config, { bestOf, outcomes: [] }]);
    setActiveTab(configuration.length);
  };

  const removeConfiguration = (e, atIndex) => {
    e.stopPropagation(); // stop default tab click behaviour
    setConfiguration((configuration) => configuration.filter((_, i) => i !== atIndex));
    setActiveTab(0);
  };

  const updateConfiguration = (data, atIndex) => {
    setConfiguration((configuration) => configuration.map((config, i) => (i === atIndex ? data : config)));
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{data ? "Edit" : "Create"} Score System</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={onSubmit}>
          <FormGroup>
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" id="name" name="name" value={name} onChange={(e) => setName(e.target.value)} />
          </FormGroup>

          <FormGroup>
            <Form.Label className="d-flex justify-content-between">
              <span>Configuration</span>

              {configuration.length < maximumBestOf && (
                <Button variant="link" size="sm" className="p-0" onClick={addConfiguration}>
                  <FontAwesomeIcon icon={faPlus} /> Add Best Of
                </Button>
              )}
            </Form.Label>

            <Card>
              <Card.Header>
                <Nav variant="tabs" activeKey={activeTab} onSelect={(t) => setActiveTab(parseFloat(t))}>
                  {configuration.map(({ bestOf }, i) => (
                    <Nav.Item key={i}>
                      <Nav.Link eventKey={i} active={activeTab === i}>
                        Best Of {bestOf}
                        {/* Only allow deleting tab if there is more than one */}
                        {configuration.length > 1 && (
                          <Button variant="link" className="text-danger p-0 ml-2" size="sm" onClick={(e) => removeConfiguration(e, i)}>
                            <FontAwesomeIcon icon={faTimes} />
                          </Button>
                        )}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Card.Header>
              <Card.Body>
                {configuration.map((config, i) => {
                  if (activeTab === i) {
                    return (
                      <SystemOutcomes
                        key={i}
                        {...config}
                        updateConfig={(data) => updateConfiguration(data, i)}
                        existingBestOfs={configuration.map(({ bestOf }, j) => i !== j && bestOf)}
                        maximumBestOf={maximumBestOf}
                      />
                    );
                  }

                  return false;
                })}
              </Card.Body>
            </Card>
          </FormGroup>

          <div className="d-flex justify-content-end">
            <Button type="submit" variant="success" disabled={loading}>
              {loading ? "Processing..." : data ? "Edit" : "Create"}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default SystemCreateEditModal;
