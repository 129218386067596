import React, { useState } from "react";
import Axios from "axios";
const DeleteLangApi = async (langid) => {
  try {
    const resp = await Axios({
      method: "delete",
      url: `${process.env.REACT_APP_CORE_API}/api/language/deleteLanguage/${langid}`
    });

    return resp.data;
  } catch (err) {
    return err;
  }
};
export default DeleteLangApi;
