import React from 'react';
import { Modal, Card, Row, Col } from "react-bootstrap";
import moment from 'moment';
import ReactJson from 'react-json-view'

const ShowViewModel = ({ show, onHide, data }) => {
  const { type, priority, createdAt, gameName, user, error, request } = data;

  return (
    <Modal 
      size="lg"
      show={show} 
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{type}</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ padding: '1rem' }}>
        <Card style={{ borderRadius: 0, border: 0, margin: 0 }}>
          <Row>
            <Col>
              <p>
                <b>Date: </b> {moment(createdAt).format("DD/MM/YYYY HH:mm")}
              </p>
            </Col>
            <Col>
              <p>
                <b>Priority: </b> <span className={priority}>{priority}</span>
              </p>
            </Col>
            <Col>
              <p>
                <b>Game: </b> {gameName}
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                <b>Type: </b> {type}
              </p>
            </Col>
            <Col>
              <p>
                <b>User: </b> {user?.firstName} {user?.lastName}
              </p>
            </Col>
          </Row>  
          <Row>
            <Col>
              <p className="mb-2"><b>Error</b></p>
              <pre className="error-box px-2">
                {error}
              </pre>
            </Col>
          </Row>
        </Card>
        <Row>
          <Col>
            <p className="mb-2"><b>Request</b></p>
            {/* <p className="error-box px-2 bg-dark border"> */}
            <ReactJson 
              style={{ overflowY: 'auto', height: 300 }}
              src={request} 
              theme={"ocean"} 
              collapseStringsAfterLength={75}
            />
            {/* </p> */}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
 
export default ShowViewModel;