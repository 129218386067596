import React, { useEffect, useState } from 'react';
import { makeCountdownString } from "../../../../../../../utils/functions";
import moment from 'moment';


const TimeCounter = ({ datetime }) => {
    const [countDown, setCountDown] = useState("");
    useEffect(() => {
        if (datetime) {
            const _interval = setInterval(() => {
                const now = moment();
                const _datetime = moment(datetime);
                var duration = now.diff(_datetime);
                if (duration > 0)
                    setCountDown(makeCountdownString(duration));
                else {
                    clearInterval(_interval);
                }
            }, 1000);
        }
    }, []);
    return (
        <span>
            {countDown}
        </span>
    );
}

export default TimeCounter
