import React, { useState, useEffect, createRef } from "react";
import { Modal, Form, Button,Col, Tooltip, OverlayTrigger } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy,faUpload,faSwatchbook, faDownload, faKey, faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import ReactJson from 'react-json-view';
import JSONInput from "react-json-editor-ajrm/index";
import locale from "react-json-editor-ajrm/locale/en";

const Module = ({show, onHide, mapId=null, currentmapData=null, setMapData, teams=null}) => {

    const [data,setData] = useState(currentmapData);
    const [goodData,setGoodData] = useState(false);
    const [loading,setLoading] = useState(false);
    const { register, handleSubmit, errors, setValue, watch } = useForm();
    const [winnerTeamSelected,setWinnerTeamSelected] = useState(null);

    const setMapJsonData = (dataObj) => {
      setLoading(true);
        if(dataObj === undefined){
            setData(null);
        
            setGoodData(false);

            setLoading(false);
        }else{
            setData(dataObj);
        
            setGoodData(true);

            setLoading(false);
        }
        
    }

    const getButton = () => {
      let retu = {'text':"Waiting for data...", 'disabled':true};
      if(!goodData){
        return retu;
      }else if(winnerTeamSelected === null){
        return {'text':"Select winner team..", 'disabled':true};
      }else if(goodData && winnerTeamSelected){
        return {'text':"Upload Data", 'disabled':false};
      }else{
        return retu;
      }
    }
    return (
        <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Paste Map Data</Modal.Title>
      </Modal.Header>
      {loading ? "Loading..." : <>
      <Modal.Body>
        <JSONInput
          placeholder={currentmapData} // data to display
          theme="light_mitsuketa_tribute"
          locale={locale}
          onChange={(data) => setMapJsonData(data.jsObject)}
          waitAfterKeyPress={5000}
          colors={{
            string: "#DAA520" // overrides theme colors with whatever color value you want
          }}
          height="450px"
        />
        </Modal.Body>
          <Modal.Footer>
            {goodData && <Col>
                <Form.Group>
                <Form.Label>Select Winner Team :</Form.Label>
                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                    {teams.t1 && <Form.Check
                      ref={register({ required: "Required" })}
                      type="radio"
                      id="winnerTeam1"
                      name="winnerTeam"
                      label={teams.t1.name}
                      isInvalid={errors.winnerTeam}
                      value={teams.t1._id}
                      onChange={(evt) => setWinnerTeamSelected(evt.target.value)}
                      // defaultChecked={game.activateScrims === true}
                    />}
                    {teams.t2 && <Form.Check
                      ref={register({ required: "Required" })}
                      type="radio"
                      id="winnerTeam2"
                      name="winnerTeam"
                      label={teams.t2.name}
                      isInvalid={errors.winnerTeam}
                      value={teams.t2._id}
                      onChange={(evt) => setWinnerTeamSelected(evt.target.value)}
                      // defaultChecked={game.activateScrims === false}
                    />}
                </div>
                <Form.Control.Feedback type="invalid">{errors.activateScrims && errors.activateScrims.message}</Form.Control.Feedback>
                </Form.Group>
            </Col>}
             
            <Button onClick={() => setMapData(data,mapId,winnerTeamSelected)} variant="success" className="d-block " disabled={getButton()['disabled']}>
              
              <FontAwesomeIcon icon={faUpload} /> {getButton()['text']}
            </Button>

              
            </Modal.Footer>
            </>
      }
        </Modal>
    )
}

export default Module;