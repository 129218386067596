import React, { useState, useEffect } from "react";
import { Breadcrumb, Row, Col, Card, Button } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faMagic } from "@fortawesome/free-solid-svg-icons";
import TabsManager from "./Tabs/TabsManager";

const Scrims = () => {
  const { id } = useParams();
  const [scrims, setScrims] = useState({});
  const [loading, setLoading] = useState(true);
  const [gameData, setGameData] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [query, setQuery] = useState('');
  const [totalRows, setTotalRows] = useState(0);
  const [dataLoading, setDataLoading] = useState(false);


  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/games/${id}`).then(({ data }) => {
      setGameData(data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    getData();
  }, [rowsPerPage, query])

  const getData = async (page = 1) => {
    setDataLoading(true)
    const _query = {
      limit: rowsPerPage,
      offset: (page-1)*rowsPerPage,
      search: query
    }
    const quertStr = new URLSearchParams(_query).toString()
    const { data } = await Axios.get(`${process.env.REACT_APP_CORE_API}/api/scrims/all?gameId=${id}&${quertStr}`)
    if (data) {
      setScrims(data.list);
      setTotalRows(data.total);
      setLoading(false);
    }

    setLoading(false);
    setDataLoading(false);
  }

  const handlePerPageChange = async (newPerPage, page) => {
    try {
      setRowsPerPage(newPerPage);
    } catch (error) {
      setLoading(false);
      console.log("[LOG_DATA_FETCH_ERROR]", error);
    }
  }

  return (
    <div>
      <Breadcrumb>
        {!loading && gameData ? (
          <>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/scrim-games" }}>
              {gameData.name}
            </Breadcrumb.Item>
            <Breadcrumb.Item>
             Scrims
            </Breadcrumb.Item>
          </>
        ) : (
          <Breadcrumb.Item active>Loading...</Breadcrumb.Item>
        )}
      </Breadcrumb>
      {scrims && <>
        <div className="admin-page-title">
          <div>
            <h2>Scrims</h2>
          </div>
        </div>

        <Row>
        {/* LEFT/main column */}
        <Col xl="12">

          {loading ? "Loading..." : <TabsManager scrims={scrims} loading={dataLoading} setQuery={setQuery} setScrims={setScrims} totalRows={totalRows} game={gameData} getData={getData} handlePerPageChange={handlePerPageChange}/>}
        </Col>
      </Row>
      </>}
    </div>
  );
};

export default Scrims;
