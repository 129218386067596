import React, { useState, useEffect } from "react";
import Axios from "axios";
import moment from "moment";
import DataTable from "react-data-table-component";
import { Col, Row, Form } from "react-bootstrap";
import { Button, Alert, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPencilAlt,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import FilterTeams from "../Tabs/includes/FilterTeams"
import "../Tabs/_table.scss"
import { Link } from "react-router-dom";

const Teams = () => {
  const [game, setGame] = useState("All");
  const [selectedRows, setSelectedRows] = useState([]);
  const [teams, setTeams] = useState([]);
  const [filteredTeams, setFilteredTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [query, setQuery] = useState({});
  const [allGame, setAllGame] = useState([]);

  const columns = [
    {
      name: "Date Created",
      cell: ({ createdAt }) => moment(createdAt).format("DD/MM/YYYY HH:mm"),
      maxWidth: "160px",
    },
    {
      name: "Team Name",
      cell: ({ name }) => name,
      maxWidth: "150px",
    },
    {
      name: "Initials",
      cell: ({ initials }) => initials,
      maxWidth: "70px",
    },
    {
      name: "Nationality",
      cell: ({ nationality }) => nationality && nationality.name,
      maxWidth: "130px",
    },
    {
      name: "Organisation name",
      cell: ({ organisation }) => organisation && organisation.name,
      maxWidth: "190px",
    },
    {
      name: "Twitter",
      cell: ({ twitter }) => twitter,
      maxWidth: "250px",
    },
    {
      name: "Game",
      cell: ({ game }) => game && game.name,
    },
    {
      right: true,
      maxWidth: "200px",
      name: <div style={{ fontWeight: "bold", fontSize: 15 }}>{filteredTeams.length ? filteredTeams.length : totalRows} Teams</div>,
      cell: (teams) => (
        teams._id ? (
          <>
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Delete</Tooltip>}>
              <Button variant="link" className="btn text-secondary" onClick={() => onRemoveTeam(teams._id)}>
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">View</Tooltip>}>

              <a
                className="btn text-secondary"
                href={`${process.env.REACT_APP_LEAGUES_URL}/teams/${teams._id}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ padding: "8px" }}
              >
                <FontAwesomeIcon icon={faEye} />
              </a>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">Edit</Tooltip>}>
              <Button variant="link" className="text-secondary" style={{ padding: "8px" }} as={Link} to={`/entities/${teams._id}/team-individual`}>
                <FontAwesomeIcon icon={faPencilAlt} />
              </Button>
            </OverlayTrigger>
          </>
        ) : ("")
      ),
    },
  ];


  useEffect(() => {
    getData();
    fetchGames();
  }, [rowsPerPage, game])

  const fetchGames = () => {
    Axios.get(`${process.env.REACT_APP_CORE_API}/api/games`).then(({ data }) => {
      setAllGame(() => {
        return data.filter((game) => game.name !== 'No Game');
      });
    });
  }

  const getData = async (page = 1) => {
    const _query = {
      limit: rowsPerPage,
      offset: (page - 1) * rowsPerPage,
      selectedGameId: game,
      ...query
    }
    const quertStr = new URLSearchParams(_query).toString()
    setLoading(true);
    const { data } = await Axios.get(`${process.env.REACT_APP_CORE_API}/api/teams/all?${quertStr}`);
    if (data) {
      setTeams(data.teams);
      setTotalRows(data.total);
    }
    setLoading(false);
  }

  const handlePerPageChange = async (newPerPage) => {
    try {
      setLoading(true);
      setRowsPerPage(newPerPage);
    } catch (e) {
      setLoading(false);

      const msg = e.response.data ? e.response.data.msg : "Error!";
      toast.error(msg);
    }
  }

  const onRemoveTeam = async (id) => {
    if (window.confirm("Are you sure you want to delete this Team? This action is irreversible!")) {
      await Axios.delete(`${process.env.REACT_APP_CORE_API}/api/teams/deleteTeam/${id}`);
      setTeams((team) => team.filter(({ _id }) => _id !== id));
      setFilteredTeams([]);
      setTotalRows(totalRows - 1);

      toast.success("Successfully deleted");
    }
  };

  const onDeleteMultiTeams = async () => {
    const ids = selectedRows.map(({ _id }) => _id);

    if (window.confirm(`Are you sure you want to delete ${ids.length} teams?`)) {
      try {
        await Axios.post(`${process.env.REACT_APP_CORE_API}/api/teams/deleteMulti`, { ids });
        setTeams((teams) => teams.filter(({ _id }) => !ids.includes(_id)));
        setTotalRows(totalRows - selectedRows.length);
        setSelectedRows([]);
        setFilteredTeams([]);

        toast.success(`Successfully deleted ${ids.length} teams`);
      } catch (e) {
        toast.error("There was a problem deleting the selected teams");
      }
    }
  };

  return (
    <div>
      <Row>
        <Col lg={9}>
          <FilterTeams setFilteredTeams={setFilteredTeams} setLoading={setLoading} />
        </Col>
        <Col lg={3}>
          <Col>
            <Form>
              <div className="d-flex align-items-center">
                <Form.Control
                  as="select"
                  id="game"
                  name="game"
                  defaultValue={game}
                  onChange={(e) => setGame(e.target.value)}
                >
                  <option value="All">All Games</option>
                  {allGame.map(({ _id, name }) => (
                    <option key={_id} value={_id}>
                      {name}
                    </option>
                  ))}
                </Form.Control>
              </div>
            </Form>
          </Col>
        </Col>
      </Row>
      {
        !!selectedRows.length && (
          <Alert key="table-actions" variant="secondary">
            <div className="d-flex justify-content-between align-items-center">
              <div>{selectedRows.length} rows selected</div>
              <div>
                <Button size="sm" variant="danger" onClick={onDeleteMultiTeams}>
                  <FontAwesomeIcon icon={faTrashAlt} />
                  &nbsp;Delete Selected
                </Button>
              </div>
            </div>
          </Alert>
        )
      }

      <DataTable
        progressPending={loading}
        paginationRowsPerPageOptions={[50, 100, 200]}
        paginationPerPage={rowsPerPage}
        paginationTotalRows={filteredTeams.length ? filteredTeams.length : totalRows}
        pagination={true}
        paginationServer={true}
        onChangeRowsPerPage={handlePerPageChange}
        onChangePage={getData}
        noHeader={true}
        columns={columns}
        data={filteredTeams.length ? filteredTeams : teams}
        selectableRows={true}
        selectableRowsHighlight={true}
        onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}
        style={{ overflow: "inherit" }}
        fixedHeader={true}
        persistTableHead={true}
        selectedRows={selectedRows}
      />
    </div >
  );
};

export default Teams;
