import React from "react";
import Axios from "axios";
const CreateLangApi = async (data) => {
  try {
    const resp = await Axios({
      method: "post",
      url: `${process.env.REACT_APP_CORE_API}/api/language/newLangugage`,
      data: data,
    });
    console.log("res =>", resp.data)
    
    return resp.data;
  } catch (err) {
    return err;
  }
};
export default CreateLangApi;
