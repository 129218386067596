import React, { useState } from "react";
import { Card, Nav } from "react-bootstrap";
import General from "./General";
import PlayerRanksAndTeams from "./PlayerRanksAndTeams";
import Accessibility from "./Accessibility";

const TabsManager = ({ game, setTempraryData, isSaved, setGame }) => {
  const [activeTab, setActiveTab] = useState("General");


  // ADD/REMOVE TABS THROUGH THIS ARRAY
  const TABS = [
    {
      name: "General",
      content: <General game={game} />,
    },
    {
      name: "Players, Teams and Ranks",
      content: <PlayerRanksAndTeams game={game} setGame={setGame} />,
    },
    {
      name: "Accessibility",
      content: <Accessibility game={game} setGame={setGame} />
      // content: <SegmentManager pageType='ProUser' data={frontPage} setTempraryData={() => setTempraryData()} isSaved={isSaved}  />,
    }
  ];

  return (
    <>
      <Card>
        <Card.Header>
          <Nav variant="tabs" activeKey={activeTab} onSelect={(t) => setActiveTab(t)}>
            {TABS.map(({ name }) => (
              <Nav.Item key={name}>
                <Nav.Link eventKey={name} active={activeTab === name}>
                  {name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Card.Header>
        <Card.Body>
          {TABS.map(({ name, content }) => name === activeTab && <div key={name}>{content}</div>)}
        </Card.Body>
      </Card>
    </>
  );
};

export default TabsManager;
